const followingModalShowFollowersReducer = (followingModalShowFollowers=false, action) => {
    switch(action.type){
      case 'FOLLOWING_MODAL_SHOW_FOLLOWERS_SET':
        return action.payload;
        case 'CLEAR_ALL':
                return false;
      default:
        return followingModalShowFollowers;
    };
  };
  
  export default followingModalShowFollowersReducer;
