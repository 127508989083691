import React from 'react';

import spinner from '../assets/spinner.png';
import BaeSvg from './BaeSvg';

const AstheticButton = ({
    iconName = '', 
    color = '',
    label = '',
    style = {},
    callback = () => {}
}) => {
    return (
        <button 
        className={'astheticButton' + ' ' + color}
        style={style}
        onClick={callback}
        >
            <BaeSvg iconName={iconName} color='base' height='26px' />
            <span style={{fontWeight: 'bold', fontSize: '1.4em', marginLeft: '60px'}}>{label}</span>
        </button>
    );
};

export default AstheticButton;