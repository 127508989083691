import { combineReducers } from "redux";

import activeChatFilterReducer from "./activeChatFilter";
import authenticatedReducer from './authenticated';
import chatMemberIdsReducer from './chatMemberIds';
import chatsEventListenerReducer from './chatsEventListener';
import darkModeEnabledReducer from "./darkModeEnabled";
import followingModalShowFollowersReducer from './followingModalShowFollowers';
import gameInvitationReducer from "./gameInvitation";
import myAuthenticationReducer from './myAuthentication';
import myChatReducer from './myChat';
import myCompaniesReducer from "./myCompanies";
import myOpenChatsReducer from "./myOpenChats";
import mediaPostsReducer from "./mediaPosts";
import mediaScrollPositionReducer from "./mediaScrollPosition";
import memberModalReducer from './memberModal';
import membersTypingReducer from './membersTyping';
import membersTypingOpenChatReducer from "./membersTypingOpenChat";
import myActivityReducer from "./myActivity";
import myDoorbellReducer from "./myDoorbell";
import myEventsReducer from "./myEvents";
import myInfoReducer from './myInfo';
import myIdReducer from './myId';
import myMatcherReducer from "./myMatcher";
import myMediaReducer from './myMedia';
import mySearchReducer from './mySearch';
import myWalletReducer from "./myWallet";
import my_idReducer from './my_id';
import nationalIdReducer from "./nationalId";
import registrationEmailReducer from "./registrationEmail";
import searchFilterDataReducer from "./searchFilterData";
import showNotificationBarReducer from "./showNotificationBar";
import ssReducer from './ss';
import topBarOffsetReducer from "./topBarOffset";
import transactionsListReducer from "./transactionsList";
import urgentCallReducer from "./urgentCall";
import visitChatFromMessageReducer from "./visitChatFromMessage";
import visitMemberReducer from "./visitMember";

export default combineReducers({
    activeChatFilter: activeChatFilterReducer,
    authenticated: authenticatedReducer,
    chatMemberIds: chatMemberIdsReducer,
    chatsEventListener: chatsEventListenerReducer,
    darkModeEnabled: darkModeEnabledReducer,
    followingModalShowFollowers: followingModalShowFollowersReducer,
    gameInvitation: gameInvitationReducer,
    mediaPosts: mediaPostsReducer,
    mediaScrollPosition: mediaScrollPositionReducer,
    myActivity: myActivityReducer,
    myAuthentication: myAuthenticationReducer,
    myDoorbell: myDoorbellReducer,
    myEvents: myEventsReducer,
    myChat: myChatReducer,
    myCompanies: myCompaniesReducer,
    myMatcher: myMatcherReducer,
    myOpenChats: myOpenChatsReducer,
    memberModal: memberModalReducer,
    membersTyping: membersTypingReducer,
    membersTypingOpenChat: membersTypingOpenChatReducer,
    myInfo: myInfoReducer,
    myId: myIdReducer,
    myMedia: myMediaReducer,
    mySearch: mySearchReducer,
    myWallet: myWalletReducer,
    my_id: my_idReducer,
    nationalId: nationalIdReducer,
    registrationEmail: registrationEmailReducer,
    searchFilterData: searchFilterDataReducer,
    showNotificationBar: showNotificationBarReducer,
    ss: ssReducer,
    topBarOffset: topBarOffsetReducer,
    transactionsList: transactionsListReducer,
    urgentCall: urgentCallReducer,
    visitChatFromMessage: visitChatFromMessageReducer,
    visitMember: visitMemberReducer
});