const activeChatFilterReducer = (activeChatFilter = false, action) => {
    switch(action.type){
        case 'ACTIVE_CHAT_FILTER_SET':
            return action.payload;
        case 'CLEAR_ALL':
            return false;
        default: 
            return activeChatFilter;
    }
};

export default activeChatFilterReducer;