import React, { useState } from 'react';
import ModalHeader from '../../dumbComponents/Home/modalHeader';
import baesappApi from '../../apis/baesappApi';
import ConstructiveButton from '../../dumbComponents/Authentication/ConstructiveButton';
import ImageViewer from '../../dumbComponents/ImageViewer';
import { Route, Routes, useNavigate } from 'react-router-dom';

const GenerateAIImage = ({
    aIImageSelected
}) => {

    const [prompt, setPrompt] = useState('');
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState(window.localStorage.getItem('lastGeneratedAIUrl'));

    const navigate = useNavigate();

    const generateImage = () => {
        setLoading(true);
        baesappApi.post('/generateAIImage', { prompt })
            .then(res => {
                setImageUrl(res.data);
                window.localStorage.setItem('lastGeneratedAIUrl', res.data);
                setLoading(false);
            })
            .catch(err => {
                window.alert(err.message);
                setLoading(false);
            })
    };

    const useImageClicked = () => {
        aIImageSelected(imageUrl);
    };

    const imageClicked = () => {
        navigate('viewImage');
    };

    return (
        <div className='fullScreen removeScrollBar modalContainer'>
            <div style={{ height: '100px' }} />
            <textarea
                placeholder='Please describe the image you have in mind...'
                onChange={e => setPrompt(e.target.value)} />
            <ConstructiveButton buttonState={loading ? 'loading' : 'active'} text='Generate Image' callback={generateImage} />
            <ModalHeader title={'Activity'} actionActive={false} />
            {
                imageUrl !== false &&
                <>
                    <img
                        onClick={imageClicked}
                        src={imageUrl}
                        style={{
                            borderRadius: '15px',
                            width: '100%'
                        }}
                    />
                    <ConstructiveButton text='Use Image' buttonState='active' callback={useImageClicked}/>
                </>
            }
            <Routes>
                <Route path="/viewImage/*" element={<ImageViewer imageUrl={imageUrl} />} />
            </Routes>
        </div>
    );
};

export default GenerateAIImage;