import React, { useState, useEffect } from "react";

import { Routes, Route, useNavigate } from 'react-router-dom';

import { connect } from 'react-redux';
import MemberModal from "../../../dumbComponents/MemberModal";

import { setMyChat, setChatMemberIds, hitChatsEventListener, setNewArchivedChats, setVisitMember } from "../../../redux/actions";

import baesappApi from '../../../apis/baesappApi';

import spinner from '../../../assets/spinner.png';
import messageSeen from '../../../assets/messageSeen.png';
import messageDelivered from '../../../assets/messageDelivered.png';
import messageDeliveredDark from '../../../assets/messageDeliveredDark.png';
import clearTextDark from '../../../assets/clearTextDark.png';
import clearText from '../../../assets/clearText.png';
import searchBoxIcon from '../../../assets/searchBoxIcon.png';
import searchBoxIconDark from '../../../assets/searchBoxIconDark.png';

import getLocalToObject from "../../../hooks/getLocalToObject";
import getUpdatedChats from "../../../hooks/getUpdatedChats";
import syncChat from "../../../hooks/syncChat";
import normalizeDate from "../../../hooks/normalizeDate";
import objectify from '../../../hooks/objectify';

import chevronAccent from '../../../assets/chevronAccent.png';
import bin from '../../../assets/bin.svg';
import archive from '../../../assets/archive.svg';
import setLocalObject from "../../../hooks/setLocalObject";
import ModalHeader from "../../../dumbComponents/Home/modalHeader";
import BaeSvg from "../../../dumbComponents/BaeSvg";

const ArchivedChats = ({
    myChat,
    chatsEventListener,
    hitChatsEventListener,
    setChatMemberIds,
    chatMemberIds,
    setMyChat,
    darkModeEnabled,
    my_id,
    membersTyping,
    setNewArchivedChats,
    _chatMemberIds,
    setVisitMember
}) => {

    const navigate = useNavigate();
    const [searchText, setSearchText] = useState('');
    const [shouldShowCancel, setShouldShowCancel] = useState(false);
    const [searchInputElement, setSearchInputElement] = useState();
    const [filteredChatsArray, setFilteredChatsArray] = useState([]);
    const [archivedChatsArray, setArchivedChatsArray] = useState([]);
    const [loading, setLoading] = useState(true);
    const [chats, setChats] = useState(false);
    const [confirmDeleteChat, setConfirmDeleteChat] = useState(false);
    const [totalUnreadMessages, setTotalUnreadMessages] = useState(0);
    const [slideBaseX, setSlideBaseX] = useState('false');
    const [slideXDistance, setSlideXDistance] = useState(0);
    const [currSlideId, setCurrSlideId] = useState(false);

    useEffect(() => {
        setChats(getLocalToObject('chats'));
    }, [chatsEventListener]);

    useEffect(() => {
        setSearchInputElement(document.getElementById('searchInput'));
    }, []);

    useEffect(() => {
        console.log(chats);
        if (chats) {
            populateChatsArray();
        }
    }, [myChat, chats, chatMemberIds])

    useEffect(() => {
        if (myChat) {
            let tempTotalUnreadMessages = 0;
            myChat.chats.map(chat => {
                tempTotalUnreadMessages += chat.newMessages.length;
            });
            setTotalUnreadMessages(tempTotalUnreadMessages);
        };
    }, [myChat.chats]);

    useEffect(() => {
        setLoading(true);
        if (chats && _chatMemberIds.length) {
            loadChatsArray();
        } else {
            setLoading(false);
        }
    }, [chats]);

    const handleTouchMove = (e, callId) => {
        const currX = Math.trunc(e.touches[0].clientX);
        if (slideBaseX == 'false') {
            setSlideBaseX(currX);
        } else {
            setCurrSlideId(prevId => prevId != callId ? callId : prevId);
            let invertedSlideDistance = slideBaseX - currX;
            let newSlideDistance = -invertedSlideDistance;
            if (newSlideDistance > -101 && newSlideDistance < 1) {
                setSlideXDistance(newSlideDistance);
            };
        }
    };
    useEffect(() => {
        setSlideBaseX('false');
        setSlideXDistance(0);
    }, [currSlideId]);
    const handleTouchEnd = () => {
        if (slideXDistance < -50) {
            setSlideXDistance(-100);
        } else {
            setSlideBaseX('false');
            setSlideXDistance(0);
        };
    };

    const loadChatsArray = () => {
        let memberIds = {};
        baesappApi.post('/getMemberIds', { Ids: Object.keys(chats) })
            .then(res => {
                res.data.map(member => {
                    memberIds[member._id] = member.memberId;
                });
                setChatMemberIds(memberIds);
            })
            .catch(err => {
                console.log(err);
            });
        if (chats) {
            populateChatsArray();
        };
    };

    const memberProfileShotClicked = (chat) => {
        let member = { ...chat };
        delete member.lastMessage;
        setLocalObject('memberModal', member);
        navigate('memberModal');
    };

    const populateChatsArray = () => {
        setLoading(true);
        if (!myChat.chats) return;
        const myChatsObject = objectify(myChat.chats, 'contactId');
        let tempArchivedChatsArray = [];

        for (const current in chats) {
            console.log(current);
            console.log(chatMemberIds);
            if (chats[current].conversation.messages.length > 0 && chatMemberIds[current]) {
                let chatArrayItem = {};
                chatArrayItem._id = myChatsObject[chats[current].contactId]._id;
                chatArrayItem.totalNewMessages = myChatsObject[chats[current].contactId].newMessages.length;
                chatArrayItem.onlyDeliveredMessages = myChatsObject[chats[current].contactId].onlyDeliveredMessages.length;
                chatArrayItem.contactId = chats[current].contactId;
                chatArrayItem.lastMessage_id = chats[current].conversation.messages.slice(-1)[0]._id;
                chatArrayItem.username = chatMemberIds[current].username;
                chatArrayItem.preferredName = chatMemberIds[current].preferredName;
                chatArrayItem.profileShot = chatMemberIds[current].profileShot;
                chatArrayItem.lastMessage = chats[current].conversation.messages.slice(-1)[0].text;
                chatArrayItem.lastMessageSender = chats[current].conversation.messages.slice(-1)[0].sender;
                chatArrayItem.time = chats[current].conversation.messages.slice(-1)[0].time;
                chatArrayItem.contentType = chats[current].conversation.messages.slice(-1)[0].contentType;
                console.log(chatArrayItem._id);
                if (myChat.archivedChats.includes(chatArrayItem._id)) {
                    tempArchivedChatsArray.push(chatArrayItem);
                };
            };
        };

        let sortedArchivedChatsArray = tempArchivedChatsArray.sort(
            (chat1, chat2) => chat2.time.localeCompare(chat1.time)
        );

        setArchivedChatsArray(sortedArchivedChatsArray);
        setFilteredChatsArray(sortedArchivedChatsArray);
        setLoading(false);
    };

    const visitMember = (chat) => {
        let member = { ...chat };
        delete member.lastMessage;
        member._id = member.contactId;
        setVisitMember({
            path: 'chat',
            memberEssentials: member
        });
        navigate(`/visit/chat?_id=${member._id}`);
    };

    const deleteChat = (contactId) => {
        baesappApi.post('/setIndexOfLastAvailableMessage', { contactId, lastMessageId: 'last' })
            .then(res => {
                syncChat(myChat);
                getUpdatedChats(hitChatsEventListener);
                setConfirmDeleteChat(false);
            })
            .catch(err => {
                console.log(err);
                setConfirmDeleteChat(false);
            })
    };

    const archiveChat = (chatId) => {
        baesappApi.post('/toggleArchiveChat', { chatId })
            .then(res => {
                setNewArchivedChats(res.data);
            })
            .catch(err => {
                console.log(err);
            })
    };

    useEffect(() => {
        if (archivedChatsArray) {
            if (searchText == '') {
                setFilteredChatsArray(archivedChatsArray)
            } else {
                let tempFilteredChatsArray = archivedChatsArray.filter(chat => chat.username.toUpperCase().includes(searchText.toUpperCase()));
                setFilteredChatsArray(tempFilteredChatsArray);
            }
        }
    }, [searchText]);

    const handleOnBlur = (blurredCause) => {
        if (blurredCause == 'cancel') {
            setSearchText('');
            setShouldShowCancel(false);
        } else if (blurredCause == 'clear') {
            setSearchText('');
            searchInputElement.focus();
        }
    };

    const renderContent = () => {
        if (archivedChatsArray) {
            if (loading) {
                return (
                    <div className="flex spaceAround" style={{ width: '100vw', textAlign: 'center', marginTop: '115px' }}>
                        <BaeSvg iconName='sideHeart' color='loaderFlicker absoluteHorizontalCenter' height='20px' />
                    </div>
                );
            } else {
                const chatsList = filteredChatsArray.map((chat, index) => {
                    return (
                        <>
                            <div className="flex vertical">
                                <div className="flex horizontal chatCard" style={{ justifyContent: 'flex-end', marginBottom: '-60px' }}>
                                    <button onClick={() => setConfirmDeleteChat({ contactId: chat.contactId, username: chat.username })} className="flex alignCenter spaceAround" style={{ borderTopLeftRadius: '10px', borderBottomLeftRadius: '10px', height: '60px', width: '50px', backgroundColor: 'var(--error)' }}>
                                        <img src={bin} style={{ height: '25px', opacity: '0.6' }} />
                                    </button>
                                    <button onClick={() => archiveChat(chat._id)} className="flex alignCenter spaceAround" style={{ borderBottomRightRadius: '10px', borderTopRightRadius: '10px', height: '60px', width: '50px', backgroundColor: 'var(--secondary)' }}>
                                        <img src={archive} style={{ height: '25px', opacity: '0.6' }} />
                                    </button>
                                </div>
                                <div className="flex horizontal chatCard" style={{ height: '60px', marginBottom: '-60px', backgroundColor: 'var(--base)', transform: `translateX(${currSlideId == chat.contactId ? slideXDistance : '0'}px)` }}>
                                </div>
                                <div key={chat._id} onTouchEnd={handleTouchEnd} onTouchMove={(e) => handleTouchMove(e, chat.contactId)} style={{ transform: `translateX(${currSlideId == chat.contactId ? slideXDistance : '0'}px)` }} className="flex horizontal chatCard" >
                                    <div className="flex horizontal chatCardLeft">
                                        <img onClick={() => memberProfileShotClicked(chat)} className="memberBarProfileShot" src={`https://baesapp.com/media/${chat.profileShot}`} />
                                        <div onClick={() => visitMember(chat)} className="flex vertical chatCardMainContent">
                                            <span className="chatCardUsername">{chat.username}</span>
                                            {
                                                membersTyping.includes(chat.contactId) ?
                                                    <div className="chatCardContentContainer">
                                                        <span className="chatCardContent">typing...</span>
                                                    </div> :
                                                    chat.contentType == 'text' ?
                                                        <div className="chatCardContentContainer">
                                                            {
                                                                chat.lastMessageSender == my_id &&
                                                                <img className={`messageStatusIcon ${chat.onlyDeliveredMessages == 0 ? 'messageSeen' : ''}`} src={chat.onlyDeliveredMessages == 0 ? messageSeen : darkModeEnabled ? messageDeliveredDark : messageDelivered} />
                                                            }
                                                            <span className="chatCardContent">{chat.lastMessage}</span>
                                                        </div> :
                                                        chat.contentType == 'postImage' ?
                                                            <span className="chatCardContent">Image post</span> :
                                                            chat.contentType == 'aIImage' ?
                                                                <span className="chatCardContent">AI generated image</span> :
                                                                chat.contentType == 'postVideo' ?
                                                                    <span className="chatCardContent">Video post</span> :
                                                                    chat.contentType == 'image' ?
                                                                        <span className="chatCardContent">Image</span> :
                                                                        <span className="chatCardContent">Video</span>
                                            }
                                        </div>
                                    </div>
                                    <div className="flex vertical chatCardRight">
                                        <span className={`${chat.totalNewMessages ? 'chatCardDateAccent' : ''}`}>{normalizeDate(chat.time, false, true)}</span>
                                        <div className={`chatCardTotalUnread ${!chat.totalNewMessages ? 'hideChatCardTotalUnread' : ''}`}>
                                            <span>{chat.totalNewMessages}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {index + 1 == archivedChatsArray.length && <div style={{ minHeight: '50px', width: '50px' }} />}
                        </>
                    );
                });
                return (
                    <div className="fullScreen flex removeScrollBar vertical" style={{ width: '100vw', maxWidth: '500px', overflow: 'scroll', margin: '0 auto' }}>
                        <div style={{ minHeight: '100px' }} />
                        <div className="flex vertical">
                            <div className="flex spaceAround">
                                <div className="searchBarContainer" style={{ width: '100%' }}>
                                    <div className="searchBar" style={shouldShowCancel ? { marginLeft: '10px' } : {}}>
                                        <img className="searchBarIcon" src={darkModeEnabled ? searchBoxIcon : searchBoxIconDark} />
                                        <input
                                            onFocus={() => setShouldShowCancel(true)}
                                            id="searchInput"
                                            placeholder="Search"
                                            value={searchText}
                                            onChange={e => setSearchText(e.target.value)}
                                            className={`cleanInput searchBarInput`} />
                                        {searchText != '' && <img onClick={() => handleOnBlur('clear')} className="searchBarClear" src={darkModeEnabled ? clearTextDark : clearText} />}
                                    </div>
                                    {shouldShowCancel && <span onClick={() => handleOnBlur('cancel')} className="searchBarCancelText">
                                        Cancel
                                    </span>}
                                </div>
                            </div>
                        </div>
                        {
                            filteredChatsArray.length ?
                                chatsList :
                                <div className="flex spaceAround" style={{ width: '100vw', textAlign: 'center', marginTop: '50px' }}>
                                    <span className="centerText noResults">No Results</span>
                                </div>
                        }
                        {confirmDeleteChat &&
                            <>
                                <div onClick={() => setConfirmDeleteChat(false)} className="middlePageConvoBackdrop" />
                                <div className="bottomSlideInCard flex vertical spaceAround alignCenter confirmationCard">
                                    <span>Delete your messages with <strong>{confirmDeleteChat.username}</strong>?</span>
                                    <div className="middlePageConvoButtonsContainer">
                                        <button onClick={() => setConfirmDeleteChat(false)} className="cleanButton middlePageConvoButtonAccent">Cancel</button>
                                        <button onClick={() => deleteChat(confirmDeleteChat.contactId)} className="cleanButton middlePageConvoButtonRed">Delete</button>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                );
            }
        } else {
            return (<BaeSvg iconName='sideHeart' color='loaderFlicker absoluteHorizontalCenter' height='20px' />);
        }
    };

    return (
        <div className='fullScreen removeScrollBar modalContainer'>
            {renderContent()}
            <ModalHeader title={'ARCHIVE'} actionName={''} actionActive={false} actionFunction={() => { }} />
            <Routes>
                <Route path="/memberModal/*" element={<MemberModal />} />
            </Routes>
        </div >
    );
};

const mapStateToProps = state => {
    return ({
        myChat: state.myChat,
        chatsEventListener: state.chatsEventListener,
        chatMemberIds: state.chatMemberIds,
        my_id: state.my_id,
        darkModeEnabled: state.darkModeEnabled,
        membersTyping: state.membersTyping
    });
};

export default connect(mapStateToProps, { setMyChat, setNewArchivedChats, hitChatsEventListener, setChatMemberIds, setVisitMember })(ArchivedChats);