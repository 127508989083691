import React, { useState, useEffect } from "react";

import { connect } from 'react-redux';
import { Routes, Route, useNavigate } from 'react-router-dom';


import baesappApi from "../apis/baesappApi";
import { getTheToken } from "../firebase";
import { clearRedux } from '../redux/actions';
import Companies from './Companies';

import { setDarkModeEnabled } from "../redux/actions";
import BaeSvg from "../dumbComponents/BaeSvg";
import Switch from "../dumbComponents/Switch";

const Settings = ({
    myId,
    myAuthentication,
    clearRedux,
    setDarkModeEnabled,
    darkModeEnabled
}) => {

    const navigate = useNavigate();

    const [token, setToken] = useState(false);


    useEffect(() => {
        if (token) {
            baesappApi.post('/logout', { deviceId: token })
                .then(res => {
                    clearRedux();
                    window.localStorage.clear();
                    navigate('/authentication');
                })
                .catch(err => {
                    console.log(err);
                })
        };
    }, [token]);

    const logout = () => {
        if ('Notification' in window &&
            'serviceWorker' in navigator &&
            'PushManager' in window) {
            getTheToken(setToken);
        } else {
            setToken('na');
        }
    };

    function setCookie(cname, cvalue, exdays) {
        const d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        let expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    const toggleTheme = () => {
        var r = document.querySelector(':root');
        if (!darkModeEnabled) {
            setCookie('theme', 'dark', 365 * 3);
            setDarkModeEnabled(true);
            r.style.setProperty('--base', '#000');
            r.style.setProperty('--primary', '#3a3a3a');
            r.style.setProperty('--primaryGlass', '#3a3a3a80');
            r.style.setProperty('--primaryBlur', '#000000a4');
            r.style.setProperty('--secondary', '#686868');
            r.style.setProperty('--onAccent', '#000');
            r.style.setProperty('--text', '#fff');
        } else {
            setCookie('theme', 'light', 365 * 3);
            setDarkModeEnabled(false);
            r.style.setProperty('--base', '#fff');
            r.style.setProperty('--primary', '#F4F4F4');
            r.style.setProperty('--primaryGlass', '#e9e9e980');
            r.style.setProperty('--primaryBlur', '#ffffffdd');
            r.style.setProperty('--secondary', '#bbbbbb');
            r.style.setProperty('--onAccent', '#fff');
            r.style.setProperty('--text', '#000');
        };
    };

    const toggleFullscreen = () => {
        if (document.fullscreenElement) {
            document.exitFullscreen();
        } else {
            document.documentElement.requestFullscreen().catch((err) => {
                console.log(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`);
            });
        }
    };

    // const Main = () => {
    //     return(
    //         <>
    //             <img style={{height: '100px', width: '100px', borderRadius: '20px'}} src={`https://baesapp.com/media/${myId.profileShot}`}/>
    //             <button onClick={() => navigate(-1)}>back</button>
    //             <p>{myId.username}</p>
    //             <p>{myId.preferredName}</p>
    //             <p>{myAuthentication.dateOfBirth}</p>
    //             <p>{myAuthentication.email}</p>
    //             <p>{myAuthentication.firstNames}</p>
    //             <p>{myAuthentication.lastName}</p>
    //             <p>{myAuthentication.sex}</p>
    //             <button onClick={() => navigate('companies')}>Companies</button>
    //             <button onClick={() => logout()}>Logout</button>
    //             <button onClick={() => toggleTheme()}>Theme</button>
    //             <button onClick={toggleFullscreen}>full screen mode</button>
    //         </>
    //     );
    // }

    const Main = () => {
        return (
            <div className="container" style={{ maxWidth: '500px', justifyContent: 'flex-start', alignSelf: 'center', gap: '10px', width: '100vw' }}>
                <div onClick={() => navigate(-1)} className="flex alignCenter" style={{ gap: '5px', alignSelf: 'start', margin: '15px', fontSize: '1.4em' }}>
                    <BaeSvg iconName="chevronDown" height='1em' width="1em" color='text' style={{ transform: 'rotate(90deg)' }} />
                    <span>Settings</span>
                </div>
                <div className="flex vertical">
                    <img className="settingsProfileShot" src={`https://baesapp.com/media/${myId.profileShot}`} />
                    <div style={{
                        height: '35px',
                        width: '35px',
                        borderRadius: '50%',
                        backgroundColor: 'var(--text)',
                        alignSelf: 'center',
                        position: 'relative',
                        top: '-35px',
                        right: '-70px',
                        marginBottom: '-35px'
                    }}>
                    </div>
                </div>
                <div className="flex alignCenter" style={{ gap: '5px' }}>
                    <span style={{ fontSize: '20px' }}>{myId.username}</span>
                    <BaeSvg iconName="baesappLogo" height='1em' width="1em" color='text' />
                </div>
                <span className="settingsHeader">
                    THEME
                </span>
                <div className="settingsBlock">
                    <div className="settingsBlockSlice">
                        <span>Dark mode</span>
                        <Switch callback={toggleTheme} switchOn={darkModeEnabled} switchBallText={!darkModeEnabled ? 'OFF' : 'ON'} />
                    </div>
                </div>
                <button onClick={() => navigate('companies')}>Companies</button>
                <button onClick={() => logout()}>Logout</button>
                <button onClick={toggleFullscreen}>full screen mode</button>
            </div >
        );
    };

    return (
        <Routes>
            <Route path="/" element={<Main />} />
            <Route path="/companies/*" element={<Companies />} />
        </Routes>
    );
};

const mapStateToProps = state => {
    return ({
        myId: state.myId,
        myAuthentication: state.myAuthentication,
        darkModeEnabled: state.darkModeEnabled
    });
};

export default connect(mapStateToProps, { clearRedux, setDarkModeEnabled })(Settings);
