import React, { useEffect, useState, useContext } from "react";

import { connect } from 'react-redux';
import { Routes, Route, useNavigate } from 'react-router-dom';
import Peer from 'simple-peer';

import chevronAccent from '../../../assets/chevronAccent.png';
import bellActive from '../../../assets/bellActive.png'
import bellActiveDark from '../../../assets/bellActiveDark.png'

import baesappApi from '../../../apis/baesappApi';
import Arena from "./Arena";
import ArenaBuilder from "./ArenaBuilder";


const VisitGames = ({
    visitMember,
    my_id,
    socket,
    gameInvitation
}) => {

    const [waitingForResponse, setWaintingForResponse] = useState(false);
    const [usedPeer, setUsedPeer] = useState(null);
    const [hasGameInvitation, setHasGameInvitation] = useState(null);
    const [isInitiator, setIsInitiator] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        let newHasGame = gameInvitation.find(invitation => invitation.memberModal._id === visitMember.memberEssentials._id);
        if (newHasGame) {
            setHasGameInvitation(newHasGame);
        };
    }, [gameInvitation]);

    const acceptInvitation = () => {
        const accPeer = new Peer({
            trickle: false
        });
        accPeer.signal(hasGameInvitation.signal);
        accPeer.on('signal', data => {
            baesappApi.post('/games/setup/accept', { memberId: hasGameInvitation.memberModal._id, signal: data })
                .catch(err => console.log(err));
        });
        accPeer.on('connect', () => {
            console.log('connection established');
            setUsedPeer(accPeer);
            setIsInitiator(false);
            navigate(`arena?_id=${visitMember.memberEssentials._id}`);
        })
    };

    const inviteMember = () => {
        setWaintingForResponse(true);

        const peer = new Peer({
            initiator: true,
            trickle: false
        });

        peer.on('signal', data => {
            console.log(data);
            baesappApi.post('/games/setup/initiate', { memberId: visitMember.memberEssentials._id, signal: data })
                .catch(err => console.log(err));
        });

        peer.on('connect', () => {
            console.log('connection established');
            setWaintingForResponse(false);
            setUsedPeer(peer);
            setIsInitiator(true);
            navigate(`arena?_id=${visitMember.memberEssentials._id}`);
        });

        socket.on(`gameAccepted${my_id}`, signal => {
            console.log(signal);
            peer.signal(signal);
        });

    };

    return (
        <div className="fullScreen walletContainer removeScrollBar">
            <ul className="removeScrollBar fullScreen messagesContainer">
                <div style={{ height: '165px' }} />
                <div style={{ height: '60px' }} />
            </ul>
            <div className="walletCard walletCardVisit">
                <div className="walletButtonsContainer">
                    {
                        hasGameInvitation ?
                            <div onClick={acceptInvitation} className="walletButton openPayButton">
                                <img className="walletButtonProfileShot" src={`https://baesapp.com/media/${visitMember.memberEssentials.profileShot}`} />
                                <span className="walletButtonText visitPayText">Accept Game</span>
                            </div> :
                            <div onClick={inviteMember} className="walletButton openPayButton">
                                <img className="walletButtonProfileShot" src={`https://baesapp.com/media/${visitMember.memberEssentials.profileShot}`} />
                                <span className="walletButtonText visitPayText">Invite to play</span>
                            </div>
                    }
                    {waitingForResponse && <span>Waiting for {visitMember.memberEssentials.username} to accept</span>}
                </div>
            </div>
            <Routes>
                <Route path='/arena' element={<Arena peer={usedPeer} isInitiator={isInitiator}/>} />
            </Routes>
        </div>
    );
};

const mapStateToProps = state => {
    return ({
        visitMember: state.visitMember,
        my_id: state.my_id,
        gameInvitation: state.gameInvitation
    });
};

export default connect(mapStateToProps,)(VisitGames);
