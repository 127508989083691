import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux'

import { useNavigate } from 'react-router-dom';

import chevronAccent from '../../../assets/chevronAccent.png';
import inactiveLock from '../../../assets/inactiveLock.png';
import activeLock from '../../../assets/activeLock.png';
import unlockedLock from '../../../assets/unlockedLock.png';
import baesappApi from '../../../apis/baesappApi';

import { setNewMyInfo } from '../../../redux/actions';
import ModalHeader from '../../../dumbComponents/Home/modalHeader';
import Switch from '../../../dumbComponents/Switch';

const Address = ({
    myInfo,
    generalKeys,
    setNewMyInfo
}) => {

    const navigate = useNavigate();

    const [canSave, setCanSave] = useState(false);
    const [activeAddress, setActiveAddress] = useState(false);
    const [addressPredictions, setAddressPredictions] = useState([]);
    const [addressText, setAddressText] = useState('');
    const [location, setLocation] = useState(false);
    const [toSaveFields, setToSaveFields] = useState(false);
    const [newMyInfoLocal, setNewMyInfoLocal] = useState(false);

    useEffect(() => {
        navigator.geolocation.getCurrentPosition((position) => {
            setLocation(position.coords.latitude + ',' + position.coords.longitude)
        });
        if (myInfo) {
            setNewMyInfoLocal(myInfo);
        }
    }, [myInfo]);

    const saveFields = () => {
        if (toSaveFields) {
            baesappApi.post('/setInfo', { info: toSaveFields })
                .then(res => {
                    setNewMyInfo(res.data)
                    setCanSave(false);
                    setToSaveFields(false);
                    navigate(-1);
                })
                .catch(err => {
                    console.log(err);
                })
        }
    };

    const saveFieldsPublic = () => {
        if (canSave) {
            let toSaveFields = {};
            toSaveFields['address1'] = newMyInfoLocal['address1'];

            baesappApi.post('/setInfo', { info: toSaveFields })
                .then(res => {
                    setNewMyInfo(res.data)
                    navigate(-1);
                })
                .catch(err => {
                    console.log(err);
                })
        }
    };

    const addressPredictionClicked = (address) => {
        if (!canSave) setCanSave(true);
        baesappApi.post('/address/getDetails', { placeId: address.place_id }).then(res => {

            let streetNumber = '';
            let route = '';
            let address2 = '';
            let city = '';
            let provinceState = '';
            let country = '';
            let postalCode = '';

            res.data.result.address_components.map(component => {
                switch (component.types[0]) {
                    case 'street_number': {
                        streetNumber = component.long_name;
                    } break;
                    case 'route': {
                        route = component.long_name;
                    } break;
                    case 'sublocality_level_1': {
                        address2 = component.long_name;
                    } break;
                    case 'locality': {
                        city = component.long_name;
                    } break;
                    case 'administrative_area_level_1': {
                        provinceState = component.long_name;
                    } break;
                    case 'country': {
                        country = component.long_name;
                    } break;
                    case 'postal_code': {
                        postalCode = component.long_name;
                    } break;
                }
            });

            //case 'addressPlaceId': {
            //field.value = res.data.result.formatted_address;

            let newToSaveFields = {
                addressPlaceId: {
                    value: res.data.result.formatted_address,
                    public: false
                },
                address1: {
                    value: streetNumber + ' ' + route,
                    public: newMyInfoLocal['address1'].public
                },
                address2: {
                    value: address2,
                    public: newMyInfoLocal['address2'].public
                },
                city: {
                    value: city,
                    public: newMyInfoLocal['city'].public
                },
                provinceState: {
                    value: provinceState,
                    public: newMyInfoLocal['provinceState'].public
                },
                country: {
                    value: country,
                    public: newMyInfoLocal['country'].public
                },
                zip: {
                    value: postalCode,
                    public: newMyInfoLocal['zip'].public
                }
            };
            setToSaveFields(newToSaveFields);

            setAddressText(streetNumber + ', ' + route + ', ' + address2 + ', ' + city + ', ' + provinceState + ', ' + postalCode);
            setActiveAddress(false);
        });
    };

    const addrressPredictionsList = addressPredictions.map(address => {
        let text = address.description;

        let matchedChars = [];
        address.matched_substrings.map(substring => {
            const o = substring.offset;
            const l = substring.length;

            for (let pos = o; pos < (0 + l); pos++) {
                matchedChars.push(pos);
            };
        });

        let finalAddressText = [];
        for (let pos = 0; pos < text.length; pos++) {
            let characterData = {
                value: text[pos],
                matched: matchedChars.includes(pos)
            };
            finalAddressText.push(characterData);
        };

        let finalAddressTextList = finalAddressText.map(char => {
            return (
                <span style={char.matched ? { color: 'var(--accent)', fontWeight: 'bold' } : {}}>{char.value}</span>
            );
        });

        return (
            <button onClick={() => addressPredictionClicked(address)} className='addressSuggestionButton'>
                <div className='addressSuggestionText'>
                    {finalAddressTextList}
                </div>
            </button>
        );
    });

    const handleTextInput = (value) => {
        if (canSave) setCanSave(false);
        setToSaveFields(false);
        setAddressText(value);
        if (value == '' || value == ' ') {
            setActiveAddress(false);
            setAddressPredictions([]);
        } else {
            if (!activeAddress) setActiveAddress(true);
            baesappApi.post('/address/predictAddress', { text: value, location }).then(res => {
                setAddressPredictions(res.data.predictions);
            });
        }
    };

    const fieldPublic = (field) => {
        if (newMyInfoLocal) {
            return newMyInfoLocal[field].public;
        } else {
            return false;
        }
    };

    const privateButtonClicked = (field) => {
        let newNewMyInfoLocal = { ...newMyInfoLocal };
        newNewMyInfoLocal[field].public = !newNewMyInfoLocal[field].public;
        setNewMyInfoLocal(newNewMyInfoLocal);

        let toSaveFields = {};

        toSaveFields[field] = {
            value: newNewMyInfoLocal[field].value,
            public: newNewMyInfoLocal[field].public
        }

        baesappApi.post('/setInfo', { info: toSaveFields })
        .then(res => {
            setNewMyInfo(res.data);
        })
        .catch(err => {
            console.log(err);
            setNewMyInfoLocal(myInfo);
        })
    };

    const getAddressValue = (field) => {
        if(myInfo){
            return myInfo[field].value;
        }else{
            return '';
        }
    };

    return (
        <div className='fullScreen removeScrollBar modalContainer'>
            <div style={{ height: '100px' }} />
            <div className='flex vertical'>

                <div className="infoCategory">
                    <span style={{ marginLeft: '15px' }}>
                        What is your address?
                    </span>
                </div>
                <div className="infoField" style={{ zIndex: '1' }}>
                    <div className="flex alignCenter" style={{ width: '100%', height: '100%', color: 'var(--secondary)', fontWeight: '600', textAlign: 'start' }}>
                        <input
                            className={`infoInputBox`}
                            placeholder='Type Address...'
                            onFocus={() => setActiveAddress(true)}
                            onChange={(e) => handleTextInput(e.target.value)}
                            value={addressText}
                        />
                    </div>
                </div>
                {(activeAddress && addressPredictions.length > 0) && <div className='addressSuggestionsContainer'>
                    {addrressPredictionsList}
                </div>}
            </div>
            <div className="infoCategory">
                <span style={{ marginLeft: '15px' }}>
                    Set your address private modes
                </span>
            </div>
            <div className="infoField" >
                <div className="flex alignCenter" style={{ height: '100%', textAlign: 'start' }}>
                    <span>{getAddressValue('address1') == '' ? 'Address 1' : getAddressValue('address1')}</span>
                </div>
                <div className="flex infoFieldPrivateSwitch" style={{ width: 'fit-content'}}>
                    <span className="infoFieldPrivateLabel" style={!fieldPublic('address1') ? { color: 'var(--accent)' } : {}}>private mode</span>
                    <Switch callback={() => privateButtonClicked('address1')} switchOn={!fieldPublic('address1')} switchBallText={fieldPublic('address1') ? 'OFF' : 'ON'} />
                </div>
            </div>
            <div className="infoField" >
                <div className="flex alignCenter" style={{ height: '100%', textAlign: 'start' }}>
                    <span>{getAddressValue('address2') == '' ? 'Address 2' : getAddressValue('address2')}</span>
                </div>
                <div className="flex infoFieldPrivateSwitch" style={{ width: 'fit-content'}}>
                    <span className="infoFieldPrivateLabel" style={!fieldPublic('address2') ? { color: 'var(--accent)' } : {}}>private mode</span>
                    <Switch callback={() => privateButtonClicked('address2')} switchOn={!fieldPublic('address2')} switchBallText={fieldPublic('address2') ? 'OFF' : 'ON'} />
                </div>
            </div>
            <div className="infoField" >
                <div className="flex alignCenter" style={{ height: '100%', textAlign: 'start' }}>
                    <span>{getAddressValue('city') == '' ? 'City' : getAddressValue('city')}</span>
                </div>
                <div className="flex infoFieldPrivateSwitch" style={{ width: 'fit-content'}}>
                    <span className="infoFieldPrivateLabel" style={!fieldPublic('city') ? { color: 'var(--accent)' } : {}}>private mode</span>
                    <Switch callback={() => privateButtonClicked('city')} switchOn={!fieldPublic('city')} switchBallText={fieldPublic('city') ? 'OFF' : 'ON'} />
                </div>
            </div>
            <div className="infoField" >
                <div className="flex alignCenter" style={{ height: '100%', textAlign: 'start' }}>
                    <span>{getAddressValue('provinceState') == '' ? 'Province / State' : getAddressValue('provinceState')}</span>
                </div>
                <div className="flex infoFieldPrivateSwitch" style={{ width: 'fit-content'}}>
                    <span className="infoFieldPrivateLabel" style={!fieldPublic('provinceState') ? { color: 'var(--accent)' } : {}}>private mode</span>
                    <Switch callback={() => privateButtonClicked('provinceState')} switchOn={!fieldPublic('provinceState')} switchBallText={fieldPublic('provinceState') ? 'OFF' : 'ON'} />
                </div>
            </div>
            <div className="infoField" >
                <div className="flex alignCenter" style={{ height: '100%', textAlign: 'start' }}>
                    <span>{getAddressValue('country') == '' ? 'Country' : getAddressValue('country')}</span>
                </div>
                <div className="flex infoFieldPrivateSwitch" style={{ width: 'fit-content'}}>
                    <span className="infoFieldPrivateLabel" style={!fieldPublic('country') ? { color: 'var(--accent)' } : {}}>private mode</span>
                    <Switch callback={() => privateButtonClicked('country')} switchOn={!fieldPublic('country')} switchBallText={fieldPublic('country') ? 'OFF' : 'ON'} />
                </div>
            </div>
            <div className="infoField" >
                <div className="flex alignCenter" style={{ height: '100%', textAlign: 'start' }}>
                    <span>{getAddressValue('zip') == '' ? 'ZIP' : getAddressValue('zip')}</span>
                </div>
                <div className="flex infoFieldPrivateSwitch" style={{ width: 'fit-content'}}>
                    <span className="infoFieldPrivateLabel" style={!fieldPublic('zip') ? { color: 'var(--accent)' } : {}}>private mode</span>
                    <Switch callback={() => privateButtonClicked('zip')} switchOn={!fieldPublic('zip')} switchBallText={fieldPublic('zip') ? 'OFF' : 'ON'} />
                </div>
            </div>
            <ModalHeader title={'ADDRESS'} actionName={'SAVE'} actionActive={canSave && toSaveFields} actionFunction={() => saveFields()} />
        </div>
    );
};

const mapStateToProps = state => {
    return ({
        myInfo: state.myInfo
    });
};

export default connect(mapStateToProps, { setNewMyInfo })(Address);