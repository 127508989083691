import { connect } from 'react-redux';
import React, { useState, useEffect, useRef } from 'react';
import Peer from 'simple-peer';
import baesappApi from '../apis/baesappApi';
import getLocalToObject from '../hooks/getLocalToObject';
import { useNavigate } from 'react-router-dom';

import phone from '../assets/phone.svg';
import mute from '../assets/mute.svg';
import unmute from '../assets/unmute.svg';

const VoiceCallUser = ({
  socket,
  my_id,
  urgentCall
}) => {

  const navigate = useNavigate();
  const memberModal = getLocalToObject('memberModal');
  const contactId = memberModal._id;
  const contactName = memberModal.username;

  const [mediaStream, setMediaStream] = useState(null);
  const [declinedCall, setDeclinedCall] = useState(false);
  const [callId, setCallId] = useState(false);
  const [muted, setMuted] = useState(false);
  const [contactMuted, setContactMuted] = useState(false);
  const [callAccepted, setCallAccepted] = useState(false);

  const userAudio = useRef();

  const getMediaDevices = async () => {
    setMediaStream(await navigator.mediaDevices.getUserMedia({ video: false, audio: true }));
  };

  const cancelMediaStream = () => {
    if (mediaStream) {
      mediaStream.getTracks().forEach(track => track.stop());
      setMediaStream(null);
    }
  };

  useEffect(() => {
    getMediaDevices();

    window.addEventListener('beforeunload', endCall);

    return () => {
      window.removeEventListener('beforeunload', endCall);
    };

  }, []);

  useEffect(() => {
    if (mediaStream) {
      const peer = new Peer({
        initiator: true,
        trickle: false,
        stream: mediaStream
      });

      peer.on('signal', data => {
        baesappApi.post('/callUser', { contactId, signal: data, urgentCall })
          .then(res => {
            setCallId(res.data);
          })
      });

      peer.on('stream', stream => {
        if (userAudio.current) {
          console.log('success');
          userAudio.current.srcObject = stream
        }
      });

      socket.on(`callAccepted${my_id}`, signal => {
        console.log('accepted');
        setCallAccepted(true);
        peer.signal(signal);
      });

      socket.on(`callMuted${my_id}`, data => {
        setContactMuted(true);
      });

      socket.on(`callUnMuted${my_id}`, data => {
        setContactMuted(false);
      });

      socket.on(`callEnded${my_id}`, data => {
        if (userAudio.current) {
          userAudio.current.srcObject = null;
        }
        cancelMediaStream();
        navigate(-1);
      });

      socket.on(`declinedCall${my_id}`, signal => {
        cancelMediaStream();
        setDeclinedCall(true);
        setTimeout(() => {
          navigate(-1);
        }, 2000);
      });
    }
  }, [mediaStream]);

  const endCall = () => {
    baesappApi.post('/endCall', { contactId, callId })
    if (userAudio.current) {
      userAudio.current.srcObject = null;
    }
    cancelMediaStream();
    navigate(-1);
  }

  const muteClicked = () => {
    if (mediaStream) {
      mediaStream.getTracks().forEach(track => {
        if (track.kind === 'audio') {
          track.enabled = muted;
        }
      });
      baesappApi.post('/muteCall', { contactId, mutedState: !muted ? 'muted' : 'unMuted' })
        .then(res => {
          setMuted(!muted);
        })
        .catch(err => {
          console.log(err);
          window.alert('something unexpected went wrong, please end the call and try again');
        })
    }
  }

  const renderContent = () => {
    return (
      <>
        {!urgentCall && <img className="callProfileShotBackground" src={`https://baesapp.com/media/${memberModal.profileShot}`} />}
        {urgentCall && <div style={{backgroundColor: 'var(--error)', filter: 'brightness(80%)'}} className="callProfileShotBackground"/>}
        <div className='flex vertical alignCenter spaceAround' style={{ position: 'absolute', height: '100vh', width: '100vw', maxWidth: '500px' }}>
          <div className='flex vertical alignCenter spaceAround'>
            <img className="callProfileShot" src={`https://baesapp.com/media/${memberModal.profileShot}`} />
            {!declinedCall && <span style={{ fontSize: '1.6em', marginTop: '20px' }}>{!callAccepted && 'Calling '}{contactName}{!callAccepted && '...'}</span>}
            {declinedCall && <span style={{ fontSize: '2em', marginTop: '20px' }}>Call declined</span>}
            {muted && <span style={{ fontSize: '1.6em', marginTop: '20px' }}>You muted your call</span>}
            {contactMuted && <span style={{ fontSize: '1.6em', marginTop: '20px' }}>{contactName} muted their call</span>}
          </div>
          <audio ref={userAudio} autoPlay />
          <div className='flex spaceAround' style={{ width: '100%', maxWidth: '500px' }}>
            {callId && <button style={{
              height: '70px',
              width: '70px',
              borderRadius: '50%',
              backgroundColor: 'white',
              display: 'flex',
              justifyContent: 'space-around',
              alignItems: 'center'
            }} onClick={muteClicked}>
              <img style={{ opacity: '0.5', height: '37px' }} src={muted ? mute : unmute} />
            </button>}
            {callId && <button style={{
              height: '70px',
              width: '70px',
              borderRadius: '50%',
              backgroundColor: 'var(--error)',
              display: 'flex',
              justifyContent: 'space-around',
              alignItems: 'center'
            }} onClick={endCall}>
              <img style={{ opacity: '0.5', height: '37px' }} src={phone} />
            </button>}
          </div>
        </div>
      </>
    );
  }

  return (
    <div className='callBackground'>
      {renderContent()}
    </div>
  );
}

const mapStateToProps = state => {
  return ({
    my_id: state.my_id,
    urgentCall: state.urgentCall
  });
};

export default connect(mapStateToProps)(VoiceCallUser);
