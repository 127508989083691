import { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import baesappApi from '../../../apis/baesappApi';

import { useNavigate, Route, Routes } from 'react-router-dom';

import chevronAccent from '../../../assets/chevronAccent.png'
import spinner from '../../../assets/spinner.png';
import checkMarkAccent from '../../../assets/checkMarkAccent.png';
import checkMarkAccentDark from '../../../assets/checkMarkAccentDark.png';
import searchBoxIcon from '../../../assets/searchBoxIcon.png'
import searchBoxIconDark from '../../../assets/searchBoxIconDark.png'
import clearText from '../../../assets/clearText.png';
import clearTextDark from '../../../assets/clearTextDark.png';

import EmptyPage from '../../../dumbComponents/EmptyPage';
import MemberModal from '../../../dumbComponents/MemberModal';

import { setNewFilters } from '../../../redux/actions';
import setLocalObject from '../../../hooks/setLocalObject';
import ModalHeader from '../../../dumbComponents/Home/modalHeader';
import Switch from '../../../dumbComponents/Switch';
import BaeSvg from '../../../dumbComponents/BaeSvg';

const AddNewFilter = ({
    myChat,
    setNewFilters,
    darkModeEnabled
}) => {

    const navigate = useNavigate();

    const [contactsList, setContactsList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [displayedList, setDisplayedList] = useState([]);
    const [searchInputElement, setSearchInputElement] = useState();
    const [filterName, setFilterName] = useState('');
    const [selectedMembers, setSelectedMembers] = useState([]);
    const [totalUnreadMessages, setTotalUnreadMessages] = useState(0);
    const [segment, setSegment] = useState('Contacts');
    const [shouldShowCancel, setShouldShowCancel] = useState(false);
    const [searchText, setSearchText] = useState('');

    useEffect(() => {
        setLoading(true);
        if (myChat) {
            baesappApi.post('/getMemberIds', { Ids: myChat.contacts })
                .then(res => {
                    setLoading(false);
                    setContactsList(res.data);
                    setDisplayedList(res.data);
                })
                .catch(err => {
                    console.log(err);
                    setLoading(false);
                })
        }
    }, [myChat.contacts]);

    useEffect(() => {
        if (myChat) {
            let tempTotalUnreadMessages = 0;
            myChat.chats.map(chat => {
                tempTotalUnreadMessages += chat.newMessages.length;
            });
            setTotalUnreadMessages(tempTotalUnreadMessages);
        };
    }, [myChat.chats]);

    useEffect(() => {
        if (searchText == '') {
            setDisplayedList(getSegmentMembers(contactsList));
        } else {
            const filteredMembers = contactsList.filter(member => member.memberId.username.toUpperCase().includes(searchText.toUpperCase()));
            setDisplayedList(getSegmentMembers(filteredMembers));
        };
    }, [segment]);

    const createNewFilter = () => {
        setLoading(true);
        baesappApi.post('/chatFilters/addFilter', { filterName, selectedMembers })
            .then((res) => {
                setNewFilters(res.data);
                setLoading(false);
                navigate(-1);
            })
            .catch(() => {
                window.alert('Oh snap! something went wrong.')
                setLoading(false);
            })
    };

    const memberClicked = (member_id) => {
        if (selectedMembers.includes(member_id)) {
            setSelectedMembers(selectedMembers.filter(selectedMember => selectedMember != member_id));
        } else {
            setSelectedMembers([...selectedMembers, member_id]);
        };
    };

    const isUniqueFilterName = () => {
        const filterExists = myChat.filters.findIndex(filter => filter.filterName == filterName);
        if (filterExists != -1 || filterName.toLowerCase() == 'unreadchats' || filterName.toLowerCase() == 'unread chats' || filterName.toLowerCase() == 'all chats') {
            return false;
        } else {
            return true;
        }
    };

    const getSegmentMembers = (members) => {
        if (segment == 'Selected') {
            return members.filter(member => selectedMembers.includes(member._id));
        } else {
            return members;
        }
    };

    useEffect(() => {
        if (searchText == '') {
            setDisplayedList(getSegmentMembers(contactsList));
        } else {
            const filteredMembers = contactsList.filter(member => member.memberId.username.toUpperCase().includes(searchText.toUpperCase()));
            setDisplayedList(getSegmentMembers(filteredMembers));
        };
    }, [searchText]);

    useEffect(() => {
        setSearchInputElement(document.getElementById('searchInput'));
    }, []);

    const handleOnBlur = (blurredCause) => {
        if (blurredCause == 'cancel') {
            setSearchText('');
            setShouldShowCancel(false);
        } else if (blurredCause == 'clear') {
            setSearchText('');
            searchInputElement.focus();
        }
    };

    const memberProfileShotClicked = (member) => {
        let memberObj = member.memberId;
        memberObj._id = member._id;
        setLocalObject('memberModal', memberObj);
        navigate('memberModal');
    };

    let alphabetPosition;
    const renderContactsList = displayedList.sort((a, b) => a.memberId.username.localeCompare(b.memberId.username)).map((member, index) => {
        let newAlphabetPosition = false;
        if (alphabetPosition != member.memberId.username.slice(0, 1)) {
            alphabetPosition = member.memberId.username.slice(0, 1)
            newAlphabetPosition = true;
        };
        return (
            <>
                {newAlphabetPosition && <div className="alphabeticalListDevider">
                    <span style={{ marginLeft: '10px' }}>
                        {alphabetPosition}
                    </span>
                </div>}

                <div key={member._id} className="flex horizontal chatCard" >
                    <div className="flex horizontal chatCardLeft">
                        <img onClick={() => memberProfileShotClicked(member)} className="memberBarProfileShot" src={`https://baesapp.com/media/${member.memberId.profileShot}`} />
                        <div onClick={() => memberClicked(member._id)} className="flex vertical chatCardMainContent">
                            <span className="chatCardUsername">{member.memberId.username}</span>
                            <div className="chatCardContentContainer">
                                <span className="chatCardContent">Tap to {selectedMembers.includes(member._id) ? 'remove from filter' : 'add to filter'}</span>
                            </div>
                        </div>
                    </div>
                    <Switch callback={() => memberClicked(member._id)} switchOn={selectedMembers.includes(member._id)} />
                </div>
            </>
        );
    });

    const renderContent = () => {
        if (loading) {
            return (<BaeSvg iconName='sideHeart' color='loaderFlicker absoluteHorizontalCenter' height='20px' />);
        } else if (myChat.contacts?.length == 0) {
            return (
                <>
                    <div className="emptyPageInfoContainer absoluteHorizontalCenter">
                        <span>You have no contacts</span>
                        <button onClick={() => navigate('/home/search')} className="cleanButton emptyPageInfoButton">Find new contacts</button>
                    </div>
                </>
            );
        } else {
            if (!displayedList.length) {
                return (
                    <span className="centerText noResults">No Results</span>
                );
            } else {
                return (
                    renderContactsList
                );
            }
        };

    };

    return (
        <div className='fullScreen removeScrollBar modalContainer'>
            <div className='flex vertical'>
                <div style={{ minHeight: '110px', width: '50px' }} />
                <div className='flex spaceAround'>
                    <div className="searchBarContainer">
                        <div className="searchBar">
                            <input
                                style={{ textAlign: 'center', padding: '6px 10px 10px 10px' }}
                                placeholder="Filter name"
                                value={filterName}
                                onChange={e => setFilterName(e.target.value)}
                                className="cleanInput searchBarInput" />
                        </div>
                    </div>
                </div>
                <div className="segmentContainer flex spaceAround">
                    <button onClick={() => setSegment('Contacts')} style={{ backgroundColor: `${segment == 'Contacts' ? 'var(--text)' : ''}`, color: `${segment == 'Contacts' ? 'var(--base)' : 'var(--secondary)'}`, borderColor: `${segment == 'Contacts' ? '' : 'var(--secondary)'}` }} className="segmentButton">
                        <span>Contacts</span>
                        <strong style={{ fontSize: '1.5em' }}>{contactsList.length}</strong>
                    </button>
                    <button onClick={() => setSegment('Selected')} style={{ backgroundColor: `${segment != 'Contacts' ? 'var(--text)' : ''}`, color: `${segment != 'Contacts' ? 'var(--base)' : 'var(--secondary)'}`, borderColor: `${segment != 'Contacts' ? '' : 'var(--secondary)'}` }} className="segmentButton">
                        <span>Selected</span>
                        <strong style={{ fontSize: '1.5em' }}>{selectedMembers.length}</strong>
                    </button>
                </div>
                {filterName && <div className="flex spaceAround">
                    <div className="searchBarContainer" style={{ width: '100%' }}>
                        <div className="searchBar" style={shouldShowCancel ? { marginLeft: '10px' } : {}}>
                            <img className="searchBarIcon" src={darkModeEnabled ? searchBoxIcon : searchBoxIconDark} />
                            <input
                                onFocus={() => setShouldShowCancel(true)}
                                id="searchInput"
                                placeholder="Search"
                                value={searchText}
                                onChange={e => setSearchText(e.target.value)}
                                className={`cleanInput searchBarInput`} />
                            {searchText != '' && <img onClick={() => handleOnBlur('clear')} className="searchBarClear" src={darkModeEnabled ? clearTextDark : clearText} />}
                        </div>
                        {shouldShowCancel && <span onClick={() => handleOnBlur('cancel')} className="searchBarCancelText">
                            Cancel
                        </span>}
                    </div>
                </div>}
                {
                    filterName ? renderContent() :
                        <div className="flex spaceAround" style={{ width: '100vw', textAlign: 'center', marginTop: '50px' }}>
                            <span className="centerText noResults">No filter name specified</span>
                        </div>
                }
                <ModalHeader title={'ADD NEW FILTER'} actionName={'ADD'} actionActive={(filterName != '') && isUniqueFilterName() && !loading} actionFunction={() => createNewFilter()} />
                <Routes>
                    <Route path="/memberModal/*" element={<MemberModal />} />
                </Routes>
            </div>
        </div>
    );
};

const mapStateToProps = state => {
    return ({
        myChat: state.myChat,
        darkModeEnabled: state.darkModeEnabled
    });
};

export default connect(mapStateToProps, {
    setNewFilters
})(AddNewFilter);
