const myEventsReducer = (myEvents = [], action) => {
    switch (action.type) {
        case 'GOT_MY_DETAILS':
            return action.payload.events;
        case 'MY_EVENTS_SET':
            return action.payload;
        case 'CLEAR_ALL':
            return [];
        default:
            return myEvents;
    };
};

export default myEventsReducer;
