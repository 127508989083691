import React, { useEffect, useState } from "react";

import { connect } from 'react-redux';
import { Routes, Route, useNavigate } from 'react-router-dom';

import MemberModal from '../../../dumbComponents/MemberModal';

import baesappApi from "../../../apis/baesappApi";

import chevronAccent from '../../../assets/chevronAccent.png';
import primaryX from '../../../assets/primaryX.png';
import clearText from '../../../assets/clearText.png';
import clearTextDark from '../../../assets/clearTextDark.png';
import searchBoxIcon from '../../../assets/searchBoxIcon.png';
import checkMarkAccent from '../../../assets/checkMarkAccent.png';
import checkMarkAccentDark from '../../../assets/checkMarkAccentDark.png';
import searchBoxIconDark from '../../../assets/searchBoxIconDark.png';

import {setMyEvents} from '../../../redux/actions';

import setLocalObject from '../../../hooks/setLocalObject';
import _ from "lodash";



const InfoSearch = ({
    darkModeEnabled,
    setMyEvents,
    my_id
}) => {
    const navigate = useNavigate();

    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth()+1;
    const day = date.getDate();

    const [hasRequiredFields, setHasRequiredFields] = useState(false);
    const [eventName, setEventName] = useState('');
    const [hosts, setHosts] = useState([]);
    const [hostSearchText, setHostSearchText] = useState('');
    const [memberPredictions, setMemberPredictions] = useState([]);
    const [doSearch, setDoSearch] = useState(null);
    const [username, setUsername] = useState('');
    const [searching, setSearching] = useState('');
    const [shouldShowCancelHosts, setShouldShowCancelHosts] = useState(false);
    const [searchInputElementHosts, setSearchInputElementHosts] = useState();
    const [dateTime, setDateTime] = useState('');
    const [endDateTime, setEndDateTime] = useState('');
    const [guests, setGuests] = useState([]);
    const [shouldShowCancelGuests, setShouldShowCancelGuests] = useState(false);
    const [searchInputElementGuests, setSearchInputElementGuests] = useState();
    const [guestsSearchText, setGuestsSearchText] = useState('');
    const [guestsMemberPredictions, setGuestsMemberPredictions] = useState([]);
    const [location, setLocation] = useState(false);
    const [addressPredictions, setAddressPredictions] = useState([]);
    const [addressSearchText, setAddressSearchText] = useState('');
    const [dressCode, setDressCode] = useState('');
    const [description, setDescription] = useState('');
    const [addressPlaceId, setAddressPlaceId] = useState('');
    const [rsvp, setRsvp] = useState('');
    const [guestFee, setGuestFee] = useState(0);
    const [isPublic, setIsPublic] = useState(false);

    useEffect(() => {
        setSearchInputElementHosts(document.getElementById('searchInputHosts'));
        setSearchInputElementGuests(document.getElementById('searchInputGuestsd'));
        navigator.geolocation.getCurrentPosition((position) => {
            setLocation(position.coords.latitude + ',' + position.coords.longitude)
        });
    }, []);

    useEffect(() => {
        if (
            eventName != '' &&
            guests.length > 0 &&
            dateTime != '' &&
            endDateTime != '' &&
            addressSearchText != ''
        ) {
            setHasRequiredFields(true);
        }else{
            setHasRequiredFields(false);
        }
    }, [eventName, guests, dateTime, addressSearchText, endDateTime]);

    const openMemberModal = (member) => {
        setLocalObject('memberModal', { _id: member._id, ...member.memberId });
        navigate('memberModal');
    };

    const createEvent = () => {
        let apiHosts = [my_id];
        hosts.map(host => {
            apiHosts.push(host._id);
        });
        let apiGuests = [...apiHosts];
        guests.map(guest => {
            apiGuests.push(guest._id);
        });
        baesappApi.post('/event/createEvent', {
            name: eventName,
            guests: apiGuests,
            hosts: apiHosts,
            dateTime,
            endDateTime,
            placeId: addressPlaceId,
            description,
            dressCode,
            rsvp,
            guestFee,
            isPublic: isPublic == 'true'
        }).then(res => {
            setMyEvents(res.data);
            navigate(-1);
        }).catch(err => {
            console.log(err);
        })
    };

    const removeHost = (memberId) => {
        let tempHosts = [...hosts];
        hosts.map((host, index) => {
            if (host._id == memberId) {
                tempHosts.splice(index, 1);
            };
        });
        setHosts(tempHosts);
    };
    const removeGuests = (memberId) => {
        let tempGuests = [...guests];
        guests.map((guest, index) => {
            if (guest._id == memberId) {
                tempGuests.splice(index, 1);
            };
        });
        setGuests(tempGuests);
    };

    const isHost = (memberId) => {
        let isHost = false;
        hosts.map((host) => {
            if (host._id == memberId) {
                isHost = true;
            };
        });
        return isHost;
    };
    const isGuest = (memberId) => {
        let isGuest = false;
        guests.map((guest) => {
            if (guest._id == memberId) {
                isGuest = true;
            };
        });
        return isGuest;
    };

    const hostsList = hosts.map(member => {
        return (
            <div className="memberBar flex alignCenter" style={{ height: '55px' }}>
                <img onClick={() => openMemberModal(member)} className="memberBarProfileShot" src={`https://baesapp.com/media/${member.memberId.profileShot}`} />
                <div className="memberBarUsername flex vertical" style={{ color: 'var(--text)' }}>
                    <span className="memberBarText">{member.memberId.username}</span>
                    <span className="memberBarText" style={{ opacity: '0.7', fontSize: '0.8em' }}>{member.memberId.preferredName}</span>
                </div>
                <button onClick={() => removeHost(member._id)}>
                    <img style={{ height: '12px' }} src={primaryX} />
                </button>
            </div>
        );
    });
    const guestsList = guests.map(member => {
        return (
            <div className="memberBar flex alignCenter" style={{ height: '55px' }}>
                <img onClick={() => openMemberModal(member)} className="memberBarProfileShot" src={`https://baesapp.com/media/${member.memberId.profileShot}`} />
                <div className="memberBarUsername flex vertical" style={{ color: 'var(--text)' }}>
                    <span className="memberBarText">{member.memberId.username}</span>
                    <span className="memberBarText" style={{ opacity: '0.7', fontSize: '0.8em' }}>{member.memberId.preferredName}</span>
                </div>
                <button onClick={() => removeGuests(member._id)}>
                    <img style={{ height: '12px' }} src={primaryX} />
                </button>
            </div>
        );
    });

    const search = (value, requester) => {
        setUsername(value);
        if (value !== '') {
            setSearching(true);
            baesappApi.post('/searchMembers/username', { username: value, lastItem: false })
                .then(res => {
                    if (requester == 'hosts') {
                        setMemberPredictions(res.data);
                    } else {
                        setGuestsMemberPredictions(res.data)
                    };
                    setSearching(false);
                })
                .catch(err => {
                    console.log(err);
                    setSearching(false);
                })
        }
    };

    const stopSearch = () => {
        clearTimeout(doSearch);
    };

    const hostSearchTextChanged = (e) => {
        setHostSearchText(e.target.value);
        stopSearch();
        if (e.target.value == '') {
            setMemberPredictions([]);
        } else {
            setDoSearch(setTimeout(() => {
                search(e.target.value, 'hosts');
            }, 350))
        }
    };
    const guestsSearchTextChanged = (e) => {
        setGuestsSearchText(e.target.value);
        stopSearch();
        if (e.target.value == '') {
            setGuestsMemberPredictions([]);
        } else {
            setDoSearch(setTimeout(() => {
                search(e.target.value, 'guest');
            }, 350))
        }
    };

    const addressPredictionClicked = (address) => {
        setAddressSearchText(address.description);
        setAddressPlaceId(address.place_id);
        setAddressPredictions([]);
    };

    const searchHostClicked = (member) => {
        let hasMember = false;
        hosts.map(host => {
            if (host._id == member._id) {
                hasMember = true;
            };
        });
        if (!hasMember) {
            setHosts([...hosts, member])
        }
    };
    const searchGuestsClicked = (member) => {
        let hasMember = false;
        guests.map(guest => {
            if (guest._id == member._id) {
                hasMember = true;
            };
        });
        if (!hasMember) {
            setGuests([...guests, member])
        }
    };

    const handleAddressInput = (value) => {
        setAddressSearchText(value);
        if (value == '' || value == ' ') {
            setAddressPredictions([]);
        } else {
            baesappApi.post('/address/predictAddress', { text: value, location, types: 'ge' }).then(res => {
                setAddressPredictions(res.data.predictions);
            });
        }
    };

    const handleOnBlurHosts = (blurredCause) => {
        if (blurredCause == 'cancel') {
            setHostSearchText('');
            setMemberPredictions([]);
            setShouldShowCancelHosts(false);
        } else if (blurredCause == 'clear') {
            setHostSearchText('');
            setMemberPredictions([]);
            searchInputElementHosts.focus();
        }
    };
    const handleOnBlurGuests = (blurredCause) => {
        setGuestsSearchText('');
        setGuestsMemberPredictions([]);
        if (blurredCause == 'cancel') {
            setShouldShowCancelGuests(false);
        } else if (blurredCause == 'clear') {
            searchInputElementGuests.focus();
        }
    };

    const addressPredictionsList = addressPredictions.map(address => {
        let text = address.description;

        let matchedChars = [];
        address.matched_substrings.map(substring => {
            const o = substring.offset;
            const l = substring.length;

            for (let pos = o; pos < (0 + l); pos++) {
                matchedChars.push(pos);
            };
        });

        let finalAddressText = [];
        for (let pos = 0; pos < text.length; pos++) {
            let characterData = {
                value: text[pos],
                matched: matchedChars.includes(pos)
            };
            finalAddressText.push(characterData);
        };

        let finalAddressTextList = finalAddressText.map(char => {
            return (
                <span style={char.matched ? { color: 'var(--accent)', fontWeight: 'bold' } : {}}>{char.value}</span>
            );
        });

        return (
            <button onClick={() => addressPredictionClicked(address)} className='addressSuggestionButton'>
                <div className='addressSuggestionText'>
                    {finalAddressTextList}
                </div>
            </button>
        );
    });

    const memberPredictionsList = memberPredictions.map(member => {
        return (
            <div className="memberBar flex alignCenter" style={{ height: '55px' }}>
                <img onClick={() => openMemberModal(member)} className="memberBarProfileShot" src={`https://baesapp.com/media/${member.memberId.profileShot}`} />
                <button onClick={() => searchHostClicked(member)} className="memberBarUsername flex vertical" style={{ color: 'var(--text)' }}>
                    <span className="memberBarText">{member.memberId.username}</span>
                    <span className="memberBarText" style={{ opacity: '0.7', fontSize: '0.8em' }}>{member.memberId.preferredName}</span>
                </button>
                <div style={isHost(member._id) ? { border: 'none' } : {}} className="selectContainer" onClick={() => searchHostClicked(member)}>
                    {isHost(member._id) && <img className='checkMarkSelect' src={darkModeEnabled ? checkMarkAccentDark : checkMarkAccent}></img>}
                </div>
            </div>
        )
    });
    const guestsMemberPredictionsList = guestsMemberPredictions.map(member => {
        return (
            <div className="memberBar flex alignCenter" style={{ height: '55px' }}>
                <img onClick={() => openMemberModal(member)} className="memberBarProfileShot" src={`https://baesapp.com/media/${member.memberId.profileShot}`} />
                <button onClick={() => searchGuestsClicked(member)} className="memberBarUsername flex vertical" style={{ color: 'var(--text)' }}>
                    <span className="memberBarText">{member.memberId.username}</span>
                    <span className="memberBarText" style={{ opacity: '0.7', fontSize: '0.8em' }}>{member.memberId.preferredName}</span>
                </button>
                <div style={isGuest(member._id) ? { border: 'none' } : {}} className="selectContainer" onClick={() => searchGuestsClicked(member)}>
                    {isGuest(member._id) && <img className='checkMarkSelect' src={darkModeEnabled ? checkMarkAccentDark : checkMarkAccent}></img>}
                </div>
            </div>
        )
    });

    const renderFields = () => {
        return (
            <>
                <div className='flex vertical'>
                    <div className='infoFieldContainer'>
                        <span className='infoFieldLabel'>Event name</span>
                        <div className='infoFieldInputContainer'>
                            <input
                                className={`cleanInput infoFieldTextInput`}
                                placeholder='Type value...'
                                onChange={(e) => setEventName(e.target.value)}
                                value={eventName}
                            />
                        </div>
                    </div>
                </div>
                <div className='flex vertical'>
                    <div className='infoFieldContainer'>
                        <span className='infoFieldLabel'>Is public event</span>
                        <div className='infoFieldInputContainer'>
                            <input
                                className={`cleanInput infoFieldTextInput`}
                                placeholder='Type value...'
                                onChange={(e) => setIsPublic(e.target.value)}
                                value={isPublic}
                            />
                        </div>
                    </div>
                </div>
                <span className='infoFieldLabel'>Hosts</span>
                {hostsList}
                <div className='flex vertical'>
                    <div className="searchBarContainer" style={{ alignSelf: 'center', zIndex: '1' }}>
                        <div className="searchBar" style={shouldShowCancelHosts ? { marginLeft: '10px' } : {}}>
                            <img className="searchBarIcon" src={darkModeEnabled ? searchBoxIcon : searchBoxIconDark} />
                            <input
                                onFocus={() => setShouldShowCancelHosts(true)}
                                id="searchInputHosts"
                                placeholder='Search...'
                                value={hostSearchText}
                                onChange={hostSearchTextChanged}
                                className={`cleanInput searchBarInput`} />
                            {hostSearchText != '' && <img onClick={() => handleOnBlurHosts('clear')} className="searchBarClear" src={darkModeEnabled ? clearTextDark : clearText} />}
                        </div>
                        {shouldShowCancelHosts && <span onClick={() => handleOnBlurHosts('cancel')} className="searchBarCancelText">
                            Done
                        </span>}
                    </div>
                    {memberPredictionsList.length > 0 && <div className='addressSuggestionsContainer' style={{ marginTop: '-40px', paddingTop: '45px' }}>
                        {memberPredictionsList}
                    </div>}
                </div>
                {guestsList}
                <div className='flex vertical'>
                    <div className="searchBarContainer" style={{ alignSelf: 'center', zIndex: '1' }}>
                        <div className="searchBar" style={shouldShowCancelGuests ? { marginLeft: '10px' } : {}}>
                            <img className="searchBarIcon" src={darkModeEnabled ? searchBoxIcon : searchBoxIconDark} />
                            <input
                                onFocus={() => setShouldShowCancelGuests(true)}
                                id="searchInputGuests"
                                placeholder='Search...'
                                value={guestsSearchText}
                                onChange={guestsSearchTextChanged}
                                className={`cleanInput searchBarInput`} />
                            {guestsSearchText != '' && <img onClick={() => handleOnBlurGuests('clear')} className="searchBarClear" src={darkModeEnabled ? clearTextDark : clearText} />}
                        </div>
                        {shouldShowCancelGuests && <span onClick={() => handleOnBlurGuests('cancel')} className="searchBarCancelText">
                            Done
                        </span>}
                    </div>
                    {guestsMemberPredictions.length > 0 && <div className='addressSuggestionsContainer' style={{ marginTop: '-40px', paddingTop: '45px' }}>
                        {guestsMemberPredictionsList}
                    </div>}
                </div>
                <div className='flex vertical'>
                    <div className='infoFieldContainer'>
                        <span className='infoFieldLabel'>Guest Fee</span>
                        <div className='infoFieldInputContainer'>
                            <input
                                className={`cleanInput infoFieldTextInput`}
                                onChange={(e) => setGuestFee(e.target.value)}
                                value={guestFee}
                            />
                        </div>
                    </div>
                </div>
                <div className='flex vertical'>
                    <div className='infoFieldContainer'>
                        <span className='infoFieldLabel'>Event date and time</span>
                        <div className='infoFieldInputContainer'>
                            <input
                                type="datetime-local"
                                min={`${year}-${month < 10 ? '0'+month : month}-${day}T00:00`}
                                className={`cleanInput infoFieldTextInput`}
                                placeholder='Type value...'
                                onChange={(e) => setDateTime(e.target.value)}
                                value={dateTime}
                            />
                        </div>
                    </div>
                </div>
                <div className='flex vertical'>
                    <div className='infoFieldContainer'>
                        <span className='infoFieldLabel'>Event End date and time</span>
                        <div className='infoFieldInputContainer'>
                            <input
                                type="datetime-local"
                                min={`${year}-${month < 10 ? '0'+month : month}-${day}T00:00`}
                                className={`cleanInput infoFieldTextInput`}
                                placeholder='Type value...'
                                onChange={(e) => setEndDateTime(e.target.value)}
                                value={endDateTime}
                            />
                        </div>
                    </div>
                </div>
                <div className='flex vertical'>
                    <div className='infoFieldContainer'>
                        <span className='infoFieldLabel'>Event location</span>
                        <div className='infoFieldInputContainer'>
                            <input
                                className={`cleanInput infoFieldTextInput`}
                                placeholder='Search address...'
                                onChange={(e) => handleAddressInput(e.target.value)}
                                value={addressSearchText}
                            />
                        </div>
                    </div>
                    {addressPredictions.length > 0 && <div className='addressSuggestionsContainer'>
                        {addressPredictionsList}
                    </div>}
                </div>
                <div className='flex vertical'>
                    <div className='infoFieldContainer'>
                        <span className='infoFieldLabel'>Dress code</span>
                        <div className='infoFieldInputContainer'>
                            <input
                                className={`cleanInput infoFieldTextInput ${'infoFieldTextInputNoValue'}`}
                                placeholder='Type value...'
                                onChange={(e) => setDressCode(e.target.value)}
                                value={dressCode}
                            />
                        </div>
                    </div>
                </div>
                <div className='flex vertical'>
                    <div className='infoFieldContainer'>
                        <span className='infoFieldLabel'>Notes</span>
                        <div className='infoFieldInputContainer'>
                            <input
                                className={`cleanInput infoFieldTextInput`}
                                placeholder='Type value...'
                                onChange={(e) => setDescription(e.target.value)}
                                value={description}
                            />
                        </div>
                    </div>
                </div>
                <div className='flex vertical'>
                    <div className='infoFieldContainer'>
                        <span className='infoFieldLabel'>rsvp</span>
                        <div className='infoFieldInputContainer'>
                            <input
                                type='datetime-local'
                                className={`cleanInput infoFieldTextInput`}
                                placeholder='Type value...'
                                max={dateTime}
                                min={`${year}-${month < 10 ? '0'+month : month}-${day}T00:00`}
                                onChange={(e) => setRsvp(e.target.value)}
                                value={rsvp}
                            />
                        </div>
                    </div>
                </div>
            </>
        );
    }

    const renderContent = () => {
        return (
            <div className='fullScreen removeScrollBar modalContainer'>
                <div style={{ height: '60px' }}></div>
                {renderFields()}
                <div style={{ height: '60px' }}></div>
                <div className="secondaryScreenHeaderContainer">
                    <div className='secondaryScreenHeader'>
                        <div onClick={() => navigate(-1)} className="headerBackButtonContainer">
                            <img src={chevronAccent} className="headerBackIcon" />
                        </div>
                        <span className="modalHeaderTitle">Create new event</span>
                        <div>
                            {
                                hasRequiredFields ?
                                    <span className="headerButtonText" onClick={() => createEvent()}>Create</span> :
                                    <span className="headerButtonText" style={{ color: 'gray' }}>Create</span>
                            }
                        </div>
                    </div>
                </div>
            </div >
        );
    };

    return (
        <Routes>
            <Route path='/*' element={renderContent()} />
            <Route path='/memberModal/*' element={<MemberModal />} />
        </Routes>
    );
};

const mapStateToProps = state => {
    return ({
        darkModeEnabled: state.darkModeEnabled,
        my_id: state.my_id
    });
};

export default connect(mapStateToProps, {setMyEvents})(InfoSearch);