const authenticatedReducer = (authenticated = false, action) => {
    switch(action.type){
        case 'AUTHENTICATION_SET':
            return action.payload;
        case 'CLEAR_ALL':
            return false;
        default: 
            return authenticated;
    }
};

export default authenticatedReducer;