import React, { useEffect, useState } from "react";

import { connect } from 'react-redux';

import { useNavigate } from "react-router-dom";

import getLocal from '../../hooks/getLocal';
import setLocalObject from '../../hooks/setLocalObject';
import getLocalToObject from "../../hooks/getLocalToObject";

import chatInactive from '../../assets/chatInactive.png';
import chatInactiveDark from '../../assets/chatInactiveDark.png';
import mediaInactive from '../../assets/mediaInactive.png';
import mediaInactiveDark from '../../assets/mediaInactiveDark.png';
import walletInactive from '../../assets/walletInactive.png';
import walletInactiveDark from '../../assets/walletInactiveDark.png';
import infoInactive from '../../assets/infoInactive.png';
import infoInactiveDark from '../../assets/infoInactiveDark.png';
import backArrow from '../../assets/backArrow.png';
import backArrowDark from '../../assets/backArrowDark.png';
import openChat from '../../assets/openChat.png';
import openChatDark from '../../assets/openChatDark.png';
import events from '../../assets/events.png';
import eventsDark from '../../assets/eventsDark.png';

import { setShowNotificationBar, setFollowingModalShowFollowers } from '../../redux/actions';

const VisitTopBar = ({
    showNotificationBar,
    setShowNotificationBar,
    setFollowingModalShowFollowers,
    darkModeEnabled,
    membersTyping,
    membersTypingOpenChat,
    setShowVisitMemberModal,
    visitMember
}) => {

    useEffect(() => {
        if (showNotificationBar) {
            setTimeout(() => {
                setShowNotificationBar(false);
            }, 4000);
        }
    }, [showNotificationBar]);

    const path = window.location.pathname;
    const navigate = useNavigate();

    const [showBubbleNavigator, setShowBubbleNavigator] = useState(false);

    useEffect(() => {

    }, [])

    const bubbleNavigationClicked = (navigatePath) => {
        navigate(navigatePath);
        setShowBubbleNavigator(false);
    };

    const renderBubbleNavigator = () => {
        return (
            <>
                <div onClick={() => setShowBubbleNavigator(false)} className="bubbleNavigatorContainer fullScreen absolute" />
                <div className="bubbleNavigator topModal flex fixed vertical right left spaceAround alignCenter">
                    <button onClick={() => bubbleNavigationClicked('chat')} className="bubbleNavigatorButton cleanButton">
                        <img className="bubbleNavigatorIcon" src={darkModeEnabled ? chatInactiveDark : chatInactive} />
                        <span>Chat</span>
                    </button>
                    <button onClick={() => bubbleNavigationClicked('media')} className="bubbleNavigatorButton cleanButton">
                        <img className="bubbleNavigatorIcon" src={darkModeEnabled ? mediaInactiveDark : mediaInactive} />
                        <span>Media</span>
                    </button>
                    <button onClick={() => bubbleNavigationClicked('info')} className="bubbleNavigatorButton cleanButton">
                        <img className="bubbleNavigatorIcon" src={darkModeEnabled ? infoInactiveDark : infoInactive} />
                        <span>Info</span>
                    </button>
                    <button onClick={() => bubbleNavigationClicked('wallet')} className="bubbleNavigatorButton cleanButton">
                        <img className="bubbleNavigatorIcon" src={darkModeEnabled ? walletInactiveDark : walletInactive} />
                        <span>Wallet</span>
                    </button>
                    <button onClick={() => bubbleNavigationClicked('openChat')} className="bubbleNavigatorButton cleanButton">
                        <img className="bubbleNavigatorIcon" src={darkModeEnabled ? openChatDark : openChat} />
                        <span>Open Chat</span>
                    </button>
                    <button onClick={() => bubbleNavigationClicked('openChat')} className="bubbleNavigatorButton cleanButton">
                        <img className="bubbleNavigatorIcon" src={darkModeEnabled ? eventsDark : events} />
                        <span>Events</span>
                    </button>
                </div>
            </>
        );
    };

    const getRightButtonIcon = () => {
        if (path.startsWith('chat', 7)) {
            return darkModeEnabled ? chatInactiveDark : chatInactive;
        } else if (path.startsWith('media', 7)) {
            return darkModeEnabled ? mediaInactiveDark : mediaInactive;
        } else if (path.startsWith('wallet', 7)) {
            return darkModeEnabled ? walletInactiveDark : walletInactive;
        } else if (path.startsWith('info', 7)) {
            return darkModeEnabled ? infoInactiveDark : infoInactive;
        } else if (path.startsWith('openChat', 7)) {
            return darkModeEnabled ? openChatDark : openChat;
        } else if (path.startsWith('events', 7)) {
            return darkModeEnabled ? eventsDark : events;
        }
    };
    const getRightButtonText = () => {
        if (path.startsWith('chat', 7)) {
            return 'Chat';
        } else if (path.startsWith('media', 7)) {
            return 'Media';
        } else if (path.startsWith('wallet', 7)) {
            return 'Wallet';
        } else if (path.startsWith('info', 7)) {
            return 'Info';
        } else if (path.startsWith('openChat', 7)) {
            return 'Open chat';
        } else if (path.startsWith('games', 7)) {
            return 'Games';
        } else if (path.startsWith('events', 7)) {
            return 'Events';
        }
    };

    const openMemberModal = () => {
        setLocalObject('memberModal', { _id: visitMember.memberEssentials._id, ...visitMember.memberEssentials });
        setShowVisitMemberModal(true);
    }; 

    const renderRightButtons = () => {
        return (
            <div onClick={openMemberModal} className="topBarVisitRight flex horizontal right alignCenter">
                <span className="secondaryText">{getRightButtonText()}</span>
                <img className="visitTopBarToolIcon" src={`https://baesapp.com/media/${visitMember.memberEssentials.profileShot}`} />
            </div>
        );
    };

    const showNotificationBarClicked = () => {
        if (showNotificationBar.path == '/media/following') setFollowingModalShowFollowers(true);
        if (showNotificationBar.path == '/media/memberModal') {
            setLocalObject('memberModal', {
                _id: showNotificationBar._id,
                username: showNotificationBar.username,
                profileShot: showNotificationBar.profileShot
            });
        }
        navigate(showNotificationBar.path);
    };

    const navigateBack = () => {
        navigate(-1)
    };

    return (
        <div className={'topBar fixed flex horizontal'} style={{backgroundColor: darkModeEnabled ? '#00000059' : '#ffffff59' }}>
            {showNotificationBar ?
                <div onClick={showNotificationBarClicked} className="notificationBar flex spaceAround alignCenter">
                    <img className="topBarVisitProfileShot" src={`https://baesapp.com/media/${showNotificationBar.profileShot}`} />
                    <p>{showNotificationBar.username}</p>
                    <p>{showNotificationBar.event}</p>
                </div> :
                <div className="topBarContentContainer">
                    <div onClick={navigateBack} className="topBarHomeLeft flex left alignCenter spaceAround">
                        <img className="headerBackIcon" style={{marginRight: '10px'}} src={darkModeEnabled ? backArrowDark : backArrow}/>
                        <div className="flex vertical">
                            <span style={{'fontSize': '1.3em'}}>{visitMember.memberEssentials.username}</span>
                            {membersTyping.includes(visitMember.memberEssentials._id) || membersTypingOpenChat.includes(visitMember.memberEssentials._id) && <span className="typingText">typing...</span>}
                        </div>
                    </div>
                    {renderRightButtons()}
                </div>
            }
            {showBubbleNavigator && renderBubbleNavigator()}
        </div>
    );
};

const mapStateToProps = state => {
    return ({
        showNotificationBar: state.showNotificationBar,
        darkModeEnabled: state.darkModeEnabled,
        membersTyping: state.membersTyping,
        membersTypingOpenChat: state.membersTypingOpenChat,
        visitMember: state.visitMember
    });
};

export default connect(mapStateToProps, {
    setShowNotificationBar,
    setFollowingModalShowFollowers
})(VisitTopBar);
