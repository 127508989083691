import getLocalToObject from "./getLocalToObject";
import setLocalObject from "./setLocalObject";

const killDuplicateMessages = () => {
    let clientChats = getLocalToObject('chats');
    
    for(let key in clientChats){
        let messageIds = [];
        clientChats[key].conversation.messages.map((message, index) => {
            if(messageIds.includes(message._id) || message._id == undefined){
                clientChats[key].conversation.messages.splice(index, 1);
            }else{
                messageIds.push(message._id);
            };
        });
    };

    setLocalObject('chats', clientChats);
};

export default killDuplicateMessages;