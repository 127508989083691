import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux'

import baesappApi from '../../../apis/baesappApi';

import ModalHeader from '../../../dumbComponents/Home/modalHeader';
import { useNavigate } from 'react-router-dom';

const AddAddress = ({
    _setLocation,
    _location
}) => {

    const navigate = useNavigate();

    const [activeAddress, setActiveAddress] = useState(false);
    const [addressPredictions, setAddressPredictions] = useState([]);
    const [addressText, setAddressText] = useState('');
    const [location, setLocation] = useState(false);
    const [tempLocation, setTempLocation] = useState(false);

    useEffect(() => {
        if(_location)setAddressText(_location.description);
        navigator.geolocation.getCurrentPosition((position) => {
            setLocation(position.coords.latitude + ',' + position.coords.longitude)
        });
    }, []);

    const applyAddress = () => {
        _setLocation(tempLocation);
        navigate(-1);
    };

    const addressPredictionClicked = (address) => {
        setAddressPredictions([]);
        setTempLocation({
            place_id: address.place_id,
            description: address.description
        });
        setAddressText(address.description);
    };

    const addrressPredictionsList = addressPredictions.map(address => {
        let text = address.description;

        let matchedChars = [];
        address.matched_substrings.map(substring => {
            const o = substring.offset;
            const l = substring.length;

            for (let pos = o; pos < (0 + l); pos++) {
                matchedChars.push(pos);
            };
        });

        let finalAddressText = [];
        for (let pos = 0; pos < text.length; pos++) {
            let characterData = {
                value: text[pos],
                matched: matchedChars.includes(pos)
            };
            finalAddressText.push(characterData);
        };

        let finalAddressTextList = finalAddressText.map(char => {
            return (
                <span style={char.matched ? { color: 'var(--accent)', fontWeight: 'bold' } : {}}>{char.value}</span>
            );
        });

        return (
            <button onClick={() => addressPredictionClicked(address)} className='addressSuggestionButton'>
                <div className='addressSuggestionText'>
                    {finalAddressTextList}
                </div>
            </button>
        );
    });

    const handleTextInput = (value) => {
        setAddressText(value);
        setTempLocation(false);
        if (value == '' || value == ' ') {
            setActiveAddress(false);
            setAddressPredictions([]);
        } else {
            if (!activeAddress) setActiveAddress(true);
            baesappApi.post('/address/predictAddress', { text: value, location, types: 'ge' }).then(res => {
                setAddressPredictions(res.data.predictions);
            });
        }
    };

    return (
        <div className='fullScreen removeScrollBar modalContainer'>
            <div style={{ height: '100px' }} />
            <div className='flex vertical'>

                <div className="infoCategory">
                    <span style={{ marginLeft: '15px' }}>
                        Search location
                    </span>
                </div>
                <div className="infoField" style={{ zIndex: '1' }}>
                    <div className="flex alignCenter" style={{ width: '100%', height: '100%', color: 'var(--secondary)', fontWeight: '600', textAlign: 'start' }}>
                        <input
                            className={`infoInputBox`}
                            placeholder='Type location...'
                            onFocus={() => setActiveAddress(true)}
                            onChange={(e) => handleTextInput(e.target.value)}
                            value={addressText}
                        />
                    </div>
                </div>
                {(activeAddress && addressPredictions.length > 0) && <div className='addressSuggestionsContainer'>
                    {addrressPredictionsList}
                </div>}
            </div>
            <ModalHeader title={'POST LOCATION'} actionName={'APPLY'} actionActive={true} actionFunction={() => applyAddress()} />
        </div>
    );
};

const mapStateToProps = state => {
    return ({
    });
};

export default connect(mapStateToProps)(AddAddress);