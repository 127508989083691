import React, { useState, useEffect, useRef } from "react";

import { connect } from 'react-redux';
import { useNavigate, Route, Routes } from 'react-router-dom';
import { setNewPost, deletePost, setMyPosts, setSavedPosts, setVisitMember } from '../../../redux/actions';

import ModalHeader from "../../../dumbComponents/Home/modalHeader";
import AudioRecorder from "../../../dumbComponents/AudioRecorder";
import BaeSvg from "../../../dumbComponents/BaeSvg";
import setLocalObject from "../../../hooks/setLocalObject";
import MediaShare from '../../Home/Media/MediaShare';
import MediaComments from "../../Home/Media/MediaComments";
import baesappApi from "../../../apis/baesappApi";

const ViewPost = ({
    mediaPosts,
    myMedia,
    myId,
    visiting = false,
    saved = false,
    setSavedPosts,
    setVisitMember
}) => {

    const navigate = useNavigate();

    const [currPost, setCurrPost] = useState(false);
    const [prevPost, setPrevPost] = useState(false);
    const [nextPost, setNextPost] = useState(false);
    const [currPostId, setCurrPostId] = useState();
    const [slideBaseX, setSlideBaseX] = useState('false');
    const [slideXDistance, setSlideXDistance] = useState(0);
    const [showBars, setShowBars] = useState(true);
    const [isMyPosts, setIsMyPosts] = useState(false);
    const [currPostSaved, setCurrPostSaved] = useState(false);
    const [savedLoading, setSavedLoading] = useState(false);

    useEffect(() => {
        let postGroup;
        const localStoragePostId = window.localStorage.getItem('activePostId');
        let newCurrPostIndex;
        if (visiting) {
            postGroup = visiting;
            newCurrPostIndex = visiting.findIndex(post => post._id == localStoragePostId);
        } else if (saved) {
            postGroup = saved;
            newCurrPostIndex = saved.findIndex(post => post._id == localStoragePostId);
        } else if (mediaPosts && myMedia) {
            if (mediaPosts.length) {
                newCurrPostIndex = mediaPosts.findIndex(post => post._id == localStoragePostId);
                if (newCurrPostIndex == -1) {
                    newCurrPostIndex = myMedia.posts.findIndex(post => post._id == localStoragePostId);
                    if (newCurrPostIndex == -1) {
                        navigate('/home/media');
                        window.alert('The post you wanted to view is no longer available');
                    } else {
                        postGroup = myMedia.posts;
                        setIsMyPosts(true);
                    }
                } else {
                    postGroup = mediaPosts;
                    setIsMyPosts(false);
                }
            }
        }
        if (mediaPosts?.length && myMedia) {
            setCurrPost(postGroup[newCurrPostIndex]);
            if (myMedia) setCurrPostSaved(myMedia.saved.includes(localStoragePostId));
            if (newCurrPostIndex == 0) {
                setNextPost(false);
                setPrevPost(postGroup[newCurrPostIndex + 1]);
            } else if (newCurrPostIndex + 1 == postGroup.length) {
                setNextPost(postGroup[newCurrPostIndex - 1]);
                setPrevPost(false);
            } else {
                setNextPost(postGroup[newCurrPostIndex - 1]);
                setPrevPost(postGroup[newCurrPostIndex + 1]);
            }
        }
    }, [currPostId, mediaPosts, myMedia, visiting, saved]);

    const openPrev = () => {
        if (prevPost) {
            window.localStorage.setItem('activePostId', prevPost._id);
            setCurrPostId(prevPost._id);
        };
    };
    const openNext = () => {
        if (nextPost) {
            window.localStorage.setItem('activePostId', nextPost._id);
            setCurrPostId(nextPost._id);
        };
    };

    const visitMember = (memberId, _id) => {
        let member = { ...memberId, _id };
        setVisitMember({
            path: 'media',
            memberEssentials: member
        });
        navigate(`/visit/media?_id=${_id}`);
    };

    const memberProfileShotClicked = (member) => {
        let memberObj = member.memberId;
        memberObj._id = member._id;
        setLocalObject('memberModal', memberObj);
        if (visiting) {
            navigate(`/visit/media/memberModal?_id=${member._id}`);
        } else {
            navigate('/home/media/memberModal');
        }
    };

    const handleTouchMove = (e) => {
        const currX = Math.trunc(e.touches[0].clientX);
        if (slideBaseX == 'false') {
            setSlideBaseX(currX);
        } else {
            let invertedSlideDistance = slideBaseX - currX;
            let newSlideDistance = -invertedSlideDistance;
            if (newSlideDistance > -200 && newSlideDistance < 200) {
                setSlideXDistance(newSlideDistance);
            };
        }
    };
    const handleTouchEnd = () => {
        if (slideXDistance < -80) {
            openNext();
        } else if (slideXDistance > 80) {
            openPrev();
        }
        setSlideBaseX('false');
        setSlideXDistance(0);
    };
    function getDateInfo(dateString) {
        const date = new Date(dateString);
        const months = [
            "January", "February", "March", "April", "May", "June", "July",
            "August", "September", "October", "November", "December"
        ];

        const dayOfMonth = date.getDate();
        const monthName = months[date.getMonth()];
        const year = date.getFullYear();

        return {
            dayOfMonth,
            monthName,
            year
        };
    }
    const starClicked = () => {
        setSavedLoading(true);
        baesappApi.post('/savePost', { postId: currPost._id, shouldRemove: currPostSaved })
            .then(res => {
                setSavedPosts(res.data);
                setSavedLoading(false);
            })
            .catch(err => {
                setSavedLoading(false);
                window.alert('something unexpected went wrong')
            })
    };

    const renderNext = () => {
        if (nextPost) {
            return (<>
                {
                    nextPost.data[0].contentType == 'aIImage' ?
                        <img
                            style={{
                                height: '100%',
                                width: '100%',
                                objectFit: 'cover'
                            }} src={nextPost.data[0].aIImageUrl} /> :
                        nextPost.data[0].contentType == 'image' ?
                            <img
                                style={{
                                    height: '100%',
                                    width: '100%',
                                    objectFit: 'cover'
                                }} src={`https://baesapp.s3.eu-central-1.amazonaws.com/${nextPost.data[0].s3Key}`} /> :
                            nextPost.data[0].contentType == 'video' ?
                                <img
                                    style={{
                                        height: '100%',
                                        width: '100%',
                                        objectFit: 'cover'
                                    }} src={`https://baesapp.s3.eu-central-1.amazonaws.com/${nextPost.data[0].thumbnail}`} /> : nextPost.data[0].contentType == 'quote' ?
                                    <div className="flex vertical" style={{ marginTop: '20px' }}>
                                        <div className="flex alignCenter" style={{ gap: '10px', marginBottom: '-55px', zIndex: '1', marginLeft: '20px' }}>
                                            <img style={{ height: '40px', width: '40px', borderRadius: '50%' }} src={`https://baesapp.com/media/${isMyPosts ? myId.profileShot : nextPost.poster_id.memberId.profileShot}`} />
                                            <span style={{ fontSize: '1.5em', fontWeight: 'bold' }}>{isMyPosts ? myId.username : nextPost.poster_id.memberId.username}</span>
                                        </div>
                                        <textarea
                                            placeholder="Type your quote here..."
                                            value={nextPost.data[0].s3Key}
                                            readOnly
                                            rows='6'
                                            style={{
                                                fontSize: '1.7em',
                                                border: 'none',
                                                resize: 'none',
                                                width: 'calc(100% - 60px)',
                                                borderRadius: '30px',
                                                backgroundColor: 'var(--primaryGlass)',
                                                color: 'var(--text)',
                                                padding: '60px 20px 20px',
                                                alignSelf: 'center',
                                                outline: 'none',
                                                border: '2px solid var(--accent)'
                                            }} />
                                    </div> :
                                    nextPost.data[0].contentType == 'audio' ?
                                        <BaeSvg iconName="audio" color="accent" height="50px" /> :
                                        null
                }
            </>
            );
        } else {
            return null;
        }
    };
    const renderPrev = () => {
        if (prevPost) {
            return (<>
                {
                    prevPost.data[0].contentType == 'aIImage' ?
                        <img
                            style={{
                                height: '100%',
                                width: '100%',
                                objectFit: 'cover'
                            }} src={nextPost.data[0].aIImageUrl} /> :
                        prevPost.data[0].contentType == 'image' ?
                            <img
                                style={{
                                    height: '100%',
                                    width: '100%',
                                    objectFit: 'cover'
                                }} src={`https://baesapp.s3.eu-central-1.amazonaws.com/${prevPost.data[0].s3Key}`} /> :
                            prevPost.data[0].contentType == 'video' ?
                                <img
                                    style={{
                                        height: '100%',
                                        width: '100%',
                                        objectFit: 'cover'
                                    }} src={`https://baesapp.s3.eu-central-1.amazonaws.com/${prevPost.data[0].thumbnail}`} /> : prevPost.data[0].contentType == 'quote' ?
                                    <div className="flex vertical" style={{ marginTop: '20px' }}>
                                        <div className="flex alignCenter" style={{ gap: '10px', marginBottom: '-55px', zIndex: '1', marginLeft: '20px' }}>
                                            <img style={{ height: '40px', width: '40px', borderRadius: '50%' }} src={`https://baesapp.com/media/${isMyPosts ? myId.profileShot : prevPost.poster_id.memberId.profileShot}`} />
                                            <span style={{ fontSize: '1.5em', fontWeight: 'bold' }}>{isMyPosts ? myId.username : prevPost.poster_id.memberId.username}</span>
                                        </div>
                                        <textarea
                                            placeholder="Type your quote here..."
                                            value={prevPost.data[0].s3Key}
                                            readOnly
                                            rows='6'
                                            style={{
                                                fontSize: '1.7em',
                                                border: 'none',
                                                resize: 'none',
                                                width: 'calc(100% - 60px)',
                                                borderRadius: '30px',
                                                backgroundColor: 'var(--primaryGlass)',
                                                color: 'var(--text)',
                                                padding: '60px 20px 20px',
                                                alignSelf: 'center',
                                                outline: 'none',
                                                border: '2px solid var(--accent)'
                                            }} />
                                    </div> :
                                    prevPost.data[0].contentType == 'audio' ?
                                        <BaeSvg iconName="audio" color="accent" height="50px" /> :
                                        null
                }
            </>
            );
        } else {
            return null;
        }
    };

    const renderContent = () => {
        if (currPost) {
            return (
                <>
                    {
                        currPost.data[0].contentType == 'aIImage' ?
                            <img
                                className="postViewImage"
                                style={{
                                    borderRadius: '7px'
                                }} src={nextPost.data[0].aIImageUrl} /> :
                            currPost.data[0].contentType == 'image' ?
                                <img
                                    className="postViewImage"
                                    style={{ borderRadius: '7px' }} src={`https://baesapp.s3.eu-central-1.amazonaws.com/${currPost.data[0].s3Key}`} /> :
                                currPost.data[0].contentType == 'video' ?
                                    <video
                                        className="postViewImage" playsInline loop autoPlay muted controls style={{ borderRadius: '7px', alignSelf: 'center' }} >
                                        <source src={`https://baesapp.s3.eu-central-1.amazonaws.com/${currPost.data[0].s3Key}`} type="video/mp4" />
                                    </video> : currPost.data[0].contentType == 'quote' ?
                                        <div className="flex vertical" style={{ marginTop: '20px' }}>
                                            <div className="flex alignCenter" style={{ gap: '10px', marginBottom: '-55px', zIndex: '1', marginLeft: '20px' }}>
                                                <img style={{ height: '40px', width: '40px', borderRadius: '50%' }} src={`https://baesapp.com/media/${isMyPosts ? myId.profileShot : currPost.poster_id.memberId.profileShot}`} />
                                                <span style={{ fontSize: '1.5em', fontWeight: 'bold' }}>{isMyPosts ? myId.username : currPost.poster_id.memberId.username}</span>
                                            </div>
                                            <textarea
                                                placeholder="Type your quote here..."
                                                value={currPost.data[0].s3Key}
                                                readOnly
                                                rows='6'
                                                style={{
                                                    fontSize: '1.7em',
                                                    border: 'none',
                                                    resize: 'none',
                                                    width: 'calc(100% - 60px)',
                                                    borderRadius: '30px',
                                                    backgroundColor: 'var(--primaryGlass)',
                                                    color: 'var(--text)',
                                                    padding: '60px 20px 20px',
                                                    alignSelf: 'center',
                                                    outline: 'none',
                                                    border: '2px solid var(--accent)'
                                                }} />
                                        </div> :
                                        currPost.data[0].contentType == 'audio' ?
                                            <AudioRecorder recordable={false} base64={`https://baesapp.s3.eu-central-1.amazonaws.com/${currPost.data[0].s3Key}`} /> :
                                            null
                    }
                </>
            );
        } else {
            return null;
        }
    };

    return (
        <>
            <div
                style={{
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
                className="flex fullScreen removeScrollBar modalContainer">
                <div
                    onTouchEnd={handleTouchEnd}
                    onTouchMove={(e) => handleTouchMove(e)}
                    onClick={() => setShowBars(!showBars)}
                    style={{
                        width: '100vw',
                        height: '100vh',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                    {renderContent()}
                </div>
                <div
                    style={{
                        width: `${slideXDistance / 2}vw`,
                        height: `${slideXDistance / 2.5}vh`,
                        zIndex: '2'
                    }}
                    className="neighbourImage flex spaceAround alignCenter"
                >
                    {renderPrev()}
                </div>
                <div
                    style={{
                        width: `${slideXDistance / -2}vw`,
                        height: `${slideXDistance / -2.5}vh`,
                        zIndex: '2'
                    }}
                    className="neighbourImage flex spaceAround alignCenter"
                >
                    {renderNext()}
                </div>
                {showBars && currPost &&
                    <>
                        <div className="visitPostTopBar vertical flex">
                            <div
                                className="flex spaceBetween alignCenter"
                                style={{ height: '50px', width: 'fill-available' }}
                            >
                                <button style={{ width: '33%', display: 'flex', justifyContent: 'flex-start' }} onClick={() => navigate(-1)}>
                                    <BaeSvg iconName="x" color="text" height='11px' />
                                </button>
                                <div className="flex vertical" style={{ width: '33%', textAlign: 'center', justifyContent: 'center' }}>
                                    <span>{getDateInfo(currPost.dateTime).dayOfMonth + ' ' + getDateInfo(currPost.dateTime).monthName}</span>
                                    <span>{getDateInfo(currPost.dateTime).year}</span>
                                </div>
                                <span
                                    onClick={() => {
                                        if (currPost.location) {
                                            window.open(`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(currPost.location.description)}&query_place_id=${currPost.location.place_id}`, "_blank");
                                        };
                                    }}
                                    className="oneLineify"
                                    style={{
                                        width: '33%',
                                        maxWidth: '33%',
                                        textAlign: 'end',
                                        fontWeight: 'bold'
                                    }}>{currPost.location ? currPost.location.description : ''}</span>
                            </div>
                            {
                                currPost.members.length ?
                                    <div
                                        style={{
                                            width: '100%',
                                            maxWidth: '500px',
                                            height: '50px',
                                            overflow: 'scroll',
                                            gap: '50px',
                                            justifyContent: 'flex-start',
                                            padding: '0 20px',
                                            boxSizing: 'border-box'
                                        }}
                                        className="flex alignCenter removeScrollBar"
                                    >
                                        <strong style={{ fontSize: '1.5em' }}>@</strong>
                                        {
                                            currPost.members.map(member => {
                                                return (<button
                                                    onClick={() => memberProfileShotClicked(member)}
                                                    style={{
                                                        padding: '5px 10px',
                                                        backgroundColor: 'var(--secondary)',
                                                        borderRadius: '20px'
                                                    }}>{member.memberId.username}</button>);
                                            })
                                        }
                                    </div> : null
                            }
                        </div>
                        <div className="flex vertical visitPostBottomBar">
                            <div
                                className="flex alignCenter"
                                style={{
                                    justifyContent: 'flex-start',
                                    height: '40px'
                                }}
                            >
                                <div style={{
                                    width: 'calc(100% - 11px)',
                                    fontSize: '1.1em',
                                    marginLeft: '10px'
                                }} className="oneLineify">{
                                        <span>
                                            <strong style={{ marginRight: '8px' }}>{isMyPosts ? myId.username : currPost.poster_id.memberId.username}</strong>{currPost.caption || ''}
                                        </span>
                                    }</div>
                            </div>
                            <div
                                style={{
                                    height: '50px',
                                    width: '100%',
                                    padding: '0 10px',
                                    boxSizing: 'border-box'
                                }}
                                className="flex spaceBetween"
                            >
                                <div
                                    className="flex spaceBetween alignCenter"
                                    style={{
                                        width: '30%'
                                    }}>
                                    <button
                                        onClick={() => navigate('comments')}
                                        style={{
                                            padding: '0',
                                            height: 'fit-content'
                                        }}>
                                        <BaeSvg iconName="comments" color="text" height="20px" />
                                    </button>
                                    <button
                                        onClick={() => navigate('share')}
                                        style={{
                                            padding: '0',
                                            height: 'fit-content'
                                        }}>
                                        <BaeSvg iconName="sendMessage" color="text" height="20px" />
                                    </button>
                                    <button
                                        onClick={starClicked}
                                        style={{
                                            padding: '0',
                                            height: 'fit-content'
                                        }}>
                                        <BaeSvg iconName="star" color={savedLoading ? 'bubbleFlicker' : currPostSaved ? 'accent' : "text"} height="20px" />
                                    </button>
                                </div>
                                {!isMyPosts &&
                                    <>
                                        {!visiting && <button onClick={() => {
                                            visitMember(currPost.poster_id.memberId, currPost.poster_id._id)
                                        }}>
                                            <span style={{
                                                color: 'var(--accent)',
                                                fontWeight: 'bold',
                                                fontSize: '1.3em'
                                            }}>
                                                visit profile
                                            </span>
                                        </button>}
                                        <button onClick={() => memberProfileShotClicked(currPost.poster_id)}>
                                            <img
                                                style={{
                                                    height: '30px',
                                                    width: '30px',
                                                    borderRadius: '50%'
                                                }}
                                                src={`https://baesapp.com/media/${currPost.poster_id.memberId.profileShot}`} />
                                        </button>
                                    </>
                                }
                            </div>
                        </div>
                    </>
                }
            </div >
            <Routes>
                {currPost && <Route path="/share/*" element={<MediaShare post={currPost} />} />}
                {currPost && <Route path="/comments/*" element={<MediaComments currPost={currPost} isMyPosts={isMyPosts} />} />}
            </Routes>
        </>
    );
};

const mapStateToProps = state => {
    return ({
        mediaPosts: state.mediaPosts,
        myMedia: state.myMedia,
        myId: state.myId
    });
};

export default connect(mapStateToProps, { setSavedPosts, setVisitMember })(ViewPost);
