import baesappApi from '../apis/baesappApi';

const updateWallet = (setNewWallet) => {
    baesappApi.post('/getMyWallet')
        .then(res => {
            setNewWallet(res.data);
        })
        .catch(err => {
            console.log(err);
            window.alert(err);
        })
};

export default updateWallet;