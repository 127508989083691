import React, { useEffect, useState } from "react";

import { connect } from 'react-redux';

import { Routes, Route, useNavigate } from 'react-router-dom';

import baesappApi from '../../../apis/baesappApi';
import { setMyWalletTotal, setNewMyWalletTransaction } from "../../../redux/actions";
import ModalHeader from "../../../dumbComponents/Home/modalHeader";

const SelectedMedia = ({
    media
}) => {

    const navigate = useNavigate();

    useEffect(() => {
        if(media[0].contentType == 'video'){
            const videoURL = URL.createObjectURL(media[0].src);
    
            const videoElement = document.getElementById('currVideo');
            videoElement.src = videoURL;
    
            videoElement.play();
        }
    }, []);

    const renderCurrentMedia = () => {
        if (media[0].contentType == 'video') {
            return (
                <video id="currVideo" controls />
            );
        };
    };

    return (
        <>
            <div className='fullScreen removeScrollBar modalContainer'>
                <div className='flex vertical'>
                    {renderCurrentMedia()}
                </div>
            </div>
        </>
    );
};

const mapStateToProps = state => {
    return ({
    });
};

export default connect(mapStateToProps, {
})(SelectedMedia);