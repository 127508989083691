const myIdReducer = (myId = false, action) => {
  switch (action.type) {
    case 'GOT_MY_DETAILS':
      return action.payload.memberId;
    case 'MY_ID_SET':
      return action.payload;
    case 'CLEAR_ALL':
      return false;
    default:
      return myId;
  };
};

export default myIdReducer;
