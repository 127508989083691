const my_idReducer = (my_id=false, action) => {
    switch(action.type){
        case 'GOT_MY_DETAILS':
      return action.payload._id;
      case 'CLEAR_ALL':
              return false;
    default:
      return my_id;
    }
};

export default my_idReducer;