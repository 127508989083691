import React, { useState, useEffect, useRef } from "react";

import { Routes, Route, useNavigate } from 'react-router-dom';

import { connect } from 'react-redux';
import ContactsModal from "../../Modals/Chat/contactsModal";
import MemberModal from "../../../dumbComponents/MemberModal";
import ArchivedChats from './ArchivedChats';
import Requests from './Requests';
import ModalBackgroundButton from "../../../dumbComponents/ModalBackgroundButton";
import AddNewFilter from './AddNewFilter';

import { setMyChat, setVisitMember, hitChatsEventListener, setChatMemberIds, setNewArchivedChats, setActiveChatFilter, setNewFilters, setTopBarOffset, setVisitChatFromMessage } from "../../../redux/actions";

import baesappApi from '../../../apis/baesappApi';

import topArrow from '../../../assets/topArrow.png';
import topArrowDark from '../../../assets/topArrowDark.png';

import getLocalToObject from "../../../hooks/getLocalToObject";
import getUpdatedChats from "../../../hooks/getUpdatedChats";
import syncChat from "../../../hooks/syncChat";
import normalizeDate from "../../../hooks/normalizeDate";
import getMyChat from "../../../hooks/getMyChat";
import objectify from '../../../hooks/objectify';
import EditFilter from "./EditFilter";
import setLocalObject from "../../../hooks/setLocalObject";
import Calls from "./Calls";
import About from "./About";
import Switch from "../../../dumbComponents/Switch";
import BaeSvg from "../../../dumbComponents/BaeSvg";
import LoadingDrop from "../../../dumbComponents/LoadingDrop";

function randomAlphaNumericString(min, max, includeSpaces = false, onlyNumbers = false) {
    if (min > max) {
        [min, max] = [max, min];
    }

    const spaceCharCode = 32;
    const numericRangeStart = 48;
    const numericRangeEnd = 57;
    const alphaRangeStartUpperCase = 65; // 'A'
    const alphaRangeEndUpperCase = 90;   // 'Z'
    const alphaRangeStartLowerCase = 97; // 'a'
    const alphaRangeEndLowerCase = 122;  // 'z'

    let rangeStart, rangeEnd;

    if (onlyNumbers) {
        rangeStart = numericRangeStart;
        rangeEnd = numericRangeEnd;
    } else {
        rangeStart = includeSpaces ? spaceCharCode : (Math.random() < 0.5 ? alphaRangeStartUpperCase : alphaRangeStartLowerCase);
        rangeEnd = includeSpaces ? spaceCharCode : (rangeStart === alphaRangeStartUpperCase ? alphaRangeEndUpperCase : alphaRangeEndLowerCase);
    }

    const length = Math.floor(Math.random() * (max - min + 1)) + min;
    let result = '';

    for (let i = 0; i < length; i++) {
        const randomCharCode = Math.floor(Math.random() * (rangeEnd - rangeStart + 1)) + rangeStart;
        result += String.fromCharCode(randomCharCode);
    }

    return result;
}


const Chat = ({
    myChat,
    chatsEventListener,
    hitChatsEventListener,
    setChatMemberIds,
    chatMemberIds,
    setMyChat,
    darkModeEnabled,
    my_id,
    membersTyping,
    setNewArchivedChats,
    setActiveChatFilter,
    activeChatFilter,
    setNewFilters,
    setTopBarOffset,
    setVisitChatFromMessage,
    setVisitMember
}) => {

    const navigate = useNavigate();

    const [chatsArray, setChatsArray] = useState(false);
    const [archivedChatsArray, setArchivedChatsArray] = useState(false);
    const [chats, setChats] = useState(false);
    const [loading, setLoading] = useState(false);
    const [editMemberFiltersLoading, setEditMemberFiltersLoading] = useState(false);
    const [confirmDeleteChat, setConfirmDeleteChat] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [shouldSelectFilter, setShouldSelectFilter] = useState(false);
    const [editMemberFilters, setEditMemberFilters] = useState(false);
    const [canSaveEditMemberFilters, setCanSaveEditMemberFilters] = useState(false);
    const [filteredChatsArray, setFilteredChatsArray] = useState([]);
    const [memberSelectedFilters, setMemberSelectedFilters] = useState([]);
    const [searchInputElement, setSearchInputElement] = useState();
    const [shouldShowCancel, setShouldShowCancel] = useState(false);
    const [totalUnreadMessages, setTotalUnreadMessages] = useState(0);
    const [memberOriginalFilters, setMemberOriginalFilters] = useState([]);
    const [scrollY, setScrollY] = useState(0);
    const [slideBaseX, setSlideBaseX] = useState('false');
    const [slideXDistance, setSlideXDistance] = useState(0);
    const [currSlideId, setCurrSlideId] = useState(false);
    const [filteredMessagesArray, setFilteredMessagesArray] = useState([]);
    const [randomString1, setRandomString1] = useState(Array.from({ length: 8 }, () => randomAlphaNumericString(4, 15)));
    const [randomString2, setRandomString2] = useState(Array.from({ length: 8 }, () => randomAlphaNumericString(2, 30)));
    const [randomString3, setRandomString3] = useState(Array.from({ length: 8 }, () => randomAlphaNumericString(8, 8, false, true)));
    const [randomString4, setRandomString4] = useState(Array.from({ length: 8 }, () => randomAlphaNumericString(1, 2, false, true)));

    const [timeoutId, setTimeoutId] = useState(null);

    const scrollElementRef = useRef(null);

    useEffect(() => {
        if (myChat && chatsArray.length) {
            filterChats();
        };
    }, [activeChatFilter]);

    useEffect(() => {
        getUpdatedChats(hitChatsEventListener);
    }, []);

    useEffect(() => {
        if (chatsArray) {
            filterChats();
        }
    }, [chatsArray]);

    useEffect(() => {
        let intervalId2;

        if (loading) {
            intervalId2 = setInterval(() => {
                setRandomString1(Array.from({ length: 8 }, () => randomAlphaNumericString(3, 10)));
                setRandomString2(Array.from({ length: 8 }, () => randomAlphaNumericString(2, 30)));
                setRandomString3(Array.from({ length: 8 }, () => randomAlphaNumericString(8, 8, false, true)));
                setRandomString4(Array.from({ length: 8 }, () => randomAlphaNumericString(1, 2, false, true)));
            }, 100);
        } else {
            // If loading is false, clear the interval
            clearInterval(intervalId2);
        }

        // Clear the interval when the component unmounts
        return () => {
            clearInterval(intervalId2);
        };
    }, [loading]);

    useEffect(() => {
        setChats(getLocalToObject('chats'));
    }, [chatsEventListener]);

    useEffect(() => {
        if (chats) {
            loadChatsArray();
        };
    }, [chats]);

    useEffect(() => {
        if (chats) populateChatsArray();
    }, [chatMemberIds, myChat]);

    useEffect(() => {
        if (myChat) {
            let tempTotalUnreadMessages = 0;
            myChat.chats.map(chat => {
                tempTotalUnreadMessages += chat.newMessages.length;
            });
            setTotalUnreadMessages(tempTotalUnreadMessages);
        };
    }, [myChat.chats]);

    const loadChatsArray = () => {
        let memberIds = {};
        setLoading(true);
        baesappApi.post('/getMemberIds', { Ids: Object.keys(chats) })
            .then(res => {
                res.data.map(member => {
                    memberIds[member._id] = member.memberId;
                });
                setChatMemberIds(memberIds);
                setLoading(false);
            })
            .catch(err => {
                console.log(err);
                setLoading(false);
            });
        if (chats) {
            populateChatsArray();
        };
    };

    const scrollToChatsOnly = () => {
        if (scrollElementRef.current) {
            const scrollTopValue = !!archivedChatsArray.length ? 160 : 100;
            scrollElementRef.current.scrollTo({
                top: scrollTopValue,
                behavior: 'smooth'
            });
        }
    };

    const populateChatsArray = () => {
        if (!myChat.chats) return;
        const myChatsObject = objectify(myChat.chats, 'contactId');
        let tempChatsArray = [];
        let tempArchivedChatsArray = [];
        for (const current in chats) {
            if (chats[current].conversation.messages.length > 0 && chatMemberIds[current]) {
                let chatArrayItem = {};
                chatArrayItem._id = myChatsObject[chats[current].contactId]._id;
                chatArrayItem.totalNewMessages = myChatsObject[chats[current].contactId].newMessages.length;
                chatArrayItem.onlyDeliveredMessages = myChatsObject[chats[current].contactId].onlyDeliveredMessages.length;
                chatArrayItem.contactId = chats[current].contactId;
                chatArrayItem.lastMessage_id = chats[current].conversation.messages.slice(-1)[0]._id;
                chatArrayItem.username = chatMemberIds[current].username;
                chatArrayItem.preferredName = chatMemberIds[current].preferredName;
                chatArrayItem.profileShot = chatMemberIds[current].profileShot;
                chatArrayItem.dateOfBirth = chatMemberIds[current].dateOfBirth;
                chatArrayItem.firstNames = chatMemberIds[current].firstNames;
                chatArrayItem.lastname = chatMemberIds[current].lastname;
                chatArrayItem.preferredName = chatMemberIds[current].preferredName;
                chatArrayItem.sex = chatMemberIds[current].sex;
                chatArrayItem.lastMessage = chats[current].conversation.messages.slice(-1)[0].text;
                chatArrayItem.lastMessageSender = chats[current].conversation.messages.slice(-1)[0].sender;
                chatArrayItem.time = chats[current].conversation.messages.slice(-1)[0].time;
                chatArrayItem.contentType = chats[current].conversation.messages.slice(-1)[0].contentType;
                if (myChat.archivedChats.includes(chatArrayItem._id)) {
                    tempArchivedChatsArray.push(chatArrayItem);
                } else {
                    tempChatsArray.push(chatArrayItem);
                }
            };
        };

        let sortedChatsArray = tempChatsArray.sort(
            (chat1, chat2) => chat2.time.localeCompare(chat1.time)
        );
        let sortedArchivedChatsArray = tempArchivedChatsArray.sort(
            (chat1, chat2) => chat2.time.localeCompare(chat1.time)
        );

        setChatsArray(sortedChatsArray);
        setArchivedChatsArray(sortedArchivedChatsArray);
    };

    const populateFilteredMessages = () => {
        let newFilteredMessages = [];

        if (chats && searchText.length > 1) {
            for (const key in chats) {
                let tempFilteredMessages = chats[key].conversation.messages.filter(conversationMessage => conversationMessage.text.toUpperCase().includes(searchText.toUpperCase()));
                tempFilteredMessages.map(filteredMessage => {
                    filteredMessage['contactId'] = key;
                });
                newFilteredMessages.push(...tempFilteredMessages);
            }
        }

        setFilteredMessagesArray(newFilteredMessages);
    };

    useEffect(() => {
        if (chatsArray) {
            let tempFilteredChatsArray = [];
            if (activeChatFilter) {
                if (activeChatFilter == 'unreadChats') {
                    tempFilteredChatsArray = chatsArray.filter(chat => chat.totalNewMessages);
                } else {
                    const filterMemberIds = myChat.filters.find(filter => filter.filterName == activeChatFilter).members;
                    tempFilteredChatsArray = chatsArray.filter(chat => chat.username.toUpperCase().includes(searchText.toUpperCase()) && filterMemberIds.includes(chat.contactId));
                }
            } else {
                tempFilteredChatsArray = chatsArray.filter(chat => chat.username.toUpperCase().includes(searchText.toUpperCase()));
            }
            setFilteredChatsArray(tempFilteredChatsArray);
        };
        populateFilteredMessages();
    }, [searchText]);

    const filterChats = () => {
        let tempFilteredChatsArray = [];
        let tempFilteredMessagesArray = [];
        if (activeChatFilter) {
            const filterMemberIds = myChat.filters.find(filter => filter.filterName == activeChatFilter)?.members;
            if (searchText != '') {
                if (activeChatFilter == 'unreadChats') {
                    tempFilteredChatsArray = chatsArray.filter(chat => chat.username.toUpperCase().includes(searchText.toUpperCase()) && chat.totalNewMessages.length > 0);
                } else {
                    tempFilteredChatsArray = chatsArray.filter(chat => chat.username.toUpperCase().includes(searchText.toUpperCase()) && filterMemberIds.includes(chat.contactId));
                }
            } else {
                if (activeChatFilter == 'unreadChats') {
                    tempFilteredChatsArray = chatsArray.filter(chat => chat.totalNewMessages);
                } else {
                    tempFilteredChatsArray = chatsArray.filter(chat => filterMemberIds.includes(chat.contactId));
                }
            }
        } else if (searchText != '') {
            tempFilteredChatsArray = chatsArray.filter(chat => chat.username.toUpperCase().includes(searchText.toUpperCase()));
        } else {
            tempFilteredChatsArray = [...chatsArray];
        }
        setFilteredChatsArray(tempFilteredChatsArray);
        //  scrollToChatsOnly();
    }

    const memberProfileShotClicked = (chat) => {
        let member = { ...chat };
        delete member.lastMessage;
        member._id = member.contactId;
        setLocalObject('memberModal', member);
        navigate('memberModal');
    };

    const visitMember = (chat) => {
        let member = { ...chat };
        delete member.lastMessage;
        member._id = member.contactId;
        setVisitMember({
            path: 'chat',
            memberEssentials: member
        });
        navigate(`/visit/chat?_id=${member._id}`);
    };
    const visitMemberFromMessage = (message) => {
        let member = { ...message };
        const currChatMemberId = chatMemberIds[member.contactId];
        member.username = currChatMemberId.username;
        member.preferredName = currChatMemberId.preferredName;
        member.profileShot = currChatMemberId.profileShot;
        member.dateOfBirth = currChatMemberId.dateOfBirth;
        member.firstNames = currChatMemberId.firstNames;
        member.lastname = currChatMemberId.lastname;
        member.preferredName = currChatMemberId.preferredName;
        member.sex = currChatMemberId.sex;
        member.messageId = message._id;
        member._id = member.contactId;
        setVisitChatFromMessage(member.messageId);
        setVisitMember({
            path: 'chat',
            memberEssentials: member
        });
        navigate(`/visit/chat?_id=${member._id}`);
    };

    const deleteChat = (contactId) => {
        baesappApi.post('/setIndexOfLastAvailableMessage', { contactId, lastMessageId: 'last' })
            .then(res => {
                syncChat(myChat);
                getUpdatedChats(hitChatsEventListener);
                setConfirmDeleteChat(false);
            })
            .catch(err => {
                console.log(err);
                setConfirmDeleteChat(false);
            })
    };

    const handleTouchMove = (e, callId) => {
        const currX = Math.trunc(e.touches[0].clientX);
        if (slideBaseX == 'false') {
            setSlideBaseX(currX);
        } else {
            setCurrSlideId(prevId => prevId != callId ? callId : prevId);
            let invertedSlideDistance = slideBaseX - currX;
            let newSlideDistance = -invertedSlideDistance;
            if (newSlideDistance > -151 && newSlideDistance < 1) {
                setSlideXDistance(newSlideDistance);
            };
        }
    };
    useEffect(() => {
        setSlideBaseX('false');
        setSlideXDistance(0);
    }, [currSlideId]);
    const handleTouchEnd = () => {
        if (slideXDistance < -75) {
            setSlideXDistance(-150);
        } else {
            setSlideBaseX('false');
            setSlideXDistance(0);
        };
    };

    const archiveChat = (chatId) => {
        baesappApi.post('/toggleArchiveChat', { chatId })
            .then(res => {
                setNewArchivedChats(res.data);
            })
            .catch(err => {
                console.log(err);
            })
    };

    let filtersList = [];
    if (myChat, shouldSelectFilter) {
        filtersList = myChat.filters.sort((a, b) => {
            const aName = a.filterName.toUpperCase();
            const bName = b.filterName.toUpperCase();
            if (aName < bName) return -1;
            if (aName > bName) return 1;
            return 0;
        }).map(filter => {
            return (
                <div className='spaceBetween bottomSlideInButton'>
                    <button
                        style={{ width: '100%', padding: '10px', fontSize: '1em', alignItems: 'center' }}
                        onClick={() => {
                            setActiveChatFilter(filter.filterName);
                            setShouldSelectFilter(false);
                        }} className={`flex activeOpacity ${filter.filterName == activeChatFilter ? 'inheritColor' : 'secondaryColor'}`}>
                        <Switch callback={() => { }} switchOn={activeChatFilter == filter.filterName} />
                        <span style={{ marginLeft: '10px' }}>{filter.filterName}</span>
                    </button>
                    <button onClick={() => {
                        navigate('/home/chat/editFilter');
                        window.localStorage.setItem('filterName', filter.filterName);
                    }} className="accentColor activeOpacity" style={{ fontSize: '0.9em', marginRight: '10px' }}>Edit</button>
                </div>
            );
        });
    }

    useEffect(() => {
        if (editMemberFilters) {
            const tempMemberSelectedFilters = [];
            myChat.filters.map(filter => {
                if (filter.members.includes(editMemberFilters.memberId)) tempMemberSelectedFilters.push(filter.filterName);
            });
            setMemberSelectedFilters(tempMemberSelectedFilters);
            setMemberOriginalFilters(tempMemberSelectedFilters);
        };
    }, [editMemberFilters]);

    const saveMemberFilters = () => {
        let memberRemoveFilters = [];
        let memberAddFilters = [];

        memberSelectedFilters.map(memberSelectedFilter => {
            if (!memberOriginalFilters.includes(memberSelectedFilter)) {
                memberAddFilters.push(memberSelectedFilter);
            };
        });

        memberOriginalFilters.map(memberOriginalFilter => {
            if (!memberSelectedFilters.includes(memberOriginalFilter)) {
                memberRemoveFilters.push(memberOriginalFilter);
            }
        });

        setEditMemberFiltersLoading(true);
        baesappApi.post('/chatFilters/moveMember', { memberId: editMemberFilters.memberId, memberRemoveFilters, memberAddFilters })
            .then(res => {
                setNewFilters(res.data);
                setEditMemberFiltersLoading(false);
            })
            .catch(err => {
                console.log(err);
                setEditMemberFiltersLoading(false)
            })
    }

    useEffect(() => {
        let tempCanSaveMemberFilters = false;
        memberSelectedFilters.map(memberSelectedFilter => {
            if (!memberOriginalFilters.includes(memberSelectedFilter)) {
                tempCanSaveMemberFilters = true;
            };
        });

        memberOriginalFilters.map(memberOriginalFilter => {
            if (!memberSelectedFilters.includes(memberOriginalFilter)) {
                tempCanSaveMemberFilters = true;
            }
        });

        setCanSaveEditMemberFilters(tempCanSaveMemberFilters)
    }, [memberSelectedFilters]);

    const handleOnBlur = (blurredCause) => {
        if (blurredCause == 'cancel') {
            setSearchText('');
            setShouldShowCancel(false);
        } else if (blurredCause == 'clear') {
            setSearchText('');
            searchInputElement.focus();
        }
    };

    let memberFiltersList = [];
    if (myChat, editMemberFilters) {
        const filterClicked = (filterName) => {
            if (memberSelectedFilters.includes(filterName)) {
                setMemberSelectedFilters(memberSelectedFilters.filter(filter => filter != filterName));
            } else {
                setMemberSelectedFilters([...memberSelectedFilters, filterName]);
            };
        };
        memberFiltersList = myChat.filters.sort((a, b) => {
            const aName = a.filterName.toUpperCase();
            const bName = b.filterName.toUpperCase();
            if (aName < bName) return -1;
            if (aName > bName) return 1;
            return 0;
        }).map(filter => {
            return (
                <div className='spaceBetween bottomSlideInButton'>
                    <button
                        style={{ width: '100%', padding: '10px', fontSize: '1em', alignItems: 'center' }}
                        onClick={() => { filterClicked(filter.filterName) }} className={`flex spaceBetween activeOpacity ${memberSelectedFilters.includes(filter.filterName) ? 'inheritColor' : 'secondaryColor'}`}>
                        {filter.filterName}
                        <Switch switchOn={memberSelectedFilters.includes(filter.filterName)} />
                    </button>
                </div>
            );
        });
    }

    const filteredMessagesList = filteredMessagesArray.map(filteredMessage => {
        const thisMemberId = chatMemberIds[filteredMessage.contactId];
        const contactUsername = thisMemberId.username;
        return (
            <>
                <button
                    key={filteredMessage._id}
                    onClick={() => visitMemberFromMessage(filteredMessage)}
                    className="flex horizontal chatCard" >
                    <div className="flex horizontal chatCardLeft">
                        <div onClick={() => { }} className="flex vertical chatCardMainContent">
                            <span className="chatCardUsername">{contactUsername}</span>
                            <div className="chatCardContentContainer">
                                <span className="chatCardContent"><bold style={{ color: 'var(--text)' }}>{filteredMessage.sender == my_id && 'You: '}</bold>{filteredMessage.text}</span>
                            </div>
                        </div>
                    </div>
                    <div onClick={() => { }} className="flex vertical chatCardRight">
                        <span>{normalizeDate(filteredMessage.time, false, true)}</span>
                    </div>
                </button>
                <div className="chatCardSeparator"></div>
            </>
        );
    });

    const renderLoadingBubbles = () => {
        const listLength = Array.from({ length: 8 }, (_, index) => index);
        let loadingBubblesList = listLength.map(item => {
            return (
                <>
                    <div className="flex vertical">
                        <button className="flex horizontal chatCard" >
                            <div className="flex horizontal chatCardLeft">
                                <div className="memberBarProfileShot" />
                                <div className="flex vertical chatCardMainContent">
                                    <span className="chatCardUsername">{randomString1[item]}</span>
                                    <div className="chatCardContentContainer">
                                        <span className="chatCardContent colorLoadingFlicker">{randomString2[item]}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="flex vertical chatCardRight">
                                <span className={`${(randomString4[item].length == 1) ? 'chatCardDateAccent' : 'colorLoadingFlicker'}`}>{randomString3[item]}</span>
                                <div className={`chatCardTotalUnread ${(randomString4[item].length != 1) ? 'hideChatCardTotalUnread' : ''}`}>
                                    <span>{randomString4[item]}</span>
                                </div>
                            </div>
                        </button>
                    </div>
                    <div className="chatCardSeparator"></div>
                </>
            );
        });
        return (loadingBubblesList);
    };

    const renderMessages = () => {
        if (chatsArray) {
            if (chatsArray.length == 0 && searchText == '' && !archivedChatsArray.length) {
                if (loading) {
                    return (
                        <LoadingDrop />
                    );
                }
                return (
                    <div className="topBarTutorialCard">
                        <img class="pointingUpArrow" style={{ height: '40px', width: '40px', margin: '10px 3% 10px 0' }} src={darkModeEnabled ? topArrowDark : topArrow} />
                        <span style={{ marginRight: '5px', fontSize: '1.1em', fontWeight: 'bold' }}>Start new chat</span>
                    </div>
                );
            } else {

                const chatsList = filteredChatsArray.map((chat, index) => {
                    return (
                        <>
                            <div className="flex vertical">
                                <div className="flex horizontal chatCard" style={{ justifyContent: 'flex-end', marginBottom: '-60px' }}>
                                    <button onClick={() => setConfirmDeleteChat({ contactId: chat.contactId, username: chat.username })} className="flex alignCenter spaceAround" style={{ borderTopLeftRadius: '10px', borderBottomLeftRadius: '10px', height: '60px', width: '50px', backgroundColor: 'var(--error)' }}>
                                        <BaeSvg iconName='bin' color="black" style={{ opacity: '0.6' }} />
                                    </button>
                                    <button onClick={() => archiveChat(chat._id)} className="flex alignCenter spaceAround" style={{ height: '60px', width: '50px', backgroundColor: 'var(--secondary)' }}>
                                        <BaeSvg iconName='archive' style={{ opacity: '0.8' }} color='text' />
                                    </button>
                                    <button onClick={() => setEditMemberFilters({ memberId: chat.contactId, username: chat.username })} className="flex alignCenter spaceAround" style={{ height: '60px', width: '50px', backgroundColor: 'var(--primary)', borderTopRightRadius: '10px', borderBottomRightRadius: '10px' }}>
                                        <BaeSvg iconName="filter" height="16px" color='accent' />
                                    </button>
                                </div>
                                <div className="flex horizontal chatCard" style={{ height: '60px', marginBottom: '-60px', backgroundColor: 'var(--base)', transform: `translateX(${currSlideId == chat.contactId ? slideXDistance : '0'}px)` }}>
                                </div>
                                <button
                                    onTouchEnd={handleTouchEnd}
                                    onTouchMove={(e) => handleTouchMove(e, chat.contactId)}
                                    key={chat.contactId}
                                    style={{ transform: `translateX(${currSlideId == chat.contactId ? slideXDistance : '0'}px)` }} className="flex horizontal chatCard" >
                                    <div className="flex horizontal chatCardLeft">
                                        <img onClick={() => memberProfileShotClicked(chat)} className="memberBarProfileShot" src={`https://baesapp.com/media/${chat.profileShot}`} />
                                        <div onClick={() => visitMember(chat)} className="flex vertical chatCardMainContent">
                                            <span className="chatCardUsername">{chat.username}</span>
                                            {
                                                membersTyping.includes(chat.contactId) ?
                                                    <div className="chatCardContentContainer">
                                                        <span className="typingText">typing...</span>
                                                    </div> :
                                                    chat.contentType == 'text' ?
                                                        <div className="chatCardContentContainer">
                                                            {
                                                                chat.lastMessageSender == my_id &&
                                                                <BaeSvg height='9px' iconName='checkmarks' style={{ transitionDuration: '1000ms', maxWidth: '14px', minWidth: '14px' }} color={chat.onlyDeliveredMessages == 0 ? 'accent' : 'gray'} />
                                                            }
                                                            <span className="chatCardContent">{chat.lastMessage}</span>
                                                        </div> :
                                                        chat.contentType == 'postImage' ?
                                                            <span className="chatCardContent">Image post</span> :
                                                            chat.contentType == 'postVideo' ?
                                                                <span className="chatCardContent">Video post</span> :
                                                                chat.contentType == 'image' ?
                                                                    <span className="chatCardContent">Image</span> :
                                                                    <span className="chatCardContent">Video</span>
                                            }
                                        </div>
                                    </div>
                                    <div onClick={() => visitMember(chat)} className="flex vertical chatCardRight">
                                        <span className={`${chat.totalNewMessages ? 'chatCardDateAccent' : ''}`}>{normalizeDate(chat.time, false, true)}</span>
                                        <div className={`chatCardTotalUnread ${!chat.totalNewMessages ? 'hideChatCardTotalUnread' : ''}`}>
                                            <span>{chat.totalNewMessages}</span>
                                        </div>
                                    </div>
                                </button>
                            </div>
                            <div className="chatCardSeparator"></div>
                        </>
                    );
                });
                if (filteredChatsArray.length) {
                    return (chatsList);
                } else if (filteredMessagesArray.length) {
                    return null;
                } else {
                    return (
                        <span className="centerText noResults">No Results</span>
                    );
                }
            }
        } else {
            return (
                renderLoadingBubbles()
            );
        }
    };

    const renderContent = () => {
        return (
            <div
                ref={scrollElementRef}
                className="fullScreen flex removeScrollBar vertical" style={{ width: '100vw', maxWidth: '500px', overflow: 'scroll' }}>
                <div className="flex vertical" style={{ gap: '10px' }}>
                    <div style={{ minHeight: '110px', width: '50px' }} />
                    <div className="searchBarContainer" style={{ width: '100%' }}>
                        <div className="searchBar" style={shouldShowCancel ? { marginLeft: '10px' } : {}}>
                            <BaeSvg height='1em' style={{ margin: '10px 5px 10px 10px' }} iconName='magnifyingGlass' color='gray' />
                            <input
                                onFocus={() => setShouldShowCancel(true)}
                                id="searchInput"
                                placeholder={activeChatFilter == 'unreadChats' ? 'Search unread chats' : !activeChatFilter ? 'Search' : `Search ${activeChatFilter}`}
                                value={searchText}
                                onChange={e => setSearchText(e.target.value)}
                                className={`cleanInput searchBarInput`} />
                            {searchText != '' &&
                                <button className="flex" onClick={() => handleOnBlur('clear')}>
                                    <BaeSvg height='1.4em' iconName='circleX' color='gray' />
                                </button>
                            }
                        </div>
                        {shouldShowCancel && <span onClick={() => handleOnBlur('cancel')} className="searchBarCancelText">
                            Cancel
                        </span>}
                    </div>
                    {myChat?.archivedChats?.length > 0 &&
                        <button onClick={() => navigate('/home/chat/archivedChats')} className="flex horizontal chatCard" style={{ backgroundColor: 'var(--primaryGlass)', borderRadius: '8px', height: '40px', margin: '10px 0' }}>
                            <span style={{ marginLeft: '15px', color: 'var(--secondary)', fontWeight: 'bold' }}>Archived chats ({myChat.archivedChats.length})</span>
                            <BaeSvg iconName='archive' height="19px" style={{ marginRight: '15px' }} color='gray' />
                        </button>}
                    {renderMessages()}
                    {filteredMessagesArray.length > 0 && <span className="centerText noResults" style={{ color: 'var(--text)', marginTop: '10px' }}>Messages</span>}
                    {filteredMessagesArray.length > 0 && filteredMessagesList}
                    {
                        filteredChatsArray.length ?
                            <div className="e2eeContainer">
                                <p>For your privacy and security, Baesapp chat messages are <strong>end-to-end</strong> encrypted.</p>
                                <a href="https://www.ibm.com/topics/end-to-end-encryption">Learn more</a>
                            </div>
                            :
                            null
                    }
                    <div style={{ minHeight: '200px', width: '50px' }} />
                </div>
                <div className="absoluteHorizontalCenter topHeaderContainer">
                    <div className="topHeaderContent">
                        <div className="flex">
                            <div className="topHeaderTextContainer">
                                {activeChatFilter == 'unreadChats' ? 'Unread Chats' : activeChatFilter || 'All Chats'}
                            </div>
                            <button onClick={() => setShouldSelectFilter(true)}>
                                <BaeSvg iconName="filter" height="13px" color='accent' />
                            </button>
                        </div>
                    </div>
                </div>
                {
                    shouldSelectFilter &&
                    <>
                        <ModalBackgroundButton dismiss={() => setShouldSelectFilter(false)} />
                        <div className='bottomSlideInCard'>
                            <div className="bottomSlideInCardHeaderContainer">
                                <span>Filter chats</span>
                            </div>
                            <div className="bottomSlideInCardFilterList removeScrollBar">

                                <div className='spaceBetween bottomSlideInButton'>
                                    <button
                                        style={{ width: '100%', padding: '10px', fontSize: '1em', alignItems: 'center' }}
                                        onClick={() => {
                                            setActiveChatFilter(false);
                                            setShouldSelectFilter(false);
                                        }} className={`flex activeOpacity ${!activeChatFilter ? 'inheritColor' : 'secondaryColor'}`}>
                                        <Switch callback={() => { }} switchOn={!activeChatFilter} />
                                        <span style={{ marginLeft: '10px' }}>All chats</span>
                                    </button>
                                </div>

                                <div className='spaceBetween bottomSlideInButton'>
                                    <button
                                        style={{ width: '100%', padding: '10px', fontSize: '1em', alignItems: 'center' }}
                                        onClick={() => {
                                            setActiveChatFilter('unreadChats');
                                            setShouldSelectFilter(false);
                                        }} className={`flex activeOpacity ${activeChatFilter == 'unreadChats' ? 'inheritColor' : 'secondaryColor'}`}>
                                        <Switch callback={() => { }} switchOn={activeChatFilter == 'unreadChats'} />
                                        <span style={{ marginLeft: '10px' }}>Unread chats</span>
                                    </button>
                                </div>

                                {filtersList}
                                <button onClick={() => navigate('addNewFilter')} className="bottomSlideInButton accentColor" style={{ justifyContent: 'center' }}>
                                    Add new filter
                                </button>
                            </div>
                        </div>
                    </>
                }
                {
                    editMemberFilters &&
                    <>
                        <ModalBackgroundButton dismiss={() => setEditMemberFilters(false)} />
                        <div className='bottomSlideInCard'>
                            {myChat.filters.length ?
                                editMemberFiltersLoading || !canSaveEditMemberFilters ?
                                    <div className="flex spaceBetween alignCenter" style={{ width: '100%' }}>
                                        <span className="bottomSlideInCardHeaderContainer">{editMemberFilters.username}</span>
                                        <div>
                                            <span className="headerButtonText" style={{ color: 'gray' }}>Save</span>
                                        </div>
                                    </div>
                                    :
                                    <div className="flex spaceBetween alignCenter" style={{ width: '100%' }}>
                                        <span className="bottomSlideInCardHeaderContainer">{editMemberFilters.username}</span>
                                        <div onClick={() => saveMemberFilters()}>
                                            <span className="headerButtonText">Save</span>
                                        </div>
                                    </div>
                                :
                                <span>Add new filters to assign to your contacts</span>
                            }
                            <div className="bottomSlideInCardFilterList removeScrollBar">
                                {memberFiltersList}
                                <button onClick={() => navigate('addNewFilter')} className="bottomSlideInButton accentColor" style={{ justifyContent: 'center' }}>
                                    Add new filter
                                </button>
                            </div>
                        </div>
                    </>
                }
                {confirmDeleteChat &&
                    <>
                        <div onClick={() => setConfirmDeleteChat(false)} className="middlePageConvoBackdrop" />
                        <div className="bottomSlideInCard flex vertical spaceAround alignCenter confirmationCard">
                            <span>Delete your messages with <strong>{confirmDeleteChat.username}</strong>?</span>
                            <div className="middlePageConvoButtonsContainer">
                                <button onClick={() => setConfirmDeleteChat(false)} className="cleanButton middlePageConvoButtonAccent">Cancel</button>
                                <button onClick={() => deleteChat(confirmDeleteChat.contactId)} className="cleanButton middlePageConvoButtonRed">Delete</button>
                            </div>
                        </div>
                    </>
                }
            </div>
        );
    };

    return (
        <>
            <Routes>
                <Route path="/*" element={renderContent()} />
                <Route path="/about" element={<About />} />
                <Route path="/contacts/*" element={<ContactsModal />} />
                <Route path="/memberModal/*" element={<MemberModal />} />
                <Route path="/archivedChats/*" element={<ArchivedChats loading={loading} _chatMemberIds={chatMemberIds} />} />
                <Route path="/requests/*" element={<Requests />} />
                <Route path="/addNewFilter/*" element={<AddNewFilter />} />
                <Route path="/editFilter/*" element={<EditFilter />} />
                <Route path="/calls/*" element={<Calls />} />
            </Routes>
        </>
    );
};

const mapStateToProps = state => {
    return ({
        myChat: state.myChat,
        chatsEventListener: state.chatsEventListener,
        chatMemberIds: state.chatMemberIds,
        my_id: state.my_id,
        darkModeEnabled: state.darkModeEnabled,
        membersTyping: state.membersTyping,
        activeChatFilter: state.activeChatFilter
    });
};

export default connect(mapStateToProps, { setVisitMember, setMyChat, setNewFilters, setNewArchivedChats, hitChatsEventListener, setChatMemberIds, setActiveChatFilter, setTopBarOffset, setVisitChatFromMessage })(Chat);
