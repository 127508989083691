import React, { useState, useRef, useEffect } from 'react';

import Webcam from 'react-webcam';

import x from '../../assets/x.png';
import baesappApi from '../../apis/baesappApi';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import faceGuideIcon from '../../assets/faceGuideIcon.png';

import ConstructiveButton from './ConstructiveButton';
import BaeSvg from '../BaeSvg';

const ProfileShotTaker = ({
    setLaunchCamera,
    setProfileShotB64
}) => {

    const webcamRef = useRef(null);

    const [facingMode, setFacingMode] = useState('user');
    const [multipleCameras, setMultipleCameras] = useState(false);
    const [imageSource, setImageSource] = useState(null);
    const [loading, setLoading] = useState(false);
    const [showRules, setShowRules] = useState(true);
    const [errorMessage, setErrorMessage] = useState(false);

    const isWideScreen = window.innerWidth > window.innerHeight;

    const videoConstraints = {
        width: 400,
        height: 400,
        facingMode
    };

    useEffect(() => {
        const checkCameraCount = async () => {
            const videoDevices = await navigator.mediaDevices.enumerateDevices();
            const cameraCount = videoDevices.filter((device) => device.kind === 'videoinput').length;
            setMultipleCameras(cameraCount > 1);
        };

        checkCameraCount();
    }, []);

    const toggleCamera = () => {
        setFacingMode((prevMode) => (prevMode === 'user' ? 'environment' : 'user'));
    };

    const handleError = (err) => {
        switch (err) {
            case 'Too many faces':
                setErrorMessage('Multiple faces were detected, please try again with only one face.');
                break;
            case 'Bad face position':
                setErrorMessage('Ensure that your face and neck fit inside the circle and are not too far away.')
                break;
            case 'Bad pose':
                setErrorMessage('Your face was not pointing straight at the camera, please try again.')
                break;
            case 'Sunglasses':
                setErrorMessage('Please remove your sunglasses and try again.')
                break;
            default:
                setErrorMessage('No face detected, make sure your face is visible then try again.')
                break;
        }
    };

    const takePhotoAndValidate = () => {
        const screenshot = webcamRef.current.getScreenshot();
        setImageSource(screenshot);
        setLoading(true);
        baesappApi.post('/register/validateProfileShot', { profileShotB64: screenshot })
            .then(res => {
                setProfileShotB64(screenshot);
                setLoading(false);
                setLaunchCamera(false);
            })
            .catch(err => {
                console.log(err.response);
                if (err.response.data) {
                    handleError(err.response.data);
                } else {
                    handleError('noFaces')
                }
                setLoading(false);
            })
    };

    const renderRuleCard = () => {
        return (
            <div className='authenticationInfo'>
                <button style={{ width: 'fit-content' }} onClick={() => setLaunchCamera(false)}>
                    <BaeSvg iconName="chevronDown" color="text" height="50px" width="20px" style={{ transform: 'rotate(90deg)' }} />
                </button>
                <span style={{ fontSize: '30px', fontWeight: 'bold' }}>Selfie guidelines:</span>

                <p style={{ fontSize: '1.2em', color: 'gray' }}>
                    <span style={{ color: 'var(--text)', fontWeight: 'bold' }}>1. </span>
                    Only one face is allowed in the photo.
                </p>

                <p style={{ fontSize: '1.2em', color: 'gray' }}>
                    <span style={{ color: 'var(--text)', fontWeight: 'bold' }}>2. </span>
                    Make sure your head and neck fit inside the circle.
                </p>

                <p style={{ fontSize: '1.2em', color: 'gray' }}>
                    <span style={{ color: 'var(--text)', fontWeight: 'bold' }}>3. </span>
                    Point your face straight at the camera.
                </p>

                <p style={{ fontSize: '1.2em', color: 'gray', marginBottom: '30px' }}>
                    <span style={{ color: 'var(--text)', fontWeight: 'bold' }}>4. </span>
                    Do not wear sunglasses.
                </p>


                <ConstructiveButton buttonState='active' text='I Understand' callback={() => setShowRules(false)} />

            </div>
        );
    };

    const renderErrorCard = () => {
        return (
            <div className='blurBackground flex spaceAround'>
                <div className='card'>
                    <strong>Invalid profile shot:</strong>
                    <p className='errorText'>{errorMessage}</p>
                    <ConstructiveButton buttonState='active' text='Try again' callback={() => setErrorMessage(false)} />
                </div>
            </div>
        );
    };

    const renderContent = () => {
        if (loading) {
            return (
                <>
                    <h1 className='headerText'>Verifying your profile shot</h1>
                    <img src={imageSource} className='previewPhoto' />
                    <div className='faceScanner' />
                </>
            );
        } else {
            return (
                <>
                    <Webcam ref={webcamRef} screenshotFormat="image/png" className={isWideScreen ? "webcamH" : "webcamW"} mirrored={true} screenshotQuality={1} videoConstraints={videoConstraints}></Webcam>
                    <div className='cameraButtons flex spaceAround'>
                        <button style={{ width: 'fit-content' }} onClick={() => setLaunchCamera(false)}>
                            <BaeSvg iconName="chevronDown" color="gray" height="50px" width="20px" style={{ transform: 'rotate(90deg)' }} />
                        </button>
                        <div className='takeImageButton' onClick={() => takePhotoAndValidate()} />
                        {true &&
                            <button style={{ width: 'fit-content' }} onClick={toggleCamera}>
                                <BaeSvg iconName="switch" color="gray" height="50px" width="20px" style={{ transform: 'rotate(90deg)' }} />
                            </button> 
                        }
                    </div>
                    {showRules && renderRuleCard()}
                    {errorMessage && renderErrorCard()}
                </>
            );
        }
    };

    return (
        <div className='container' style={!loading ? { backgroundColor: "white" } : { backgroundColor: 'transparent' }}>
            {renderContent()}
        </div>
    );
};

export default ProfileShotTaker;