import { createFFmpeg, fetchFile } from '@ffmpeg/ffmpeg';

async function compressVideo(file) {
    const ffmpeg = createFFmpeg({ log: true });
    await ffmpeg.load();

    ffmpeg.FS('writeFile', 'input.mp4', await fetchFile(file));

    await ffmpeg.run('-i', 'input.mp4', '-vf', 'scale=640:-1', '-b:v', '500k', 'output.mp4');

    const data = ffmpeg.FS('readFile', 'output.mp4');
    const compressedVideoBlob = new Blob([data.buffer], { type: 'video/mp4' });

    return compressedVideoBlob;
}

export default compressVideo;