const gameInvitationReducer = (gameInvitation=[], action) => {
    switch(action.type){
        case 'SET_GAME_INVITATION':
            return [...gameInvitation, action.payload];
            case 'CLEAR_ALL':
                return [];
        default: return gameInvitation;
    };
};

export default gameInvitationReducer;
