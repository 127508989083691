const convert2mp4 = function (videoFileData, targetFormat) {
    targetFormat = targetFormat.toLowerCase();
    let reader = new FileReader();
    return new Promise(resolve => {
        reader.onload = function (event) {
            let contentType = 'video/'+targetFormat;
            let data = event.target.result.split(',');
            let b64Data = data[1];
            let res = b64toBlob(b64Data, contentType)
                .then(res => {
                    let newBase64 = blobToBase64(res)
                    return(newBase64);
                });
            resolve(res);
        }
        b64toBlob(videoFileData.split(',')[1], videoFileData.split(',')[0].split(':')[1].split(';')[0])
            .then(res => reader.readAsDataURL(res))
    });
}

const b64toBlob = (base64, type) => {
        const blob = fetch(`data:${type};base64,${base64}`).then(res =>{ return res.blob()});
        return(blob);
};

function blobToBase64(blob) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }

  export default convert2mp4;