import React, { useEffect, useState } from "react";

import { connect } from 'react-redux';
import { Routes, Route, useNavigate } from 'react-router-dom';

import chevronAccent from '../../../assets/chevronAccent.png';
import bellActive from '../../../assets/bellActive.png'
import bellActiveDark from '../../../assets/bellActiveDark.png'

import baesappApi from '../../../apis/baesappApi';


const Doorbell = ({
    darkModeEnabled,
    myDoorbell
}) => {

    const navigate = useNavigate();

    const [location, setLocation] = useState(false);
    const [addresses, setAddresses] = useState([]);
    const [isRingable, setIsRingable] = useState(false);
    const [activeAddressName, setActiveAddressName] = useState('');
    const [activeAddressPlaceId, setActiveAddressPlaceId] = useState('');
    const [loadingRingable, setLoadingRingable] = useState(false);
    const [ringing, setRinging] = useState(false);
    const [note, setNote] = useState('');
    const [logMemberDetail, setLogMemberDetail] = useState(false);
 
    useEffect(() => {
        navigator.geolocation.getCurrentPosition((position) => {
            setLocation(position.coords.latitude + ',' + position.coords.longitude);
            baesappApi.post('/address/reverseGeocode', { lat: position.coords.latitude, long: position.coords.longitude }).then(res => {
                console.log(res.data);
                setAddresses(res.data.results);
            })
        },
            (err) => {
                window.alert('Enable location if you want to use doorbell');
            },
            {
                enableHighAccuracy: true
            })
    }, []);

    useEffect(() => {
        if(myDoorbell){
            console.log(myDoorbell);
            if(myDoorbell.logs.length){
                baesappApi.post('/getMemberIds', {Ids: [myDoorbell.logs[myDoorbell.logs.length-1].logMember]})
                .then(res => {
                    setLogMemberDetail(res.data[0]);
                })
                .catch(err => {
                    console.log(err);
                })
            };
        }
    }, [myDoorbell]);

    const addressClicked = (placeId, addressName) => {
        setActiveAddressName(addressName);
        setActiveAddressPlaceId(placeId);
        setLoadingRingable(true);
        navigate('ringer');
        baesappApi.post('/ringer/isRingable', { placeId }).then(res => {
            setIsRingable(true);
            setLoadingRingable(false);
        }).catch(err => {
            setIsRingable(false);
            setLoadingRingable(false);
        })
    };

    const postNote = () => {
        baesappApi.post('/ringer/postNote', { placeId: activeAddressPlaceId, note, date: new Date() }).then(res => {
            setNote('');
        }).catch(err => {
            window.alert('Something unexpected went wrong');
        })
    };

    const ringBell = () => {
        baesappApi.post('/ringer/ring', { placeId: activeAddressPlaceId, date: new Date()}).then(res => {
            setRinging(true);
        }).catch(err => {
            window.alert('Something unexpected went wrong')
        })
    };

    const addressesList = addresses.map(address => {
        let addressName = '';
        let hasStreetNumber = false;
        address.address_components.map(addressComponent => {
            if (addressComponent.types[0] == 'street_number') {
                addressName = addressComponent.long_name;
                hasStreetNumber = true;
            };
            if (addressComponent.types[0] == 'route') {
                addressName = addressName + ' ' + addressComponent.long_name;
            }
        });

        if (addressName != '' && hasStreetNumber) {
            return (
                <button onClick={() => addressClicked(address.formatted_address, addressName)} className="doorbellAddressButton">
                    {addressName}
                </button>
            );
        } else {
            return (
                null
            );
        }
    });

    const Ringer = () => {
        return (
            <>
                <div className='fullScreen secondaryScreen removeScrollBar flex vertical'>
                    <div style={{ height: '60px' }}></div>
                    {loadingRingable ?
                        <div>
                            Loading...
                        </div> :
                        isRingable ?
                            <>
                                {note == '' && <span style={{ color: 'var(--text)', zIndex: '1', alignSelf: 'center', fontSize: '1.2em', position: 'fixed', top: '70px' }}>Type note...</span>}
                                <textarea class="noteInput" value={note} onChange={e => setNote(e.target.value)} style={{ resize: 'none' }}></textarea>
                                {note != '' && <button onClick={() => postNote()} className="postNoteButton">Post note</button>}
                                <button style={ringing ? {
                                    backgroundColor: 'transparent',
                                    border: '3px solid var(--text)',
                                    color: 'var(--text)'
                                } : {}} className="ringButton flex alignCenter spaceAround" onClick={() => !ringing && ringBell()}>
                                    <img style={{height: '30px'}} src={darkModeEnabled ? bellActiveDark : bellActive}></img>
                                </button>
                            </>
                            :
                            <div>No doorbell linked to this address</div>
                    }
                </div>
                <div className="secondaryScreenHeaderContainer">
                    <div className='secondaryScreenHeader'>
                        <div onClick={() => navigate(-1)} className="headerBackButtonContainer">
                            <img src={chevronAccent} className="headerBackIcon" />
                        </div>
                        <span className="modalHeaderTitle">{activeAddressName}</span>
                    </div>
                </div>
            </>
        );
    }

    const Main = () => {
        const lastLog = myDoorbell.logs[myDoorbell.logs.length-1];
        return (
            <div className="flex vertical alignCenter" style={{ gap: '20px', width: '100vw' }}>
                <div style={{ height: '60px' }}></div>
                {myDoorbell && myDoorbell.logs.length && <button onClick={() => {}} className="doorbellAddressButton">
                    {logMemberDetail.memberId.username} {lastLog.logType} {lastLog.date}
                </button>}
                {addressesList}
                <div style={{ height: '60px' }}></div>
            </div>
        );
    }

    const renderContent = () => {
        if (location) {
            if (addresses.length) {
                return (
                    <Routes>
                        <Route path="/" element={<Main />} />
                        <Route path="/ringer" element={Ringer()} />
                    </Routes>
                );
            } else {
                return (
                    <div>No ringable addresses found</div>
                );
            }
        } else {
            return (
                <div>No Location found</div>
            );
        }
    }

    return (renderContent());
};

const mapStateToProps = state => {
    return ({
        darkModeEnabled: state.darkModeEnabled,
        myDoorbell: state.myDoorbell
    });
};

export default connect(mapStateToProps,)(Doorbell);
