import React, { useEffect, useState, useRef } from "react";

import { connect } from 'react-redux';
import { Routes, Route, useNavigate } from 'react-router-dom';

import { updateComments } from "../../../redux/actions";

import baesappApi from '../../../apis/baesappApi';
import ModalHeader from "../../../dumbComponents/Home/modalHeader";
import MemberModal from "../../../dumbComponents/MemberModal";
import AudioRecorder from "../../../dumbComponents/AudioRecorder";
import setLocalObject from "../../../hooks/setLocalObject";

const MediaComments = ({
    currPost,
    myId,
    my_id
}) => {

    const navigate = useNavigate();

    const [isMyPosts, setIsMyPosts] = useState(false);
    const [newCommentText, setNewCommentText] = useState('');
    const [comments, setComments] = useState([]);
    const [commentsList, setCommentsList] = useState([]);
    const [loadingComments, setLoadingComments] = useState(false);
    const [loadingNewComment, setLoadingNewComment] = useState(false);
    const [showRepliesOn, setShowRepliesOn] = useState([]);
    const [replyEnabledOn, setReplyEnabledOn] = useState(null);
    const [replyText, setReplyText] = useState('');
    const [openedReplies, setOpenedReplies] = useState([]);
    const [openedRepliesData, setOpenedRepliesData] = useState({});

    const sendMessageContainerRef = useRef();

    useEffect(() => {
        if (currPost) {
            setLoadingComments(true);
            baesappApi.post('/comment/getComments', { commentIds: currPost.comments })
                .then(res => {
                    setComments(res.data);
                    setLoadingComments(false);
                })
                .catch(err => {
                    window.alert('something unexpected went wrong')
                    setLoadingComments(false);
                })
        };
    }, []);

    const openMemberModal = (member) => {
        if (member._id != my_id) {
            setLocalObject('memberModal', { _id: member._id, ...member.memberId });
            navigate('memberModal');
        }
    };

    useEffect(() => {
        setReplyText('');
    }, [replyEnabledOn]);

    const postComment = () => {
        setLoadingNewComment(true);
        baesappApi.post('/comment/add', { postId: currPost._id, content: newCommentText })
            .then(res => {
                let newComment = res.data;
                newComment.postedBy = { memberId: myId };
                setNewCommentText('');
                setComments(prev => [...prev, newComment])
                setLoadingNewComment(false);
            })
            .catch(err => {
                console.log(err);
                window.alert('something unexpected went wrong')
                setLoadingNewComment(false);
            })
    };

    const postReply = () => {
        setLoadingNewComment(true);
        baesappApi.post('/comment/reply', { commentId: replyEnabledOn, content: replyText })
            .then(res => {
                if (openedReplies.includes(replyEnabledOn)) {
                    let newOpenedRepliesData = openedRepliesData;
                    let newComment = res.data;
                    newComment.postedBy = { memberId: myId };
                    newOpenedRepliesData[replyEnabledOn] = [...newOpenedRepliesData[replyEnabledOn], newComment];
                    setOpenedRepliesData(newOpenedRepliesData);
                };
                setReplyEnabledOn(null);
            })
            .catch(err => {
                console.log(err);
                window.alert('something unexpected went wrong')
                setLoadingNewComment(false);
            })
    };

    const repliesClicked = (commentId, comments) => {
        if (openedReplies.includes(commentId)) {
            setOpenedReplies(prev => prev.filter(reply => reply != commentId));
        } else {
            baesappApi.post('/comment/getComments', { commentIds: comments })
                .then(res => {
                    let newReplies = openedRepliesData;
                    newReplies[commentId] = res.data;
                    setOpenedRepliesData(newReplies);
                    setOpenedReplies(prev => [...prev, commentId]);
                })
                .catch(err => {
                    window.alert('something unexpected went wrong');
                })
        }
    };

    const CommentComponent = (comment) => {
        let replyEnabled = replyEnabledOn == comment._id;
        let thisReplies = [];
        if (openedReplies.includes(comment._id)) {
            thisReplies = openedRepliesData[comment._id];
        };
        return (
            <div key={comment._id} className="flex vertical" style={{ alignSelf: 'flex-start', marginLeft: '20px' }}>
                <div className="flex vertical">
                    <span
                        onClick={() => openMemberModal(comment.postedBy)}
                        className="commentUsername">{comment.postedBy.memberId.username}<span style={{ color: 'var(--success)' }}>{comment.postedBy._id == currPost.poster_id ? ' • Poster' : null}</span></span>
                    <span>{comment.content}</span>
                    <div className="flex" style={{ marginTop: '5px', marginBottom: '15px', gap: '10px' }}>
                        <span style={{ color: 'var(--accent)' }} onClick={() => setReplyEnabledOn(comment)}>reply</span>
                        {
                            thisReplies.length > 0 ? <span style={{ fontWeight: 'bold', color: 'var(--secondary)' }} onClick={() => repliesClicked(comment._id, comment.comments)}>{thisReplies.length} {thisReplies.length == 1 ? 'reply' : 'replies'}</span>
                                :
                                comment.comments.length > 0 ? <span style={{ fontWeight: 'bold', color: 'var(--secondary)' }} onClick={() => repliesClicked(comment._id, comment.comments)}>{comment.comments.length} replies</span>
                                    :
                                    null
                        }
                    </div>
                    {replyEnabled &&
                        <flex className="flex vertical">
                            <input
                                value={replyText}
                                onChange={e => setReplyText(e.target.value)}
                                type="text"
                                placeholder="type reply..."
                            ></input>
                            {replyText != '' && <span onClick={postReply}>post reply</span>}
                        </flex>
                    }
                </div>
                <div style={{ paddingLeft: '15px', alignSelf: 'flex-start' }}>
                    {
                        thisReplies.length ?
                            thisReplies.map(comment => {
                                return CommentComponent(comment)
                            }) : null
                    }
                </div>
            </div>
        );
    };

    useEffect(() => {
        if (comments.length) {
            setCommentsList(comments.map(comment => {
                return CommentComponent(comment)
            }));
        };
    }, [comments, replyEnabledOn, replyText, openedReplies, openedRepliesData]);

    const autoGrowNewMessageInput = (event) => {
        event.target.style.height = '1.2em';
        event.target.style.height = (event.target.scrollHeight) + "px";
    }

    const renderPost = () => {
        return (
            <>
                {
                    currPost.data[0].contentType == 'aIImage' ?
                        <img
                            className="postViewImage"
                            style={{
                                borderRadius: '7px',
                                maxHeight: '20%',
                                maxWidth: '30%'
                            }} src={currPost.data[0].aIImageUrl} /> :
                        currPost.data[0].contentType == 'image' ?
                            <img
                                className="postViewImage"
                                style={{
                                    borderRadius: '7px',
                                    maxHeight: '20%',
                                    maxWidth: '30%'
                                }} src={`https://baesapp.s3.eu-central-1.amazonaws.com/${currPost.data[0].s3Key}`} /> :
                            currPost.data[0].contentType == 'video' ?
                                <video
                                    className="postViewImage" playsInline loop autoPlay muted controls
                                    style={{
                                        borderRadius: '7px',
                                        alignSelf: 'center',
                                        maxHeight: '20%',
                                        maxWidth: '30%'
                                    }} >
                                    <source src={`https://baesapp.s3.eu-central-1.amazonaws.com/${currPost.data[0].s3Key}`} type="video/mp4" />
                                </video> : currPost.data[0].contentType == 'quote' ?
                                    <div className="flex vertical" style={{ marginTop: '20px' }}>
                                        <div className="flex alignCenter" style={{ gap: '10px', marginBottom: '-55px', zIndex: '1', marginLeft: '20px' }}>
                                            <img style={{ height: '40px', width: '40px', borderRadius: '50%' }} src={`https://baesapp.s3.eu-central-1.amazonaws.com/${isMyPosts ? myId.profileShot : currPost.poster_id.memberId.profileShot}`} />
                                            <span style={{ fontSize: '1.5em', fontWeight: 'bold' }}>{isMyPosts ? myId.username : currPost.poster_id.memberId.username}</span>
                                        </div>
                                        <textarea
                                            placeholder="Type your quote here..."
                                            value={currPost.data[0].s3Key}
                                            readOnly
                                            rows='6'
                                            style={{
                                                fontSize: '1.7em',
                                                border: 'none',
                                                resize: 'none',
                                                width: 'calc(100% - 60px)',
                                                borderRadius: '30px',
                                                backgroundColor: 'var(--primaryGlass)',
                                                color: 'var(--text)',
                                                padding: '60px 20px 20px',
                                                alignSelf: 'center',
                                                outline: 'none',
                                                border: '2px solid var(--accent)'
                                            }} />
                                    </div> :
                                    currPost.data[0].contentType == 'audio' ?
                                        <audio controls src={`https://baesapp.s3.eu-central-1.amazonaws.com/${currPost.data[0].s3Key}`} /> :
                                        null
                }
            </>
        );
    };

    const renderContent = () => {
        return (
            <>
                {renderPost()}
                <span
                    style={{
                        fontWeight: 'bold',
                        color: 'var(--text)',
                        fontSize: '1.6em',
                        marginTop: '20px'
                    }}>{currPost.poster_id.memberId.username}<span style={{ color: 'var(--success)', fontSize: '0.8em' }}> • Poster</span></span>
                <span
                    style={{
                        color: 'var(--secondary)',
                        fontSize: '1.5em',
                        marginBottom: '20px'
                    }}>{currPost.caption}</span>
                <div className="flex vertical" style={{ gap: '20px', alignSelf: 'flex-start' }}>
                    {commentsList}
                </div>
                <div id="messageEnd" style={{ minHeight: sendMessageContainerRef.current?.clientHeight, width: '50px' }}></div>
                <div className="commentInputContainer">
                    <div className="fixed bottom sendMessageContainerBackground">
                        <div ref={sendMessageContainerRef} className="sendMessageContainer">
                            <div className="createMessageContainer">
                                {replyEnabledOn ?
                                    <>
                                        <div className="sendMessageReplyingContainer">
                                            <button className="flex replyingMessageBubble">
                                                <span>{replyEnabledOn.content}</span>
                                            </button>
                                            <span className="danger cancelText" onClick={() => setReplyEnabledOn(null)}>cancel</span>
                                        </div>
                                        <textarea
                                            type="text"
                                            placeholder={`Reply to ${replyEnabledOn.postedBy.memberId.username}`}
                                            value={replyText}
                                            className="newMessageTextArea"
                                            onChange={e => {
                                                setReplyText(e.target.value);
                                                autoGrowNewMessageInput(e);
                                            }}
                                        />
                                    </> :
                                    <textarea
                                        type="text"
                                        placeholder={`Comment on ${currPost.poster_id.memberId.username}'s post...`}
                                        value={newCommentText}
                                        className="newMessageTextArea"
                                        onChange={e => {
                                            setNewCommentText(e.target.value);
                                            autoGrowNewMessageInput(e);
                                        }}
                                    />}
                            </div>
                            {
                                loadingNewComment ?
                                    <span>posting</span>
                                    :
                                    newCommentText ?
                                        <span
                                            onClick={postComment}
                                            style={{
                                                fontWeight: 'bold',
                                                color: 'var(--accent)'
                                            }}>post</span> :
                                        replyText ?
                                            <span
                                                onClick={postReply}
                                                style={{
                                                    fontWeight: 'bold',
                                                    color: 'var(--accent)'
                                                }}>post</span> :
                                            <span style={{
                                                fontWeight: 'bold',
                                                color: 'var(--secondary)'
                                            }}>post</span>
                            }
                        </div>
                    </div>
                </div>

            </>
        );
    };

    return (
        <>
            <div className='fullScreen removeScrollBar modalContainer'>
                <div className='flex vertical alignCenter'>
                    <div style={{ minHeight: '110px', width: '50px' }} />
                    {
                        renderContent()
                    }
                </div>
                <ModalHeader title={'COMMENTS'} actionName={''} actionActive={false} actionFunction={() => { }} />
            </div>
            <Routes>
                <Route path="/memberModal/*" element={<MemberModal />} />
            </Routes>
        </>
    );
};

const mapStateToProps = state => {
    return ({
        mediaPosts: state.mediaPosts,
        myId: state.myId,
        my_id: state.my_id
    });
};

export default connect(mapStateToProps, { updateComments })(MediaComments);
