import React, { useEffect, useState } from "react";

import { connect } from 'react-redux';
import CryptoJS from 'crypto-js';
import { Routes, Route, useNavigate } from 'react-router-dom';

import baesappApi from '../../../apis/baesappApi';

import getLocal from '../../../hooks/getLocal';

import NumberPad from "../../../dumbComponents/NumberPad";
import CreateMagicCash from "./CreateMagicCash";
import ModalHeader from "../../../dumbComponents/Home/modalHeader";

import { deleteMagicCashToDelete } from '../../../redux/actions';

import backArrow from '../../../assets/backArrow.png';
import chevronAccent from '../../../assets/chevronAccent.png';
import Lock from '../../../assets/Lock.png';
import magicCashNoteBackground from '../../../assets/magicCashNote.png';
import BaeSvg from "../../../dumbComponents/BaeSvg";

const MagicCash = ({
    myWallet,
    deleteMagicCashToDelete,
    myId
}) => {

    const navigate = useNavigate();
    const ep = getLocal('ep');

    const [magicCashNotes, setMagicCashNotes] = useState([]);
    const [magicCashNotesUnlocked, setMagicCashNotesUnlocked] = useState(false);
    const [handledCard, setHandledCard] = useState(false);
    const [flippedCard, setFlippedCard] = useState(false);

    const numberFormat = Intl.NumberFormat(undefined, {
        style: "currency",
        currency: "ZAR",
    })

    useEffect(() => {
        if (myWallet.magicCashToDelete?.length) {
            let newMagicCashNotes = [...magicCashNotes];
            magicCashNotes.map((note, index) => {
                if (myWallet.magicCashToDelete.includes(note._id)) {
                    newMagicCashNotes.splice(index, 1);
                    deleteMagicCashToDelete(note._id);
                };
            });
            setMagicCashNotes(newMagicCashNotes);
        }
    }, [myWallet.magicCashToDelete]);

    useEffect(() => {
        if (!magicCashNotesUnlocked) navigate('confirmWithPin')
    }, []);

    const deleteMagicCashNote = (magicCashId) => {
        baesappApi.post('/magicCash/delete', { magicCashId })
            .then(res => {
                let newMagicCashNotes = [...magicCashNotes];
                const deleteIndex = newMagicCashNotes.findIndex(magicCashNote => magicCashNote._id == magicCashId);
                if (deleteIndex != -1) {
                    newMagicCashNotes.splice(deleteIndex, 1);
                    setMagicCashNotes(newMagicCashNotes);
                };
            })
            .catch(err => {
                console.log(err);
                window.alert('something went wrong');
            })
    };

    const getMyMagicCash = (number, setLoading) => {
        baesappApi.post('/getMyMagicCash', { pin: number })
            .then(res => {
                setMagicCashNotes(res.data);
                setMagicCashNotesUnlocked(true);
                navigate(-1);
            })
            .catch(err => {
                console.log(err);
                window.alert('something went wrong');
            })
    };

    function calculateTotalMaxAmount(magicCashNotes) {
        let sum = 0;

        for (let i = 0; i < magicCashNotes.length; i++) {
            if (magicCashNotes[i].maxAmountInCents) {
                sum += magicCashNotes[i].maxAmountInCents;
            }
        }

        return sum;
    }

    const cardClicked = (_id) => {
        setHandledCard(_id);
        setTimeout(() => {
            if (flippedCard) {
                setFlippedCard(false);
            } else {
                setFlippedCard(_id);
            }
        }, 150);
        setTimeout(() => {
            setHandledCard(false)
        }, 300);
    }

    const renderMagicCashNotesList = magicCashNotes.map((magicCashNote, index) => {
        return (
            <div style={{overflow: 'hidden'}} onClick={() => cardClicked(magicCashNote._id)} className={`flex vertical alignCenter spaceAround magicCashNote ${handledCard == magicCashNote._id ? 'flipMagicCashNote' : ''}`}>

                {
                    flippedCard != magicCashNote._id ?
                        <>
                            <img
                                src={`https://baesapp.com/media/${myId.profileShot}`}
                                style={{
                                    height: '33%',
                                    borderRadius: '50%'
                                }}
                            />
                            <span>{myId?.username}</span>
                            <span className="magicCashNoteValue">{numberFormat.format(magicCashNote.maxAmountInCents / 100)}</span>
                            <span className="magicCashNotePassphrase">{CryptoJS.AES.decrypt(magicCashNote.passphrase, ep).toString(CryptoJS.enc.Utf8)}</span>
                        </>
                        :
                        <div>
                            <div style={{fontWeight: 'bold', color: 'var(--error)'}} onClick={() => deleteMagicCashNote(magicCashNote._id)}>Delete</div>
                        </div>
                }

            </div>
        );
    });

    return (
        <>
            {magicCashNotesUnlocked && <div className='fullScreen removeScrollBar modalContainer'>
                <div className='flex vertical'>
                    <div style={{ minHeight: '110px' }} />
                    <div className="flex vertical"
                        style={{
                            backgroundColor: 'var(--text)',
                            color: 'var(--base)',
                            minHeight: '150px',
                            padding: '20px',
                            boxSizing: 'border-box'
                        }}>
                        <span style={{
                            color: 'var(--secondary)',
                            fontSize: '1.3em',
                            fontWeight: 'bold'
                        }}>Magicash Net Worth</span>
                        <span style={{ fontWeight: 'bold', fontSize: '2.4em' }}>{numberFormat.format(calculateTotalMaxAmount(magicCashNotes) / 100).slice(2)}</span>
                    </div>
                    <div className="flex spaceAround"
                        style={{
                            width: '100%',
                            transform: 'translateY(-50%)',
                            marginBottom: '-70px',
                            zIndex: '1'
                        }}>
                        <button
                            onClick={() => navigate('createMagicCash')}
                            className="flex alignCenter walletActionButton">
                            <BaeSvg height="20px" iconName="plus" color="accent" />
                            <span>New</span>
                        </button>
                    </div>
                    <div style={{
                        height: 'calc(100vh - 260px)',
                        overflow: 'scroll',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '10px'
                    }}>
                        <div style={{ minHeight: '45px' }} />
                        <span
                            onClick={() => {
                                if (!!magicCashNotes.length) navigate('createMagicCash');
                            }}
                            style={{ fontSize: '1.5em', color: 'var(--secondary)', fontWeight: 'bold', textAlign: 'center' }}>
                            {!!magicCashNotes.length ? `Magicash Notes (${magicCashNotes.length})` : 'Click + New to create magicash notes'}
                        </span>
                        {!!magicCashNotes.length && renderMagicCashNotesList}
                        <div style={{ minHeight: '45px' }} />
                    </div>
                    <ModalHeader title={'MAGICASH'} actionName={''} actionActive={false} actionFunction={() => { }} />
                </div>
            </div>}
            <Routes>
                <Route path='confirmWithPin' element={<NumberPad doubleBack={true} callback={getMyMagicCash} infoText="Wallet PIN" />} />
                <Route path='createMagicCash/*' element={<CreateMagicCash magicCashNotes={magicCashNotes} setMagicCashNotes={setMagicCashNotes} magicCashNotesUnlocked={magicCashNotesUnlocked} />} />
            </Routes>

        </>
    );
};

const mapStateToProps = state => {
    return ({
        myWallet: state.myWallet,
        myId: state.myId
    });
};

export default connect(mapStateToProps, { deleteMagicCashToDelete })(MagicCash);