import './styles/App.css';
import Main from './smartComponents/Main';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import { BrowserRouter as Router} from 'react-router-dom';

import Helmet from 'react-helmet';

import reducers from './redux/reducers';

function App() {

  const store = createStore(reducers);

  return (
    <Provider store={store}>
      <Router>
        <Helmet><meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" /></Helmet>
        <Main />
      </Router>
    </Provider>
  );
}

export default App;