import React, { useState, useMemo } from "react";

import { connect } from 'react-redux';

import ConstructiveButton from "../../../../dumbComponents/Authentication/ConstructiveButton";
import CountrySelector from "../../../../dumbComponents/Authentication/CountrySelector";
import SouthAfrica from "./SouthAfrica";

const NationalIdAuth = ({
    setStep,
    nationalId
}) => {

    const [selectCountryActive, setSelectCountryActive] = useState(false);
    const [country, setCountry] = useState('South Africa');

    const countrySelected = (countryValue) => {
        setCountry(countryValue);
        setSelectCountryActive(false);
    }

    const renderCountryValidationCard = () => {
        switch (country) {
            case 'South Africa':
                return <SouthAfrica setStep={setStep} nationalId={nationalId}/>
            default:
                return <p className="errorText">We're sorry, {country}<br />is not yet supported by<br />Baesapp</p>
        }
    };

    return (
        renderCountryValidationCard()
    );
};

const mapStateToProps = state => {
    return ({
        nationalId: state.nationalId,
        registrationEmail: state.registrationEmail
    });
};

export default connect(mapStateToProps)(NationalIdAuth);
