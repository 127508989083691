const myChatReducer = (myChat=false, action) => {
  switch(action.type){
    case 'GOT_MY_DETAILS':
      return action.payload.chat;
    case 'NEW_CHAT_SET':
      return action.payload;
    case 'NEW_CONTACT_RELATIONS_SET':{
      let newChat = {...myChat};
      newChat.contacts = action.payload.contacts;
      newChat.requestsOutgoing = action.payload.requestsOutgoing;
      newChat.requestsIncoming = action.payload.requestsIncoming;
      return newChat;
    };
    case 'READ_CHAT': {
      console.log(action.payload);
      let newChat = {...myChat};
      let chatIndex = newChat.chats.indexOf(chat => chat.contactId == action.payload);
      if(chatIndex == -1){
        console.log('err');
        return myChat;
      };
      newChat.chats[chatIndex].newMessages = [];
      return newChat;
    };
    case 'NEW_OUTGOING_SET': {
      let newChat = {...myChat};
      if(action.payload.action == '-' && newChat.requestsOutgoing.includes(action.payload._id)){
        newChat.requestsOutgoing.splice(newChat.requestsOutgoing.indexOf(action.payload._id), 1);
      }else{
        newChat.requestsOutgoing.push(action.payload._id);
      }
      return newChat;
    };
    case 'NEW_INCOMMING_SET': {
      let newChat = {...myChat};
      if(action.payload.action == '-' && newChat.requestsIncoming.includes(action.payload._id)){
        newChat.requestsIncoming.splice(newChat.requestsIncoming.indexOf(action.payload._id), 1);
      }else{
        newChat.requestsIncoming.push(action.payload._id);
      }
      return newChat;
    };
    case 'NEW_CONTACT_SET': {
      let newChat = {...myChat};
      if(action.payload.action == '-' && newChat.contacts.includes(action.payload._id)){
        newChat.contacts.splice(newChat.contacts.indexOf(action.payload._id), 1);
      }else{
        newChat.contacts.push(action.payload._id);
      }
      return newChat;
    };
    case 'NEW_ARCHIVED_CHATS_SET': {
      let newChat = {...myChat};
      newChat.archivedChats = action.payload;
      return newChat;
    };
    case 'UPDATE_MY_CHAT_CALLS': {
      let newChat = {...myChat};
      newChat.calls = action.payload;
      return newChat;
    };
    case 'NEW_FILTERS_SET': {
      let newChat = {...myChat};
      newChat.filters = action.payload;
      return newChat;
    };
    case 'CLEAR_ALL':
            return false;

    default:
      return myChat;
  };
};

export default myChatReducer;
