import React, { useState, useRef, useEffect } from "react";

import { connect } from 'react-redux';
import MemberSearchBar from "../../../dumbComponents/Home/MemberSearchBar";
import WebCam from "../../../dumbComponents/WebCam";
import { Routes, Route, useNavigate } from 'react-router-dom';


import useWindowDimensions from '../../../hooks/useWindowDimensions';
import handleReloadWithScroll from "../../../hooks/handleReloadWithScroll";
import spinner from '../../../assets/spinner.png';
import faceIdIcon from '../../../assets/faceIdIcon.png';
import faceIdIconDark from '../../../assets/faceIdIconDark.png';
import filterLines from '../../../assets/filterLines.png';
import filterLinesDark from '../../../assets/filterLinesDark.png';
import clearText from '../../../assets/clearText.png';
import clearTextDark from '../../../assets/clearTextDark.png';
import searchBoxIcon from '../../../assets/searchBoxIcon.png';
import searchBoxIconDark from '../../../assets/searchBoxIconDark.png';
import primaryX from '../../../assets/primaryX.png';
import baesappApi from "../../../apis/baesappApi";
import MyFaceModal from '../../Modals/Search/MyFaceModal';
import MemberModal from '../../../dumbComponents/MemberModal';
import BaeButton from '../../../dumbComponents/BaeButton';
import InfoSearch from './InfoSearch';


import { setMySearch, setMySearchHistory, setSearchFilterData, setTopBarOffset } from "../../../redux/actions";
import setLocalObject from "../../../hooks/setLocalObject";
import BaeSvg from "../../../dumbComponents/BaeSvg";
import LoadingDrop from "../../../dumbComponents/LoadingDrop";

const Search = ({
    mySearch,
    setMySearch,
    setMySearchHistory,
    darkModeEnabled,
    setTopBarOffset,
    topBarOffset
}) => {

    const navigate = useNavigate();

    const { height } = useWindowDimensions();

    const [loading, setLoading] = useState(false);
    const [demoList, setDemoList] = useState([]);
    const [searching, setSearching] = useState(false);
    const [doSearch, setDoSearch] = useState(null);
    const [username, setUsername] = useState('');
    const [searchText, setSearchText] = useState('');
    const [faceDataCurently, setFaceDataCurrently] = useState(false);
    const [infoSearchActive, setInfoSearchActive] = useState(false);
    const [historyListActive, setHistoryListActive] = useState(false);
    const [launchCamera, setLaunchCamera] = useState(false);
    const [shouldShowCancel, setShouldShowCancel] = useState(false);
    const [searchInputElement, setSearchInputElement] = useState();
    const [screenShot, setScreenShot] = useState(false);
    const [currentUsername, setCurrentUsername] = useState(false);
    const [cannotShowMore, setCannotShowMore] = useState(false);
    const [currentProfileImage, setCurrentProfileImage] = useState(false);
    const [scrollY, setScrollY] = useState(0);
    const [searchingPfs, setSearchingPfs] = useState(false);

    const scrollElementRef = useRef(null);



    useEffect(() => {
        setSearching(true);
        baesappApi.get('/getMySearch')
            .then(res => {
                setMySearch(res.data);
                setDemoList(res.data.searchHistory);
                setHistoryListActive(true);
                setSearching(false);
            })
            .catch(err => {
                setSearching(false);
                console.log(err);
            })
    }, []);

    useEffect(() => {
        setSearchInputElement(document.getElementById('searchInput'));
    }, []);

    useEffect(() => {
        if (faceDataCurently) {
            setInfoSearchActive(false);
            setHistoryListActive(false);
        }
    }, [faceDataCurently]);

    useEffect(() => {
        if (infoSearchActive) {
            setFaceDataCurrently(false);
            setHistoryListActive(false);
        }
    }, [infoSearchActive]);

    useEffect(() => {
        if (historyListActive) {
            setFaceDataCurrently(false);
            setInfoSearchActive(false);
        }
    }, [historyListActive]);

    const stopSearch = () => {
        clearTimeout(doSearch);
    };

    const handleOnBlur = (blurredCause) => {
        if (blurredCause == 'cancel') {
            setSearchText('');
            setDemoList(mySearch.searchHistory);
            setHistoryListActive(true);
            setShouldShowCancel(false);
        } else if (blurredCause == 'clear') {
            setSearchText('');
            setDemoList(mySearch.searchHistory);
            setHistoryListActive(true);
            searchInputElement.focus();
        }
    };

    const searchTextChanged = (e) => {
        setSearchText(e.target.value);
        stopSearch();
        setDoSearch(setTimeout(() => {
            search(e.target.value);
        }, 350))
    };

    const handleImageSelect = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            const b64Image = reader.result.split(",")[1];
            takePhoto(b64Image);
        };
    };

    const faceIdClicked = () => {
        navigate('searchProfileShot');
    }
    const filterClicked = () => {
        navigate('searchFilter');
    }

    const search = (value) => {
        setUsername(value);
        if (value !== '') {
            setSearching(true);
            baesappApi.post('/searchMembers/username', { username: value, lastItem: false })
                .then(res => {
                    setDemoList(res.data);
                    setCurrentUsername(value);
                    setHistoryListActive(false);
                    setFaceDataCurrently(false);
                    setInfoSearchActive(false);
                    setSearching(false);
                })
                .catch(err => {
                    console.log(err);
                    setSearching(false);
                })
        } else {
            setDemoList(mySearch.searchHistory);
            setHistoryListActive(true);
        }
    };

    const takePhoto = (_screenShot) => {
        setScreenShot(_screenShot)
        setSearching(true);
        setSearchingPfs(true);
        navigate(-1);
        baesappApi.post('/searchMembers/profileShot', { B64ProfileShot: _screenShot })
            .then(res => {
                setCurrentProfileImage(_screenShot);
                setDemoList(res.data);
                setFaceDataCurrently(true);
                setSearching(false);
                setSearchingPfs(false);
            })
            .catch(err => {
                console.log(err);
                setSearching(false);
            })
    };

    const openMemberModal = (member) => {
        setLocalObject('memberModal', { _id: member._id, ...member.memberId });
        navigate('memberModal');
        baesappApi.post('/setSearchHistory', { memberId: member._id })
            .then(res => {
                setMySearchHistory(res.data);
                if (historyListActive) {
                    setDemoList(res.data);
                };
            })
            .catch(err => {
                console.log(err)
            });
    };

    const loadMore = () => {
        if (infoSearchActive) {
            console.log('none')
        } else if (faceDataCurently) {
            baesappApi.post('/searchMembers/profileShot', { B64ProfileShot: currentProfileImage })
                .then(res => {
                    setDemoList([...demoList.slice(), ...res.data]);
                    if (res.data.length != 10) setCannotShowMore(true);
                })
                .catch(err => {
                    console.log(err);
                })
        } else {
            baesappApi.post('/searchMembers/username', { username: currentUsername, lastItem: demoList[demoList.length - 1]._id })
                .then(res => {
                    setDemoList([...demoList.slice(), ...res.data]);
                    if (res.data.length != 10) setCannotShowMore(true);
                })
                .catch(err => {
                    console.log(err);
                })
        }
    }

    const removeMember = (memberId) => {
        baesappApi.post('/removeFromSearchHistory', { memberId })
            .then(res => {
                setMySearchHistory(res.data);
                setDemoList(res.data);
            })
            .catch(err => {
                console.log(err);
            });
    }

    const renderMemberList = demoList.map((member, index) => {
        return (
            <div className="memberBar flex alignCenter" >
                <img onClick={() => openMemberModal(member)} className="memberBarProfileShot" src={`https://baesapp.com/media/${member.memberId.profileShot}`} />
                <button onClick={() => openMemberModal(member)} className="memberBarUsername flex vertical">
                    <span className="memberBarText">{member.memberId.username}</span>
                    <span className="memberBarText" style={{ opacity: '0.7', fontSize: '0.8em' }}>{member.memberId.preferredName}</span>
                </button>
                {
                    historyListActive &&
                    <button onClick={() => removeMember(member._id)}>
                        <img style={{ height: '12px' }} src={primaryX} />
                    </button>
                }
            </div>
        )
    });

    const fetch = () => {
        setLoading(true);
    };

    const renderLoadingBubbles = () => {
        const listLength = Array.from({ length: 15 }, (_, index) => index + 1);
        let loadingBubblesList = listLength.map(item => {
            return (
                <div className="chatCard flex">
                    <div className="memberBarProfileShot"></div>
                    <div className="flex vertical" style={{ height: '50px', width: '84%', gap: '5px' }}>
                        <div className="loadingFlicker" style={{ height: '16px', width: '10%', borderRadius: '4px' }}></div>
                        <div className="loadingFlicker" style={{ height: '16px', width: '30%', borderRadius: '4px' }}></div>
                    </div>
                </div>
            );
        });
        return (loadingBubblesList);
    };

    const RenderCamera = () => {
        return (
            <div className="fixed blurBackground">
                <WebCam setLaunchCamera={setLaunchCamera} takePhoto={takePhoto} />
            </div>
        );
    };

    const renderContent = () => {
        return (
            <div class='container'>
                <div style={{ width: '100vw', position: 'fixed', top: `${+topBarOffset + 39}px`, zIndex: '1', transitionDuration: '300ms', display: 'flex', justifyContent: 'center', backgroundColor: 'var(--base)', padding: '30px 0 10px' }}>
                    <div className="searchBarContainer">
                        <div className="searchBar" style={shouldShowCancel ? { marginLeft: '10px' } : {}}>
                            <img className="searchBarIcon" src={darkModeEnabled ? searchBoxIcon : searchBoxIconDark} />
                            <input
                                onFocus={() => setShouldShowCancel(true)}
                                id="searchInput"
                                placeholder='Search username...'
                                value={searchText}
                                onChange={searchTextChanged}
                                className={`cleanInput searchBarInput`} />
                            {searchText != '' && <img onClick={() => handleOnBlur('clear')} className="searchBarClear" src={darkModeEnabled ? clearTextDark : clearText} />}
                        </div>
                        {shouldShowCancel && <span onClick={() => handleOnBlur('cancel')} className="searchBarCancelText">
                            Cancel
                        </span>}
                    </div>
                </div>

                {searching ?
                    <div ref={scrollElementRef} style={{gap: '20px'}} className="memberListContainer">
                        <LoadingDrop />
                    </div>
                    :
                    <div ref={scrollElementRef} className="memberListContainer">
                        <div style={{ minHeight: "116px" }} />
                        {infoSearchActive &&
                            <div
                                style={{ fontSize: '1.4em', fontWeight: 'bold', color: 'var(--text)', height: 'fit-content', marginBottom: '15px' }}
                                className={"memberBar flex alignCenter"}
                                onClick={() => navigate('searchFilter')}
                            >
                                <BaeSvg iconName="filter" color="magicashFlicker" height="1.4em" width="1.4em" />
                                <span className="magicashFlicker" style={{ marginLeft: '1.4em' }}>Info search results</span>
                            </div>
                        }
                        {faceDataCurently &&
                            <div
                                style={{ fontSize: '1.4em', fontWeight: 'bold', color: 'var(--text)', height: 'fit-content', marginBottom: '15px' }}
                                className={"memberBar flex alignCenter"}
                                onClick={() => navigate('searchFilter')}
                            >
                                <BaeSvg iconName="faceScan" color="magicashFlicker" height="1.4em" width="1.4em" />
                                <span className="magicashFlicker" style={{ marginLeft: '1.4em' }}>Face scanner results</span>
                            </div>
                        }
                        {(historyListActive && demoList.length) && <div style={{ fontSize: '1.2em', fontWeight: 'bold', color: 'var(--text)', height: 'fit-content', padding: '10px 0' }} className={"memberBar flex alignCenter"} >Recent</div>}
                        {demoList.length ?
                            <>
                                {renderMemberList}
                                {!historyListActive && !cannotShowMore && <button onClick={() => loadMore()} style={{ color: 'var(--accent)', marginBottom: '90px', fontSize: '1.4em' }}>Show more</button>}
                                <div style={{ height: '160px' }}></div>
                            </>
                            : <span style={{ display: 'block' }} className="centerText noResults">No Results</span>}
                    </div>}
            </div>
        );
    };


    return (
        <Routes>
            <Route path='/*' element={renderContent()} />
            <Route path='/searchProfileShot' element={<RenderCamera />} />
            <Route path='/myFace' element={<MyFaceModal />} />
            <Route path='/memberModal/*' element={<MemberModal />} />
            <Route path='/searchFilter/*' element={<InfoSearch handleImageSelect={handleImageSelect} setSearching={setSearching} setDemoList={setDemoList} setInfoSearchActive={setInfoSearchActive} setHistoryListActive={setHistoryListActive} />} />
        </Routes>
    );
};

const mapStateToProps = state => {
    return ({
        mySearch: state.mySearch,
        darkModeEnabled: state.darkModeEnabled,
        topBarOffset: state.topBarOffset
    });
};

export default connect(mapStateToProps, {
    setMySearch,
    setMySearchHistory,
    setTopBarOffset
})(Search);
