import axios from 'axios';

const baesappApi = axios.create({
    baseURL: 'https://baesapp.com/api',
    headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true
    },    
    withCredentials: true
});

export default baesappApi;
