import baesappApi from '../apis/baesappApi';

const getMyChat = () => {

    return new Promise((resolve, reject) => {
    baesappApi.post('/getMyChat')
        .then(res => {
            resolve(res.data);
        })
        .catch(err => {
            reject(err);
        })
    });
};

export default getMyChat;