import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux'

import { useNavigate } from 'react-router-dom';

import hobbies from "../../../assets/hobbies.png";
import person from '../../../assets/person.png';
import coloPalete from '../../../assets/coloPalete.png';
import fox from '../../../assets/fox.png';
import music from '../../../assets/music.png';
import movie from '../../../assets/movie.png';
import tv from '../../../assets/tv.png';
import book from '../../../assets/book.png';
import baesappApi from '../../../apis/baesappApi';

import { setNewMyInfo } from '../../../redux/actions';
import ModalHeader from '../../../dumbComponents/Home/modalHeader';
import Switch from '../../../dumbComponents/Switch';

const Favourites = ({
    myInfo,
    generalKeys,
    setNewMyInfo
}) => {

    const navigate = useNavigate();

    const [canSave, setCanSave] = useState(true);
    const [newMyInfoLocal, setNewMyInfoLocal] = useState(false);

    useEffect(() => {
        if (myInfo) {
            setNewMyInfoLocal(myInfo);
        }
    }, []);

    const categoryFields = [
        {
            field: 'favouriteColors',
            label: 'What is your favourite color?',
            values: ['red', 'blue', 'green', 'yellow', 'orange', 'purple', 'pink', 'black', 'white', 'gray'],
            src: coloPalete,
            isArray: true
        },
        {
            field: 'favouriteAnimals',
            label: 'What is your favourite animals?',
            values: ['Antelope', 'Bear', 'Cat', 'Dog', 'Elephant', 'Frog', 'Giraffe', 'Horse', 'Iguana', 'Jaguar', 'Kangaroo', 'Lion', 'Monkey', 'Narwhal', 'Ostrich', 'Panda', 'Quokka', 'Raccoon', 'Squirrel', 'Tiger', 'Unicorn', 'Vulture', 'Walrus', 'X-ray Fish', 'Yak', 'Zebra'],
            src: fox,
            isArray: true
        },
        {
            field: 'favouriteMusicGenres',
            label: 'What is your favourite music genres?',
            values: [
                "Acid Jazz",
                "Acoustic",
                "Adult Contemporary",
                "Afrikaans Pop",
                "Afrikaans Rock",
                "Afrikaans Indie",
                "Afrobeat",
                "Alternative",
                "Ambient",
                "Americana",
                "Avant-Garde",
                "Bachata",
                "Ballad",
                "Baroque",
                "Big Band",
                "Bluegrass",
                "Blues",
                "Bossa Nova",
                "Breakbeat",
                "Calypso",
                "Caribbean",
                "Chamber Music",
                "Chanson",
                "Chill",
                "Chiptune",
                "Choral",
                "Christian",
                "Classic Rock",
                "Classical",
                "Club",
                "Comedy",
                "Contemporary",
                "Country",
                "Cumbia",
                "Dance",
                "Darkwave",
                "Death Metal",
                "Disco",
                "Drum and Bass",
                "Dub",
                "Dubstep",
                "EDM",
                "Electric",
                "Electronic",
                "Electropop",
                "Emo",
                "Eurodance",
                "Experimental",
                "Fado",
                "Folk",
                "Folk Rock",
                "Funk",
                "Fusion",
                "Gospel",
                "Goth",
                "Grime",
                "Grindcore",
                "Grunge",
                "Hard Rock",
                "Hardcore",
                "Heavy Metal",
                "Hip Hop",
                "House",
                "IDM",
                "Indie",
                "Industrial",
                "Instrumental",
                "Jazz",
                "Jungle",
                "K-Pop",
                "Latin",
                "Lounge",
                "Mambo",
                "Math Rock",
                "Merengue",
                "Metal",
                "Minimal",
                "Modern Classical",
                "Motown",
                "Neo-Soul",
                "New Age",
                "New Wave",
                "Noise",
                "Nu Jazz",
                "Opera",
                "Orchestral",
                "Pop",
                "Post-Punk",
                "Post-Rock",
                "Power Pop",
                "Prog Rock",
                "Progressive House",
                "Psych Rock",
                "Psychedelic",
                "Punk",
                "R&B",
                "Rap",
                "Reggae",
                "Reggaeton",
                "Rock",
                "Rockabilly",
                "Salsa",
                "Samba",
                "Shoegaze",
                "Singer-Songwriter",
                "Ska",
                "Soul",
                "Soundtrack",
                "Southern Rock",
                "Space Rock",
                "Spoken Word",
                "Surf",
                "Swing",
                "Synthpop",
                "Tango",
                "Techno",
                "Trance",
                "Trap",
                "Trip Hop",
                "World"
            ],
            src: music,
            isArray: true
        },
        {
            field: 'favouriteMovies',
            label: 'What is your favourite movie genres?',
            values: [
                "Action",
                "Adventure",
                "Animation",
                "Biography",
                "Comedy",
                "Crime",
                "Documentary",
                "Drama",
                "Family",
                "Fantasy",
                "Film Noir",
                "History",
                "Horror",
                "Music",
                "Musical",
                "Mystery",
                "Romance",
                "Sci-Fi",
                "Short",
                "Sport",
                "Superhero",
                "Thriller",
                "War",
                "Western"
            ],
            src: movie,
            isArray: true
        },
        {
            field: 'favouriteTVShows',
            label: 'What is your favourite tv show genres?',
            values: [
                "Action",
                "Adventure",
                "Animation",
                "Anime",
                "Comedy",
                "Crime",
                "Documentary",
                "Drama",
                "Family",
                "Fantasy",
                "Game Show",
                "History",
                "Horror",
                "Kids",
                "Music",
                "Mystery",
                "News",
                "Reality",
                "Romance",
                "Sci-Fi",
                "Soap",
                "Sitcom",
                "Talk",
                "Thriller",
                "War",
                "Western"
            ],
            src: tv,
            isArray: true
        },
        {
            field: 'favouriteBooks',
            label: 'What is your favourite book genres?',
            values: [
                "Action and Adventure",
                "Art",
                "Alternate History",
                "Autobiography",
                "Anthology",
                "Biography",
                "Chick Lit",
                "Children's",
                "Classic",
                "Comics",
                "Coming of Age",
                "Cookbook",
                "Crime",
                "Drama",
                "Diary",
                "Dictionary",
                "Encyclopedia",
                "Fairytale",
                "Fantasy",
                "Guide",
                "Graphic Novel",
                "Health",
                "History",
                "Historical Fiction",
                "Horror",
                "Humor",
                "Journal",
                "Mystery",
                "Memoir",
                "Math",
                "Medical",
                "Mind",
                "Music",
                "New Age",
                "Nonfiction",
                "Poetry",
                "Philosophy",
                "Paranormal",
                "Picture Books",
                "Psychology",
                "Reference",
                "Religion",
                "Romance",
                "Science",
                "Science Fiction",
                "Self Help",
                "Suspense",
                "Spirituality",
                "Sports",
                "Thriller",
                "Travel",
                "Young Adult"
            ],
            src: book,
            isArray: true
        },
        {
            field: 'favouriteAuthors',
            label: 'Who is your favourite authors?',
            values: [
                "Albert Einstein",
                "Agatha Christie",
                "Carl Sagan",
                "Charles Dickens",
                "Christopher Hitchens",
                "C.S. Lewis",
                "Dan Brown",
                "David Baldacci",
                "Ernest Hemingway",
                "Friedrich Nietzsche",
                "Fyodor Dostoevsky",
                "Gabriel Garcia Marquez",
                "George Orwell",
                "Herman Melville",
                "Immanuel Kant",
                "Isaac Asimov",
                "J.K. Rowling",
                "James Patterson",
                "Jane Austen",
                "Jean-Paul Sartre",
                "John Grisham",
                "Karen Armstrong",
                "Leo Tolstoy",
                "Margaret Atwood",
                "Mark Twain",
                "Maya Angelou",
                "Michel Foucault",
                "Nora Roberts",
                "Neil deGrasse Tyson",
                "Neil Gaiman",
                "Other",
                "Richard Dawkins",
                "Søren Kierkegaard",
                "Saint Augustine",
                "Sam Harris",
                "Stephen Hawking",
                "Stephen King",
                "Toni Morrison",
                "Thomas Aquinas",
                "Virginia Woolf",
                "William Shakespeare",
                "J.R.R. Tolkien"
            ],
            src: person,
            isArray: true
        },
        {
            field: 'favouriteHobbies',
            label: 'What hobbies do you have?',
            values: [
                "Aerobics",
                "Acting",
                "Amateur radio",
                "Attending live music events",
                "Baking",
                "Bird watching",
                "Board games",
                "Bungee jumping",
                "Calligraphy",
                "Canoeing",
                "Card games",
                "Camping",
                "Charity work",
                "Chess",
                "Climbing",
                "Collecting antiques",
                "Collecting art",
                "Collecting books",
                "Collecting coins",
                "Collecting stamps",
                "Cooking",
                "Cosplaying",
                "Crossfit",
                "Crocheting",
                "Cycling",
                "Dancing",
                "DIY projects",
                "Drone flying",
                "Drawing",
                "Embroidery",
                "Fishing",
                "Film-making",
                "Football",
                "Gaming",
                "Gardening",
                "Ghost hunting",
                "Glassblowing",
                "Going to art galleries",
                "Going to the theatre",
                "Hang gliding",
                "Hiking",
                "Home brewing",
                "Horseback riding",
                "Hunting",
                "Jewelry making",
                "Kayaking",
                "Knitting",
                "Learning languages",
                "Leatherworking",
                "Meditation",
                "Metal detecting",
                "Metalworking",
                "Model building",
                "Model railroading",
                "Mountaineering",
                "Musical instruments",
                "Origami",
                "Painting",
                "Parkour",
                "Paragliding",
                "Photography",
                "Pilates",
                "Playing video games",
                "Playing musical instruments",
                "Pottery",
                "Programming",
                "Quilting",
                "Rafting",
                "Reading",
                "Robotics",
                "Rock climbing",
                "Running",
                "Sailing",
                "Scrapbooking",
                "Scuba diving",
                "Sewing",
                "Singing",
                "Skydiving",
                "Skiing",
                "Snorkeling",
                "Snowboarding",
                "Spelunking",
                "Sports",
                "Star gazing",
                "Surfing",
                "Swimming",
                "Tea tasting",
                "Treasure hunting",
                "Traveling",
                "Urban exploration",
                "Virtual Reality",
                "Volunteering",
                "Watching movies",
                "Watching sports",
                "Watching TV shows",
                "Weight lifting",
                "Wine tasting",
                "Woodworking",
                "Writing",
                "Yoga",
                "Zumba"
            ],
            src: hobbies,
            isArray: true
        }
    ]

    const saveFields = () => {
        if (canSave) {
            let toSaveFields = {};
            toSaveFields['favouriteColors'] = newMyInfoLocal['favouriteColors'];
            toSaveFields['favouriteAnimals'] = newMyInfoLocal['favouriteAnimals'];
            toSaveFields['favouriteMusicGenres'] = newMyInfoLocal['favouriteMusicGenres'];
            toSaveFields['favouriteMovies'] = newMyInfoLocal['favouriteMovies'];
            toSaveFields['favouriteTVShows'] = newMyInfoLocal['favouriteTVShows'];
            toSaveFields['favouriteBooks'] = newMyInfoLocal['favouriteBooks'];
            toSaveFields['favouriteAuthors'] = newMyInfoLocal['favouriteAuthors'];
            toSaveFields['favouriteHobbies'] = newMyInfoLocal['favouriteHobbies'];

            baesappApi.post('/setInfo', { info: toSaveFields })
                .then(res => {
                    setNewMyInfo(res.data)
                    navigate(-1);
                })
                .catch(err => {
                    console.log(err);
                })
        }
    };

    const valueClicked = (field, value, isArray) => {
        let tempNewMyInfo = { ...newMyInfoLocal };
        if (isArray) {
            const indexOfValue = tempNewMyInfo[field].value.findIndex(_value => value == _value);
            if (indexOfValue != -1) {
                tempNewMyInfo[field].value.splice(indexOfValue, 1);
            } else {
                tempNewMyInfo[field].value.push(value);
            };
        } else {
            if (tempNewMyInfo[field].value == value) {
                tempNewMyInfo[field].value = '';
            } else {
                tempNewMyInfo[field].value = value;
            }
        };
        setNewMyInfoLocal(tempNewMyInfo);
    };

    const isSelected = (field, value) => {
        if (field.isArray) {
            return newMyInfoLocal[field.field].value.includes(value);
        } else {
            return newMyInfoLocal[field.field].value == value;
        }
    };

    const fieldPublic = (field) => {
        if (newMyInfoLocal) {
            return newMyInfoLocal[field].public;
        } else {
            return false;
        }
    };

    const privateButtonClicked = (field) => {
        let newNewMyInfoLocal = { ...newMyInfoLocal };
        newNewMyInfoLocal[field].public = !newNewMyInfoLocal[field].public;
        setNewMyInfoLocal(newNewMyInfoLocal);

        let toSaveFields = {};

        toSaveFields[field] = {
            value: newNewMyInfoLocal[field].value,
            public: newNewMyInfoLocal[field].public
        }

        baesappApi.post('/setInfo', { info: toSaveFields })
        .then(res => {
            setNewMyInfo(res.data);
        })
        .catch(err => {
            console.log(err);
            setNewMyInfoLocal(myInfo);
        })
    };

    let fieldsList = [];
    if (newMyInfoLocal) {
        fieldsList = categoryFields.map(field => {
            let fieldValuesList = field.values.map(value => {
                return (
                    <div onClick={() => valueClicked(field.field, value, field.isArray)} className='infoPickable' style={isSelected(field, value) ? { borderColor: 'var(--accent)', color: 'var(--text)' } : {}}>
                        {value}
                    </div>
                )
            });
            return (
                <div className='flex vertical' style={{ marginLeft: '20px' }}>
                    <div className='flex fieldEditHeader'>
                        <div className='flex'>
                            <img style={{ maxWidth: '20px', maxHeight: '20px', marginRight: '15px', minWidth: '20px', objectFit: 'contain' }} src={field.src} />
                            <span style={{ fontWeight: 'bold', fontSize: '1.2em' }}>{field.label}</span>
                        </div>
                    </div>
                    <div>
                        {fieldValuesList}
                    </div>
                    <div className="flex infoFieldPrivateSwitch" style={{width: 'fit-content', margin: '5px 20px 20px 0px', alignSelf: 'flex-end'}}>
                        <span className="infoFieldPrivateLabel" style={!fieldPublic(field.field) ? { color: 'var(--accent)' } : {}}>private mode</span>
                        <Switch callback={() => privateButtonClicked(field.field)} switchOn={!fieldPublic(field.field)} switchBallText={fieldPublic(field.field) ? 'OFF' : 'ON'} />
                    </div>
                    <div className='infoLineSeparator' />
                </div>
            );
        })
    }

    return (
        <div className='fullScreen removeScrollBar modalContainer'>
            <div style={{ height: '100px' }} />
            {fieldsList}
            <ModalHeader title={'FAVOURITES'} actionName={'SAVE'} actionActive={canSave} actionFunction={() => saveFields()} />
        </div>
    );
};

const mapStateToProps = state => {
    return ({
        myInfo: state.myInfo
    });
};

export default connect(mapStateToProps, { setNewMyInfo })(Favourites);