import React, {useEffect, useState} from "react";

import baesappApi from "../../../apis/baesappApi";

import spinner from '../../../assets/spinner.png';
import { useNavigate } from "react-router-dom";

import {connect} from 'react-redux';

const InlinePost = ({
    post,
    setCurrentPost
}) => {

    const navigate = useNavigate();

    const smallPostStyle = {
        width: '100%',
        borderRadius: '15px',
        objectFit: 'contain'
    };

    const postClicked = () => {
        setCurrentPost(post);
        navigate('viewPost')
    };

    return(
        <div>
            {
                post ?
                    post.data[0].contentType == 'video' ?
                        <img style={smallPostStyle} onClick={postClicked} src={`https://baesapp.s3.eu-central-1.amazonaws.com/${post.data[0].thumbnail}`}/>
                        :
                        <img style={smallPostStyle} onClick={postClicked} src={`https://baesapp.s3.eu-central-1.amazonaws.com/${post.data[0].s3Key}`}/>
                :
                    <img src={spinner} style={smallPostStyle} className="loginSpinner loginLoader"></img>
            }
        </div>
    );
};

const mapStateToProps = state => {
    return({
    });
};

export default connect(mapStateToProps)(InlinePost);