import React, { useEffect, useState } from "react";

import { connect } from 'react-redux';
import { Routes, Route, useLocation } from 'react-router-dom';


import VisitChat from './VisitChat';
import VisitMedia from './VisitMedia';
import VisitTopBar from "../../dumbComponents/Visit/VisitTopBar";
import VisitInfo from "./VisitInfo";
import VisitWallet from "./VisitWallet";
import VisitOpenChat from "./VisitOpenChat";
import MemberModal from "../../dumbComponents/MemberModal";
import baesappApi from "../../apis/baesappApi";
import { setVisitMember } from "../../redux/actions";
import VisitGames from "./VisitGames";

const Visit = ({
    setVisitMember,
    visitMember,
    socket
}) => {

    const [showVisitMemberModal, setShowVisitMemberModal] = useState(false);
    const [loading, setLoading] = useState(true);

    const { search, pathname } = useLocation();
    const params = new URLSearchParams(search);
    const isGamesArenaPath = pathname.startsWith('/visit/games/arena');

    useEffect(() => {
        let member_id = params.get('_id');
        if(visitMember){
            setLoading(false);
        } else if (member_id) {
            baesappApi.post('/getMemberIds', { Ids: [member_id] })
                .then(res => {
                    setVisitMember({
                        memberEssentials: {...res.data[0].memberId, _id: member_id}
                    });
                    setLoading(false);
                })
                .catch(err => {
                    console.log(err);
                });
        } else {
            setLoading(false);
        }
    }, []);

    return (
        <div style={{ width: '100vw', maxWidth: '500px' }}>
            {
                !loading ?
                    <>
                        <Routes>
                            <Route path="/chat/*" element={<VisitChat />} />
                            <Route path="/media/*" element={<VisitMedia />} />
                            <Route path="/info/*" element={<VisitInfo />} />
                            <Route path="/wallet/*" element={<VisitWallet />} />
                            <Route path="/games/*" element={<VisitGames socket={socket}/>} />
                            <Route path="/openChat/*" element={<VisitOpenChat />} />
                        </Routes>
                        {showVisitMemberModal && <MemberModal setShowVisitMemberModal={setShowVisitMemberModal} />}
                        {!isGamesArenaPath && <VisitTopBar setShowVisitMemberModal={setShowVisitMemberModal} />}
                    </> :
                    <div className="loadingFlicker fixed top" style={{minHeight: '55px', width: '100%'}}></div>
            }
        </div>
    );
};

const mapStateToProps = state => {
    return ({
        visitMember: state.visitMember
    });
};

export default connect(mapStateToProps, {
    setVisitMember
})(Visit);