const topBarOffsetReducer = (topBarOffset = 0, action) => {
    switch(action.type){
        case 'TOP_BAR_OFFSET_SET':
            return action.payload;
            case 'CLEAR_ALL':
                    return 0;
        default: 
            return topBarOffset;
    }
};

export default topBarOffsetReducer;
