import setLocalObject from "./setLocalObject";
import getLocalToObject from "./getLocalToObject";

const removeBefore = (removeBeforeIds) => {
    let clientChats = getLocalToObject('chats');
    for(const key in removeBeforeIds){
        const indexOfRemoveBefore = clientChats[key].conversation.messages.findIndex(message => message._id == removeBeforeIds[key]);
        clientChats[key].conversation.messages = clientChats[key].conversation.messages.splice(indexOfRemoveBefore);
    };
    setLocalObject('chats', clientChats);
};

export default removeBefore;