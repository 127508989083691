import React, { useState } from "react";
import { connect } from 'react-redux';

import emailAuthIcon from '../../../assets/emailAuthIcon.png';
import emailAuthIconDark from '../../../assets/emailAuthIconDark.png';
import baesappApi from "../../../apis/baesappApi";
import { setEmailRegistrationData } from "../../../redux/actions";
import BaeSvg from "../../../dumbComponents/BaeSvg";

import ConstructiveButton from "../../../dumbComponents/Authentication/ConstructiveButton";
import { useNavigate } from "react-router-dom";

const EmailAuth = ({
    setStep,
    setEmailRegistrationData,
    registrationEmail,
    darkModeEnabled
}) => {

    const [email, setEmail] = useState('');
    const [sentToEmail, setSentToEmail] = useState('');
    const [emailId, setEmailId] = useState('');
    const [code, setCode] = useState('');
    const [firstMailSent, setFirstMailSent] = useState(false);
    const [sendCodeLoading, setSendCodeLoading] = useState(false);
    const [confirmCodeLoading, setConfirmCodeLoading] = useState(false);
    const [invalidCode, setInvalidCode] = useState(false);

    const navigate = useNavigate();

    const validEmail = () => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    const renderSendCodeButton = () => {
        if (sendCodeLoading) {
            return <ConstructiveButton style={{ width: '100%' }} buttonState="loading" />
        }
        if (!validEmail()) {
            return <ConstructiveButton style={{ width: '100%' }} buttonState="inactive" text="Send code" />
        } else {
            if (firstMailSent) return null;
            return <ConstructiveButton style={{ width: '100%' }} buttonState="active" text="Send code" callback={sendCode} />
        }
    };

    const renderConfirmCodeButton = () => {
        if (firstMailSent) {
            if (confirmCodeLoading) {
                return <ConstructiveButton style={{ width: '100%' }} buttonState="loading" />
            }
            if (code.length == 6 && emailId !== '') {
                return <ConstructiveButton style={{ width: '100%' }} buttonState="active" text="Confirm code" callback={confirmCode} />
            } else {
                return <ConstructiveButton style={{ width: '100%' }} buttonState="inactive" text="Confirm code" />
            }
        }
    };

    const sendCode = () => {
        setFirstMailSent(false);
        if (validEmail()) {
            setCode('');
            setInvalidCode(false);
            setSendCodeLoading(true);
            baesappApi.post('/emailAuth/requestCode', { email })
                .then(res => {
                    setSentToEmail(email);
                    setEmailId(res.data.emailId);
                    setSendCodeLoading(false);
                    setFirstMailSent(true);
                })
                .catch(err => {
                    setSendCodeLoading(false);
                })
        }
    };

    const confirmCode = () => {
        if (code.length == 6 && emailId !== '') {
            setInvalidCode(false);
            setConfirmCodeLoading(true);
            baesappApi.post('/emailAuth/confirmEmail', { emailId, code })
                .then(res => {
                    setEmailRegistrationData(emailId, email, res.data.emailToken);
                    setStep(2);
                })
                .catch(res => {
                    setInvalidCode(true);
                    setConfirmCodeLoading(false);
                })
        }
    };

    const renderErrorMessage = () => {
        if (invalidCode) {
            return <p className="secondaryText errorText">The code you have entered is incorrect.</p>
        }
    };

    const renderAlreadyVerified = () => {
        if (registrationEmail) {
            return (
                <div className="card stepCard" onClick={() => setStep(2)}>
                    <p className="secondaryText">click here to continue with <strong>{registrationEmail.email}</strong></p>
                </div>
            );
        }
    };

    return (
        <div className="container" style={{ justifyContent: 'start', alignSelf: 'center', width: '100vw', maxWidth: '500px', padding: '15px', boxSizing: 'border-box', alignItems: 'start' }}>
            <button style={{ width: 'fit-content' }} onClick={() => navigate(-1)}>
                <BaeSvg iconName="chevronDown" color="text" height="50px" width="20px" style={{ transform: 'rotate(90deg)' }} />
            </button>
            {
                !firstMailSent ?
                    <div className="flex vertical" style={{ gap: '10px', marginBottom: '20px' }}>
                        <span style={{ fontSize: '30px', fontWeight: 'bold' }}>We're glad you're joining Baesapp</span>
                        <span style={{ fontSize: '1.2em', color: 'gray', fontWeight: 'bold' }}>Let's get started! Please enter your email address for verification.</span>
                    </div> :
                    <div className="flex vertical" style={{ gap: '10px', marginBottom: '20px' }}>
                        <span style={{ fontSize: '30px', fontWeight: 'bold' }}>Verifying your email address</span>
                        <span style={{ fontSize: '1.2em', color: 'gray', fontWeight: 'bold' }}>Please enter the code we have sent to {sentToEmail}.</span>
                    </div>
            }
            {renderAlreadyVerified()}
            {
                !firstMailSent &&
                <div style={{ width: '100%' }}>
                    <span style={{color: 'gray'}}>Email</span>
                    <input style={{ margin: '5px 0 20px 0' }} className="registerInput" onKeyDown={e => { if (e.key === "Enter") { sendCode() } }} autoComplete="email" type="email" onChange={e => setEmail(e.target.value)} placeholder="example@email.com" value={email} />
                </div>}
            {!firstMailSent && renderSendCodeButton()}
            {firstMailSent &&
                <>
                    {renderErrorMessage()}
                    <input
                    style={{marginBottom: '15px'}}
                        onKeyDown={e => { if (e.key === "Enter") { confirmCode() } }}
                        onChange={e => setCode(e.target.value)}
                        value={code}
                        inputprops={{ inputMode: 'numeric' }}
                        className="registerInput" 
                        inputMode="numerical"
                        placeholder="enter code here"
                        type="number"
                        onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                            }
                        }} />
                </>}
            {renderConfirmCodeButton()}
            {
                !firstMailSent ?
                    <span className="authBottomHelper">Already have an account? <strong onClick={() => navigate(-1)} style={{ color: 'var(--accent)', marginLeft: '5px' }}>Log in</strong></span> :
                    <span className="authBottomHelper">Didn't receive a code? <strong onClick={sendCode} style={{ color: 'var(--accent)', marginLeft: '5px' }}>Resend Code</strong></span>
            }
        </div>
    );
    // return(
    //     <div className="container">
    //         <div className="card stepCard">
    //             <img className="emailAuthIcon" src={darkModeEnabled ? emailAuthIconDark : emailAuthIcon} onClick={() => setStep(2)}></img>  
    //             <p>Please verify your email address</p>              
    //             <div className="clamp"> 
    //                 {renderAlreadyVerified()}
    //                 <input className="lineInput" onKeyDown={e => {if(e.key === "Enter"){sendCode()}}} autoComplete="email" type="email" onChange={e => setEmail(e.target.value)} placeholder="example@email.com" value={email}/>
    //                 {renderSendCodeButton()}
    //                 {firstMailSent && 
    //                 <>
    //                 <p className="secondaryText">We sent a verification code to <br/> <strong>{sentToEmail}</strong></p>
    //                 {renderErrorMessage()}
    //                 <input 
    //                     onKeyDown={e => {if(e.key === "Enter"){confirmCode()}}}
    //                     onChange={e => setCode(e.target.value)}
    //                     value={code}
    //                     inputprops={{ inputMode: 'numeric' }}
    //                     className="lineInput" 
    //                     inputMode="numerical" 
    //                     placeholder="enter code here" 
    //                     onKeyPress={(event) => {
    //                         if (!/[0-9]/.test(event.key)) {
    //                         event.preventDefault();
    //                     }
    //                 }}/>
    //                 </>}
    //                 {renderConfirmCodeButton()}
    //             </div>
    //         </div>
    //     </div>
    // );
};

const mapStateToProps = state => {
    return ({
        registrationEmail: state.registrationEmail,
        darkModeEnabled: state.darkModeEnabled
    });
};

export default connect(mapStateToProps, { setEmailRegistrationData })(EmailAuth);
