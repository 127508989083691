import React, { useState } from 'react';
import BaeSvg from './BaeSvg';
import { useNavigate } from 'react-router-dom';

const ImageViewer = ({
  imageUrl
}) => {
  const [scale, setScale] = useState(1);
  const [lastDist, setLastDist] = useState(null);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [startPos, setStartPos] = useState(null);

  const navigate = useNavigate();

  const getDistance = (touch1, touch2) => {
    return Math.sqrt(Math.pow(touch2.pageX - touch1.pageX, 2) + Math.pow(touch2.pageY - touch1.pageY, 2));
  };

  const getMidpoint = (touch1, touch2) => {
    return {
      x: (touch1.pageX + touch2.pageX) / 2,
      y: (touch1.pageY + touch2.pageY) / 2,
    };
  };

  const handleTouchMove = (e) => {
    if (e.touches.length === 2) {
      e.preventDefault(); // Prevent default behavior

      const touch1 = e.touches[0];
      const touch2 = e.touches[1];
      const dist = getDistance(touch1, touch2);

      if (lastDist) {
        // Adjust this factor to control zoom speed
        const zoomSpeedFactor = 0.01;

        const scaleChange = 1 + (zoomSpeedFactor * (dist - lastDist));
        const newScale = scale * scaleChange;

        // Calculate zoom midpoint
        const midpoint = getMidpoint(touch1, touch2);
        const scaleDiff = newScale - scale;
        const newPosition = {
          x: position.x - (midpoint.x - position.x) * (scaleDiff / scale),
          y: position.y - (midpoint.y - position.y) * (scaleDiff / scale),
        };

        setScale(newScale);
        setPosition(newPosition);
      }

      setLastDist(dist);
    } else if (e.touches.length === 1 && scale > 1) {
      e.preventDefault(); // Prevent default behavior

      const touch = e.touches[0];
      if (startPos) {
        // Calculate the difference in position since the last event
        const dx = (touch.pageX - startPos.x) / scale; // Adjust dx based on the scale
        const dy = (touch.pageY - startPos.y) / scale; // Adjust dy based on the scale

        // Update the position state
        setPosition({
          x: position.x + dx,
          y: position.y + dy
        });

        // Update the starting position for the next move event
        setStartPos({ x: touch.pageX, y: touch.pageY });
      } else {
        // Set the initial position for a new touch event
        setStartPos({ x: touch.pageX, y: touch.pageY });
      }
    }
  };

  const handleTouchEnd = () => {
    setLastDist(null);
    setStartPos(null);
  };

  return (
    <div
      className="image-viewer"
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      <img
        src={imageUrl}
        alt="Full Screen"
        style={{
          transform: `scale(${scale}) translate(${position.x}px, ${position.y}px)`,
          transformOrigin: '0 0',
        }}
      />
      <button
        onClick={() => navigate(-1)}
        style={{
          backgroundColor: 'var(--primaryBlur)',
          borderRadius: '50%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '8px',
          boxSizing: 'border-box',
          position: 'absolute',
          top: '10px',
          left: '10px'
        }}>
        <BaeSvg iconName='x' color='text' height='15px'/>
      </button>
    </div>
  );
};

export default ImageViewer;