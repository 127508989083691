const defaultSearchFilterData = [
  {
    key: 'label',
    value: 'PHYSICAL ATTRIBUTES'
  },
  {
    key: 'height',
    label: 'How tall are they? (cm)',
    value: '',
    public: true,
    possibleActions: ['gt', 'lt', 'eq'],
    selectedAction: 'eq'
  },
  {
    key: 'weight',
    label: 'What is their weight? (kg)',
    value: '',
    public: true,
    possibleActions: ['gt', 'lt', 'eq'],
    selectedAction: 'eq'
  },
  {
    key: 'bodyType',
    label: 'What is their body type?',
    value: [],
    public: true,
    possibleActions: ['multipleOr'],
    selectedAction: 'multipleOr'
  },
  {
    key: 'hairColor',
    label: 'What is their hair color?',
    value: [],
    public: true,
    possibleActions: ['multipleAnd', 'multipleOr'],
    selectedAction: 'multipleAnd'
  },
  {
    key: 'hairType',
    label: 'What is their hair type?',
    value: [],
    public: true,
    possibleActions: ['multipleAnd', 'multipleOr'],
    selectedAction: 'multipleAnd'
  },
  {
    key: 'eyeColor',
    label: 'What is their eye color?',
    value: [],
    public: true,
    possibleActions: ['multipleAnd', 'multipleOr'],
    selectedAction: 'multipleAnd'
  },
  {
    key: 'skinTone',
    label: 'What is their skin tone?',
    value: [],
    public: true,
    possibleActions: ['multipleAnd', 'multipleOr'],
    selectedAction: 'multipleAnd'
  },
  {
    key: 'dexterity',
    label: 'Are they left-handed or right-handed?',
    value: [],
    public: true,
    possibleActions: ['multipleOr'],
    selectedAction: 'multipleOr'
  },
  {
    key: 'label',
    value: 'GENERAL'
  },
  {
    key: 'maritalStatus',
    label: 'What is their marital status?',
    value: [],
    public: false,
    possibleActions: ['multipleOr'],
    selectedAction: 'multipleOr'
  },
  {
    key: 'employmentStatus',
    label: 'What is their employment status?',
    value: [],
    public: false,
    possibleActions: ['multipleOr'],
    selectedAction: 'multipleOr'
  },
  {
    key: 'jobSkills',
    label: 'What job skills do they have?',
    value: [],
    public: true,
    possibleActions: ['multipleAnd', 'multipleOr'],
    selectedAction: 'multipleAnd'
  },
  {
    key: 'occupation',
    label: 'What is their occupation?',
    value: [],
    public: true,
    possibleActions: ['multipleAnd', 'multipleOr'],
    selectedAction: 'multipleAnd'
  },
  {
    key: 'highestEducationLevels',
    label: 'What is their highest education levels?',
    value: [],
    public: true,
    possibleActions: ['multipleOr'],
    selectedAction: 'multipleOr'
  }, 
  {
    key: 'degrees',
    label: 'What degrees do they have?',
    value: [],
    public: true,
    possibleActions: ['multipleAnd', 'multipleOr'],
    selectedAction: 'multipleAnd'
  },
  {
    key: 'languagesSpoken',
    label: 'What languages can they speak?',
    value: [],
    public: true,
    possibleActions: ['multipleAnd', 'multipleOr'],
    selectedAction: 'multipleAnd'
  },
  {
    key: 'label',
    value: 'FAVOURITES'
  },
  {
    key: 'favouriteColors',
    label: 'What is their favorite Colors?',
    value: [],
    public: true,
    possibleActions: ['multipleAnd', 'multipleOr'],
    selectedAction: 'multipleAnd'
  },
  {
    key: 'favouriteAnimals',
    label: 'What is their favorite Animals?',
    value: [],
    public: true,
    possibleActions: ['multipleAnd', 'multipleOr'],
    selectedAction: 'multipleAnd'
  },
  {
    key: 'favouriteMusicGenres',
    label: 'What is their favorite Music Genres?',
    value: [],
    public: true,
    possibleActions: ['multipleAnd', 'multipleOr'],
    selectedAction: 'multipleAnd'
  },
  {
    key: 'favouriteMovies',
    label: 'What is their favorite Movies?',
    value: [],
    public: true,
    possibleActions: ['multipleAnd', 'multipleOr'],
    selectedAction: 'multipleAnd'
  },
  {
    key: 'favouriteTVShows',
    label: 'What is their favorite TV Shows?',
    value: [],
    public: true,
    possibleActions: ['multipleAnd', 'multipleOr'],
    selectedAction: 'multipleAnd'
  },
  {
    key: 'personalityTraits',
    label: 'What is their personality Traits?',
    value: [],
    public: true,
    possibleActions: ['multipleAnd', 'multipleOr'],
    selectedAction: 'multipleAnd'
  },
  {
    key: 'favouriteBooks',
    label: 'What is their favorite Books?',
    value: [],
    public: true,
    possibleActions: ['multipleAnd', 'multipleOr'],
    selectedAction: 'multipleAnd'
  },
  {
    key: 'favouriteAuthors',
    label: 'What is their favorite Authors?',
    value: [],
    public: true,
    possibleActions: ['multipleAnd', 'multipleOr'],
    selectedAction: 'multipleAnd'
  },
  {
    key: 'favouriteHobbies',
    label: 'What is their favorite Hobbies?',
    value: [],
    public: true,
    possibleActions: ['multipleAnd', 'multipleOr'],
    selectedAction: 'multipleAnd'
  },
  {
    key: 'label',
    value: 'CONTACT DETAILS'
  },
  {
    key: 'phone',
    label: 'What is their phone number?',
    value: '',
    public: true,
    possibleActions: ['regex'],
    selectedAction: 'regex'
  },
  {
    key: 'email',
    label: 'What is their email?',
    value: '',
    public: true,
    possibleActions: ['regex'],
    selectedAction: 'regex'
  },
  {
    key: 'label',
    value: 'ADDRESS'
  },
  {
    key: 'city',
    label: 'In what city do they live?',
    value: '',
    public: true,
    possibleActions: ['regex'],
    selectedAction: 'regex'
  },
  {
    key: 'provinceState',
    label: 'In what province/state do they live?',
    value: '',
    public: true,
    possibleActions: ['regex'],
    selectedAction: 'regex'
  },
  {
    key: 'country',
    label: 'In what country do they live?',
    value: '',
    public: true,
    possibleActions: ['regex'],
    selectedAction: 'regex'
  },
  {
    key: 'label',
    value: 'MEDICAL'
  },
  {
    key: 'bloodType',
    label: 'What is their blood type?',
    value: [],
    public: false,
    possibleActions: ['multipleOr'],
    selectedAction: 'multipleOr'
  },
  {
    key: 'allergies',
    label: 'What allergies do they have?',
    value: [],
    public: false,
    possibleActions: ['multipleAnd', 'multipleOr'],
    selectedAction: 'multipleAnd'
  },
  {
    key: 'medications',
    label: 'What medications are they currently using?',
    value: [],
    public: false,
    possibleActions: ['multipleAnd', 'multipleOr'],
    selectedAction: 'multipleAnd'
  },
  {
    key: 'medicalConditions',
    label: 'What medical conditions do they have?',
    value: [],
    public: false,
    possibleActions: ['multipleAnd', 'multipleOr'],
    selectedAction: 'multipleAnd'
  }
];

const searchFilterDataReducer = (searchFilterData = defaultSearchFilterData, action) => {
  switch (action.type) {
    case 'SET_SEARCH_FILTER_DATA':
      return action.payload;
    case 'CLEAR_ALL':
      return defaultSearchFilterData;
    default:
      return searchFilterData;
  }
};

export default searchFilterDataReducer;