import baesappApi from '../apis/baesappApi';
import CryptoJS from 'crypto-js';
import syncDbSs from './syncDbSs';

const syncChat = (myChat, setNewContactRelations=() => {}) => {
    baesappApi.post('/syncChat', {
        requestsOutgoing: myChat.requestsOutgoing,
        requestsIncoming: myChat.requestsIncoming,
        contacts: myChat.contacts
    })
    .then(res => {
        let newContactRelations = {
            requestsIncoming: myChat.requestsIncoming,
            requestsOutgoing: myChat.requestsOutgoing,
            contacts: myChat.contacts
        };
        if(res.data.requestsIncoming) newContactRelations.requestsIncoming = res.data.requestsIncoming;
        if(res.data.requestsOutgoing) newContactRelations.requestsOutgoing = res.data.requestsOutgoing;
        if(res.data.contacts) newContactRelations.contacts = res.data.contacts;
        setNewContactRelations(newContactRelations);
        
        if(res.data.ss) window.localStorage.setItem('ss', CryptoJS.AES.decrypt(res.data.ss, window.localStorage.getItem('ep')).toString(CryptoJS.enc.Utf8));
    })
    .catch(err => console.log(err))
};

export default syncChat;