import getLocalToObject from "./getLocalToObject";
import CryptoJS from 'crypto-js';

const decryptMessage = (cypherText, contactId) => {
    const ss = getLocalToObject('ss');
    const contactSs = ss[contactId];
    try{
        return CryptoJS.AES.decrypt(cypherText, contactSs).toString(CryptoJS.enc.Utf8);
    }catch(err) {
        console.log(err)
    }
};  

export default decryptMessage;