import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux'

import { useNavigate } from 'react-router-dom';

import baesappApi from '../../../apis/baesappApi';

import heartBeat from '../../../assets/heartBeat.png';
import pill from '../../../assets/pill.png';
import sadFace from '../../../assets/sadFace.png';
import drop from '../../../assets/drop.png';
import emergencyPhone from '../../../assets/emergencyPhone.png';

import { setNewMyInfo } from '../../../redux/actions';
import ModalHeader from '../../../dumbComponents/Home/modalHeader';
import Switch from '../../../dumbComponents/Switch';

const Medical = ({
    myInfo,
    generalKeys,
    setNewMyInfo
}) => {

    const navigate = useNavigate();

    const [canSave, setCanSave] = useState(true);
    const [newMyInfoLocal, setNewMyInfoLocal] = useState(false);

    useEffect(() => {
        if (myInfo) {
            setNewMyInfoLocal(myInfo);
        }
    }, []);

    const categoryFields = [
        {
            field: 'bloodType',
            label: 'What is your blood type?',
            values: ["A+", "A-", "B+", "B-", "AB+", "AB-", "O+", "O-"],
            src: drop
        },
        {
            field: 'allergies',
            label: 'What allergies do you have?',
            values: [
                'Pollen',
                'Dust mites',
                'Mold',
                'Pet dander',
                'Bee stings',
                'Wasp stings',
                'Peanuts',
                'Tree nuts',
                'Eggs',
                'Milk',
                'Soy',
                'Wheat',
                'Fish',
                'Shellfish',
                'Penicillin',
                'Aspirin',
                'Ibuprofen',
                'Sulfa drugs',
                'other'
            ],
            src: sadFace,
            isArray: true
        },
        {
            field: 'medications',
            label: 'What medications are you currently on?',
            values: [
                'Acetaminophen',
                'Ibuprofen',
                'Aspirin',
                'Naproxen',
                'Antibiotics',
                'Antidepressants',
                'Antihistamines',
                'Antipsychotics',
                'Bronchodilators',
                'Corticosteroids',
                'Insulin',
                'Blood pressure medications',
                'Cholesterol-lowering medications',
                'Oral contraceptives',
                'Thyroid medications',
                'Vaccines'
            ],
            src: pill,
            isArray: true
        },
        {
            field: 'medicalConditions',
            label: 'What medical conditions do you have?',
            values: [
                "Acne",
                "Age-related macular degeneration",
                "Allergies",
                "Alzheimer's disease",
                "Anxiety",
                "Arthritis",
                "Astigmatism",
                "Asthma",
                "Attention-deficit/hyperactivity disorder (ADHD)",
                "Autism spectrum disorder (ASD)",
                "Basal cell carcinoma",
                "Bipolar disorder",
                "Cancer",
                "Cataracts",
                "Celiac disease",
                "Chronic fatigue syndrome",
                "Chronic kidney disease",
                "Chronic obstructive pulmonary disease (COPD)",
                "Crohn's disease",
                "Cystic fibrosis",
                "Depression",
                "Diabetes",
                "Diabetic retinopathy",
                "Down syndrome",
                "Eczema",
                "Endometriosis",
                "Farsightedness",
                "Fibromyalgia",
                "Glaucoma",
                "Gout",
                "Heart disease",
                "Hemophilia",
                "HIV/AIDS",
                "Huntington's disease",
                "Hyperthyroidism",
                "Hypothyroidism",
                "Influenza",
                "Irritable bowel syndrome (IBS)",
                "Klinefelter syndrome",
                "Melanoma",
                "Migraine",
                "Multiple sclerosis",
                "Muscular dystrophy",
                "Myasthenia gravis",
                "Narcolepsy",
                "Nearsightedness",
                "Obsessive-compulsive disorder (OCD)",
                "Obesity",
                "Obstructive sleep apnea",
                "Osteoporosis",
                "Parkinson's disease",
                "Polycystic ovary syndrome (PCOS)",
                "Post-traumatic stress disorder (PTSD)",
                "Psoriasis",
                "Retinal detachment",
                "Rheumatoid arthritis",
                "Rosacea",
                "Schizophrenia",
                "Sickle cell disease",
                "Squamous cell carcinoma",
                "Stroke",
                "Thalassemia",
                "Tourette's syndrome",
                "Turner syndrome",
                "Ulcerative colitis",
                "Vitiligo"
            ],
            src: heartBeat,
            isArray: true
        },
        {
            field: 'emergencyContacts',
            label: 'Who is your emergency contacts?',
            src: emergencyPhone,
            isArray: true
        }
    ]

    const saveFields = () => {
        if (canSave) {
            let toSaveFields = {};
            toSaveFields['bloodType'] = newMyInfoLocal['bloodType'];
            toSaveFields['allergies'] = newMyInfoLocal['allergies'];
            toSaveFields['medications'] = newMyInfoLocal['medications'];
            toSaveFields['medicalConditions'] = newMyInfoLocal['medicalConditions'];
            toSaveFields['emergencyContacts'] = newMyInfoLocal['emergencyContacts'];

            baesappApi.post('/setInfo', { info: toSaveFields })
                .then(res => {
                    setNewMyInfo(res.data)
                    navigate(-1);
                })
                .catch(err => {
                    console.log(err);
                })
        }
    };

    const valueClicked = (field, value, isArray) => {
        let tempNewMyInfo = { ...newMyInfoLocal };
        if (isArray) {
            const indexOfValue = tempNewMyInfo[field].value.findIndex(_value => value == _value);
            if (indexOfValue != -1) {
                tempNewMyInfo[field].value.splice(indexOfValue, 1);
            } else {
                tempNewMyInfo[field].value.push(value);
            };
        } else {
            if (tempNewMyInfo[field].value == value) {
                tempNewMyInfo[field].value = '';
            } else {
                tempNewMyInfo[field].value = value;
            }
        };
        setNewMyInfoLocal(tempNewMyInfo);
    };

    const isSelected = (field, value) => {
        if (field.isArray) {
            return newMyInfoLocal[field.field].value.includes(value);
        } else {
            return newMyInfoLocal[field.field].value == value;
        }
    };

    const fieldPublic = (field) => {
        if (newMyInfoLocal) {
            return newMyInfoLocal[field].public;
        } else {
            return false;
        }
    };

    const privateButtonClicked = (field) => {
        let newNewMyInfoLocal = { ...newMyInfoLocal };
        newNewMyInfoLocal[field].public = !newNewMyInfoLocal[field].public;
        setNewMyInfoLocal(newNewMyInfoLocal);

        let toSaveFields = {};

        toSaveFields[field] = {
            value: newNewMyInfoLocal[field].value,
            public: newNewMyInfoLocal[field].public
        }

        baesappApi.post('/setInfo', { info: toSaveFields })
        .then(res => {
            setNewMyInfo(res.data);
        })
        .catch(err => {
            console.log(err);
            setNewMyInfoLocal(myInfo);
        })
    };

    let fieldsList = [];
    if (newMyInfoLocal) {
        fieldsList = categoryFields.map(field => {
            if (field.values) {
                let fieldValuesList = field.values.map(value => {
                    return (
                        <div onClick={() => valueClicked(field.field, value, field.isArray)} className='infoPickable' style={isSelected(field, value) ? { borderColor: 'var(--accent)', color: 'var(--text)' } : {}}>
                            {value}
                        </div>
                    )
                });
                return (
                    <div className='flex vertical' style={{ marginLeft: '20px' }}>
                        <div className='flex fieldEditHeader'>
                            <div className='flex'>
                                <img style={{ maxWidth: '20px', maxHeight: '20px', marginRight: '15px', minWidth: '20px', objectFit: 'contain' }} src={field.src} />
                                <span style={{ fontWeight: 'bold', fontSize: '1.2em' }}>{field.label}</span>
                            </div>
                        </div>
                        <div>
                            {fieldValuesList}
                        </div>
                        <div className="flex infoFieldPrivateSwitch" style={{ width: 'fit-content', margin: '5px 20px 20px 0px', alignSelf: 'flex-end' }}>
                            <span className="infoFieldPrivateLabel" style={!fieldPublic(field.field) ? { color: 'var(--accent)' } : {}}>private mode</span>
                            <Switch callback={() => privateButtonClicked(field.field)} switchOn={!fieldPublic(field.field)} switchBallText={fieldPublic(field.field) ? 'OFF' : 'ON'} />
                        </div>
                        <div className='infoLineSeparator' />
                    </div>
                );
            } else {
                return null;
            }
        })
    }

    return (
        <div className='fullScreen removeScrollBar modalContainer'>
            <div style={{ height: '100px' }} />
            {fieldsList}
            <ModalHeader title={'MEDICAL'} actionName={'SAVE'} actionActive={canSave} actionFunction={() => saveFields()} />
        </div>
    );
};

const mapStateToProps = state => {
    return ({
        myInfo: state.myInfo
    });
};

export default connect(mapStateToProps, { setNewMyInfo })(Medical);