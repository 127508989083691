import React, { useState, useEffect, useRef } from "react";

import { connect } from 'react-redux';

import { useFilePicker } from 'use-file-picker';

import convert2mp4 from '../../../hooks/convert2mp4';

import { useNavigate, Route, Routes } from 'react-router-dom';

import newPost from '../../../assets/newPost.png';
import collapseArrow from '../../../assets/collapseArrow.png';

import baesappApi from '../../../apis/baesappApi';

import { setNewPost, deletePost, setMyPosts } from '../../../redux/actions';

import MyPostViewer from '../../../dumbComponents/Home/Media/MyPostViewer';
import ModalHeader from "../../../dumbComponents/Home/modalHeader";
import NewPostLoading from "./NewPostLoading";
import ModalBackgroundButton from "../../../dumbComponents/ModalBackgroundButton";
import BaeSvg from '../../../dumbComponents/BaeSvg';
import EditNewPost from "./EditNewPost";
import ViewPost from "./ViewPost";

const Saved = ({
}) => {

    const [loadingMedia, setLoadingMedia] = useState(false);
    const [posts, setPosts] = useState([]);
    const [postsList, setPostsList] = useState([]);
    const [totalQuotes, setTotalQuotes] = useState(0);
    const [totalAudios, setTotalAudios] = useState(0);
    const [totalImages, setTotalImages] = useState(0);
    const [totalVideos, setTotalVideos] = useState(0);

    const navigate = useNavigate();

    useEffect(() => {
        setLoadingMedia(true);
        baesappApi.get('/getMySavedPosts')
            .then(res => {
                setPosts(res.data.reverse());
                setLoadingMedia(false);
            }).catch(err => {
                setLoadingMedia(false);
            })
    }, []);

    useEffect(() => {
        let newTotalQuotes = 0;
        let newTotalAudios = 0;
        let newTotalImages = 0;
        let newTotalVideos = 0;
        posts.map(post => {
            if (post.data[0].contentType == 'quote') {
                newTotalQuotes++;
            } else if (post.data[0].contentType == 'audio') {
                newTotalAudios++;
            } else if (post.data[0].contentType == 'image') {
                newTotalImages++;
            } else if (post.data[0].contentType == 'video') {
                newTotalVideos++;
            }
        });
        setTotalQuotes(newTotalQuotes);
        setTotalAudios(newTotalAudios);
        setTotalImages(newTotalImages);
        setTotalVideos(newTotalVideos);
        setPostsList(posts.map(post => {
            if (post.data[0].contentType == 'quote') {
                return (
                    <button
                        onClick={() => {
                            postClicked(post._id);
                        }}
                        className="galleryImage flex alignCenter spaceAround" style={{ backgroundColor: 'transparent', transform: 'none' }}>
                        <div key={post._id} style={{ padding: '5px', border: '2px solid var(--accent)', borderRadius: '16px', height: '80%', width: '80%' }} className="galleryImage flex alignCenter spaceAround">
                            <span style={{ fontSize: '1em', textAlign: 'center' }}>{post.data[0].s3Key}</span>
                        </div>
                    </button>
                );
            } else if (post.data[0].contentType == 'audio') {
                return (
                    <button
                        onClick={() => {
                            postClicked(post._id);
                        }} key={post._id} className="galleryImage" style={{ backgroundColor: 'transparent' }}>
                        <BaeSvg iconName="audio" color='accent' />
                    </button>
                );
            } else {
                return (
                    <button
                        onClick={() => {
                            postClicked(post._id);
                        }} key={post._id} className="galleryImage">
                        <img style={{ height: '100%', width: '100%', objectFit: 'cover', opacity: post.data[0].contentType == 'video' ? '0.9' : '1' }} src={`https://baesapp.s3.eu-central-1.amazonaws.com/${post.data[0].thumbnail || post.data[0].s3Key}`} />
                        {post.data[0].contentType == 'video' && <BaeSvg iconName="playButton" height="50%" style={{ marginTop: '-75px' }} color="text" />}
                    </button>
                );
            }
        }));
    }, [posts]);

    const postClicked = (activePostId) => {
        window.localStorage.setItem('activePostId', activePostId);
        navigate('viewPost');
    };


    let loaderArr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18];
    const loaderList = loaderArr.map(item => {
        return (
            <div className={'flashMediaLoader galleryImage'}></div>
        );
    });

    return (
        <>
            <div className="fullScreen removeScrollBar modalContainer">
                <div className="flex vertical" style={{ gap: '6px' }}>
                    <div style={{ minHeight: '115px', width: '50px' }} />
                    <div className="mediaGridContainer" style={{ paddingBottom: '114px', bottom: '0', maxHeight: '100vh' }}>
                        <div className={'flashMediaLoader galleryImage'} style={{ height: '0' }}></div>
                        <div className={'flashMediaLoader galleryImage mediaInfoContainer'} style={{ background: 'none', color: 'var(--text)' }}>
                            <span><strong>{totalImages}</strong> Photos, <strong>{totalVideos}</strong> Videos, <strong>{totalQuotes}</strong> Quotes, <strong>{totalAudios}</strong> Audio</span>
                        </div>
                        <div className={'flashMediaLoader galleryImage'} style={{ height: '0' }}></div>
                        {loadingMedia ? loaderList : postsList}
                    </div>
                    <ModalHeader title={'STARRED MEDIA'} actionName={''} actionActive={false} actionFunction={() => { }} />
                </div>
            </div>
            {
                (!loadingMedia && !posts.length) &&
                <span
                    className="noResults centerText"
                    style={{
                        position: 'absolute',
                        width: 'fit-content',
                        left: '0',
                        right: '0',
                        margin: 'auto',
                        zIndex: '2'
                    }}
                >You have no starred media</span>
            }
            <Routes>
                <Route path="/viewPost/*" element={<ViewPost saved={posts}/>} />
            </Routes>
        </>
    );
};

const mapStateToProps = state => {
    return ({
        myId: state.myId
    });
};

export default connect(mapStateToProps, { setNewPost, deletePost, setMyPosts })(Saved);
