import React, { useEffect, useRef, useState } from "react";

import { connect } from 'react-redux';
import { Routes, Route } from 'react-router-dom';
import baesappApi from "../../../apis/baesappApi";

import sendMessageReadyDark from '../../../assets/sendMessageReadyDark.png';
import sendMessageReady from '../../../assets/sendMessageReady.png';

import getLocalToObject from "../../../hooks/getLocalToObject";
import { setMyOpenChats } from "../../../redux/actions";

const VisitOpenChat = ({
    setMyOpenChats,
    myOpenChats,
    darkModeEnabled,
    my_id,
    visitMember
}) => {

    const [currentOpenChat, setCurrentOpenChat] = useState(false);
    const [loading, setLoading] = useState(false);
    const [sending, setSending] = useState(false);
    const [text, setText] = useState('');
    const [stillTyping, setStillTyping] = useState(false);

    const sendMessageContainerRef = useRef();


    useEffect(() => {
        setLoading(true);
        baesappApi.get('/openChats/getMyOpenChats')
            .then(res => {
                setMyOpenChats(res.data);
                setLoading(false);
            })
            .catch(err => {
                console.log(err);
                setLoading(false);
            })
    }, []);

    useEffect(() => {
        if (myOpenChats.length) {
            let newCurrentOpenChat = myOpenChats.find(openChat => openChat.member == visitMember.memberEssentials._id);
            if (newCurrentOpenChat) {
                setCurrentOpenChat(newCurrentOpenChat);
            };
        }
    }, [myOpenChats]);

    useEffect(() => {
        if (text != '' && !stillTyping) {
            setStillTyping(true);
            baesappApi.post('/openChats/iAmTyping', { memberId: visitMember.memberEssentials._id });
            setTimeout(() => {
                setStillTyping(false);
            }, 1000);
        }
    }, [text]);

    const sendNewMessage = () => {
        setSending(true);

        const dateTime = new Date();
        baesappApi.post('/openChats/sendMessage', { memberId: visitMember.memberEssentials._id, text, dateTime })
            .then(res => {
                setMyOpenChats(res.data);
                setSending(false);
                setText('');
                scrollToBottom();
            })
            .catch(err => {
                console.log(err);
                setSending(false);
            })
    };

    const scrollToBottom = () => {
        let messageEnd = document.getElementById('messageEnd');
        messageEnd?.scrollIntoView({ behavior: 'smooth' });
    };

    const autoGrowNewMessageInput = (event) => {
        event.target.style.height = '1.2em';
        event.target.style.height = (event.target.scrollHeight) + "px";
    }

    let messagesList = null;

    if (currentOpenChat.messages?.length) {
        messagesList = currentOpenChat.messages.slice().reverse().map((message, index) => {
            return (
                <li key={message._id} id={message._id} className="flex vertical">
                    <div className={`${message.sender == my_id ? 'rightifyContainer' : 'leftifyContainer'}`}>
                        <div className={message.sender == my_id ? 'rightify' : 'leftify'}>
                            <div>
                                <div style={{ alignSelf: 'stretch' }}>
                                    <span>{message.text}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            );
        });
    };

    const renderContent = () => {
        if (currentOpenChat) {
            return (
                <ul className="removeScrollBar fullScreen messagesContainer">
                    <li id="messageEnd" style={{ minHeight: sendMessageContainerRef.current?.clientHeight, width: '50px' }}></li>
                    <div style={{height: '7px'}}/>
                    {messagesList}
                    <li style={{ minHeight: '60px', width: '50px' }}><p /></li>
                </ul>
            );
        } else if (loading) {
            return (
                <div>Loading...</div>
            )
        } else {
            return (
                <div>Open chats are public to everyone!</div>
            )
        }
    }

    return (
        <div>
            {renderContent()}
            <div className="fixed bottom sendMessageContainerBackground">
                <div ref={sendMessageContainerRef} className="sendMessageContainer"><div className="createMessageContainer">
                    <textarea
                        type="text"
                        placeholder=""
                        value={text}
                        className="newMessageTextArea"
                        onChange={e => {
                            setText(e.target.value);
                            autoGrowNewMessageInput(e);
                        }}
                    />
                </div>
                    {
                        sending ?
                            <img src={darkModeEnabled ? sendMessageReadyDark : sendMessageReady} className="sendMessageButton sendMessageButtonMoved"></img> :
                            text ?
                                <img onClick={() => { if (text) sendNewMessage() }} src={darkModeEnabled ? sendMessageReadyDark : sendMessageReady} className="sendMessageButton sendMessageActivated"></img> :
                                <img className="sendMessageButton" src={darkModeEnabled ? sendMessageReadyDark : sendMessageReady}></img>
                    }
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = state => {
    return ({
        myOpenChats: state.myOpenChats,
        darkModeEnabled: state.darkModeEnabled,
        my_id: state.my_id,
        visitMember: state.visitMember
    });
};

export default connect(mapStateToProps, {
    setMyOpenChats
})(VisitOpenChat);