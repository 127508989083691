const myDoorbellReducer = (myDoorbell = false, action) => {
    switch (action.type) {
        case 'GOT_MY_DETAILS':
            return action.payload.doorbell;
        case 'MY_DOORBELL_UPDATED':
            return action.payload;
        case 'CLEAR_ALL':
            return false;
        default:
            return myDoorbell;
    };
};

export default myDoorbellReducer;
