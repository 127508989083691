import { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import baesappApi from '../../../apis/baesappApi';

import { useNavigate, Route, Routes } from 'react-router-dom';

import chevronAccent from '../../../assets/chevronAccent.png'
import spinner from '../../../assets/spinner.png';
import checkMarkAccent from '../../../assets/checkMarkAccent.png';
import checkMarkAccentDark from '../../../assets/checkMarkAccentDark.png';
import searchBoxIcon from '../../../assets/searchBoxIcon.png';
import searchBoxIconDark from '../../../assets/searchBoxIconDark.png';
import clearText from '../../../assets/clearText.png';
import clearTextDark from '../../../assets/clearTextDark.png';

import EmptyPage from '../../../dumbComponents/EmptyPage';
import MemberModal from '../../../dumbComponents/MemberModal';

import { setNewFilters } from '../../../redux/actions';
import setLocalObject from '../../../hooks/setLocalObject';
import Switch from '../../../dumbComponents/Switch';
import ModalHeader from '../../../dumbComponents/Home/modalHeader';
import BaeSvg from '../../../dumbComponents/BaeSvg';

const EditFilter = ({
    myChat,
    darkModeEnabled,
    setNewFilters
}) => {

    const navigate = useNavigate();
    let filterName = window.localStorage.getItem('filterName');


    const [contactsList, setContactsList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [displayedList, setDisplayedList] = useState([]);
    const [searchInputElement, setSearchInputElement] = useState();
    const [selectedMembers, setSelectedMembers] = useState([]);
    const [originalSelectedMembers, setOriginalSelectedMembers] = useState([]);
    const [totalUnreadMessages, setTotalUnreadMessages] = useState(0);
    const [isSaveable, setIsSaveable] = useState(false);
    const [shouldShowCancel, setShouldShowCancel] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [confirmAction, setConfirmAction] = useState(false);

    useEffect(() => {
        setSearchInputElement(document.getElementById('searchInput'));
        if (myChat && filterName) {
            setLoading(true);
            let filterMembers = myChat.filters.find(filter => filter.filterName == filterName).members;
            setSelectedMembers(filterMembers);
            setOriginalSelectedMembers(filterMembers);
            baesappApi.post('/getMemberIds', { Ids: myChat.contacts })
                .then(res => {
                    setLoading(false);
                    setContactsList(res.data);
                    setDisplayedList(res.data);
                })
                .catch(err => {
                    console.log(err);
                    setLoading(false);
                })
        }
    }, []);

    useEffect(() => {
        if (myChat && filterName) {
            let filterMembers = myChat.filters.find(filter => filter.filterName == filterName).members;
            setOriginalSelectedMembers(filterMembers);
        }
    }, [myChat.filters]);

    useEffect(() => {
        if (myChat && filterName) {
            let tempTotalUnreadMessages = 0;
            myChat.chats.map(chat => {
                tempTotalUnreadMessages += chat.newMessages.length;
            });
            setTotalUnreadMessages(tempTotalUnreadMessages);
        };
    }, [myChat.chats]);

    const saveFilter = () => {
        let newMemberIds = [];
        let removeMemberIds = [];
        selectedMembers.map(selectedMember => {
            if (!originalSelectedMembers.includes(selectedMember)) {
                newMemberIds.push(selectedMember);
            }
        });
        originalSelectedMembers.map(originalSelectedMember => {
            if (!selectedMembers.includes(originalSelectedMember)) {
                removeMemberIds.push(originalSelectedMember);
            }
        });

        setLoading(true);
        baesappApi.post('/chatFilters/members', { filterName, newMemberIds, removeMemberIds })
            .then(res => {
                setNewFilters(res.data);
                setLoading(false);
            })
            .catch(err => {
                console.log(err);
                setLoading(false)
            })
    }

    const deleteFilter = () => {
        baesappApi.post('/chatFilters/deleteFilter', { filterName })
            .then(res => {
                filterName = false;
                window.localStorage.removeItem('filterName');
                setNewFilters(res.data);
                setLoading(false);
                navigate(-1);
            })
            .catch(err => {
                console.log(err);
                setLoading(false)
            })
    };

    const memberClicked = (member_id) => {
        if (selectedMembers.includes(member_id)) {
            setSelectedMembers(selectedMembers.filter(selectedMember => selectedMember != member_id));
        } else {
            setSelectedMembers([...selectedMembers, member_id]);
        };
    };

    const memberProfileShotClicked = (member) => {
        let memberObj = member.memberId;
        memberObj._id = member._id;
        setLocalObject('memberModal', memberObj);
        navigate('memberModal');
    };

    useEffect(() => {
        if (myChat && filterName) {
            if (selectedMembers.length != originalSelectedMembers.length) {
                setIsSaveable(true);
            } else {
                let tempIsSaveable = false;
                selectedMembers.map(selectedMember => {
                    if (!originalSelectedMembers.includes(selectedMember)) {
                        tempIsSaveable = true;
                    }
                });
                setIsSaveable(tempIsSaveable);
            }
        }
    }, [selectedMembers, originalSelectedMembers]);

    useEffect(() => {
        if (searchText == '') {
            setDisplayedList([...contactsList]);
        } else {
            const filteredContacts = contactsList.filter(contact => contact.memberId.username.toUpperCase().includes(searchText.toUpperCase()));
            setDisplayedList(filteredContacts);
        };
    }, [searchText]);

    const handleOnBlur = (blurredCause) => {
        if (blurredCause == 'cancel') {
            setSearchText('');
            setShouldShowCancel(false);
        } else if (blurredCause == 'clear') {
            setSearchText('');
            searchInputElement.focus();
        }
    };

    let alphabetPosition;
    const renderContactsList = displayedList.sort((a, b) => a.memberId.username.localeCompare(b.memberId.username)).map((member, index) => {
        let newAlphabetPosition = false;
        if (alphabetPosition != member.memberId.username.slice(0, 1)) {
            alphabetPosition = member.memberId.username.slice(0, 1)
            newAlphabetPosition = true;
        };
        return (
            <>
                {newAlphabetPosition && <div className="alphabeticalListDevider">
                    {alphabetPosition}
                </div>}
                <div key={member._id} className="flex horizontal chatCard" >
                    <div className="flex horizontal chatCardLeft">
                        <img onClick={() => memberProfileShotClicked(member)} className="memberBarProfileShot" src={`https://baesapp.com/media/${member.memberId.profileShot}`} />
                        <div onClick={() => memberClicked(member._id)} className="flex vertical chatCardMainContent">
                            <span className="chatCardUsername">{member.memberId.username}</span>
                            <div className="chatCardContentContainer">
                                <span className="chatCardContent">Tap to {selectedMembers.includes(member._id) ? 'remove from filter' : 'add to filter'}</span>
                            </div>
                        </div>
                    </div>
                    <Switch callback={() => memberClicked(member._id)} switchOn={selectedMembers.includes(member._id)} />
                </div>
            </>
        );
    });

    const renderContent = () => {
        if (loading) {
            return (<BaeSvg iconName='sideHeart' color='loaderFlicker absoluteHorizontalCenter' height='20px'/>);
        } else if (myChat.contacts?.length == 0) {
            return (
                <div className="emptyPageInfoContainer absoluteHorizontalCenter">
                    <span>You have no contacts</span>
                    <button onClick={() => navigate('/home/search')} className="cleanButton emptyPageInfoButton">Find new contacts</button>
                </div>
            );
        } else {
            return (
                <div className='flex vertical'>
                    <div style={{ minHeight: '110px' }} />
                    <button style={{ color: '#ff6363', fontSize: '1.3em', textAlign: 'end', padding: '20px 10px', maxWidth: '500px', width: '100%', alignSelf: 'center' }} onClick={() => setConfirmAction(true)}>
                        delete
                    </button>
                    <div className="flex spaceAround">
                        <div className="searchBarContainer" style={{ width: '100%' }}>
                            <div className="searchBar" style={shouldShowCancel ? { marginLeft: '10px' } : {}}>
                                <img className="searchBarIcon" src={darkModeEnabled ? searchBoxIcon : searchBoxIconDark} />
                                <input
                                    onFocus={() => setShouldShowCancel(true)}
                                    id="searchInput"
                                    placeholder="Search"
                                    value={searchText}
                                    onChange={e => setSearchText(e.target.value)}
                                    className={`cleanInput searchBarInput`} />
                                {searchText != '' && <img onClick={() => handleOnBlur('clear')} className="searchBarClear" src={darkModeEnabled ? clearTextDark : clearText} />}
                            </div>
                            {shouldShowCancel && <span onClick={() => handleOnBlur('cancel')} className="searchBarCancelText">
                                Cancel
                            </span>}
                        </div>
                    </div>
                    {displayedList.length ?
                        renderContactsList :
                        <span className="centerText noResults">No Results</span>
                    }
                </div>
            );
        };

    };

    return (
        <div className='fullScreen modalContainer'>
            {
                filterName && renderContent()
            }
            <ModalHeader title={filterName} actionName={'SAVE'} actionActive={isSaveable} actionFunction={saveFilter} />
            {
                confirmAction &&
                <>
                    <div onClick={() => setConfirmAction(false)} className="middlePageConvoBackdrop" />
                    <div className="bottomSlideInCard flex vertical spaceAround alignCenter confirmationCard">
                        <span>Delete your <strong>{filterName}</strong> filter?</span>
                        <div className="middlePageConvoButtonsContainer">
                            <button onClick={() => setConfirmAction(false)} className="cleanButton middlePageConvoButtonAccent">Cancel</button>
                            <button onClick={() => deleteFilter()} className="cleanButton middlePageConvoButtonRed">Delete</button>
                        </div>
                    </div>
                </>
            }
            <Routes>
                <Route path="/memberModal/*" element={<MemberModal />} />
            </Routes>
        </div>
    );
};

const mapStateToProps = state => {
    return ({
        myChat: state.myChat,
        darkModeEnabled: state.darkModeEnabled
    });
};

export default connect(mapStateToProps, {
    setNewFilters
})(EditFilter);
