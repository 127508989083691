import React, { useEffect, useState } from "react";

import { connect } from 'react-redux';

import { useNavigate, Route, Routes } from 'react-router-dom';

import collapseArrow from '../../../assets/collapseArrow.png';
import followingModalIcon from '../../../assets/followingModalIcon.png';

import baesappApi from '../../../apis/baesappApi';

import { setMediaFollowing, setFollowingModalShowFollowers } from '../../../redux/actions';

import BaeButton from '../../../dumbComponents/BaeButton';

import MemberModal from '../../../dumbComponents/MemberModal';
import setLocalObject from "../../../hooks/setLocalObject";
import ModalHeader from "../../../dumbComponents/Home/modalHeader";
import BaeSvg from "../../../dumbComponents/BaeSvg";

const FollowingModal = ({
    myMedia,
    setMediaFollowing,
    followingModalShowFollowers,
    setFollowingModalShowFollowers
}) => {

    const navigate = useNavigate();

    const [followingLoading, setFollowingLoading] = useState(false);
    const [followersList, setFollowersList] = useState([]);
    const [followingList, setFollowingList] = useState([]);
    const [searchInputElement, setSearchInputElement] = useState();
    const [followList, setFollowList] = useState([]);
    const [filteredDisplayedList, setFilteredDisplayedList] = useState([]);
    const [shouldShowCancel, setShouldShowCancel] = useState(false);
    const [searchText, setSearchText] = useState('');

    useEffect(() => {
        setSearchInputElement(document.getElementById('searchInput'));
    }, []);
    useEffect(() => {
        if (searchText == '') {
            if (followingModalShowFollowers) {
                setFilteredDisplayedList(followersList);
                populateFollowList();
            } else {
                setFilteredDisplayedList(followingList);
            }
        } else {
            if (followingModalShowFollowers) {
                let newFilteredDisplayedList = []
                newFilteredDisplayedList = followersList.filter(member => member.memberId.username.toUpperCase().includes(searchText.toUpperCase()));
                setFilteredDisplayedList(newFilteredDisplayedList);
            } else {
                let newFilteredDisplayedList = []
                newFilteredDisplayedList = followingList.filter(member => member.memberId.username.toUpperCase().includes(searchText.toUpperCase()));
                setFilteredDisplayedList(newFilteredDisplayedList);
            }
        };
    }, [searchText, followingModalShowFollowers, followingList, followersList]);

    useEffect(() => {
        baesappApi.post('/getMemberIds', { Ids: myMedia.followers })
            .then(res => {
                setFollowersList(res.data);
            })
            .catch(err => console.log(err))
    }, [myMedia.followers]);
    useEffect(() => {
        baesappApi.post('/getMemberIds', { Ids: myMedia.following })
            .then(res => {
                setFollowingList(res.data);
            })
            .catch(err => console.log(err))
    }, [myMedia.following]);

    useEffect(() => {
        populateFollowList();
    }, [filteredDisplayedList]);

    const handleOnBlur = (blurredCause) => {
        if (blurredCause == 'cancel') {
            setSearchText('');
            setShouldShowCancel(false);
        } else if (blurredCause == 'clear') {
            setSearchText('');
            searchInputElement.focus();
        }
    };

    const toggleFollowing = (followeeId) => {
        setFollowingLoading(followeeId);
        baesappApi.post('/following/toggleFollowing', { followeeId })
            .then(res => {
                if (res.data == '+' && !followingModalShowFollowers) {//incase it was aalready done on another device and not updated on this device yet.
                    toggleFollowing(followeeId);
                };
                setMediaFollowing(followeeId, res.data);
                setFollowingList(followingList.filter(member => member._id != followeeId));
                setFollowingLoading(false);
            })
            .catch(err => {
                console.log(err)
                if(err.message == 'following max exceeded'){
                    window.alert("You already follow the maximum number of 150 members.");
                }
                setFollowingLoading(false);
            })
    }

    const memberProfileShotClicked = (member) => {
        let memberObj = member.memberId;
        memberObj._id = member._id;
        setLocalObject('memberModal', memberObj);
        navigate('/home/media/memberModal');
    };

    const renderFollowButton = (id) => {
        const getButtonStyle = () => {
            if (followingLoading == id) {
                return {
                    height: '40px',
                    width: '40px',
                    borderRadius: '50%',
                    backgroundColor: 'gray'
                }
            };
            if (myMedia.followers.includes(id) && !myMedia.following.includes(id)) {
                return {
                    height: '40px',
                    padding: '0 10px',
                    borderRadius: '5px',
                    backgroundColor: 'var(--accent)',
                    color: 'white',
                    fontWeight: 'bold'
                }
            };
            if (myMedia.following.includes(id)) {
                return {
                    height: '40px',
                    padding: '0 10px',
                    borderRadius: '5px',
                    backgroundColor: 'var(--base)',
                    color: 'var(--accent)',
                    fontWeight: 'bold'
                }
            } else {
                return {
                    height: '40px',
                    padding: '0 10px',
                    borderRadius: '5px',
                    backgroundColor: 'var(--accent)',
                    color: 'white',
                    fontWeight: 'bold'
                }
            }
        };
        const getButtonText = () => {
            if (followingLoading == id) {
                return ''
            };
            if (myMedia.followers.includes(id) && !myMedia.following.includes(id)) {
                return 'Follow back'
            };
            if (myMedia.following.includes(id)) {
                return 'Unfollow'
            } else {
                return 'Follow'
            }
        }
        return (
            <button onClick={() => toggleFollowing(id)} style={getButtonStyle()}>
                {getButtonText()}
            </button>
        );
    }

    const populateFollowList = () => {
        setFollowList(filteredDisplayedList.map((member, index) => {
            return (
                <div key={member._id} className="flex horizontal chatCard" >
                    <div className="flex horizontal chatCardLeft">
                        <img onClick={() => memberProfileShotClicked(member)} className="memberBarProfileShot" src={`https://baesapp.com/media/${member.memberId.profileShot}`} />
                        <div onClick={() => { }} className="flex vertical chatCardMainContent">
                            <span className="chatCardUsername">{member.memberId.username}</span>
                        </div>
                    </div>
                    <div className="flex alignCenter chatCardRight" style={{ height: '100%', gap: '5px' }}>
                        <button style={{ height: '40%', fontSize: '1em', padding: '0 10px' }} className='flex alignCenter vertical' onClick={() => { }}>
                            {renderFollowButton(member._id)}
                        </button>
                    </div>
                </div>
            );
        }));
    };

    return (
        <div className="fullScreen removeScrollBar modalContainer">
            <div className="flex vertical" style={{ gap: '6px' }}>
                <div style={{ minHeight: '115px', width: '50px' }} />
                <div className="segmentContainer flex spaceAround">
                    <button onClick={() => setFollowingModalShowFollowers(false)} style={{
                        backgroundColor: `${!followingModalShowFollowers ? 'var(--text)' : ''}`,
                        color: myMedia.following.length > 149 ? 'var(--error)' : 'var(--secondary)',
                        borderColor: `${!followingModalShowFollowers ? '' : 'var(--secondary)'}`
                    }} className="segmentButton">
                        <span>Following</span>
                        <strong style={{
                            fontSize: '1.5em'
                        }}><strong style={{
                            color: myMedia.following.length > 149 ? 'var(--error)' : 'var(--accent)'
                        }}>{myMedia ? myMedia.following.length : ''}</strong>/150</strong>
                    </button>
                    <button onClick={() => setFollowingModalShowFollowers(true)} style={{ backgroundColor: `${followingModalShowFollowers ? 'var(--text)' : ''}`, color: `${followingModalShowFollowers ? 'var(--base)' : 'var(--secondary)'}`, borderColor: `${followingModalShowFollowers ? '' : 'var(--secondary)'}` }} className="segmentButton">
                        <span>Followers</span>
                        <strong style={{ fontSize: '1.5em' }}>{myMedia ? myMedia.followers.length : ''}</strong>
                    </button>
                </div>
                <div className="flex spaceAround">
                    <div className="searchBarContainer" style={{ width: '100%' }}>
                        <div className="searchBar" style={shouldShowCancel ? { marginLeft: '10px' } : {}}>
                            <BaeSvg height='1em' style={{ margin: '10px 5px 10px 10px' }} iconName='magnifyingGlass' color='gray' />
                            <input
                                onFocus={() => setShouldShowCancel(true)}
                                id="searchInput"
                                placeholder="Search"
                                value={searchText}
                                onChange={e => setSearchText(e.target.value)}
                                className={`cleanInput searchBarInput`} />
                            {searchText != '' &&
                                <button className="flex" onClick={() => handleOnBlur('clear')}>
                                    <BaeSvg height='1.4em' iconName='circleX' color='gray' />
                                </button>
                            }
                        </div>
                        {shouldShowCancel && <span onClick={() => handleOnBlur('cancel')} className="searchBarCancelText">
                            Cancel
                        </span>}
                    </div>
                </div>
                {followList}
                <ModalHeader title={followingModalShowFollowers ? 'FOLLOWERS' : 'FOLLOWING'} actionName={'New'} actionActive={true} actionFunction={() => navigate('/home/search')} />
            </div>
        </div>
    );
};

const mapStateToProps = state => {
    return ({
        myMedia: state.myMedia,
        followingModalShowFollowers: state.followingModalShowFollowers
    });
};

export default connect(mapStateToProps, {
    setMediaFollowing,
    setFollowingModalShowFollowers
})(FollowingModal);
