const myActivityReducer = (myActivity=[], action) => {
    switch(action.type){
      case 'GOT_MY_DETAILS':
        return action.payload.activity;
        case 'CLEAR_ALL':
                return [];
      default:
        return myActivity;
    };
  };
  
  export default myActivityReducer;
  