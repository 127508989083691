import baesappApi from "../apis/baesappApi";
import getLocalToObject from "./getLocalToObject";
import setLocalObject from "./setLocalObject";
import setEndNew from "./setEndNew";
import setNewChats from "./setNewChats";
import setLocal from "./setLocal";
import removeBefore from "./removeBefore";
import setStartNew from "./setStartNew";
import killDuplicateMessages from "./killDuplicateMessages";
import deleteMessages from "./deleteMessages";

const updateLocalStorageChats = (dbResponse, clientChats, eventListener) => {
    dbResponse.deletedChats.map(contactId => {
        delete clientChats[contactId];
        setLocalObject('chats', clientChats);
    });
    setNewChats(dbResponse.newChats);
    setStartNew(dbResponse.startNew);
    removeBefore(dbResponse.removeBeforeIds);
    setEndNew(dbResponse.endNew);
    deleteMessages(dbResponse.deletedMessages);
    killDuplicateMessages();
    eventListener();
};

const getUpdatedChats = (eventListener = () => {}) => {
    let chats = getLocalToObject('chats');
    if(!chats) {
        setLocal('chats', '{}')
        chats = {};
    };
    let clientChats = {};
    for(const key in chats){
        if(chats[key].conversation.messages.length > 0){
            clientChats[key] = {
                firstMessageId: chats[key].conversation.messages[0]._id,
                lastMessageId: chats[key].conversation.messages.slice(-1)[0]._id
            }
        }else{
            clientChats[key] = {
                firstMessageId: 0,
                lastMessageId: 0
            }
        }
    };
    baesappApi.post('/updateChats', {clientChats})
        .then(res => {
            updateLocalStorageChats(
                res.data,
                chats, 
                eventListener
            )
        })
        .catch(err => {
            console.log(err)
        })
};

export default getUpdatedChats;