import React, {useRef, useState, useEffect} from "react";

import { useNavigate } from "react-router-dom";

import x from '../assets/x.png';

import Webcam from "react-webcam";

const WebCam = ({
    takePhoto
}) => {

    const webCamRef = useRef();

    const navigate = useNavigate();
    

    const [availableCameras, setAvailableCameras] = useState([]);
    const [currentDevice, setCurrentDevice] = useState(1);
    const [mirrored, setMirrored] = useState(false);
    const [videoConstraints, setVideoConstraints] = useState({
        height: 400,
        width: 400,
        mirrored: false
    });

    useEffect(() => {
        setUpCameraSwitcher();
    }, []);

    useEffect(() => {
        setVideoConstraints({
            ...videoConstraints,
            deviceId: availableCameras[currentDevice]
        })
    }, [availableCameras, currentDevice]);

    const switchCamera = () => {
        const totalCameras = availableCameras.length;
        if(currentDevice == (totalCameras-1)){
            setCurrentDevice(0);
        }else{
            setCurrentDevice(currentDevice+1);
        }
    };

    const setUpCameraSwitcher = () => {
        const navigator = window.navigator;
        const gotDevices = (mediaDevices) =>
        new Promise((resolve, reject) => {
          const availableCameraInputs = []
          mediaDevices.forEach(mediaDevice => {
              console.log(mediaDevices);
            if (mediaDevice.kind === 'videoinput') {
              availableCameraInputs.push(mediaDevice.deviceId)
            }
          })
  
          if (availableCameraInputs.length > 0) {
            resolve(availableCameraInputs)
          } else {
            reject(new Error('ERR::NO_MEDIA_TO_STREAM'))
          }
        })
  
  
      navigator.mediaDevices.enumerateDevices().then(gotDevices)
        .then((availableCameraInputs) => {
            setAvailableCameras(availableCameraInputs);
        })
        .catch(() => setAvailableCameras(false))
    };

    return(
        <>
            <Webcam ref={webCamRef} screenshotFormat="image/png" mirrored={mirrored} class="" screenshotQuality={1} videoConstraints={videoConstraints}></Webcam>
            <div className='cameraButtons flex spaceAround'>
                <div className='takeImageButton' onClick={() => {
                    takePhoto(webCamRef.current.getScreenshot());
                    navigate(-1);
                }}/>
            </div>
            <img onClick={() => navigate(-1)} src={x} className='cancelButton'/>
            <img onClick={() => setMirrored(!mirrored)} src={x}/>
            {availableCameras.length > 1 && <img onClick={() => switchCamera()} className='switchCameraButton' src={x}/>}
        </>
    );
};

export default WebCam;
