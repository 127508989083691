import React, { useEffect, useState, useRef } from "react";

import { connect } from 'react-redux';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { useSpring, animated } from '@react-spring/web';

import chevronAccent from '../../../assets/chevronAccent.png';
import bellActive from '../../../assets/bellActive.png'
import bellActiveDark from '../../../assets/bellActiveDark.png'

import baesappApi from '../../../apis/baesappApi';


const Arena = ({
    peer,
    isInitiator,
    visitMember,
    myId
}) => {

    const [isMouseDown, setIsMouseDown] = useState(false);
    const divRef = useRef();
    const div1Ref = useRef(null);
    const div2Ref = useRef(null);
    const [gameLive, setGameLive] = useState(false);
    const [countDown, setCountDown] = useState(5);
    const [lifeTakerInterval, setLifeTakerInterval] = useState(undefined);
    const [myLife, setMyLife] = useState(100);
    const [theirLife, setTheirLife] = useState(100);
    const [losingLife, setLosingLife] = useState(false);
    const [myFriction, setMyFriction] = useState(isInitiator ? 150 : 110);
    const [theirFriction, setTheirFriction] = useState(isInitiator ? 110 : 150);
    const [touching, setTouching] = useState(false);
    const [resetting, setResetting] = useState(false);
    const [flipped, setFlipped] = useState(false);

    let [{ xy }, set] = useSpring(() => ({
        xy: isInitiator ? [20, 20] : [200, 600],
        config: { mass: 1, tension: 200, friction: myFriction },
        onChange: (res) => {
            areDivsTouching()
        }
    }));

    let [{ xy: theirXy }, setTheir] = useSpring(() => ({
        xy: !isInitiator ? [20, 20] : [200, 600],
        config: { mass: 1, tension: 200, friction: theirFriction },
        onChange: (res) => {
            areDivsTouching();
        }
    }));

    useEffect(() => {
        if (countDown) {
            setGameLive(false);
            setTimeout(() => {
                setCountDown(prevCountdown => prevCountdown - 1);
            }, 1000);
        } else {
            console.log("hit");
            setGameLive(true);
        }
    }, [countDown]);

    useEffect(() => {
        if (peer) {
            peer.on('data', data => {
                const receivedObject = JSON.parse(data);
                if (receivedObject.lifeStatus) {
                    setTheirLife(receivedObject.lifeStatus);
                };
                if (receivedObject.flipped) {
                    setFlipped(true);
                    setResetting(true);
                };
                if (receivedObject.touched) {
                    setTouching(true);
                } else if (receivedObject.xy) {
                    const [xPercentage, yPercentage] = receivedObject.xy;

                    const divRect = divRef.current.getBoundingClientRect();
                    const xPixel = (xPercentage / 100) * divRect.width;
                    const yPixel = (yPercentage / 100) * divRect.height;

                    const pixelCoordinates = { xy: [xPixel, yPixel] };

                    setTheir(pixelCoordinates);
                }
            });

            // Attach the event listener
            window.addEventListener('beforeunload', handleBeforeUnload);

            // Remove the event listener when the component is unmounted
            return () => {
                window.removeEventListener('beforeunload', handleBeforeUnload);
            };
        };
    }, [peer]);

    const amIIt = () => {
        return (isInitiator && flipped) || (!isInitiator && !flipped);
    };

    useEffect(() => {
        if (!resetting && gameLive) {
            if (touching && amIIt()) {
                setLifeTakerInterval(setInterval(() => {
                    setMyLife(prevLife => {
                        if (prevLife > 0) {
                            peer.send(JSON.stringify({ lifeStatus: prevLife - 2 }));
                            return (prevLife - 2);
                        } else {
                            console.log('done');
                            setFlipped(true);
                            peer.send(JSON.stringify({ flipped: true }));
                            setResetting(true);
                            return prevLife;
                        }
                    });
                    setLosingLife(true);
                }, 50));
            } else {
                clearInterval(lifeTakerInterval);
                setLosingLife(false);
            }
        }
    }, [touching]);

    useEffect(() => {
        if (resetting) {
            if (isInitiator) {
                setToAway();
            } else {
                setToInitiator();
            };
        }
    }, [resetting]);

    useEffect(() => {
        if (flipped) {
            setMyLife(100);
            setResetting(false);
        }
    }, [flipped]);

    const setToInitiator = () => {
        setMyFriction(200);
        setTheirFriction(60);
        set({ xy: [20, 20] });
        setTheir({ xy: [200, 600] });
        setFlipped(true);
    };

    const setToAway = () => {
        setMyFriction(60);
        setTheirFriction(200);
        set({ xy: [200, 600] });
        setTheir({ xy: [20, 20] });
        setFlipped(true);
    };

    function areDivsTouching() {
        const div1 = div1Ref.current.getBoundingClientRect();
        const div2 = div2Ref.current.getBoundingClientRect();

        const center1 = {
            x: div1.left + div1.width / 2,
            y: div1.top + div1.height / 2,
        };

        const center2 = {
            x: div2.left + div2.width / 2,
            y: div2.top + div2.height / 2,
        };


        const distance = Math.sqrt(
            Math.pow(center2.x - center1.x, 2) + Math.pow(center2.y - center1.y, 2)
        );

        if (distance <= 30) {
            setTouching(true);
            peer.send(JSON.stringify({ touched: true }));
        } else {
            setTouching(false);
        }
    }

    const handleBeforeUnload = () => {
        // Close the connection before the page unloads
        if (peer) {
            peer.destroy();
        };
    };

    const handleMouseDown = () => {
        setIsMouseDown(true);
    };

    const handleMouseUp = () => {
        setIsMouseDown(false);
    };

    const handleMouseMove = (e) => {
        if (isMouseDown && !resetting) {
            const divRect = divRef.current.getBoundingClientRect();

            const xPercentage = (e.clientX - divRect.left) / divRect.width * 100;
            const yPercentage = (e.clientY - divRect.top) / divRect.height * 100;

            peer.send(JSON.stringify({ xy: [xPercentage.toFixed(2), yPercentage.toFixed(2)] }));
            set({ xy: [e.clientX - divRect.left, e.clientY - divRect.top] });
        }
    };

    const handleTouchStart = (e) => {
        setIsMouseDown(true);
        handleTouchMove(e);
    };

    const handleTouchMove = (e) => {
        if (isMouseDown && !resetting) {
            e.preventDefault(); // Prevent default behavior (scrolling) on touch move
            const touch = e.touches[0];
            const divRect = divRef.current.getBoundingClientRect();

            const xPercentage = (touch.clientX - divRect.left) / divRect.width * 100;
            const yPercentage = (touch.clientY - divRect.top) / divRect.height * 100;

            peer.send(JSON.stringify({ xy: [xPercentage.toFixed(2), yPercentage.toFixed(2)] }));
            set({ xy: [touch.clientX - divRect.left, touch.clientY - divRect.top] });
        }
    };

    const handleTouchEnd = () => {
        setIsMouseDown(false);
    };

    React.useEffect(() => {
        document.addEventListener('touchmove', handleTouchMove, { passive: false });
        return () => {
            document.removeEventListener('touchmove', handleTouchMove, { passive: false });
        };
    }, [handleTouchMove]);

    return (
        <div
            style={{ position: 'relative', width: '100vw', height: '100vh', zIndex: '4', maxWidth: '500px', backgroundColor: losingLife ? 'gray' : 'var(--base)' }}
            ref={divRef}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            onMouseMove={handleMouseMove}
            onTouchStart={handleTouchStart}
            onTouchEnd={handleTouchEnd}
        >
            <div>My life: {myLife}</div>
            <div>Their life: {theirLife}</div>
            {<span style={{position: 'fixed', margin: 'auto', top: '0', bottom: '0', left:'0', right: '0', fontSize: '40px'}}>{countDown}</span>}
            <animated.img
                ref={div1Ref}
                src={`https://baesapp.com/media/${myId.profileShot}`}
                style={{
                    position: 'absolute',
                    width: '30px',
                    height: '30px',
                    borderRadius: '50%',
                    background: 'blue',
                    transform: xy.to((x, y) => `translate(${x - 10}px, ${y - 10}px)`),
                }}
            />
            <animated.img
                ref={div2Ref}
                src={`https://baesapp.com/media/${visitMember.memberEssentials.profileShot}`}
                style={{
                    position: 'absolute',
                    width: '30px',
                    height: '30px',
                    borderRadius: '50%',
                    background: 'coral',
                    transform: theirXy.to((x, y) => `translate(${x - 10}px, ${y - 10}px)`),
                }}
            />
        </div>
    );
};

const mapStateToProps = state => {
    return ({
        visitMember: state.visitMember,
        myId: state.myId
    });
};

export default connect(mapStateToProps,)(Arena);
