import React, { useEffect, useState } from "react";

import { connect } from 'react-redux';
import { useNavigate } from "react-router-dom";

import BaeSvg from '../../dumbComponents/BaeSvg';

import settingsActive from '../../assets/settingsActive.svg';


const BottomNav = ({
    myId,
    myChat,
    myActivity
}) => {

    const [totalNewMessages, setTotalNewMessages] = useState(0);
    const [totalNewActivity, setTotalNewActivity] = useState(0);

    const navigate = useNavigate();

    const path = window.location.pathname;

    useEffect(() => {
        let tempTotalNewMessages = 0;
        myChat.chats?.map(chat => {
            tempTotalNewMessages += chat.newMessages.length;
        });
        setTotalNewMessages(tempTotalNewMessages);
    }, [myChat]);

    useEffect(() => {
        let tempTotalNewActivity = 0;
        myActivity?.map(activity => {
            if (!activity.seen) tempTotalNewActivity++;
        });
        setTotalNewActivity(tempTotalNewActivity);
    }, [myActivity]);

    const bottomBubbleClicked = () => {
        if (isMyPath('core')) {
            navigate(-1);
        } else {
            navigate('core');
        }
    };

    const handleNavigation = (path) => {
        navigate(path);
    }

    const isMyPath = (name) => {
        return path.startsWith(name, 6);
    };

    const getMoreButtonSource = () => {
        if (isMyPath('core')) {
            return 'chevronDown';
        } else if (isMyPath("events")) {
            return 'events'
        } else if (isMyPath("doorbell")) {
            return 'doorbell'
        } else if (isMyPath("matcher")) {
            return 'matcher'
        } else if (isMyPath("info")) {
            return 'info'
        } else if (isMyPath("games")) {
            return 'games'
        } else {
            return 'chevronDown'
        }
    };

    const isMoreActive = () => {
        return isMyPath('events') || isMyPath('doorbell') || isMyPath('matcher') || isMyPath('info') || isMyPath('games');
    };

    const renderLinks = () => {
        return (
            <div className="linkButtons flex horizontal spaceAround alignCenter">
                <button onClick={() => handleNavigation('/home/chat')} className="flex vertical alignCenter" style={{ height: '100%', justifyContent: 'center' }}>
                    <BaeSvg iconName="chat" style={{ maxWidth: '22px' }} height='22px' color={isMyPath('chat') ? 'toolSelectFlash' : 'gray'} />
                </button>
                <button onClick={() => handleNavigation('/home/media')} className="flex vertical alignCenter" style={{ height: '100%', justifyContent: 'center' }}>
                    <BaeSvg iconName="media" style={{ maxWidth: '22px' }} height='22px' color={isMyPath('media') ? 'toolSelectFlash' : 'gray'} />
                </button>
                <div />
                <button onClick={() => handleNavigation('/home/search')} className="flex vertical alignCenter" style={{ height: '100%', justifyContent: 'center' }}>
                    <BaeSvg iconName="search" style={{ maxWidth: '22px' }} height='22px' color={isMyPath('search') ? 'toolSelectFlash' : 'gray'} />
                </button>
                <button onClick={() => handleNavigation('/home/wallet')} className="flex vertical alignCenter" style={{ height: '100%', justifyContent: 'center' }}>
                    <BaeSvg iconName="wallet" style={{ maxWidth: '22px' }} height='22px' color={isMyPath('wallet') ? 'toolSelectFlash' : 'gray'} />
                </button>
            </div>
        );
    }

    return (
        <>
            <div
                className={`bottomNav fixed bottom flex verticalx`}
                style={{paddingBottom: '17px'}}
                >

                <div className="flex bottomNavMain">
                    {renderLinks()}
                </div>

            </div>

            <button onClick={bottomBubbleClicked} className="flex vertical alignCenter fixed"
                style={{
                    justifyContent: 'end',
                    overflow: 'hidden',
                    transitionDuration: '300ms',
                    zIndex: 1,
                    bottom: '12px'
                }}>
                <BaeSvg iconName="bottomBubble" style={{ marginBottom: '-26px' }} height='33px' width="86px" color={'text'} />
                <BaeSvg iconName={getMoreButtonSource()} style={{
                    maxWidth: '14px',
                    transitionDuration: '200ms',
                    marginBottom: '3px',
                    transform: (!isMoreActive() && !isMyPath('core')) ? 'rotate(180deg)' : 'rotate(0deg'
                }}
                    height='22px'    
                    color={isMoreActive() ? 'toolSelectFlashToBase' : 'base'} />
            </button>
        </>
    );

};

const mapStateToProps = state => {
    return ({
        myId: state.myId,
        myChat: state.myChat,
        myActivity: state.myActivity
    });
};

export default connect(mapStateToProps)(BottomNav);