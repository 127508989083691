const mediaPostsReducer = (mediaPosts=[], action) => {
    switch(action.type){
      case 'MEDIA_POSTS_SET':
        return action.payload;
      case 'TOGGLED_MEDIA_POST_LIKE':{
        let tempMediaPost = {};
        let newMediaPosts = [...mediaPosts];
        let postPosition = 0;
        newMediaPosts.map((mediaPost, index) => {
          if(mediaPost._id == action.payload.postId){
            tempMediaPost = {...mediaPost};
            postPosition = index;
          };
        }); 
        if(action.payload.shouldRemove){
          tempMediaPost.likes.splice(tempMediaPost.likes.indexOf(action.payload.my_id, 1));
        }else{
          tempMediaPost.likes.push(action.payload.my_id);
        };
        newMediaPosts.splice(postPosition, 1, tempMediaPost);
        return newMediaPosts;
      };
      case 'COMMENTS_UPDATED':{
        let tempMediaPost = {};
        let newMediaPosts = [...mediaPosts];
        let postPosition = 0;
        newMediaPosts.map((mediaPost, index) => {
          if(mediaPost._id == action.payload.postId){
            tempMediaPost = {...mediaPost};
            postPosition = index;
          };
        }); 
        switch(action.payload.action){
          case 'add':
            tempMediaPost.comments.push(action.payload.comment);
            break;
          case 'update':
            tempMediaPost.comments.map((comment, index) => {
              if(comment._id == action.payload.commentId){
                tempMediaPost.comments[index] = action.payload.comment;
              };
            });
        };

        newMediaPosts.splice(postPosition, 1, tempMediaPost);
        console.log(newMediaPosts);
        return newMediaPosts;
      };
      case 'CLEAR_ALL':
              return [];
      default:
        return mediaPosts;
    };
  };
  
  export default mediaPostsReducer;
  