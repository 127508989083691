import React, { useState, useEffect } from "react";

import { connect } from 'react-redux';
import getLocalToObject from '../../../hooks/getLocalToObject';
import CryptoJS from 'crypto-js';
import { Routes, Route, useNavigate } from 'react-router-dom';

import baesappApi from '../../../apis/baesappApi';
import Switch from "../../../dumbComponents/Switch";
import ModalHeader from "../../../dumbComponents/Home/modalHeader";
import MemberModal from "../../../dumbComponents/MemberModal";
import BaeSvg from "../../../dumbComponents/BaeSvg";
import setLocalObject from "../../../hooks/setLocalObject";

const MediaShare = ({
    post,
    myChat
}) => {

    const [contactsList, setContactsList] = useState([]);
    const [selectedMembers, setSelectedMembers] = useState([]);
    const [segment, setSegment] = useState('Contacts');
    const [loading, setLoading] = useState(true);
    const [displayedList, setDisplayedList] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [searchInputElement, setSearchInputElement] = useState(null);
    const [shouldShowCancel, setShouldShowCancel] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        setSearchInputElement(document.getElementById('searchInput'));
    }, []);

    useEffect(() => {
        setLoading(true);
        if (myChat) {
            baesappApi.post('/getMemberIds', { Ids: myChat.contacts })
                .then(res => {
                    setLoading(false);
                    setContactsList(res.data);
                    setDisplayedList(res.data);
                })
                .catch(err => {
                    console.log(err);
                    setLoading(false);
                })
        }
    }, [myChat.contacts]);

    useEffect(() => {
        if (searchText == '') {
            setDisplayedList(getSegmentMembers(contactsList));
        } else {
            const filteredMembers = contactsList.filter(member => member.memberId.username.toUpperCase().includes(searchText.toUpperCase()));
            setDisplayedList(getSegmentMembers(filteredMembers));
        };
    }, [segment]);

    useEffect(() => {
        if (searchText == '') {
            setDisplayedList(getSegmentMembers(contactsList));
        } else {
            const filteredMembers = contactsList.filter(member => member.memberId.username.toUpperCase().includes(searchText.toUpperCase()));
            setDisplayedList(getSegmentMembers(filteredMembers));
        };
    }, [searchText]);

    const getSegmentMembers = (members) => {
        if (segment == 'Selected') {
            return members.filter(member => selectedMembers.includes(member._id));
        } else {
            return members;
        }
    };

    const handleOnBlur = (blurredCause) => {
        if (blurredCause == 'cancel') {
            setSearchText('');
            setShouldShowCancel(false);
        } else if (blurredCause == 'clear') {
            setSearchText('');
            searchInputElement.focus();
        }
    };

    const sendPost = async () => {
        const totalMembersSentAttempted = selectedMembers.length;
        console.log(post);
        let contentType = post.data[0].contentType;
        switch (contentType) {
            case 'image': {
                contentType = 'postImage';
                break;
            };
            case 'video': {
                contentType = 'postVideo';
                break;
            };
            default: {
                contentType = false;
                break;
            };
        };

        const sendMessage = async (selectedMembersIndex) => {
            const member_id = selectedMembers[selectedMembersIndex];
            const conversationId = getLocalToObject('chats')[member_id].conversation._id;
            const time = new Date();
            const contactId = member_id;

            const text = post._id;
            const ss = getLocalToObject('ss');
            const mss = ss[member_id];
            const encryptedText = CryptoJS.AES.encrypt(text, mss).toString();

            const data = {
                text: encryptedText,
                conversationId,
                time,
                contactId,
                contentType
            };
            await baesappApi.post('/sendMessage', data)
                .then(res => {
                    if (selectedMembersIndex + 1 < selectedMembers.length) {
                        sendMessage(selectedMembersIndex + 1);
                    } else {
                        setSelectedMembers([]);
                        navigate(-1);
                    }
                })
        }
        sendMessage(0);
    };
    const memberProfileShotClicked = (member) => {
        let memberObj = member.memberId;
        memberObj._id = member._id;
        setLocalObject('memberModal', memberObj);
        navigate('memberModal');
    };
    const memberClicked = (member_Id) => {
        if (selectedMembers.includes(member_Id)) {
            setSelectedMembers(selectedMembers.filter(member_id => member_id != member_Id));
        } else {
            setSelectedMembers([...selectedMembers, member_Id])
        };
    };

    let alphabetPosition;
    const renderContactsList = displayedList.sort((a, b) => a.memberId.username.localeCompare(b.memberId.username)).map((member, index) => {
        let newAlphabetPosition = false;
        if (alphabetPosition != member.memberId.username.slice(0, 1)) {
            alphabetPosition = member.memberId.username.slice(0, 1)
            newAlphabetPosition = true;
        };
        return (
            <>
                {newAlphabetPosition && <div className="alphabeticalListDevider">
                    <span style={{ marginLeft: '10px' }}>
                        {alphabetPosition.toUpperCase()}
                    </span>
                </div>}

                <div key={member._id} className="flex horizontal chatCard" >
                    <div className="flex horizontal chatCardLeft">
                        <img onClick={() => memberProfileShotClicked(member)} className="memberBarProfileShot" src={`https://baesapp.com/media/${member.memberId.profileShot}`} />
                        <div onClick={() => memberClicked(member._id)} className="flex vertical chatCardMainContent">
                            <span className="chatCardUsername">{member.memberId.username}</span>
                            <div className="chatCardContentContainer">
                                <span className="chatCardContent">Tap to {selectedMembers.includes(member._id) ? 'unselect' : 'select'}</span>
                            </div>
                        </div>
                    </div>
                    <Switch callback={() => memberClicked(member._id)} switchOn={selectedMembers.includes(member._id)} />
                </div>
            </>
        );
    });

    const renderContent = () => {
        if (loading) {
            return (<BaeSvg iconName='sideHeart' color='loaderFlicker absoluteHorizontalCenter' height='20px' />);
        } else if (myChat.contacts?.length == 0) {
            return (
                <>
                    <div className="emptyPageInfoContainer absoluteHorizontalCenter">
                        <span>You have no contacts</span>
                        <button onClick={() => navigate('/home/search')} className="cleanButton emptyPageInfoButton">Find new contacts</button>
                    </div>
                </>
            );
        } else {
            if (!displayedList.length) {
                return (
                    <span className="centerText noResults">No Results</span>
                );
            } else {
                return (
                    renderContactsList
                );
            }
        };

    };

    return (
        <>
            <div className='fullScreen removeScrollBar modalContainer'>
                <div className='flex vertical'>
                    <div style={{ minHeight: '110px', width: '50px' }} />
                    <div className="segmentContainer flex spaceAround">
                        <button onClick={() => setSegment('Contacts')} style={{ backgroundColor: `${segment == 'Contacts' ? 'var(--text)' : ''}`, color: `${segment == 'Contacts' ? 'var(--base)' : 'var(--secondary)'}`, borderColor: `${segment == 'Contacts' ? '' : 'var(--secondary)'}` }} className="segmentButton">
                            <span>Contacts</span>
                            <strong style={{ fontSize: '1.5em' }}>{contactsList.length}</strong>
                        </button>
                        <button onClick={() => setSegment('Selected')} style={{ backgroundColor: `${segment != 'Contacts' ? 'var(--text)' : ''}`, color: `${segment != 'Contacts' ? 'var(--base)' : 'var(--secondary)'}`, borderColor: `${segment != 'Contacts' ? '' : 'var(--secondary)'}` }} className="segmentButton">
                            <span>Selected</span>
                            <strong style={{ fontSize: '1.5em' }}>{selectedMembers.length}</strong>
                        </button>
                    </div>
                    <div className="flex spaceAround">
                        <div className="searchBarContainer" style={{ width: '100%' }}>
                            <div className="searchBar" style={shouldShowCancel ? { marginLeft: '10px' } : {}}>
                                <BaeSvg height='1em' style={{ margin: '10px 5px 10px 10px' }} iconName='magnifyingGlass' color='gray' />
                                <input
                                    onFocus={() => setShouldShowCancel(true)}
                                    id="searchInput"
                                    placeholder="Search"
                                    value={searchText}
                                    onChange={e => setSearchText(e.target.value)}
                                    className={`cleanInput searchBarInput`} />
                                {searchText != '' &&
                                    <button className="flex" onClick={() => handleOnBlur('clear')}>
                                        <BaeSvg height='1.4em' iconName='circleX' color='gray' />
                                    </button>
                                }
                            </div>
                            {shouldShowCancel && <span onClick={() => handleOnBlur('cancel')} className="searchBarCancelText">
                                Cancel
                            </span>}
                        </div>
                    </div>
                    {
                        renderContent()
                    }
                    <ModalHeader title={'SHARE MEDIA'} actionName={'share'} actionActive={selectedMembers.length} actionFunction={sendPost} />
                </div>
            </div>
            <Routes>
                <Route path="/memberModal/" element={<MemberModal />} />
            </Routes>
        </>

    );
};

const mapStateToProps = state => {
    return ({
        myChat: state.myChat
    });
};

export default connect(mapStateToProps,)(MediaShare);
