import React, { useEffect, useState } from "react";

import { connect } from 'react-redux';

import spacemanDark from '../assets/spacemanDark.png';
import spaceman from '../assets/spaceman.png';
import star from '../assets/star.png';
import starDark from '../assets/starDark.png';

const EmptyPage = ({
    darkModeEnabled
}) => {
    return (
        <img className="spaceman" src={darkModeEnabled ? spacemanDark : spaceman} />
    );
};

const mapStateToProps = state => {
    return ({
        darkModeEnabled: state.darkModeEnabled
    });
};

export default connect(mapStateToProps)(EmptyPage);