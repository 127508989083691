import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux'

import { useNavigate } from 'react-router-dom';

import baesappApi from '../../../apis/baesappApi';

import ruler from '../../../assets/ruler.png';
import weight from '../../../assets/weight.png';
import person from '../../../assets/person.png';
import comb from '../../../assets/comb.png';
import string from '../../../assets/string.png';
import eye from '../../../assets/eye.png';
import coloPalete from '../../../assets/coloPalete.png';
import glove from '../../../assets/glove.png';

import { setNewMyInfo } from '../../../redux/actions';
import ModalHeader from '../../../dumbComponents/Home/modalHeader';
import Switch from '../../../dumbComponents/Switch';

const PhysicalAttributes = ({
    myInfo,
    generalKeys,
    setNewMyInfo
}) => {

    const navigate = useNavigate();

    const [canSave, setCanSave] = useState(true);
    const [newMyInfoLocal, setNewMyInfoLocal] = useState(false);

    useEffect(() => {
        if (myInfo) {
            setNewMyInfoLocal(myInfo);
        }
    }, [myInfo]);

    const categoryFields = [
        {
            field: 'bodyType',
            label: "What is your body type?",
            values: [
                "Endomorph",
                "Mesomorph",
                "Ectomorph"
            ],
            src: person
        },
        {
            field: 'hairColor',
            label: "What is your hair color?",
            values: [
                "Black",
                "Brown",
                "Blonde",
                "Red",
                "Gray/White",
                "Other"
            ],
            src: comb,
            isArray: true
        },
        {
            field: 'hairType',
            label: "What is your hair type?",
            values: [
                "Coarse",
                "Curly",
                "Fine",
                "Frizzy",
                "Kinky",
                "Straight",
                "Thick",
                "Thin",
                "Wavy"
              ],
            src: string,
            isArray: true
        },
        {
            field: 'eyeColor',
            label: "What is your eye color?",
            values: ['Amber', 'Blue', 'Brown', 'Gray', 'Green', 'Hazel', 'Red', 'Violet'],
            src: eye,
            isArray: true
        },
        {
            field: 'skinTone',
            label: "What is your skin tone?",
            values: [
                "Fair",
                "Light",
                "Medium",
                "Olive",
                "Brown",
                "Dark"
            ],
            src: coloPalete,
            isArray: true
        },
        {
            field: 'dexterity',
            label: "What is your dominant hand?",
            values: [
                "Left-handed",
                "Right-handed",
                "Ambidextrous"
            ],
            src: glove
        }
    ];

    const saveFields = () => {
        if (canSave) {
            let toSaveFields = {};
            toSaveFields['bodyType'] = newMyInfoLocal['bodyType'];
            toSaveFields['hairColor'] = newMyInfoLocal['hairColor'];
            toSaveFields['hairType'] = newMyInfoLocal['hairType'];
            toSaveFields['eyeColor'] = newMyInfoLocal['eyeColor'];
            toSaveFields['skinTone'] = newMyInfoLocal['skinTone'];
            toSaveFields['dexterity'] = newMyInfoLocal['dexterity'];

            baesappApi.post('/setInfo', { info: toSaveFields })
                .then(res => {
                    setNewMyInfo(res.data)
                    navigate(-1);
                })
                .catch(err => {
                    console.log(err);
                })
        }
    };

    const valueClicked = (field, value, isArray) => {
        let tempNewMyInfo = { ...newMyInfoLocal };
        if (isArray) {
            const indexOfValue = tempNewMyInfo[field].value.findIndex(_value => value == _value);
            if (indexOfValue != -1) {
                tempNewMyInfo[field].value.splice(indexOfValue, 1);
            } else {
                tempNewMyInfo[field].value.push(value);
            };
        } else {
            if (tempNewMyInfo[field].value == value) {
                tempNewMyInfo[field].value = '';
            } else {
                tempNewMyInfo[field].value = value;
            }
        };
        setNewMyInfoLocal(tempNewMyInfo);
    };

    const isSelected = (field, value) => {
        if (field.isArray) {
            return newMyInfoLocal[field.field].value.includes(value);
        } else {
            return newMyInfoLocal[field.field].value == value;
        }
    };

    const fieldPublic = (field) => {
        if (newMyInfoLocal) {
            return newMyInfoLocal[field].public;
        } else {
            return false;
        }
    };

    const privateButtonClicked = (field) => {
        let newNewMyInfoLocal = { ...newMyInfoLocal };
        newNewMyInfoLocal[field].public = !newNewMyInfoLocal[field].public;
        setNewMyInfoLocal(newNewMyInfoLocal);

        let toSaveFields = {};

        toSaveFields[field] = {
            value: newNewMyInfoLocal[field].value,
            public: newNewMyInfoLocal[field].public
        }

        baesappApi.post('/setInfo', { info: toSaveFields })
        .then(res => {
            setNewMyInfo(res.data);
        })
        .catch(err => {
            console.log(err);
            setNewMyInfoLocal(myInfo);
        })
    };

    let fieldsList = [];
    if (newMyInfoLocal) {
        fieldsList = categoryFields.map(field => {
            let fieldValuesList = field.values.map(value => {
                return (
                    <div onClick={() => valueClicked(field.field, value, field.isArray)} className='infoPickable' style={isSelected(field, value) ? { borderColor: 'var(--accent)', color: 'var(--text)' } : {}}>
                        {value}
                    </div>
                )
            });
            return (
                <div className='flex vertical' style={{ marginLeft: '20px' }}>
                    <div className='flex fieldEditHeader'>
                        <div className='flex'>
                            <img style={{ maxWidth: '20px', maxHeight: '20px', marginRight: '15px', minWidth: '20px', objectFit: 'contain' }} src={field.src} />
                            <span style={{ fontWeight: 'bold', fontSize: '1.2em' }}>{field.label}</span>
                        </div>
                    </div>
                    <div>
                        {fieldValuesList}
                    </div>
                    <div className="flex infoFieldPrivateSwitch" style={{width: 'fit-content', margin: '5px 20px 20px 0px', alignSelf: 'flex-end'}}>
                        <span className="infoFieldPrivateLabel" style={!fieldPublic(field.field) ? { color: 'var(--accent)' } : {}}>private mode</span>
                        <Switch callback={() => privateButtonClicked(field.field)} switchOn={!fieldPublic(field.field)} switchBallText={fieldPublic(field.field) ? 'OFF' : 'ON'} />
                    </div>
                    <div className='infoLineSeparator' />
                </div>
            );
        })
    }

    return (
        <div className='fullScreen removeScrollBar modalContainer'>
            <div style={{ height: '100px' }} />
            <div className="infoField" >
                <div className="flex alignCenter" style={{ height: '100%', textAlign: 'start' }}>
                    <span>Height</span>
                </div>
                <div className="flex infoFieldPrivateSwitch" style={{ width: 'fit-content'}}>
                    <span className="infoFieldPrivateLabel" style={!fieldPublic('height') ? { color: 'var(--accent)' } : {}}>private mode</span>
                    <Switch callback={() => privateButtonClicked('height')} switchOn={!fieldPublic('height')} switchBallText={fieldPublic('height') ? 'OFF' : 'ON'} />
                </div>
            </div>
            <div className="infoField" style={{marginBottom: '20px'}}>
                <div className="flex alignCenter" style={{ height: '100%', textAlign: 'start' }}>
                    <span>Weight</span>
                </div>
                <div className="flex infoFieldPrivateSwitch" style={{ width: 'fit-content'}}>
                    <span className="infoFieldPrivateLabel" style={!fieldPublic('weight') ? { color: 'var(--accent)' } : {}}>private mode</span>
                    <Switch callback={() => privateButtonClicked('weight')} switchOn={!fieldPublic('weight')} switchBallText={fieldPublic('weight') ? 'OFF' : 'ON'} />
                </div>
            </div>
            {fieldsList}
            <ModalHeader title={'PHYSICAL'} actionName={'SAVE'} actionActive={canSave} actionFunction={() => saveFields()} />
        </div>
    );
};

const mapStateToProps = state => {
    return ({
        myInfo: state.myInfo
    });
};

export default connect(mapStateToProps, { setNewMyInfo })(PhysicalAttributes);