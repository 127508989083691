import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux'

import { useNavigate } from 'react-router-dom';

import baesappApi from '../../../apis/baesappApi';

import rings from '../../../assets/rings.png';
import briefcase from "../../../assets/briefcase.png";
import languages from "../../../assets/languages.png";
import education from "../../../assets/education.png";
import degree from "../../../assets/degree.png";
import skills from "../../../assets/skills.png";

import { setNewMyInfo } from '../../../redux/actions';
import ModalHeader from '../../../dumbComponents/Home/modalHeader';
import Switch from '../../../dumbComponents/Switch';

const Form = ({
    myInfo,
    generalKeys,
    setNewMyInfo
}) => {

    const navigate = useNavigate();

    const [canSave, setCanSave] = useState(true);
    const [newMyInfoLocal, setNewMyInfoLocal] = useState(false);

    useEffect(() => {
        if (myInfo) {
            setNewMyInfoLocal(myInfo);
        }
    }, []);

    const categoryFields = [
        {
            field: 'maritalStatus',
            label: 'What is your marital Status?',
            values: [
                'Single',
                'Married',
                'Divorced',
                'Widowed'
            ],
            src: rings
        },
        {
            field: 'degrees',
            label: 'What degrees do you have?',
            values: [
                "Accounting",
                "Agriculture",
                "Animal Science",
                "Anthropology",
                "Architecture",
                "Art",
                "Biology",
                "Business Administration",
                "Chemistry",
                "Civil Engineering",
                "Communication",
                "Computer Science",
                "Construction Management",
                "Criminal Justice",
                "Dental Hygiene",
                "Dentistry",
                "Economics",
                "Education",
                "Electrical Engineering",
                "Engineering Technology",
                "English",
                "Environmental Science",
                "Fashion Design",
                "Film",
                "Finance",
                "Food Science",
                "Foreign Languages",
                "Geography",
                "Geology",
                "Graphic Design",
                "Healthcare Administration",
                "History",
                "Hospitality Management",
                "Human Resources",
                "Industrial Design",
                "Information Technology",
                "Interior Design",
                "Journalism",
                "Law",
                "Library Science",
                "Management",
                "Manufacturing Engineering",
                "Marketing",
                "Mathematics",
                "Mechanical Engineering",
                "Medical Laboratory Science",
                "Medicine",
                "Music",
                "Nursing",
                "Nutrition",
                "Occupational Therapy",
                "Optometry",
                "Pharmacy",
                "Philosophy",
                "Physical Therapy",
                "Physics",
                "Political Science",
                "Psychology",
                "Public Health",
                "Public Relations",
                "Radiologic Technology",
                "Real Estate",
                "Religious Studies",
                "Social Work",
                "Sociology",
                "Speech-Language Pathology",
                "Sports Management",
                "Statistics",
                "Theater",
                "Urban Planning",
                "Veterinary Medicine"
            ],
            src: degree,
            isArray: true
        },
        {
            field: 'employmentStatus',
            label: 'Employment status',
            values: [
                'Employed',
                'Unemployed (Looking for a job)',
                'Unemployed (Not looking for a job)',
                'Self-employed',
                'Freelance',
                'Retired',
                'Student',
                'Homemaker',
                'Other'
            ],
            src: briefcase
        },
        {
            field: 'highestEducationLevels',
            label: 'Education level',
            values: [
                "Associate's Degree",
                "Bachelor's Degree",
                "Certificate",
                "Doctorate Degree",
                "High School Diploma",
                "Master's Degree",
                "Professional Degree",
                "Vocational Degree"
            ],
            src: education
        },
        {
            field: 'occupation',
            label: 'What is your occupation?',
            values: [
                "Accountant",
                "Architect",
                "Chef",
                "Computer Programmer",
                "Copywriter",
                "Dentist",
                "Doctor",
                "Electrician",
                "Engineer",
                "Financial Analyst",
                "Graphic Designer",
                "Human Resources Manager",
                "Interior Designer",
                "Journalist",
                "Lawyer",
                "Marketing Manager",
                "Mechanic",
                "Nurse",
                "Occupational Therapist",
                "Pharmacist",
                "Physical Therapist",
                "Police Officer",
                "Real Estate Agent",
                "Sales Representative",
                "Software Developer",
                "Teacher",
                "Veterinarian",
                "Web Developer",
                "Writer"
            ],
            src: briefcase,
            isArray: true
        },
        {
            field: 'languagesSpoken',
            label: 'What languages can you speak?',
            values: [
                'Afrikaans',
                'Albanian',
                'Amharic',
                'Arabic',
                'Armenian',
                'Azerbaijani',
                'Basque',
                'Belarusian',
                'Bengali',
                'Bosnian',
                'Bulgarian',
                'Catalan',
                'Cebuano',
                'Chichewa',
                'Chinese (Simplified)',
                'Chinese (Traditional)',
                'Corsican',
                'Croatian',
                'Czech',
                'Danish',
                'Dutch',
                'English',
                'Esperanto',
                'Estonian',
                'Filipino',
                'Finnish',
                'French',
                'Frisian',
                'Galician',
                'Georgian',
                'German',
                'Greek',
                'Gujarati',
                'Haitian Creole',
                'Hausa',
                'Hawaiian',
                'Hebrew',
                'Hindi',
                'Hmong',
                'Hungarian',
                'Icelandic',
                'Igbo',
                'Indonesian',
                'Irish',
                'Italian',
                'Japanese',
                'Javanese',
                'Kannada',
                'Kazakh',
                'Khmer',
                'Kinyarwanda',
                'Korean',
                'Kurdish',
                'Kyrgyz',
                'Lao',
                'Latin',
                'Latvian',
                'Lithuanian',
                'Luxembourgish',
                'Macedonian',
                'Malagasy',
                'Malay',
                'Malayalam',
                'Maltese',
                'Maori',
                'Marathi',
                'Mongolian',
                'Myanmar (Burmese)',
                'Nepali',
                'Norwegian',
                'Odia (Oriya)',
                'Pashto',
                'Persian',
                'Polish',
                'Portuguese',
                'Punjabi',
                'Romanian',
                'Russian',
                'Samoan',
                'Scots Gaelic',
                'Serbian',
                'Sesotho',
                'Shona',
                'Sindhi',
                'Sinhala',
                'Slovak',
                'Slovenian',
                'Somali',
                'Spanish',
                'Sundanese',
                'Swahili',
                'Swedish',
                'Tajik',
                'Tamil',
                'Tatar',
                'Telugu',
                'Thai',
                'Turkish',
                'Turkmen',
                'Ukrainian',
                'Urdu',
                'Uyghur',
                'Uzbek',
                'Vietnamese',
                'Welsh',
                'Xhosa',
                'Yiddish',
                'Yoruba',
                'Zulu'
            ],
            src: languages,
            isArray: true
        },
        {
            field: 'jobSkills',
            label: 'What job skills do you have?',
            values: [
                "Accounting",
                "Adaptability",
                "Analytical thinking",
                "Artificial intelligence",
                "Attention to detail",
                "Business analysis",
                "Civil engineering",
                "Cloud computing",
                "Coaching",
                "Communication",
                "Content creation",
                "Copywriting",
                "Culinary skills",
                "Customer service",
                "Cybersecurity",
                "Data analysis",
                "Database management",
                "Digital marketing",
                "Electrical engineering",
                "Financial analysis",
                "Fitness training",
                "Foreign language proficiency",
                "Graphic design",
                "Interpersonal skills",
                "Investment management",
                "Leadership",
                "Legal knowledge",
                "Machine learning",
                "Marketing",
                "Mechanical engineering",
                "Medical knowledge",
                "Mobile development",
                "Networking",
                "Negotiation",
                "Product management",
                "Programming",
                "Project management",
                "Public speaking",
                "Research",
                "Sales",
                "SEM",
                "SEO",
                "Social media management",
                "Software development",
                "System administration",
                "Teaching",
                "Teamwork",
                "Time management",
                "UI/UX design",
                "Video production",
                "Web development"
            ]
            ,
            src: skills,
            isArray: true
        }
    ]

    const saveFields = () => {
        if (canSave) {
            let toSaveFields = {};
            toSaveFields['maritalStatus'] = newMyInfoLocal['maritalStatus'];
            toSaveFields['degrees'] = newMyInfoLocal['degrees'];
            toSaveFields['highestEducationLevels'] = newMyInfoLocal['highestEducationLevels'];
            toSaveFields['occupation'] = newMyInfoLocal['occupation'];
            toSaveFields['languagesSpoken'] = newMyInfoLocal['languagesSpoken'];
            toSaveFields['jobSkills'] = newMyInfoLocal['jobSkills'];

            baesappApi.post('/setInfo', { info: toSaveFields })
                .then(res => {
                    setNewMyInfo(res.data)
                    navigate(-1);
                })
                .catch(err => {
                    console.log(err);
                })
        }
    };

    const valueClicked = (field, value, isArray) => {
        let tempNewMyInfo = { ...newMyInfoLocal };
        if (isArray) {
            const indexOfValue = tempNewMyInfo[field].value.findIndex(_value => value == _value);
            if (indexOfValue != -1) {
                tempNewMyInfo[field].value.splice(indexOfValue, 1);
            } else {
                tempNewMyInfo[field].value.push(value);
            };
        } else {
            if (tempNewMyInfo[field].value == value) {
                tempNewMyInfo[field].value = '';
            } else {
                tempNewMyInfo[field].value = value;
            }
        };
        setNewMyInfoLocal(tempNewMyInfo);
    };

    const isSelected = (field, value) => {
        if (field.isArray) {
            return newMyInfoLocal[field.field].value.includes(value);
        } else {
            return newMyInfoLocal[field.field].value == value;
        }
    };

    const fieldPublic = (field) => {
        if (newMyInfoLocal) {
            return newMyInfoLocal[field].public;
        } else {
            return false;
        }
    };

    const privateButtonClicked = (field) => {
        let newNewMyInfoLocal = { ...newMyInfoLocal };
        newNewMyInfoLocal[field].public = !newNewMyInfoLocal[field].public;
        setNewMyInfoLocal(newNewMyInfoLocal);

        let toSaveFields = {};

        toSaveFields[field] = {
            value: newNewMyInfoLocal[field].value,
            public: newNewMyInfoLocal[field].public
        }

        baesappApi.post('/setInfo', { info: toSaveFields })
            .then(res => {
                setNewMyInfo(res.data);
            })
            .catch(err => {
                console.log(err);
                setNewMyInfoLocal(myInfo);
            })
    };

    let fieldsList = [];
    if (newMyInfoLocal) {
        fieldsList = categoryFields.map(field => {
            let fieldValuesList = field.values.map(value => {
                return (
                    <div onClick={() => valueClicked(field.field, value, field.isArray)} className='infoPickable' style={isSelected(field, value) ? { borderColor: 'var(--accent)', color: 'var(--text)' } : {}}>
                        {value}
                    </div>
                )
            });
            return (
                <div className='flex vertical' style={{ marginLeft: '20px' }}>
                    <div className='flex fieldEditHeader'>
                        <div className='flex'>
                            <img style={{ maxWidth: '20px', maxHeight: '20px', marginRight: '15px', minWidth: '20px', objectFit: 'contain' }} src={field.src} />
                            <span style={{ fontWeight: 'bold', fontSize: '1.2em' }}>{field.label}</span>
                        </div>
                    </div>
                    <div>
                        {fieldValuesList}
                    </div>
                    <div className="flex infoFieldPrivateSwitch" style={{ width: 'fit-content', margin: '5px 20px 20px 0px', alignSelf: 'flex-end' }}>
                        <span className="infoFieldPrivateLabel" style={!fieldPublic(field.field) ? { color: 'var(--accent)' } : {}}>private mode</span>
                        <Switch callback={() => privateButtonClicked(field.field)} switchOn={!fieldPublic(field.field)} switchBallText={fieldPublic(field.field) ? 'OFF' : 'ON'} />
                    </div>
                    <div className='infoLineSeparator' />
                </div>
            );
        })
    }

    return (
        <div className='fullScreen removeScrollBar modalContainer'>
            <div style={{ height: '100px' }} />
            {fieldsList}
            <ModalHeader title={'GENERAL'} actionName={'SAVE'} actionActive={canSave} actionFunction={() => saveFields()} />
        </div>
    );
};

const mapStateToProps = state => {
    return ({
        myInfo: state.myInfo
    });
};

export default connect(mapStateToProps, { setNewMyInfo })(Form);