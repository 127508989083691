import React, { useState, useEffect, useRef } from "react";

import { connect } from 'react-redux';

import { setNewPost, deletePost, setMyPosts } from '../../../redux/actions';

import ModalHeader from "../../../dumbComponents/Home/modalHeader";
import AudioRecorder from "../../../dumbComponents/AudioRecorder";

const NewPostLoading = ({
    fileContent,
    fileContentType,
    postUploadCompleted,
    myId
}) => {

    const [loadingPercentage, setLoadingPercentage] = useState(0);
    const [loadingPercentageTimeout, setLoadingPercentageTimeout] = useState(null);

    useEffect(() => {
        startLoading(0);
        return (() => {
            clearTimeout(loadingPercentageTimeout);
        });
    }, []);

    const startLoading = (prev) => {
        if (prev < 100) {
            let newValue;
            if (fileContentType == 'quote') {
                newValue = prev + 20;
            } else if (fileContentType == 'image') {
                newValue = prev + 10;
            } else {
                newValue = prev + 2;
            }
            setLoadingPercentage(newValue);
            setLoadingPercentageTimeout(setTimeout(() => {
                startLoading(newValue);
            }, 1000));
        };
    };

    useEffect(() => {
        if (postUploadCompleted) {
            clearTimeout(loadingPercentageTimeout);
            setLoadingPercentage(100);
        };
    }, [postUploadCompleted]);

    return (
        <>
            <div className="fullScreen removeScrollBar modalContainer">
                <div className="flex vertical" style={{ gap: '6px' }}>
                    <div style={{ minHeight: '115px', width: '50px' }} />
                    <div style={{ position: 'fixed', zIndex: '2', top: '85px', height: '5px', maxWidth: '500px', width: '100%', display: 'flex', justifyContent: 'flex-start' }}>
                        <div className="loadBarFlicker" style={{ height: '100%', width: `${loadingPercentage}%`, transitionDuration: '300ms', borderRadius: '5px' }}></div>
                    </div>
                    {
                        fileContentType == 'aIImage' ?
                            <img style={{ width: '98%', borderRadius: '7px', alignSelf: 'center' }} src={fileContent} /> :
                            fileContentType == 'image' ?
                                <img style={{ width: '98%', borderRadius: '7px', alignSelf: 'center' }} src={fileContent} /> :
                                fileContentType == 'data:video/quicktime' ?
                                    <video playsInline loop autoPlay muted controls style={{ width: '98%', borderRadius: '7px', alignSelf: 'center' }} >
                                        <source src={fileContent} type="video/quicktime" />
                                    </video> :
                                    fileContentType == 'data:video/mp4' ?
                                        <video playsInline loop autoPlay muted controls style={{ width: '98%', borderRadius: '7px', alignSelf: 'center' }} src={fileContent} >
                                            <source src={fileContent} type="video/mp4" />
                                        </video> : fileContentType == 'quote' ?
                                            <div className="flex vertical" style={{ marginTop: '20px' }}>
                                                {myId &&
                                                    <div className="flex alignCenter" style={{ gap: '10px', marginBottom: '-55px', zIndex: '1', marginLeft: '20px' }}>
                                                        <img style={{ height: '40px', width: '40px', borderRadius: '50%' }} src={`https://baesapp.com/media/${myId.profileShot}`} />
                                                        <span style={{ fontSize: '1.5em', fontWeight: 'bold' }}>{myId.username}</span>
                                                    </div>
                                                }
                                                <textarea
                                                    placeholder="Type your quote here..."
                                                    value={fileContent}
                                                    readOnly
                                                    rows='6'
                                                    style={{
                                                        fontSize: '1.7em',
                                                        border: 'none',
                                                        resize: 'none',
                                                        width: 'calc(100% - 60px)',
                                                        borderRadius: '30px',
                                                        backgroundColor: 'var(--primaryGlass)',
                                                        color: 'var(--text)',
                                                        padding: '60px 20px 20px',
                                                        alignSelf: 'center',
                                                        outline: 'none',
                                                        border: '2px solid var(--accent)'
                                                    }} />
                                            </div> :
                                            fileContentType == 'audio' ?
                                                <AudioRecorder recordable={false} base64={fileContent} /> :
                                                null
                    }
                    <ModalHeader title={postUploadCompleted ? 'DONE' : 'UPLOADING...'} totalBack={-2} actionName={''} actionActive={false} actionFunction={() => { }} />
                </div>
            </div>
        </>
    );
};

const mapStateToProps = state => {
    return ({
        myMedia: state.myMedia,
        myId: state.myId
    });
};

export default connect(mapStateToProps, { setNewPost, deletePost, setMyPosts })(NewPostLoading);
