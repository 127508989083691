import { useNavigate } from "react-router-dom";

const ModalBackgroundButton = ({
    dismiss,
    unblur
}) => {

    const navigate = useNavigate();

    return(
        <div className='modalBackgroundButton removeScrollBar' onClick={dismiss} style={unblur ? {backdropFilter: 'none', backgroundColor: 'none'} : {}}/>
    );
};

export default ModalBackgroundButton;