import React, {useEffect, useState} from "react";

import {connect} from 'react-redux';
import {useNavigate, Routes, Route} from 'react-router-dom';

import baesappApi from "../apis/baesappApi";

import { setMyCompanies } from "../redux/actions";

import setLocal from '../hooks/setLocal';

const Company = ({
    setMyCompanies,
    myCompanies
}) => {

    const navigate = useNavigate();

    const [name, setName] = useState('');

    useEffect(() => {
        console.log(myCompanies)
    }, [myCompanies]);

    const Main = () => {
        const myCompaniesList = myCompanies.map(company => {
            return(
                <button onClick={() => {
                    setLocal('companyId', company._id);
                    navigate('/company')
                }}>{company.name}</button>
            );
        });
        return( 
            <>
                <button onClick={() => navigate('createCompany')}>Create new company</button>
                {myCompaniesList}
            </>
        );
    };

    const CreateCompany = () => {
        console.log('hit');
        const createCompany = () => {
            baesappApi.post('/createCompany', {name})
                .then(res => {
                    setMyCompanies(res.data);
                    navigate('/account/companies');
                })
                .catch(err => {
                    console.log(err);
                });
        };
        return(
            <>
                <p>Enter the company name</p>
                <input value={name} onChange={e => setName(e.target.value)}></input>
                {name != '' && <button onClick={() => createCompany()}>Create company</button>}
            </>
        );
    };

    return(
        <>
        <Routes>
            <Route path="/" element={<Main/>}/>
            <Route path="/createCompany" element={CreateCompany()}/>
        </Routes>
    </>
    );
};

const mapStateToProps = state => {
    return({
        myCompanies: state.myCompanies
    });
};

export default connect(mapStateToProps, {
    setMyCompanies
})(Company);
