import React, { useEffect, useState, useRef } from "react";

import { connect } from 'react-redux';

import { Routes, Route, useNavigate } from 'react-router-dom';

import baesappApi from '../../../apis/baesappApi';

import MyPostsModal from '../../Modals/Media/MyPostsModal';
import FollowingModal from '../../Modals/Media/FollowingModal';
import MemberModal from '../../../dumbComponents/MemberModal';
import objectify from '../../../hooks/objectify';
import useWindowDimensions from "../../../hooks/useWindowDimensions";

import hot from '../../../assets/hot.png';
import hotDark from '../../../assets/hotDark.png';
import trending from '../../../assets/trending.png';
import trendingDark from '../../../assets/trendingDark.png';
import fresh from '../../../assets/fresh.png';
import freshDark from '../../../assets/freshDark.png';
import x from '../../../assets/x.png';
import addAccent from '../../../assets/addAccent.png';

import MediaComments from "./MediaComments";
import MediaShare from './MediaShare';

import { setMediaScrollPosition, setMediaPosts, toggledMediaPostLike, setTopBarOffset } from '../../../redux/actions';
import _ from "lodash";
import setLocalObject from "../../../hooks/setLocalObject";
import BaeSvg from "../../../dumbComponents/BaeSvg";
import ViewPost from "../../Modals/Media/ViewPost";
import Saved from "../../Modals/Media/Saved";
import LoadingDrop from "../../../dumbComponents/LoadingDrop";

const Media = ({
    setMediaPosts,
    mediaPosts,
    setTopBarOffset
}) => {

    const navigate = useNavigate();

    const [currLoadBoxFlash, setCurrLoadBoxFlash] = useState([1, 2, 3]);
    const [scrollY, setScrollY] = useState(0);
    const [loading, setLoading] = useState(false);
    const [mediaPostsList, setMediaPostsList] = useState([]);
    const [totalQuotes, setTotalQuotes] = useState(0);
    const [totalAudios, setTotalAudios] = useState(0);
    const [totalImages, setTotalImages] = useState(0);
    const [totalVideos, setTotalVideos] = useState(0);

    const scrollElementRef = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            if (scrollElementRef.current) {
                const scrollTop = scrollElementRef.current.scrollTop;
                const scrollDirection = scrollTop > scrollY ? 'down' : 'up';
                if (scrollDirection == 'down') {
                    setTopBarOffset('-50');
                } else if (scrollDirection == 'up') {
                    setTopBarOffset('0');
                }
                setScrollY(scrollTop);
            }
        };
        if (scrollY == 0) {
            setTopBarOffset('0')
        }
        const scrollElement = scrollElementRef.current;
        if (scrollElement) {
            scrollElement.addEventListener('scroll', handleScroll);
            return () => {
                scrollElement.removeEventListener('scroll', handleScroll);
            };
        }
    }, [scrollY]);

    useEffect(() => {
        setBoxes();
        fetchNewPosts();
    }, []);

    useEffect(() => {
        if (mediaPosts) {
            let newTotalQuotes = 0;
            let newTotalAudios = 0;
            let newTotalImages = 0;
            let newTotalVideos = 0;
            mediaPosts.map(post => {
                if (post.data[0].contentType == 'quote') {
                    newTotalQuotes++;
                } else if (post.data[0].contentType == 'audio') {
                    newTotalAudios++;
                } else if (post.data[0].contentType == 'image') {
                    newTotalImages++;
                } else if (post.data[0].contentType == 'video') {
                    newTotalVideos++;
                }
            });
            setTotalQuotes(newTotalQuotes);
            setTotalAudios(newTotalAudios);
            setTotalImages(newTotalImages);
            setTotalVideos(newTotalVideos);
            updateMediaPostsList();
        };
    }, [mediaPosts]);

    const setBoxes = () => {
    };

    const fetchNewPosts = () => {
        setLoading(true);
        baesappApi.post('/getPosts')
            .then(res => {
                setLoading(false);
                setMediaPosts(res.data.reverse())
            })
            .catch(err => {
                setLoading(false);
                console.log(err)
            }
            )
    };

    const postClicked = (activePostId) => {
        window.localStorage.setItem('activePostId', activePostId);
        navigate('viewPost');
    };

    const updateMediaPostsList = () => {
        setMediaPostsList(mediaPosts.map(post => {
            if (post.data[0].contentType == 'quote') {
                return (
                    <button onClick={() => postClicked(post._id)} className="galleryImage flex alignCenter spaceAround" style={{ backgroundColor: 'transparent', transform: 'none' }}>
                        <div key={post._id} style={{ padding: '5px', border: '2px solid var(--accent)', borderRadius: '16px', height: '80%', width: '80%' }} className="galleryImage flex alignCenter spaceAround">
                            <span style={{ fontSize: '1em', textAlign: 'center' }}>{post.data[0].s3Key}</span>
                        </div>
                    </button>
                );
            } else if (post.data[0].contentType == 'audio') {
                return (
                    <button onClick={() => postClicked(post._id)} className="galleryImage flex alignCenter spaceAround" style={{ backgroundColor: 'transparent', transform: 'none' }}>
                        <div key={post._id} style={{ padding: '5px', border: '2px solid var(--accent)', borderRadius: '16px', height: '80%', width: '80%' }} className="galleryImage flex alignCenter spaceAround">
                            <BaeSvg iconName="audio" color='accent' />
                        </div>
                    </button>
                );
            } else if (post.data[0].contentType == 'aIImage') {
                return (
                    <button
                        onClick={() => {
                            postClicked(post._id);
                        }} key={post._id} className="galleryImage">
                        <img style={{ height: '100%', width: '100%', objectFit: 'cover', opacity: '1' }} src={post.data[0].aIImageUrl} />
                    </button>
                );
            } else {
                return (
                    <button onClick={() => postClicked(post._id)} key={post._id} className="galleryImage"> 
                        <img style={{ height: '100%', width: '100%', objectFit: 'cover', opacity: post.data[0].contentType == 'video' ? '0.9' : '1' }} src={`https://baesapp.s3.eu-central-1.amazonaws.com/${post.data[0].thumbnail || post.data[0].s3Key}`} />
                        {post.data[0].contentType == 'video' && <BaeSvg iconName="playButton" height="50%" style={{ marginTop: '-75px' }} color="text" />}
                    </button>
                );
            }
        }));
    };


    let loaderArr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18];
    const loaderList = loaderArr.map(item => {
        return (
            <div className={'flashMediaLoader galleryImage'}>

            </div>
        );
    });

    const renderContent = () => {
        return (
            <div className="mediaGridContainer"
                ref={scrollElementRef}>
                <div className={'flashMediaLoader galleryImage'} style={{ height: '0' }}></div>
                <div className={'flashMediaLoader galleryImage mediaInfoContainer'} style={{ background: 'none', color: 'var(--text)' }}>
                    <span><strong>{totalImages}</strong> Photos, <strong>{totalVideos}</strong> Videos, <strong>{totalQuotes}</strong> Quotes, <strong>{totalAudios}</strong> Audio</span>
                </div>
                <div className={'flashMediaLoader galleryImage'} style={{ height: '0' }}></div>
                {loading ? <LoadingDrop /> : mediaPostsList}
            </div>
        );
    };

    return (
        <>
            {renderContent()}
            {
                (!loading && !mediaPosts.length) &&
                <span
                    className="noResults centerText"
                    style={{
                        position: 'absolute',
                        width: 'fit-content',
                        left: '0',
                        right: '0',
                        margin: 'auto'
                    }}
                >When members you follow post media, it will display here</span>
            }
            
            <Routes>
                <Route path="/myPosts/*" element={<MyPostsModal />} />
                <Route path="/following/*" element={<FollowingModal />} />
                <Route path="/viewPost/*" element={<ViewPost />} />
                <Route path="/memberModal" element={<MemberModal />} />
                <Route path="/saved/*" element={<Saved />} />
            </Routes>
        </>
    );
};


const mapStateToProps = state => {
    return ({
        mediaPosts: state.mediaPosts
    });
};

export default connect(mapStateToProps, {
    setMediaPosts,
    setTopBarOffset
})(Media);
