import React, { useEffect, useState } from "react";

import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import primaryX from '../../assets/primaryX.png';

const ModalHeader = ({
    darkModeEnabled,
    title,
    actionName = '',
    actionActive,
    actionFunction,
    totalBack = -1
}) => {

    const navigate = useNavigate();

    return (
        <div className="secondaryScreenHeaderContainer">
            <div className='secondaryScreenHeader'>
                <div className="flex vertical secondaryScreenHeaderLeft" onClick={() => navigate(totalBack)}>
                    <img src={primaryX} className="headerBackIcon" />
                    <span className="modalHeaderTitle">{title}</span>
                </div>
                {actionActive ?
                    <span style={{ 'marginRight': '20px' }} className="headerButtonText" onClick={() => actionFunction()}>{actionName}</span> :
                    <span style={{ 'marginRight': '20px', color: 'var(--secondary)' }} className="headerButtonText">{actionName}</span>
                }
            </div>
        </div>
    );
};

const mapStateToProps = state => {
    return ({
        darkModeEnabled: state.darkModeEnabled
    });
};

export default connect(mapStateToProps)(ModalHeader);