import React, { useEffect, useState, useRef } from "react";

import { connect } from 'react-redux';
import baesappApi from "../../apis/baesappApi";
import { Route, Routes, useNavigate } from 'react-router-dom';

import getLocalToObject from '../../hooks/getLocalToObject';
import ViewPost from "../Modals/Media/ViewPost";
import BaeSvg from "../../dumbComponents/BaeSvg";
import { setMediaFollowing } from "../../redux/actions";
import MemberModal from "../../dumbComponents/MemberModal";

const VisitMedia = ({
    myMedia,
    setMediaFollowing,
    visitMember
}) => {


    const [currLoadBoxFlash, setCurrLoadBoxFlash] = useState([1, 2, 3]);
    const [loading, setLoading] = useState(false);
    const [mediaPostsList, setMediaPostsList] = useState([]);
    const [posts, setPosts] = useState([]);
    const [totalQuotes, setTotalQuotes] = useState(0);
    const [totalAudios, setTotalAudios] = useState(0);
    const [totalImages, setTotalImages] = useState(0);
    const [totalVideos, setTotalVideos] = useState(0);
    const [followingLoading, setFollowingLoading] = useState(false);

    const scrollElementRef = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        setLoading(true);
        baesappApi.post('/getMemberPosts', { _id: visitMember.memberEssentials._id })
            .then(res => {
                setPosts(res.data.reverse());
                setLoading(false);
            })
            .catch(err => {
                console.log(err);
                setLoading(false);
            });
    }, []);

    const toggleFollowing = (followeeId) => {
        setFollowingLoading(true);
        baesappApi.post('/following/toggleFollowing', { followeeId })
            .then(res => {
                setMediaFollowing(followeeId, res.data);
                setFollowingLoading(false);
            })
            .catch(err => {
                setFollowingLoading(false);
                window.alert('something unexpected went wrong')
                console.log(err)
            }
            )
    };

    useEffect(() => {
        if (posts.length) {
            let newTotalQuotes = 0;
            let newTotalAudios = 0;
            let newTotalImages = 0;
            let newTotalVideos = 0;
            posts.map(post => {
                if (post.data[0].contentType == 'quote') {
                    newTotalQuotes++;
                } else if (post.data[0].contentType == 'audio') {
                    newTotalAudios++;
                } else if (post.data[0].contentType == 'image') {
                    newTotalImages++;
                } else if (post.data[0].contentType == 'video') {
                    newTotalVideos++;
                }
            });
            setTotalQuotes(newTotalQuotes);
            setTotalAudios(newTotalAudios);
            setTotalImages(newTotalImages);
            setTotalVideos(newTotalVideos);
            updateMediaPostsList();
        };
    }, [posts]);

    const postClicked = (activePostId) => {
        window.localStorage.setItem('activePostId', activePostId);
        navigate(`viewPost?_id=${visitMember.memberEssentials._id}`);
    };

    const updateMediaPostsList = () => {
        setMediaPostsList(posts.map(post => {
            if (post.data[0].contentType == 'quote') {
                return (
                    <button onClick={() => postClicked(post._id)} className="galleryImage flex alignCenter spaceAround" style={{ backgroundColor: 'transparent', transform: 'none' }}>
                        <div key={post._id} style={{ padding: '5px', border: '2px solid var(--accent)', borderRadius: '16px', height: '80%', width: '80%' }} className="galleryImage flex alignCenter spaceAround">
                            <span style={{ fontSize: '1em', textAlign: 'center' }}>{post.data[0].s3Key}</span>
                        </div>
                    </button>
                );
            } else if (post.data[0].contentType == 'audio') {
                return (
                    <button onClick={() => postClicked(post._id)} key={post._id} className="galleryImage" style={{ backgroundColor: 'transparent' }}>
                        <BaeSvg iconName="audio" color='accent' />
                    </button>
                );
            } else if (post.data[0].contentType == 'aIImage') {
                return (
                    <button
                        onClick={() => {
                            postClicked(post._id);
                        }} key={post._id} className="galleryImage">
                        <img style={{ height: '100%', width: '100%', objectFit: 'cover', opacity: '1' }} src={post.data[0].aIImageUrl} />
                    </button>
                );
            } else {
                return (
                    <button onClick={() => postClicked(post._id)} key={post._id} className="galleryImage">
                        <img style={{ height: '100%', width: '100%', objectFit: 'cover', opacity: post.data[0].contentType == 'video' ? '0.9' : '1' }} src={`https://baesapp.s3.eu-central-1.amazonaws.com/${post.data[0].thumbnail || post.data[0].s3Key}`} />
                        {post.data[0].contentType == 'video' && <BaeSvg iconName="playButton" height="50%" style={{ marginTop: '-75px' }} color="text" />}
                    </button>
                );
            }
        }));
    };

    const renderFollowButton = () => {
        if (followingLoading) {
            return (
                <button
                    className={'baeButton loadBarFlicker'}
                    style={{
                        transform: 'rotate(180deg)',
                        margin: '20px 0'
                    }}
                >
                    Loading...
                </button>
            )
        }
        if (myMedia) {
            if (myMedia.followers.includes(visitMember.memberEssentials._id) && !myMedia.following.includes(visitMember.memberEssentials._id)) {
                return (
                    <button
                        onClick={() => toggleFollowing(visitMember.memberEssentials._id)}
                        className="baeButton"
                        style={{
                            transform: 'rotate(180deg)',
                            margin: '20px 0'
                        }}
                    >
                        Follow back
                    </button>
                )
            };
            if (myMedia.following.includes(visitMember.memberEssentials._id)) {
                return (
                    <button
                        onClick={() => toggleFollowing(visitMember.memberEssentials._id)}
                        className="baeButton"
                        style={{
                            background: 'transparent',
                            border: '2px solid var(--accent)',
                            color: 'var(--accent)',
                            transform: 'rotate(180deg)',
                            margin: '20px 0'
                        }}
                    >
                        Unfollow
                    </button>
                )
            } else {
                return (
                    <button
                        onClick={() => toggleFollowing(visitMember.memberEssentials._id)}
                        className="baeButton"
                        style={{
                            transform: 'rotate(180deg)',
                            margin: '20px 0'
                        }}
                    >
                        Follow
                    </button>
                )
            }
        }
    }

    let loaderArr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18];
    const loaderList = loaderArr.map(item => {
        return (
            <div className={'flashMediaLoader galleryImage'} />
        );
    });

    const renderContent = () => {
        return (
            <div
                className="mediaGridContainer"
                style={{
                    bottom: '0px'
                }}
                ref={scrollElementRef}>
                <div className={'flashMediaLoader galleryImage'} style={{ height: '0' }}></div>
                {renderFollowButton()}
                <div className={'flashMediaLoader galleryImage'} style={{ height: '0' }}></div>
                <div className={'flashMediaLoader galleryImage'} style={{ height: '0' }}></div>
                <div className={'flashMediaLoader galleryImage mediaInfoContainer'} style={{
                    background: 'none',
                    color: 'var(--text)',
                    height: 'fit-content',
                    margin: '10px 0'
                }}>
                    <span><strong>{totalImages}</strong> Photos, <strong>{totalVideos}</strong> Videos, <strong>{totalQuotes}</strong> Quotes, <strong>{totalAudios}</strong> Audio</span>
                </div>
                <div className={'flashMediaLoader galleryImage'} style={{ height: '0' }}></div>
                {loading ? loaderList : mediaPostsList}
            </div>
        );
    };



    return (
        <>
            {renderContent()}
            {
                (!loading && !posts.length) &&
                <span
                    className="noResults centerText"
                    style={{
                        position: 'absolute',
                        width: 'fit-content',
                        left: '0',
                        right: '0',
                        margin: 'auto'
                    }}
                >This member has no media posted yet</span>
            }
            <Routes>
                <Route path="/viewPost/*" element={<ViewPost visiting={posts} />} />
                <Route path="/memberModal/*" element={<MemberModal />} />
            </Routes>
        </>
    );
};

const mapStateToProps = state => {
    return ({
        myMedia: state.myMedia,
        visitMember: state.visitMember
    });
};

export default connect(mapStateToProps, { setMediaFollowing })(VisitMedia);
