import React from 'react';
import spinner from '../../assets/spinner.png';

const ConstructiveButton = ({
    buttonState='',
    text='',
    callback = () => {},
    style = {width: '100%'}
}) => {
    switch(buttonState) {
        case 'loading':
            return(
                <button style={{...style, color: 'white'}} className="constructiveButton constructiveLoading" disabled={true}>
                    Loading...
                </button>
                
            );
        case 'active':
            return(
                <button style={style} className="constructiveButton constructiveActive" onClick={callback}>{text}</button>
            );
        case 'inactive':
            return(
                <button style={style} className="constructiveButton" onClick={callback}>{text}</button>   
            );
    };
};

export default ConstructiveButton;