const urgentCallReducer = (urgentCall = false, action) => {
    switch(action.type){
        case 'URGENT_CALL_SET':
            return action.payload;
            case 'CLEAR_ALL':
                    return false;
        default: 
            return urgentCall;
    }
};

export default urgentCallReducer;
