import React, { useEffect, useState } from "react";

import { connect } from 'react-redux';

import { Routes, Route, useNavigate } from 'react-router-dom';

import baesappApi from '../../../apis/baesappApi';
import { setMyWalletTotal, setNewMyWalletTransaction } from "../../../redux/actions";
import ModalHeader from "../../../dumbComponents/Home/modalHeader";
import BaeSvg from "../../../dumbComponents/BaeSvg";

const WithdrawZAR = ({
    myWallet,
    setMyWalletTotal,
    setNewMyWalletTransaction
}) => {

    const navigate = useNavigate();

    const [amountInCents, setAmountInCents] = useState(0);
    const [bank, setBank] = useState(false);
    const [branch, setBranch] = useState(false);
    const [accountType, setAccountType] = useState(false);
    const [accountNumber, setAccountNumber] = useState('');
    const [beneficiaryReference, setBeneficiaryReference] = useState('');
    const [notificationType, setNotificationType] = useState(false);
    const [notificationValue, setNotificationValue] = useState('');
    const [totalInputActive, setTotalInputActive] = useState(false);
    const [editBank, setEditBank] = useState(false);
    const [editAccType, setEditAccType] = useState(false);
    const [editNotificationType, setEditNotificationType] = useState(false);

    const banks = [
        {
            name: 'Absa Bank Limited',
            branchCode: '632 005'
        },
        {
            name: 'African Bank Limited',
            branchCode: '430 000'
        },
        {
            name: 'Bidvest Bank Limited',
            branchCode: '462 005'
        },
        {
            name: 'Capitec Bank Limited',
            branchCode: '470 010'
        },
        {
            name: 'Discovery Bank Limited',
            branchCode: '679 000'
        },
        {
            name: 'First National Bank (FNB)',
            branchCode: '250 655'
        },
        {
            name: 'Grindrod Bank Limited',
            branchCode: '223 626'
        },
        {
            name: 'Investec Bank Limited',
            branchCode: '580 105'
        },
        {
            name: 'Mercantile Bank Limited',
            branchCode: '450 105'
        },
        {
            name: 'Nedbank Limited',
            branchCode: '198 765'
        },
        {
            name: 'Old Mutual',
            branchCode: '462 005'
        },
        {
            name: 'Sasfin Bank Limited',
            branchCode: '683 000'
        },
        {
            name: 'Standard Bank of South Africa',
            branchCode: '051 001'
        },
        {
            name: 'SA Post Bank (Post Office)',
            branchCode: '460 005'
        },
        {
            name: 'Tyme Bank',
            branchCode: '678 910'
        },
    ];

    const withdrawMoney = () => {
        baesappApi.post('/withdrawZAR', {
            amountInCents,
            bank,
            branch,
            accountType,
            accountNumber,
            beneficiaryReference,
            notificationType,
            notificationValue
        })
            .then(res => {
                setMyWalletTotal(res.data.totalLeftInCents);
                setNewMyWalletTransaction(res.data);
                navigate(-1);
            })
            .catch(err => {
                console.log(err);
                window.alert('something went wrong');
            })
    };

    const getTotalString = () => {
        let leftString;
        let rightString;
        const toFixed = (amountInCents / 100).toFixed(2);

        leftString = toFixed.slice(0, toFixed.indexOf('.'));
        leftString = (+leftString).toLocaleString();

        rightString = toFixed.slice(toFixed.indexOf('.'));

        return ('R' + leftString + rightString);
    };

    const bankClicked = (bank, branch) => {
        setBank(bank);
        setBranch(branch);
        setEditBank(false);
    };

    return (
        <div className='fullScreen removeScrollBar modalContainer'>
            <div className='flex vertical'>
                <div style={{ minHeight: '110px', width: '50px' }} />
                <span style={{
                    color: 'var(--secondary)',
                    fontSize: '1.5em',
                    width: '70%',
                    alignSelf: 'center',
                    textAlign: 'center',
                    margin: '50px 0'
                }}>How much would you like to withdraw?</span>
                <div className="flex vertical">
                    <span style={totalInputActive ? { color: 'var(--accent)', fontSize: '50px', textAlign: 'center', fontWeight: 'bold' } : { color: 'var(--text)', fontSize: '45px', textAlign: 'center', fontWeight: 'bold' }}>{getTotalString()}</span>
                    <input
                        style={{ opacity: '0', height: '50px', marginTop: '-50px' }}
                        type='number'
                        min="0"
                        pattern="\d*"
                        onFocus={() => setTotalInputActive(true)}
                        onBlur={() => setTotalInputActive(false)}
                        onChange={e => setAmountInCents(e.target.value)}
                        value={amountInCents}
                        placeholder={0}
                    />
                </div>
                <button onClick={() => setAmountInCents(myWallet.totalInCents)}>Withdraw entire balance</button>
                <div className="infoField" style={{ zIndex: '1', height: 'fit-content', flexDirection: 'column' }} onClick={() => setEditBank(!editBank)}>
                    <div className="flex alignCenter"
                        style={{
                            width: '100%',
                            height: '100%',
                            color: !!bank ? 'var(--text)' : 'var(--secondary)',
                            fontWeight: '600',
                            textAlign: 'start',
                            justifyContent: 'space-between',
                            padding: '10px 0',
                            marginBottom: editBank ? '10px' : '0px'
                        }}>
                        {bank || 'Tap to select a bank'}
                        <BaeSvg iconName="chevronDown" height='1em' width="1em" color={editBank ? 'accent' : 'secondary'} style={{
                            transitionDuration: '200ms',
                            transform: editBank ? 'rotate(180deg)' : 'rotate(0deg)'
                        }} />
                    </div>
                    <div style={{
                        height: editBank ? `${banks.length * 50}px` : '0px',
                        transitionDuration: '200ms',
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        overflow: 'hidden',
                        justifyContent: 'center',
                        gap: '20px',
                        overflow: 'scroll'
                    }}>
                        {
                            banks.map((bankItem, index) => {
                                return (
                                    <button
                                        className="dropdownButton flex spaceBetween"
                                        onClick={() => bankClicked(bankItem.name, bankItem.branchCode)}
                                        style={
                                            index + 1 == banks.length ?
                                                {
                                                    border: 'none',
                                                    paddingBottom: '5px',
                                                    color: 'var(--text)',
                                                    fontWeight: 'bold'
                                                } : {
                                                    paddingBottom: '5px',
                                                    color: 'var(--text)',
                                                    fontWeight: 'bold'
                                                }}
                                    >
                                        {bankItem.name}
                                        {bank == bankItem.name && <BaeSvg iconName="checkmark" height='0.7em' width="0.7em" color={'secondary'} />}
                                    </button>
                                )
                            })
                        }
                    </div>
                </div>
                {branch && <div className="infoField" style={{ zIndex: '1' }}>
                    <div className="flex alignCenter" style={{ width: '100%', height: '100%', color: 'var(--secondary)', fontWeight: '600', textAlign: 'start' }}>
                        <span style={{ color: 'var(--text)' }}>{branch}</span>
                    </div>
                </div>}
                <div className="infoField" style={{ zIndex: '1', height: 'fit-content', flexDirection: 'column' }} onClick={() => setEditAccType(!editAccType)}>
                    <div className="flex alignCenter"
                        style={{
                            width: '100%',
                            height: '100%',
                            color: !!accountType ? 'var(--text)' : 'var(--secondary)',
                            fontWeight: '600',
                            textAlign: 'start',
                            justifyContent: 'space-between',
                            padding: '10px 0',
                            marginBottom: editAccType ? '10px' : '0px'
                        }}>
                        {accountType || 'Tap to select an account type'}
                        <BaeSvg iconName="chevronDown" height='1em' width="1em" color={editAccType ? 'accent' : 'secondary'} style={{
                            transitionDuration: '200ms',
                            transform: editAccType ? 'rotate(180deg)' : 'rotate(0deg)'
                        }} />
                    </div>
                    <div style={{
                        height: editAccType ? `100px` : '0px',
                        transitionDuration: '200ms',
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        overflow: 'hidden',
                        justifyContent: 'center',
                        gap: '20px',
                        overflow: 'scroll'
                    }}>
                        <button
                            className="dropdownButton flex spaceBetween"
                            onClick={() => {
                                setAccountType('Cheque');
                                setEditAccType(false);
                            }}
                            style={{
                                paddingBottom: '5px',
                                color: 'var(--text)',
                                fontWeight: 'bold'
                            }}
                        >
                            Cheque
                            {accountType == 'Cheque' && <BaeSvg iconName="checkmark" height='0.7em' width="0.7em" color={'secondary'} />}
                        </button>
                        <button
                            className="dropdownButton flex spaceBetween"
                            onClick={() => {
                                setAccountType('Savings');
                                setEditAccType(false);
                            }}
                            style={{
                                paddingBottom: '5px',
                                color: 'var(--text)',
                                fontWeight: 'bold'
                            }}
                        >
                            Savings
                            {accountType == 'Savings' && <BaeSvg iconName="checkmark" height='0.7em' width="0.7em" color={'secondary'} />}
                        </button>
                    </div>
                </div>
                <div className="infoField" style={{ zIndex: '1' }}>
                    <div className="flex alignCenter" style={{ width: '100%', height: '100%', color: 'var(--secondary)', fontWeight: '600', textAlign: 'start' }}>
                        <input
                            className="infoInputBoxNormalPlaceholder"
                            type="text"
                            value={accountNumber}
                            onChange={e => setAccountNumber(e.target.value)}
                            placeholder="Bank account number"
                            inputMode="numeric"
                            pattern="[0-9]*"
                        />
                    </div>
                </div>
                <div className="infoField" style={{ zIndex: '1' }}>
                    <div className="flex alignCenter" style={{ width: '100%', height: '100%', color: 'var(--secondary)', fontWeight: '600', textAlign: 'start' }}>
                        <input
                            className="infoInputBoxNormalPlaceholder"
                            type="text"
                            value={beneficiaryReference}
                            onChange={e => setBeneficiaryReference(e.target.value)}
                            placeholder={'Beneficiary Reference'}
                        />
                    </div>
                </div>
                <div className="infoField" style={{ zIndex: '1', height: 'fit-content', flexDirection: 'column' }} onClick={() => setEditNotificationType(!notificationType)}>
                    <div className="flex alignCenter"
                        style={{
                            width: '100%',
                            height: '100%',
                            color: !!notificationType ? 'var(--text)' : 'var(--secondary)',
                            fontWeight: '600',
                            textAlign: 'start',
                            justifyContent: 'space-between',
                            padding: '10px 0',
                            marginBottom: editNotificationType ? '10px' : '0px'
                        }}>
                        {notificationType || 'Tap to select your notification type'}
                        <BaeSvg iconName="chevronDown" height='1em' width="1em" color={editNotificationType ? 'accent' : 'secondary'} style={{
                            transitionDuration: '200ms',
                            transform: editNotificationType ? 'rotate(180deg)' : 'rotate(0deg)'
                        }} />
                    </div>
                    <div style={{
                        height: editNotificationType ? `100px` : '0px',
                        transitionDuration: '200ms',
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        overflow: 'hidden',
                        justifyContent: 'center',
                        gap: '20px',
                        overflow: 'scroll'
                    }}>
                        <button
                            className="dropdownButton flex spaceBetween"
                            onClick={() => {
                                setNotificationType('SMS');
                                setEditNotificationType(false);
                            }}
                            style={{
                                paddingBottom: '5px',
                                color: 'var(--text)',
                                fontWeight: 'bold'
                            }}
                        >
                            SMS
                            {notificationType == 'SMS' && <BaeSvg iconName="checkmark" height='0.7em' width="0.7em" color={'secondary'} />}
                        </button>
                        <button
                            className="dropdownButton flex spaceBetween"
                            onClick={() => {
                                setNotificationType('Email');
                                setEditNotificationType(false);
                            }}
                            style={{
                                paddingBottom: '5px',
                                color: 'var(--text)',
                                fontWeight: 'bold'
                            }}
                        >
                            Email
                            {notificationType == 'Email' && <BaeSvg iconName="checkmark" height='0.7em' width="0.7em" color={'secondary'} />}
                        </button>
                    </div>
                </div>
                {notificationType && <div className="infoField" style={{ zIndex: '1' }}>
                    <div className="flex alignCenter" style={{ width: '100%', height: '100%', color: 'var(--secondary)', fontWeight: '600', textAlign: 'start' }}>
                        <input
                            className="infoInputBoxNormalPlaceholder"
                            type="text"
                            value={notificationValue}
                            onChange={(e) => {
                                if(notificationType == 'SMS'){
                                    setNotificationValue(e.target.value.replace(/\D/g, ''));
                                }else{
                                    setNotificationValue(e.target.value)
                                }
                            }}
                            placeholder={notificationType == 'SMS' ? 'Enter Phone Number' : 'Enter Email Address'}
                            inputMode={notificationType == 'SMS' ? "tel" : 'email'}
                        />
                    </div>
                </div>}
                <ModalHeader title={'WITHDRAW ZAR'} actionName={`Withdraw R${amountInCents / 100}`} actionActive={amountInCents > 0}
                    actionFunction={withdrawMoney}
                />
            </div>
        </div>
    );
};

const mapStateToProps = state => {
    return ({
        myWallet: state.myWallet
    });
};

export default connect(mapStateToProps, {
    setMyWalletTotal,
    setNewMyWalletTransaction
})(WithdrawZAR);