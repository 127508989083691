const mediaScrollPositionReducer = (mediaScrollPosition=0, action) => {
    switch(action.type){
      case 'MEDIA_SCROLL_POSITION_SET':
        return action.payload;
        case 'CLEAR_ALL':
                return 0;
      default:
        return mediaScrollPosition;
    };
  };
  
  export default mediaScrollPositionReducer;
  