export const addSs = (key, value) => {
    return({
        type: 'ADD_SS',
        payload: {key, value}
    });
};

export const clearRedux = () => {
    return({
        type: 'CLEAR_ALL',
        payload: null
    });
};

export const deleteMagicCashToDelete = (_id) => {
    return({
        type: 'DELETE_MAGIC_CASH_TO_DELETE',
        payload: _id
    });
}

export const hitChatsEventListener = () => {
    return({
        type: 'CHATS_EVENT_LISTENER_HIT',
        payload: null
    });
};

export const readChat = (contactId) => {
    return({
        type: 'READ_CHAT',
        payload: contactId
    });
};

export const removeFromMembersTyping = (member_id) => {
    return({
        type: 'REMOVE_FROM_MEMBERS_TYPING',
        payload: member_id
    });
};
export const removeFromMembersTypingOpenChat = (member_id) => {
    return({
        type: 'REMOVE_FROM_MEMBERS_TYPING_OPEN_CHAT',
        payload: member_id
    });
};

export const setActiveChatFilter = (filterName) => {
    return({
        type: 'ACTIVE_CHAT_FILTER_SET',
        payload: filterName
    });
};

export const setAuthenticated = bool => {
    return({
        type: 'AUTHENTICATION_SET',
        payload: bool
    });
};

export const setChatMemberIds = data => {
    return({
        type: 'CHAT_MEMBER_IDS_SET',
        payload: data
    });
};

export const setDarkModeEnabled = (bool) => {
    return({
        type: 'SET_DARK_MODE_ENABLED',
        payload: bool
    });
};

export const setGameInvitation = (data) => {
    return({
        type: 'SET_GAME_INVITATION',
        payload: data
    });
};

export const setMyChat = data => {
    return({
        type: 'NEW_CHAT_SET',
        payload: data
    });
};

export const updateCalls = data => {
    return({
        type: 'UPDATE_MY_CHAT_CALLS',
        payload: data
    });
};

export const setMyCompanies = data => {
    return({
        type: 'MY_COMPANIES_SET',
        payload: data
    });
};

export const setMyEvents = data => {
    return({
        type: 'MY_EVENTS_SET',
        payload: data
    });
};

export const setMyId = data => {
    return({
        type: 'MY_ID_SET',
        payload: data
    });
};

export const setMyMatcherLike = data => {
    return({
        type: 'MY_MATCHER_LIKE_SET',
        payload: data
    });
};

export const setMyMatcherMatches = data => {
    return({
        type: 'MY_MATCHER_MATCHES_SET',
        payload: data
    });
};

export const setMyMatcherLikedMe = data => {
    return({
        type: 'MY_MATCHER_LIKEDME_SET',
        payload: data
    });
};

export const setMyOpenChats = data => {
    return({
        type: 'SET_MY_OPEN_CHATS',
        payload: data
    });
};

export const setEmailRegistrationData = (emailId, email, token) => {
    return({
        type: 'EMAIL_REGISTRATION_COMPLETE',
        payload: {emailId, email, token}
    });
};

export const setFollowingModalShowFollowers = (bool) => {
    return({
        type: 'FOLLOWING_MODAL_SHOW_FOLLOWERS_SET',
        payload: bool
    });
};

export const setMediaFollowing = (_id, action) => {
    return({
        type: "NEW_MEDIA_FOLLOWING_SET",
        payload: {_id, action}
    });
};
export const setMediaFollowers = (_id, action) => {
    return({
        type: "NEW_MEDIA_FOLLOWERS_SET",
        payload: {_id, action}
    });
};

export const setMediaPosts = (arr) => {
    return({
        type: 'MEDIA_POSTS_SET',
        payload: arr
    });
};

export const setMediaScrollPosition = (scrollY) => {
    return({
        type: 'MEDIA_SCROLL_POSITION_SET',
        payload: scrollY
    });
};

export const setMyData = (data) => {
    return({
        type: 'GOT_MY_DETAILS',
        payload: data
    });
};

export const setMySearch = (data) => {
    return({
        type: 'MY_SEARCH_SET',
        payload: data
    });
};

export const setMySearchHistory = (arr) => {
    return({
        type: 'MY_SEARCH_HISTORY_SET',
        payload: arr
    });
}

export const setMyWalletTotal = (totalAmountInCents) => {
    return({
        type: 'MY_WALLET_TOTAL_SET',
        payload: totalAmountInCents
    });
};

export const setNationalId = (token, firstNames) => {
    return({
        type: 'NATIONAL_ID_REGISTRATION_COMPLETE',
        payload: {token, firstNames}
    });
};

export const setNewArchivedChats = (archivedChatsArray) => {
    return({
        type: 'NEW_ARCHIVED_CHATS_SET',
        payload: archivedChatsArray
    });
};

export const setNewContact = (data) => {
    return({
        type: 'NEW_CONTACT_SET',
        payload: data
    });
};

export const setNewContactRelations = (data) => {
    return({
        type: 'NEW_CONTACT_RELATIONS_SET',
        payload: data
    });
};

export const setNewFilters = (data) => {
    return({
        type: 'NEW_FILTERS_SET',
        payload: data
    });
};

export const setNewMyInfo = (data) => {
    return({
        type: 'NEW_MY_INFO_SET',
        payload: data
    });
};

export const setNewIncomingRequest = (data) => {
    return({
        type: 'NEW_INCOMMING_SET',
        payload: data
    });
};
export const setNewWalletHasPin = (pin) => {
    return({
        type: 'SET_NEW_MY_WALLET_HAS_PIN',
        payload: pin
    });
};

export const setNewMagicCashToDelete = (_id) => {
    return({
        type: 'SET_NEW_MAGIC_CASH_TO_DELETE',
        payload: _id
    });
};

export const setNewMyWalletTransaction = (transaction) => {
    return({
        type: 'NEW_MY_WALLET_TRANSACTION_SET',
        payload: transaction
    });
};

export const setNewPost = (data) => {
    return({
        type: 'NEW_POST_SET',
        payload: data
    });
};

export const setNewOutgoingRequest = (data) => {
    return({
        type: 'NEW_OUTGOING_SET',
        payload: data
    });
};

export const setNewWallet = (newWallet) => {
    return({
        type: 'NEW_WALLET_SET',
        payload: newWallet
    });
};

export const setMyPosts = (data) => {
    return({
        type: 'SET_MY_POSTS',
        payload: data
    });
};

export const setSavedPosts = (data) => {
    return({
        type: 'SET_SAVED_POSTS',
        payload: data
    });
};

export const setSearchableByProfileShot = (bool) => {
    return({
        type: 'SEARCHABLE_BY_PROFILE_SHOT_SET',
        payload: bool
    });
};

export const setSearchFilterData = (data) => {
    return({
        type: 'SET_SEARCH_FILTER_DATA',
        payload: data
    });
};

export const setShowNotificationBar = (data) => {
    return({
        type: 'SHOW_NOTIFICATION_BAR_SET',
        payload: data
    });
};

export const setTopBarOffset = (px) => {
    return({
        type: 'TOP_BAR_OFFSET_SET',
        payload: px
    });
}

export const setTransactionsList = (data) => {
    return({
        type: 'TRANSACTIONS_LIST_SET',
        payload: data
    });
}

export const setUrgentCall = (bool) => {
    return({
        type: 'URGENT_CALL_SET',
        payload: bool
    });
}

export const setVisitChatFromMessage = (bool) => {
    return({
        type: 'SET_VISIT_CHAT_FROM_MESSAGE',
        payload: bool
    });
}

export const setVisitMember = (data) => {
    return({
        type: 'SET_VISIT_MEMBER',
        payload: data
    });
};

export const thisMemberTyped = (member_id) => {
    return({
        type: 'ADD_MEMBERS_TYPING',
        payload: member_id
    });
};
export const thisMemberTypedOpenChat = (member_id) => {
    return({
        type: 'ADD_MEMBERS_TYPING_OPEN_CHAT',
        payload: member_id
    });
};

export const toggledMediaPostLike = (data) => {
    return({
        type: 'TOGGLED_MEDIA_POST_LIKE',
        payload: data
    });
};

export const updateComments = (postId, comment, action, commentId=null) => {
    return({
        type: 'COMMENTS_UPDATED',
        payload: {
            postId,
            action,
            commentId,
            comment
        }
    });
};

export const updateMyDoorbell = (data) => {
    return({
        type: 'MY_DOORBELL_UPDATED',
        payload: data
    });
};

export const deletePost = (postId) => {
    return({
        type: 'POST_DELETED',
        payload: postId
    });
}