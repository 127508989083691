const chatsEventListenerReducer = (chatsEventListener=false, action) => {
    switch(action.type){
        case 'CHATS_EVENT_LISTENER_HIT':
            return !chatsEventListener;
            case 'CLEAR_ALL':
                return false;
        default: return chatsEventListener;
    };
};

export default chatsEventListenerReducer;
