import React, { useEffect, useRef, useState } from "react";

import { connect } from 'react-redux';
import { Routes, Route, useNavigate } from 'react-router-dom';

import chevronAccent from '../../../assets/chevronAccent.png';

import BaeSvg from '../../../dumbComponents/BaeSvg';

import baesappApi from "../../../apis/baesappApi";
import { setSearchFilterData } from "../../../redux/actions";

import hobbies from "../../../assets/hobbies.png";
import person from '../../../assets/person.png';
import coloPalete from '../../../assets/coloPalete.png';
import fox from '../../../assets/fox.png';
import music from '../../../assets/music.png';
import movie from '../../../assets/movie.png';
import tv from '../../../assets/tv.png';
import book from '../../../assets/book.png';
import rings from '../../../assets/rings.png';
import briefcase from "../../../assets/briefcase.png";
import languages from "../../../assets/languages.png";
import education from "../../../assets/education.png";
import degree from "../../../assets/degree.png";
import skills from "../../../assets/skills.png";
import heartBeat from '../../../assets/heartBeat.png';
import pill from '../../../assets/pill.png';
import sadFace from '../../../assets/sadFace.png';
import drop from '../../../assets/drop.png';
import ruler from '../../../assets/ruler.png';
import weight from '../../../assets/weight.png';
import comb from '../../../assets/comb.png';
import string from '../../../assets/string.png';
import eye from '../../../assets/eye.png';
import glove from '../../../assets/glove.png';
import mail from '../../../assets/mail.png';
import sellphone from '../../../assets/sellphone.png';
import tie from '../../../assets/tie.png';
import plusMinus from '../../../assets/plusMinus.png';
import emergencyPhone from '../../../assets/emergencyPhone.png';
import cake from '../../../assets/cake.png';
import gender from '../../../assets/gender.png';
import ModalHeader from "../../../dumbComponents/Home/modalHeader";

const InfoSearch = ({
    setSearching,
    setDemoList,
    setSearchFilterData,
    searchFilterData,
    setInfoSearchActive,
    handleImageSelect
}) => {

    const [fieldClickedData, setFieldClickedData] = useState(false);
    const navigate = useNavigate();
    const [hasActiveFields, setHasActiveFields] = useState(false);
    const [editFilterAction, setEditFilterAction] = useState(false);

    const fileInputRef = useRef();

    useEffect(() => {
        let tempHasActiveFields = false;
        if (searchFilterData) {
            searchFilterData.map(field => {
                if (field.value != '') {
                    tempHasActiveFields = true;
                };
            });
        };
        if (!tempHasActiveFields) {
            setHasActiveFields(false);
        } else {
            setHasActiveFields(true);
        };
    }, [searchFilterData]);

    const search = () => {
        setSearching(true);
        let filterItems = [];
        searchFilterData.filter(data => data.key != 'label').map(field => {
            let times100 = false;
            if (field.key == 'height' || field.key == 'weight') {
                times100 = true;
            };
            if (field.value != '') {
                filterItems.push({
                    key: field.key,
                    value: times100 ? field.value * 100 : field.value,
                    action: field.selectedAction
                })
            }
        });
        navigate(-1);
        baesappApi.post('/searchMembers/info', { filterItems })
            .then(res => {
                setDemoList(res.data);
                setInfoSearchActive(true);
                setSearching(false);
            })
            .catch(err => {
                console.log(err);
                setSearching(false);
            })
    };

    const fieldClicked = (field) => {
        setFieldClickedData(field);
        navigate('editField');
    };

    const handleTextInput = (value) => {
        let tempFieldClickedData = { ...fieldClickedData };

        if (
            (tempFieldClickedData.selectedAction == 'multipleAnd' || tempFieldClickedData.selectedAction == 'multipleOr') &&
            !isFreeTextField(fieldClickedData.key)
        ) {
            if (tempFieldClickedData.value == '') {
                tempFieldClickedData.value = [value];
                setFieldClickedData(tempFieldClickedData);
            } else {
                if (tempFieldClickedData.value.includes(value)) {
                    tempFieldClickedData.value.splice(tempFieldClickedData.value.indexOf(value), 1);
                    setFieldClickedData(tempFieldClickedData);
                } else {
                    tempFieldClickedData.value.push(value);
                    setFieldClickedData(tempFieldClickedData);
                }
            };
        } else {
            tempFieldClickedData.value = value;
            setFieldClickedData(tempFieldClickedData);
        }

    };

    const setNewFieldValue = () => {
        let tempFieldClickedData = { ...fieldClickedData };

        let tempFilterData = [...searchFilterData];
        tempFilterData.map(filter => {
            if (filter.key == tempFieldClickedData.key) {
                filter.value = tempFieldClickedData.value;
                filter.active = true;
                filter.action = tempFieldClickedData.active;
            }
        });
        setSearchFilterData(tempFilterData);
        navigate(-1);
    };

    const removeField = () => {
        let tempFieldClickedData = { ...fieldClickedData };

        let tempFilterData = [...searchFilterData];
        tempFilterData.map(filter => {
            if (filter.key == tempFieldClickedData.key) {
                filter.value = '';
            }
        });
        setSearchFilterData(tempFilterData);
        navigate(-1);
    };

    const isFreeTextField = (fieldName) => {
        switch (fieldName) {
            case 'height': return true
            case 'weight': return true
            case 'firstNames': return true
            case 'lastname': return true
            case 'sex': return true
            case 'dateOfBirth': return true
            case 'preferredName': return true
            case 'phone': return true
            case 'email': return true
            case 'country': return true
            case 'provinceState': return true
            case 'city': return true
            default: return false;
        }
    };

    const allPossibleFields = [
        {
            field: 'favouriteColors',
            label: 'What is their favourite color?',
            values: ['red', 'blue', 'green', 'yellow', 'orange', 'purple', 'pink', 'black', 'white', 'gray'],
            src: coloPalete,
            isArray: true
        },
        {
            field: 'favouriteAnimals',
            label: 'What is their favourite animals?',
            values: ['Antelope', 'Bear', 'Cat', 'Dog', 'Elephant', 'Frog', 'Giraffe', 'Horse', 'Iguana', 'Jaguar', 'Kangaroo', 'Lion', 'Monkey', 'Narwhal', 'Ostrich', 'Panda', 'Quokka', 'Raccoon', 'Squirrel', 'Tiger', 'Unicorn', 'Vulture', 'Walrus', 'X-ray Fish', 'Yak', 'Zebra'],
            src: fox,
            isArray: true
        },
        {
            field: 'favouriteMusicGenres',
            label: 'What is their favourite music genres?',
            values: [
                "Acid Jazz",
                "Acoustic",
                "Adult Contemporary",
                "Afrikaans Pop",
                "Afrikaans Rock",
                "Afrikaans Indie",
                "Afrobeat",
                "Alternative",
                "Ambient",
                "Americana",
                "Avant-Garde",
                "Bachata",
                "Ballad",
                "Baroque",
                "Big Band",
                "Bluegrass",
                "Blues",
                "Bossa Nova",
                "Breakbeat",
                "Calypso",
                "Caribbean",
                "Chamber Music",
                "Chanson",
                "Chill",
                "Chiptune",
                "Choral",
                "Christian",
                "Classic Rock",
                "Classical",
                "Club",
                "Comedy",
                "Contemporary",
                "Country",
                "Cumbia",
                "Dance",
                "Darkwave",
                "Death Metal",
                "Disco",
                "Drum and Bass",
                "Dub",
                "Dubstep",
                "EDM",
                "Electric",
                "Electronic",
                "Electropop",
                "Emo",
                "Eurodance",
                "Experimental",
                "Fado",
                "Folk",
                "Folk Rock",
                "Funk",
                "Fusion",
                "Gospel",
                "Goth",
                "Grime",
                "Grindcore",
                "Grunge",
                "Hard Rock",
                "Hardcore",
                "Heavy Metal",
                "Hip Hop",
                "House",
                "IDM",
                "Indie",
                "Industrial",
                "Instrumental",
                "Jazz",
                "Jungle",
                "K-Pop",
                "Latin",
                "Lounge",
                "Mambo",
                "Math Rock",
                "Merengue",
                "Metal",
                "Minimal",
                "Modern Classical",
                "Motown",
                "Neo-Soul",
                "New Age",
                "New Wave",
                "Noise",
                "Nu Jazz",
                "Opera",
                "Orchestral",
                "Pop",
                "Post-Punk",
                "Post-Rock",
                "Power Pop",
                "Prog Rock",
                "Progressive House",
                "Psych Rock",
                "Psychedelic",
                "Punk",
                "R&B",
                "Rap",
                "Reggae",
                "Reggaeton",
                "Rock",
                "Rockabilly",
                "Salsa",
                "Samba",
                "Shoegaze",
                "Singer-Songwriter",
                "Ska",
                "Soul",
                "Soundtrack",
                "Southern Rock",
                "Space Rock",
                "Spoken Word",
                "Surf",
                "Swing",
                "Synthpop",
                "Tango",
                "Techno",
                "Trance",
                "Trap",
                "Trip Hop",
                "World"
            ],
            src: music,
            isArray: true
        },
        {
            field: 'favouriteMovies',
            label: 'What is their favourite movie genres?',
            values: [
                "Action",
                "Adventure",
                "Animation",
                "Biography",
                "Comedy",
                "Crime",
                "Documentary",
                "Drama",
                "Family",
                "Fantasy",
                "Film Noir",
                "History",
                "Horror",
                "Music",
                "Musical",
                "Mystery",
                "Romance",
                "Sci-Fi",
                "Short",
                "Sport",
                "Superhero",
                "Thriller",
                "War",
                "Western"
            ],
            src: movie,
            isArray: true
        },
        {
            field: 'favouriteTVShows',
            label: 'What is their favourite tv show genres?',
            values: [
                "Action",
                "Adventure",
                "Animation",
                "Anime",
                "Comedy",
                "Crime",
                "Documentary",
                "Drama",
                "Family",
                "Fantasy",
                "Game Show",
                "History",
                "Horror",
                "Kids",
                "Music",
                "Mystery",
                "News",
                "Reality",
                "Romance",
                "Sci-Fi",
                "Soap",
                "Sitcom",
                "Talk",
                "Thriller",
                "War",
                "Western"
            ],
            src: tv,
            isArray: true
        },
        {
            field: 'favouriteBooks',
            label: 'What is their favourite book genres?',
            values: [
                "Action and Adventure",
                "Art",
                "Alternate History",
                "Autobiography",
                "Anthology",
                "Biography",
                "Chick Lit",
                "Children's",
                "Classic",
                "Comics",
                "Coming of Age",
                "Cookbook",
                "Crime",
                "Drama",
                "Diary",
                "Dictionary",
                "Encyclopedia",
                "Fairytale",
                "Fantasy",
                "Guide",
                "Graphic Novel",
                "Health",
                "History",
                "Historical Fiction",
                "Horror",
                "Humor",
                "Journal",
                "Mystery",
                "Memoir",
                "Math",
                "Medical",
                "Mind",
                "Music",
                "New Age",
                "Nonfiction",
                "Poetry",
                "Philosophy",
                "Paranormal",
                "Picture Books",
                "Psychology",
                "Reference",
                "Religion",
                "Romance",
                "Science",
                "Science Fiction",
                "Self Help",
                "Suspense",
                "Spirituality",
                "Sports",
                "Thriller",
                "Travel",
                "Young Adult"
            ],
            src: book,
            isArray: true
        },
        {
            field: 'favouriteAuthors',
            label: 'Who is their favourite authors?',
            values: [
                "Albert Einstein",
                "Agatha Christie",
                "Carl Sagan",
                "Charles Dickens",
                "Christopher Hitchens",
                "C.S. Lewis",
                "Dan Brown",
                "David Baldacci",
                "Ernest Hemingway",
                "Friedrich Nietzsche",
                "Fyodor Dostoevsky",
                "Gabriel Garcia Marquez",
                "George Orwell",
                "Herman Melville",
                "Immanuel Kant",
                "Isaac Asimov",
                "J.K. Rowling",
                "James Patterson",
                "Jane Austen",
                "Jean-Paul Sartre",
                "John Grisham",
                "Karen Armstrong",
                "Leo Tolstoy",
                "Margaret Atwood",
                "Mark Twain",
                "Maya Angelou",
                "Michel Foucault",
                "Nora Roberts",
                "Neil deGrasse Tyson",
                "Neil Gaiman",
                "Other",
                "Richard Dawkins",
                "Søren Kierkegaard",
                "Saint Augustine",
                "Sam Harris",
                "Stephen Hawking",
                "Stephen King",
                "Toni Morrison",
                "Thomas Aquinas",
                "Virginia Woolf",
                "William Shakespeare",
                "J.R.R. Tolkien"
            ],
            src: person,
            isArray: true
        },
        {
            field: 'favouriteHobbies',
            label: 'What hobbies do they have?',
            values: [
                "Aerobics",
                "Acting",
                "Amateur radio",
                "Attending live music events",
                "Baking",
                "Bird watching",
                "Board games",
                "Bungee jumping",
                "Calligraphy",
                "Canoeing",
                "Card games",
                "Camping",
                "Charity work",
                "Chess",
                "Climbing",
                "Collecting antiques",
                "Collecting art",
                "Collecting books",
                "Collecting coins",
                "Collecting stamps",
                "Cooking",
                "Cosplaying",
                "Crossfit",
                "Crocheting",
                "Cycling",
                "Dancing",
                "DIY projects",
                "Drone flying",
                "Drawing",
                "Embroidery",
                "Fishing",
                "Film-making",
                "Football",
                "Gaming",
                "Gardening",
                "Ghost hunting",
                "Glassblowing",
                "Going to art galleries",
                "Going to the theatre",
                "Hang gliding",
                "Hiking",
                "Home brewing",
                "Horseback riding",
                "Hunting",
                "Jewelry making",
                "Kayaking",
                "Knitting",
                "Learning languages",
                "Leatherworking",
                "Meditation",
                "Metal detecting",
                "Metalworking",
                "Model building",
                "Model railroading",
                "Mountaineering",
                "Musical instruments",
                "Origami",
                "Painting",
                "Parkour",
                "Paragliding",
                "Photography",
                "Pilates",
                "Playing video games",
                "Playing musical instruments",
                "Pottery",
                "Programming",
                "Quilting",
                "Rafting",
                "Reading",
                "Robotics",
                "Rock climbing",
                "Running",
                "Sailing",
                "Scrapbooking",
                "Scuba diving",
                "Sewing",
                "Singing",
                "Skydiving",
                "Skiing",
                "Snorkeling",
                "Snowboarding",
                "Spelunking",
                "Sports",
                "Star gazing",
                "Surfing",
                "Swimming",
                "Tea tasting",
                "Treasure hunting",
                "Traveling",
                "Urban exploration",
                "Virtual Reality",
                "Volunteering",
                "Watching movies",
                "Watching sports",
                "Watching TV shows",
                "Weight lifting",
                "Wine tasting",
                "Woodworking",
                "Writing",
                "Yoga",
                "Zumba"
            ],
            src: hobbies,
            isArray: true
        },
        {
            field: 'maritalStatus',
            label: 'What is their marital Status?',
            values: [
                'Single',
                'Married',
                'Divorced',
                'Widowed'
            ],
            src: rings
        },
        {
            field: 'employmentStatus',
            label: 'What is their employment status',
            values: [
                'Employed',
                'Unemployed (Looking for a job)',
                'Unemployed (Not looking for a job)',
                'Self-employed',
                'Freelance',
                'Retired',
                'Student',
                'Homemaker',
                'Other'
            ],
            src: briefcase
        },
        {
            field: 'degrees',
            label: 'What degrees do they have?',
            values: [
                "Accounting",
                "Agriculture",
                "Animal Science",
                "Anthropology",
                "Architecture",
                "Art",
                "Biology",
                "Business Administration",
                "Chemistry",
                "Civil Engineering",
                "Communication",
                "Computer Science",
                "Construction Management",
                "Criminal Justice",
                "Dental Hygiene",
                "Dentistry",
                "Economics",
                "Education",
                "Electrical Engineering",
                "Engineering Technology",
                "English",
                "Environmental Science",
                "Fashion Design",
                "Film",
                "Finance",
                "Food Science",
                "Foreign Languages",
                "Geography",
                "Geology",
                "Graphic Design",
                "Healthcare Administration",
                "History",
                "Hospitality Management",
                "Human Resources",
                "Industrial Design",
                "Information Technology",
                "Interior Design",
                "Journalism",
                "Law",
                "Library Science",
                "Management",
                "Manufacturing Engineering",
                "Marketing",
                "Mathematics",
                "Mechanical Engineering",
                "Medical Laboratory Science",
                "Medicine",
                "Music",
                "Nursing",
                "Nutrition",
                "Occupational Therapy",
                "Optometry",
                "Pharmacy",
                "Philosophy",
                "Physical Therapy",
                "Physics",
                "Political Science",
                "Psychology",
                "Public Health",
                "Public Relations",
                "Radiologic Technology",
                "Real Estate",
                "Religious Studies",
                "Social Work",
                "Sociology",
                "Speech-Language Pathology",
                "Sports Management",
                "Statistics",
                "Theater",
                "Urban Planning",
                "Veterinary Medicine"
            ],
            src: degree,
            isArray: true
        },
        {
            field: 'highestEducationLevels',
            label: 'What is their education level',
            values: [
                "Associate's Degree",
                "Bachelor's Degree",
                "Certificate",
                "Doctorate Degree",
                "High School Diploma",
                "Master's Degree",
                "Professional Degree",
                "Vocational Degree"
            ],
            src: education
        },
        {
            field: 'occupation',
            label: 'What is their occupation?',
            values: [
                "Accountant",
                "Architect",
                "Chef",
                "Computer Programmer",
                "Copywriter",
                "Dentist",
                "Doctor",
                "Electrician",
                "Engineer",
                "Financial Analyst",
                "Graphic Designer",
                "Human Resources Manager",
                "Interior Designer",
                "Journalist",
                "Lawyer",
                "Marketing Manager",
                "Mechanic",
                "Nurse",
                "Occupational Therapist",
                "Pharmacist",
                "Physical Therapist",
                "Police Officer",
                "Real Estate Agent",
                "Sales Representative",
                "Software Developer",
                "Teacher",
                "Veterinarian",
                "Web Developer",
                "Writer"
            ],
            src: briefcase,
            isArray: true
        },
        {
            field: 'jobSkills',
            label: 'What job skills do they have?',
            values: [
                "Accounting",
                "Adaptability",
                "Analytical thinking",
                "Artificial intelligence",
                "Attention to detail",
                "Business analysis",
                "Civil engineering",
                "Cloud computing",
                "Coaching",
                "Communication",
                "Content creation",
                "Copywriting",
                "Culinary skills",
                "Customer service",
                "Cybersecurity",
                "Data analysis",
                "Database management",
                "Digital marketing",
                "Electrical engineering",
                "Financial analysis",
                "Fitness training",
                "Foreign language proficiency",
                "Graphic design",
                "Interpersonal skills",
                "Investment management",
                "Leadership",
                "Legal knowledge",
                "Machine learning",
                "Marketing",
                "Mechanical engineering",
                "Medical knowledge",
                "Mobile development",
                "Networking",
                "Negotiation",
                "Product management",
                "Programming",
                "Project management",
                "Public speaking",
                "Research",
                "Sales",
                "SEM",
                "SEO",
                "Social media management",
                "Software development",
                "System administration",
                "Teaching",
                "Teamwork",
                "Time management",
                "UI/UX design",
                "Video production",
                "Web development"
            ]
            ,
            src: skills,
            isArray: true
        },
        {
            field: 'languagesSpoken',
            label: 'What languages can they speak?',
            values: [
                'Afrikaans',
                'Albanian',
                'Amharic',
                'Arabic',
                'Armenian',
                'Azerbaijani',
                'Basque',
                'Belarusian',
                'Bengali',
                'Bosnian',
                'Bulgarian',
                'Catalan',
                'Cebuano',
                'Chichewa',
                'Chinese (Simplified)',
                'Chinese (Traditional)',
                'Corsican',
                'Croatian',
                'Czech',
                'Danish',
                'Dutch',
                'English',
                'Esperanto',
                'Estonian',
                'Filipino',
                'Finnish',
                'French',
                'Frisian',
                'Galician',
                'Georgian',
                'German',
                'Greek',
                'Gujarati',
                'Haitian Creole',
                'Hausa',
                'Hawaiian',
                'Hebrew',
                'Hindi',
                'Hmong',
                'Hungarian',
                'Icelandic',
                'Igbo',
                'Indonesian',
                'Irish',
                'Italian',
                'Japanese',
                'Javanese',
                'Kannada',
                'Kazakh',
                'Khmer',
                'Kinyarwanda',
                'Korean',
                'Kurdish',
                'Kyrgyz',
                'Lao',
                'Latin',
                'Latvian',
                'Lithuanian',
                'Luxembourgish',
                'Macedonian',
                'Malagasy',
                'Malay',
                'Malayalam',
                'Maltese',
                'Maori',
                'Marathi',
                'Mongolian',
                'Myanmar (Burmese)',
                'Nepali',
                'Norwegian',
                'Odia (Oriya)',
                'Pashto',
                'Persian',
                'Polish',
                'Portuguese',
                'Punjabi',
                'Romanian',
                'Russian',
                'Samoan',
                'Scots Gaelic',
                'Serbian',
                'Sesotho',
                'Shona',
                'Sindhi',
                'Sinhala',
                'Slovak',
                'Slovenian',
                'Somali',
                'Spanish',
                'Sundanese',
                'Swahili',
                'Swedish',
                'Tajik',
                'Tamil',
                'Tatar',
                'Telugu',
                'Thai',
                'Turkish',
                'Turkmen',
                'Ukrainian',
                'Urdu',
                'Uyghur',
                'Uzbek',
                'Vietnamese',
                'Welsh',
                'Xhosa',
                'Yiddish',
                'Yoruba',
                'Zulu'
            ],
            src: languages,
            isArray: true
        },
        {
            field: 'bloodType',
            label: 'What is their blood type?',
            values: ["A+", "A-", "B+", "B-", "AB+", "AB-", "O+", "O-"],
            src: drop
        },
        {
            field: 'allergies',
            label: 'What allergies do they have?',
            values: [
                'Pollen',
                'Dust mites',
                'Mold',
                'Pet dander',
                'Bee stings',
                'Wasp stings',
                'Shellfish',
                'Peanuts',
                'Tree nuts',
                'Eggs',
                'Milk',
                'Soy',
                'Wheat',
                'Fish',
                'Penicillin',
                'Aspirin',
                'Ibuprofen',
                'Sulfa drugs',
                'other'
            ],
            src: sadFace,
            isArray: true
        },
        {
            field: 'medications',
            label: 'What medications are they currently on?',
            values: [
                'Acetaminophen',
                'Ibuprofen',
                'Aspirin',
                'Naproxen',
                'Antibiotics',
                'Antidepressants',
                'Antihistamines',
                'Antipsychotics',
                'Bronchodilators',
                'Corticosteroids',
                'Insulin',
                'Blood pressure medications',
                'Cholesterol-lowering medications',
                'Oral contraceptives',
                'Thyroid medications',
                'Vaccines'
            ],
            src: pill,
            isArray: true
        },
        {
            field: 'medicalConditions',
            label: 'What medical conditions do they have?',
            values: [
                "Acne",
                "Age-related macular degeneration",
                "Allergies",
                "Alzheimer's disease",
                "Anxiety",
                "Arthritis",
                "Astigmatism",
                "Asthma",
                "Attention-deficit/hyperactivity disorder (ADHD)",
                "Autism spectrum disorder (ASD)",
                "Basal cell carcinoma",
                "Bipolar disorder",
                "Cancer",
                "Cataracts",
                "Celiac disease",
                "Chronic fatigue syndrome",
                "Chronic kidney disease",
                "Chronic obstructive pulmonary disease (COPD)",
                "Crohn's disease",
                "Cystic fibrosis",
                "Depression",
                "Diabetes",
                "Diabetic retinopathy",
                "Down syndrome",
                "Eczema",
                "Endometriosis",
                "Farsightedness",
                "Fibromyalgia",
                "Glaucoma",
                "Gout",
                "Heart disease",
                "Hemophilia",
                "HIV/AIDS",
                "Huntington's disease",
                "Hyperthyroidism",
                "Hypothyroidism",
                "Influenza",
                "Irritable bowel syndrome (IBS)",
                "Klinefelter syndrome",
                "Melanoma",
                "Migraine",
                "Multiple sclerosis",
                "Muscular dystrophy",
                "Myasthenia gravis",
                "Narcolepsy",
                "Nearsightedness",
                "Obsessive-compulsive disorder (OCD)",
                "Obesity",
                "Obstructive sleep apnea",
                "Osteoporosis",
                "Parkinson's disease",
                "Polycystic ovary syndrome (PCOS)",
                "Post-traumatic stress disorder (PTSD)",
                "Psoriasis",
                "Retinal detachment",
                "Rheumatoid arthritis",
                "Rosacea",
                "Schizophrenia",
                "Sickle cell disease",
                "Squamous cell carcinoma",
                "Stroke",
                "Thalassemia",
                "Tourette's syndrome",
                "Turner syndrome",
                "Ulcerative colitis",
                "Vitiligo"
            ],
            src: heartBeat,
            isArray: true
        },
        {
            field: 'personalityTraits',
            label: 'What personality traits do they have?',
            values: [
                "Adventurous",
                "Affectionate",
                "Agreeable",
                "Analytical",
                "Assertive",
                "Attentive",
                "Calm",
                "Charismatic",
                "Cheerful",
                "Confident",
                "Considerate",
                "Courageous",
                "Creative",
                "Curious",
                "Dedicated",
                "Dependable",
                "Determined",
                "Disciplined",
                "Easygoing",
                "Energetic",
                "Enthusiastic",
                "Flexible",
                "Friendly",
                "Generous",
                "Gentle",
                "Giving",
                "Gracious",
                "Honest",
                "Humble",
                "Imaginative",
                "Independent",
                "Industrious",
                "Innovative",
                "Intelligent",
                "Intuitive",
                "Kind",
                "Logical",
                "Loyal",
                "Meticulous",
                "Modest",
                "Open-minded",
                "Organized",
                "Patient",
                "Persistent",
                "Persuasive",
                "Philosophical",
                "Practical",
                "Proactive",
                "Professional",
                "Quick-witted",
                "Realistic",
                "Reliable",
                "Resourceful",
                "Respectful",
                "Responsible",
                "Self-assured",
                "Self-aware",
                "Self-disciplined",
                "Sensitive",
                "Serious",
                "Sincere",
                "Social",
                "Strategic",
                "Supportive",
                "Thoughtful",
                "Tolerant",
                "Trustworthy",
                "Understanding",
                "Versatile",
                "Witty"
            ],
            isArray: true
        },
        {
            field: 'bodyType',
            label: "What is their body type?",
            values: [
                "Endomorph",
                "Mesomorph",
                "Ectomorph"
            ],
            src: person
        },
        {
            field: 'hairColor',
            label: "What is their hair color?",
            values: [
                "Black",
                "Brown",
                "Blonde",
                "Red",
                "Gray/White",
                "Other"
            ],
            src: comb
        },
        {
            field: 'hairType',
            label: "What is their hair type?",
            values: [
                "Coarse",
                "Curly",
                "Fine",
                "Frizzy",
                "Kinky",
                "Straight",
                "Thick",
                "Thin",
                "Wavy"
            ],
            src: string
        },
        {
            field: 'eyeColor',
            label: "What is their eye color?",
            values: ['Amber', 'Blue', 'Brown', 'Gray', 'Green', 'Hazel', 'Red', 'Violet'],
            src: eye
        },
        {
            field: 'skinTone',
            label: "What is their skin tone?",
            values: [
                "Fair",
                "Light",
                "Medium",
                "Olive",
                "Brown",
                "Dark"
            ],
            src: coloPalete
        },
        {
            field: 'dexterity',
            label: "What is their dominant hand?",
            values: [
                "Left-handed",
                "Right-handed",
                "Ambidextrous"
            ],
            src: glove
        }
    ];

    const filterActionClicked = (action) => {
        let newSearchFilterData = [...searchFilterData];
        let indexOfAction = newSearchFilterData.findIndex(filterData => filterData.key == fieldClickedData.key);
        newSearchFilterData[indexOfAction].selectedAction = action;
        setSearchFilterData(newSearchFilterData);
        setEditFilterAction(false);
    };

    const getImage = (key) => {
        switch (key) {
            case 'email': return mail;
            case 'phone': return sellphone;
            case 'maritalStatus': return rings;
            case 'employmentStatus': return tie;
            case 'bloodType': return drop;
            case 'allergies': return sadFace;
            case 'medications': return pill;
            case 'medicalConditions': return heartBeat;
            case 'height': return ruler;
            case 'weight': return weight;
            case 'bodyType': return person;
            case 'hairColor': return comb;
            case 'hairType': return string;
            case 'eyeColor': return eye;
            case 'skinTone': return coloPalete;
            case 'dexterity': return glove;
            case 'favouriteAnimals': return fox;
            case 'favouriteMusicGenres': return music;
            case 'favouriteMovies': return movie;
            case 'favouriteTVShows': return tv;
            case 'favouriteBooks': return book;
            case 'favouriteHobbies': return hobbies;
            case 'highestEducationLevels': return education;
            case 'degrees': return degree;
            case 'languagesSpoken': return languages;
            case 'jobSkills': return skills;
            case 'occupation': return briefcase;
            case 'City':
            case 'Province / State':
            case 'Country':
                {
                    return false;
                }
            case 'Add my Personality traits': return person;
            default: return person;
        }
    }

    const getPossibleFields = (key) => {
        return allPossibleFields.find(field => field.field == key).values;
    };

    const isActiveValue = (value) => {
        return fieldClickedData.value.includes(value);
    };

    const getFilterBehavioursLabel = (behaviourCode, value) => {
        switch (behaviourCode) {
            case 'regex':
                return `Equal to ${value}`;
            case 'gt':
                return `Greater than ${value}`;
            case 'lt':
                return `Less than ${value}`;
            case 'eq':
                return `Equal to ${value}`;
            case 'multipleAnd':
                return `Contains all selected values`;
            case 'multipleOr':
                return 'Contains at least one selected value';
        }
    };

    const getInputType = (field) => {
        switch (field) {
            case 'phone': return 'tel';
            case 'email': return 'email';
            case 'height':
            case 'weight': {
                return 'decimal';
            }
            default: return 'text';
        }
    };

    const renderEditField = () => {
        const thisFieldFreeText = isFreeTextField(fieldClickedData.key);
        let fieldValuesList = null
        if (!thisFieldFreeText) {
            const possibleFields = getPossibleFields(fieldClickedData.key);
            fieldValuesList = possibleFields.map(value => {
                return (
                    <div onClick={() => handleTextInput(value)} className='infoPickable' style={isActiveValue(value) ? { borderColor: 'var(--accent)', color: 'var(--accent)' } : {}}>
                        {value}
                    </div>
                )
            });
        }
        return (
            <>
                <div onClick={() => navigate(-1)} className="middlePageConvoBackdrop" style={{ backgroundColor: 'var(--base)' }} />
                <div className="bottomSlideInCard flex vertical spaceAround alignCenter confirmationCard">
                    <span style={{ margin: '20px' }}><strong>{fieldClickedData.label}</strong></span>
                    {
                        isFreeTextField(fieldClickedData.key) ?
                            <input
                                type={getInputType(fieldClickedData.key)}
                                autoComplete={getInputType(fieldClickedData.key)}
                                inputMode={((fieldClickedData.key == 'height') || (fieldClickedData.key == 'weight')) ? 'decimal' : ''}
                                placeholder='Type value...'
                                oninput={fieldClickedData.key == 'phone' ? "this.value=this.value.replace(/[^0-9-]/g,'');" : ((fieldClickedData.key == 'height') || (fieldClickedData.key == 'weight')) ? "(?=.{1,5}$)\\d{1,3}(\\.\\d{1,2})?" : ''}
                                pattern={fieldClickedData.key == 'phone' ? "\d{3}-\d{3}-\d{4}" : ((fieldClickedData.key == 'height') || (fieldClickedData.key == 'weight')) ? "\\d{1,3}(\\.\\d{1,2})?" : ''}
                                onChange={(e) => handleTextInput(e.target.value)}
                                value={fieldClickedData.value}
                                style={{ color: 'var(--base)' }}
                            /> :
                            <div style={{ overflow: 'scroll' }}>
                                {fieldValuesList}
                            </div>
                    }
                    <div
                        style={{
                            borderRadius: '15px',
                            border: editFilterAction ? '2px solid var(--accent)' : '1px solid var(--secondary)',
                            marginTop: '5px',
                            width: '90%',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center'
                        }}
                        onClick={() => setEditFilterAction(!editFilterAction)}>
                        <button style={{
                            color: editFilterAction ? 'var(--accent)' : 'var(--secondary)',
                            margin: '5px',
                            padding: '0',
                            display: 'flex',
                            fontSize: '1em',
                            gap: '15px',
                            width: '95%',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}>
                            {getFilterBehavioursLabel(fieldClickedData.selectedAction, fieldClickedData.value)}
                            <BaeSvg iconName="chevronDown" height='1em' width="1em" color={editFilterAction ? 'accent' : 'secondary'} style={{
                                transitionDuration: '200ms',
                                transform: editFilterAction ? 'rotate(180deg)' : 'rotate(0deg)'
                            }} />
                        </button>
                        <div style={{
                            height: editFilterAction ? `${fieldClickedData.possibleActions.length * 50}px` : '0px',
                            transitionDuration: '200ms',
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            overflow: 'hidden',
                            justifyContent: 'center',
                            gap: '20px'
                        }}>
                            {
                                fieldClickedData.possibleActions.map((action, index) => {
                                    return (
                                        <button
                                            className="dropdownButton flex spaceBetween"
                                            onClick={() => filterActionClicked(action)}
                                            style={index + 1 == fieldClickedData.possibleActions.length ? { border: 'none' } : {}}
                                        >
                                            {getFilterBehavioursLabel(action, fieldClickedData.value)}
                                            {fieldClickedData.selectedAction == action && <BaeSvg iconName="checkmark" height='0.7em' width="0.7em" color={'secondary'} />}
                                        </button>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className="middlePageConvoButtonsContainer">
                        <button onClick={() => removeField()} className="cleanButton middlePageConvoButtonRed">{fieldClickedData.value == '' ? 'Cancel' : 'Remove'}</button>
                        <button onClick={() => setNewFieldValue()} className="cleanButton middlePageConvoButtonAccent">Apply</button>
                    </div>
                </div>
            </>
        );
    };

    const activeFieldsList = searchFilterData.map(field => {
        if (field.value != '' && field.key != 'label') {
            const imageSrc = getImage(field.key);
            return (
                <div className="flex vertical" style={{ width: '95%' }}>
                    <span className="infoSearchLabel">{field.label}</span>
                    <button style={{ backgroundColor: 'var(--text)', color: 'var(--base)' }} className="infoSearchButton" onClick={() => fieldClicked(field)}>
                        <div className="flex" style={{ gap: '10px' }}>
                            {imageSrc && <img style={{ maxWidth: '20px', maxHeight: '20px', marginRight: '15px', minWidth: '20px', objectFit: 'contain' }} src={imageSrc} />}
                            {field.value}
                        </div>
                        <span style={{ fontWeight: 'bold', color: 'var(--accent)', fontSize: '0.8em' }}>Edit</span>
                    </button>
                </div>
            );
        }
    });

    const renderActiveInfo = () => {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '15px' }}>
                <span style={{ fontSize: '1.4em', color: 'var(--secondary)', margin: '20px 0', textAlign: 'center', width: '90%' }}>Want to find people who have a resemblance to a particular face? Click the button and choose or capture an image of the desired face.</span>
                <input ref={fileInputRef} style={{ display: 'none' }} type="file" accept="image/*" onChange={handleImageSelect} />
                <button onClick={() => fileInputRef.current.click()}>
                    <BaeSvg iconName="faceScan" color="magicashFlicker" height='60px' width="60px" />
                </button>
                <span style={{ color: 'var(--secondary)', fontSize: '2em', margin: '20px 0', fontWeight: 'bold' }}>OR</span>
                <span style={{ fontSize: '1.4em', color: 'var(--secondary)', margin: '20px 0', textAlign: 'center', width: '90%' }}>Add filters you would like the person you are searching for to have</span>
                {activeFieldsList}
            </div>
        );
    };

    const inactiveInfoList = searchFilterData.map(field => {
        if (field.key == 'label') {
            return (
                <span style={{ fontSize: '1.4em', fontWeight: 'bold', marginTop: '20px' }}>
                    {field.value}
                </span>
            );
        } else {
            const imageSrc = getImage(field.key);
            return (
                <div className="flex vertical" style={{ width: '95%' }}>
                    <span className="infoSearchLabel">{field.label}</span>
                    <button className="infoSearchButton" onClick={() => fieldClicked(field)}>
                        <div className="flex" style={{ gap: '10px' }}>
                            {imageSrc && <img style={{ maxWidth: '20px', maxHeight: '20px', marginRight: '15px', minWidth: '20px', objectFit: 'contain' }} src={imageSrc} />}
                            Add this filter
                        </div>
                        <BaeSvg iconName="plus" color="gray" height="20px" width="20px" />
                    </button>
                </div>
            );
        }
    });

    const renderInactiveInfo = () => {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '15px' }}>
                {inactiveInfoList}
            </div>
        );
    };

    const renderFields = () => {
        return (
            <>
                {hasActiveFields && renderActiveInfo()}
                {renderInactiveInfo()}
            </>
        );
    };

    const renderContent = () => {
        return (
            <div className='fullScreen removeScrollBar modalContainer'>
                <div style={{ minHeight: '115px' }}></div>
                {renderFields()}
                <ModalHeader title={'ADVANCE SEARCH'} actionName={'Search'} actionActive={hasActiveFields} actionFunction={search} />
            </div >
        );
    };

    return (
        <Routes>
            <Route path='/*' element={renderContent()} />
            {fieldClickedData && <Route path='/editField' element={renderEditField()} />}
        </Routes>
    );
};

const mapStateToProps = state => {
    return ({
        searchFilterData: state.searchFilterData
    });
};

export default connect(mapStateToProps, { setSearchFilterData })(InfoSearch);