import React, { useEffect, useState } from "react";

import { connect } from 'react-redux';
import { Routes, Route } from 'react-router-dom';
import baesappApi from "../../apis/baesappApi";

import getLocalToObject from "../../hooks/getLocalToObject";

import arrowRight from '../../assets/arrowRight.png';
import ruler from '../../assets/ruler.png';
import mail from '../../assets/mail.png';
import sellphone from '../../assets/sellphone.png';
import rings from '../../assets/rings.png';
import tie from '../../assets/tie.png';
import heartBeat from '../../assets/heartBeat.png';
import pill from '../../assets/pill.png';
import sadFace from '../../assets/sadFace.png';
import plusMinus from '../../assets/plusMinus.png';
import drop from '../../assets/drop.png';
import emergencyPhone from '../../assets/emergencyPhone.png';
import weight from '../../assets/weight.png';
import person from '../../assets/person.png';
import comb from '../../assets/comb.png';
import string from '../../assets/string.png';
import eye from '../../assets/eye.png';
import coloPalete from '../../assets/coloPalete.png';
import glove from '../../assets/glove.png';
import fox from '../../assets/fox.png';
import music from '../../assets/music.png';
import movie from '../../assets/movie.png';
import tv from '../../assets/tv.png';
import book from '../../assets/book.png';
import editIcon from '../../assets/editIcon.png';
import editIconDark from '../../assets/editIconDark.png';
import cake from "../../assets/cake.png";
import briefcase from "../../assets/briefcase.png";
import gender from "../../assets/gender.png";
import hobbies from "../../assets/hobbies.png";
import languages from "../../assets/languages.png";
import education from "../../assets/education.png";
import degree from "../../assets/degree.png";
import skills from "../../assets/skills.png";

const VisitInfo = ({
    visitMember
}) => {

    const [infoArray, setInfoArray] = useState([]);

    useEffect(() => {
        baesappApi.post('/getInfo', { _id: visitMember.memberEssentials._id })
            .then(res => {
                updateList(res.data);
            })
            .catch(err => {
                return err;
            });
    }, []);

    const getLabel = (field) => {
        switch (field) {
            case 'favouriteColors':
                return 'Favorite colors';
            case 'favouriteAnimals':
                return 'Favorite animals';
            case 'favouriteMusicGenres':
                return 'Favorite music genres';
            case 'favouriteMovies':
                return 'Favorite movies';
            case 'favouriteTVShows':
                return 'Favorite TV shows';
            case 'personalityTraits':
                return 'Personality traits';
            case 'favouriteBooks':
                return 'Favorite books';
            case 'favouriteAuthors':
                return 'Favorite authors';
            case 'favouriteHobbies':
                return 'Favorite hobbies';
            case 'phone':
                return 'Phone';
            case 'email':
                return 'Email';
            case 'addressPlaceId':
                return 'Address Place ID';
            case 'address1':
                return 'Address 1';
            case 'address2':
                return 'Address 2';
            case 'city':
                return 'City';
            case 'provinceState':
                return 'Province/State';
            case 'country':
                return 'Country';
            case 'zip':
                return 'ZIP/Postal Code';
            case 'maritalStatus':
                return 'Marital status';
            case 'employmentStatus':
                return 'Employment status';
            case 'emergencyContacts':
                return 'Emergency contacts';
            case 'bloodType':
                return 'Blood type';
            case 'allergies':
                return 'Allergies';
            case 'jobSkills':
                return 'Job skills';
            case 'occupation':
                return 'Occupation';
            case 'highestEducationLevels':
                return 'Highest education levels';
            case 'degrees':
                return 'Degrees';
            case 'languagesSpoken':
                return 'Languages spoken';
            case 'medications':
                return 'Medications';
            case 'medicalConditions':
                return 'Medical conditions';
            case 'height':
                return 'Height';
            case 'weight':
                return 'Weight';
            case 'bodyType':
                return 'Body type';
            case 'hairColor':
                return 'Hair color';
            case 'hairType':
                return 'Hair type';
            case 'eyeColor':
                return 'Eye color';
            case 'skinTone':
                return 'Skin tone';
            case 'dexterity':
                return 'Dexterity';
            default:
                return 'Invalid field';
        }
    };

    const updateList = (info) => {
        let tempMyInfoArray = [];
        for (const key in info) {
            if (info[key].value) {
                if (Array.isArray(info[key].value)) {
                    if (info[key].value.length) {
                        tempMyInfoArray.push({
                            ...info[key],
                            key
                        });
                    }
                } else {
                    tempMyInfoArray.push({
                        ...info[key],
                        key
                    });
                }
            }
        };
        setInfoArray(tempMyInfoArray);
        return tempMyInfoArray;
    };
    

    const getImage = (fieldName) => {
        switch (fieldName) {
            case 'Add my Email': return mail;
            case 'Add my Phone Number': return sellphone;
            case 'Marital status': return rings;
            case 'Employment status': return tie;
            case 'Blood type': return drop;
            case 'Rh Factor': return plusMinus;
            case 'Allergies': return sadFace;
            case 'Current Medications': return pill;
            case 'Medical Conditions': return heartBeat;
            case 'Emergency contacts': return emergencyPhone;
            case 'Add my Height': return ruler;
            case 'Add my Weight': return weight;
            case 'Body type': return person;
            case 'Hair color': return comb;
            case 'Hair type': return string;
            case 'Eye color': return eye;
            case 'Skin tone': return coloPalete;
            case 'Dexterity': return glove;
            case 'Color': return coloPalete;
            case 'Animal': return fox;
            case 'Music genre': return music;
            case 'Movie': return movie;
            case 'TV show': return tv;
            case 'Book': return book;
            case 'DOB': return cake;
            case 'SEX': return gender;
            case 'Hobby': return hobbies;
            case 'Highest education levels': return education;
            case 'Degrees': return degree;
            case 'Languages spoken': return languages;
            case 'Job skills': return skills;
            case 'Occupation': return briefcase;
            case 'First Name':
            case 'Last Name':
            case 'Address 1':
            case 'Address 2':
            case 'Account Email':
            case 'Preferred Name':
            case 'City':
            case 'Province / State':
            case 'ZIP':
            case 'Country':
            case 'Add my Personality traits':
                {
                    return false;
                }
            default: return person;
        }
    }

    const infoList = infoArray.map(info => {
        return (
            <div className='flex vertical' style={{ marginLeft: '20px' }}>
                <div className='flex fieldEditHeader'>
                    <div className='flex'>
                        <img style={{ maxWidth: '20px', maxHeight: '20px', marginRight: '15px', minWidth: '20px', objectFit: 'contain' }} src={''} />
                        <span style={{ fontWeight: 'bold', fontSize: '1.2em' }}>{getLabel(info.key)}</span>
                    </div>
                </div>
                <div>
                    
                </div>
                <div className='infoLineSeparator' />
            </div>
        );
    });

    return (
        <div className="removeScrollBar fullScreen flex vertical" style={{ maxWidth: '500px' }}>
            <div style={{ height: '50px' }} />
            {infoList}
            <div style={{ height: '50px' }} />
        </div>
    );
};

const mapStateToProps = state => {
    return ({
        visitMember: state.visitMember
    });
};

export default connect(mapStateToProps)(VisitInfo);