import React from 'react';

function Calendar({
    setSelectedDate
}) {
    const today = new Date();
    const currentYear = today.getFullYear();
    const currentMonth = today.getMonth();
    const currentDate = today.getDate();

    // Calculate the first day of the month
    const firstDayOfMonth = new Date(currentYear, currentMonth, 1);

    // Find the first Sunday before or on the first day of the month
    const startDay = new Date(
        firstDayOfMonth.setDate(firstDayOfMonth.getDate() - firstDayOfMonth.getDay())
    );

    function isInCurrentMonth(date) {
        const currentDate = new Date(); // Gets today's date
        const currentMonth = currentDate.getMonth(); // Current month (0-11)
        const currentYear = currentDate.getFullYear(); // Current year
    
        const inputDate = new Date(date); // Converts input to a Date object
        const inputMonth = inputDate.getMonth(); // Month of the input date
        const inputYear = inputDate.getFullYear(); // Year of the input date
    
        // Check if the year and the month match
        return inputMonth === currentMonth && inputYear === currentYear;
    }

    // Calculate the last day of the month
    const lastDayOfMonth = new Date(currentYear, currentMonth + 1, 0);

    // Calculate the last day to display (end of week containing last day of month)
    const endDay = new Date(
        lastDayOfMonth.setDate(lastDayOfMonth.getDate() + (6 - lastDayOfMonth.getDay()))
    );

    // Prepare days array
    const days = [];
    for (let d = new Date(startDay); d <= endDay; d.setDate(d.getDate() + 1)) {
        days.push({
            label: d.getDate().toString(),
            date: new Date(d)
        });
    }

    // Number of weeks to display
    const weeks = Math.ceil(days.length / 7);

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', color: 'var(--text)' }}>
            <div style={{ display: 'flex' }}>
                {['S', 'M', 'T', 'W', 'T', 'F', 'S'].map(day => (
                    <div key={day} style={{ width: '30px', textAlign: 'center', fontWeight: 'bold', margin: '3px' }}>
                        {day}
                    </div>
                ))}
            </div>
            {[...Array(weeks)].map((_, weekIndex) => (
                <div key={weekIndex} style={{ display: 'flex' }}>
                    {days.slice(weekIndex * 7, (weekIndex + 1) * 7).map((day, index) => (
                        <div
                            onClick={() => setSelectedDate(day.date)}
                            key={index}
                            style={{
                                width: '30px',
                                height: '30px',
                                lineHeight: '30px',
                                textAlign: 'center',
                                margin: '3px',
                                backgroundColor: day.date.getDate() === currentDate && day.date.getMonth() === currentMonth ? 'var(--text)' : 'none',
                                color: day.date.getDate() === currentDate && day.date.getMonth() === currentMonth ? 'var(--base)' : 'var(--text)',
                                fontWeight: 'bold',
                                borderRadius: '5px',
                                opacity: isInCurrentMonth(day.date) ? 1 : 0.5
                            }}
                        >
                            {day.label}
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
}

export default Calendar;
