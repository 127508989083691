import React, { useEffect, useState } from 'react';
import BaeSvg from './BaeSvg';

const AudioRecorder = ({
    base64,
    setBase64,
    recordable = true
}) => {
    const [audioStream, setAudioStream] = useState(null);
    const [recorder, setRecorder] = useState(null);
    const [isRecording, setIsRecording] = useState(false);
    const [recordedChunks, setRecordedChunks] = useState([]);
    const [duration, setDuration] = useState(0);
    const [countTimeout, setCountTimeout] = useState(null);
    const [volume, setVolume] = useState(0);
    const [volumeInterval, setVolumeInterval] = useState(null);
    const [prevAve, setPrevAve] = useState(0);

    const count = () => {
        setCountTimeout(setTimeout(() => {
            setDuration(prevDuration => prevDuration + 1);
            count();
        }, 100));
    };

    const startRecording = async () => {
        setBase64(false);
        setDuration(0);
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            const audioContext = new (window.AudioContext || window.webkitAudioContext)();
            const source = audioContext.createMediaStreamSource(stream);
            const analyser = audioContext.createAnalyser();
            source.connect(analyser);
            const mediaRecorder = new MediaRecorder(stream);

            mediaRecorder.addEventListener('dataavailable', (event) => {
                if (event.data.size > 0) {
                    setRecordedChunks((prevChunks) => [...prevChunks, event.data]);
                }
            });

            setAudioStream(stream);
            setRecorder(mediaRecorder);
            setIsRecording(true);

            const dataArray = new Uint8Array(analyser.frequencyBinCount);

            const updateVolume = () => {
                analyser.getByteFrequencyData(dataArray);
                const average = Array.from(dataArray).reduce((a, b) => a + b, 0) / dataArray.length;
                const smoothingFactor = 0.2;
                const smoothedVolume = prevAve * smoothingFactor + average * (1 - smoothingFactor);
                setPrevAve(average);
                setVolume((smoothedVolume * 5) + 15);
            };

            setVolumeInterval(setInterval(updateVolume, 200));
            count();
            mediaRecorder.start();
        } catch (error) {
            console.error('Error accessing microphone:', error);
        }
    };

    useEffect(() => {
        console.log(volume);
    }, [volume]);

    function formatTime() {
        const seconds = Math.floor(duration / 10);
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;

        const formattedMinutes = String(minutes).padStart(2, '0');
        const formattedSeconds = String(remainingSeconds).padStart(2, '0');

        return `${formattedMinutes}:${formattedSeconds}`;
    }

    useEffect(() => {
        return () => {
            audioStream.getTracks().forEach(track => track.stop());
        }
    }, []);

    useEffect(() => {
        if (recordedChunks.length) {
            clearTimeout(countTimeout);
            const blob = new Blob(recordedChunks, { type: 'audio/wav' });
            console.log(blob);
            const reader = new FileReader();
            reader.onloadend = () => {
                // const base64String = reader.result.split(',')[1];
                setBase64(reader.result);
                setRecordedChunks([]);
            };
            reader.readAsDataURL(blob);
        };
    }, [recordedChunks]);

    const pauseRecording = () => {
        if (recorder && recorder.state === 'recording') {
            clearTimeout(countTimeout);
            recorder.pause();
            setIsRecording(false);
        }
    };

    const resumeRecording = () => {
        if (recorder && recorder.state === 'paused') {
            count();
            recorder.resume();
            setIsRecording(true);
        }
    };

    const stopRecording = () => {
        if (recorder && recorder.state !== 'inactive') {
            clearTimeout(countTimeout);
            clearInterval(volumeInterval);
            recorder.stop();
            audioStream.getTracks().forEach(track => track.stop());
            setIsRecording(false);
            setAudioStream(null);
        }
    };

    const StartButton = () => {
        return (
            <button onClick={startRecording} className='audioRecorderButton'>
                <div style={{
                    height: '35%',
                    width: '35%',
                    borderRadius: '50%',
                    backgroundColor: 'var(--error)'
                }}>
                </div>
            </button>
        );
    };
    const StopButton = () => {
        return (
            <button onClick={stopRecording} className='audioRecorderButton'>
                <div style={{
                    height: '35%',
                    width: '35%',
                    borderRadius: '8%',
                    backgroundColor: 'var(--text)'
                }}>
                </div>
            </button>
        );
    };
    const PauseButton = () => {
        return (
            <button onClick={pauseRecording} className='audioRecorderButton'>
                <div
                    className='flex spaceBetween'
                    style={{
                        height: '35%',
                        width: '35%'
                    }}>
                    <div style={{
                        height: '100%',
                        width: '33%',
                        backgroundColor: 'var(--text)'
                    }} />
                    <div style={{
                        height: '100%',
                        width: '33%',
                        backgroundColor: 'var(--text)'
                    }} />
                </div>
            </button>
        );
    };
    const ResumeButton = () => {
        return (
            <button className='audioRecorderButton' onClick={resumeRecording}>
                <BaeSvg iconName='resumeButton' color='text' />
            </button>
        );
    }
    const DeleteButton = () => {
        return (
            <button className='audioRecorderButton' onClick={() => {
                setBase64(false);
                setDuration(0);
                setAudioStream(null);
                setRecorder(null);
                setIsRecording(false);
            }}>
                <BaeSvg iconName='bin' color='red' />
            </button>
        );
    }

    const getContentColor = () => {
        if (isRecording) {
            return 'bubbleFlicker';
        } else {
            if (base64) {
                return 'accent'
            } else {
                if (recorder?.state == 'paused') {
                    return 'red'
                } else {
                    return 'secondary';
                }
            }
        }
    }

    return (
        <div className='flex vertical alignCenter' style={{
            width: '100vw',
            maxWidth: '500px',
            position: 'fixed',
            bottom: '0',
            height: '50%',
            justifyContent: 'flex-end'
        }}>
            <div style={{
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                <BaeSvg iconName='audio' height={volume + 'px'} width='100vw' style={{ maxWidth: '200px', transitionDuration: '200ms' }} color={getContentColor()} />
            </div>
            {recordable &&
                <>
                    <span
                        style={{
                            marginTop: '20px',
                            fontSize: '1.4em',
                            fontWeight: 'bold',
                            color: 'var(--text)'
                        }}>{formatTime()}</span>
                    <div style={{ marginTop: '20px ', fontSize: '2em', marginBottom: '20px', display: 'flex', gap: '20px' }}>
                        {!isRecording ? base64 ? DeleteButton() : recorder?.state !== 'paused' && StartButton() : null}
                        {isRecording && PauseButton()}
                        {recorder && recorder.state === 'paused' && ResumeButton()}
                        {isRecording && StopButton()}
                    </div>
                </>
            }
            <div className='flex vertical spaceBetween' stlye={{ width: '100%', marginTop: '100px' }}>
                {base64 && (
                    <audio controls style={{ width: '95%', marginBottom: '-80px', marginTop: '80px', zIndex: '1', alignSelf: 'center' }}>
                        <source src={base64} type="audio/mp3" />
                        <source src={base64} type="audio/wav" />
                        Your browser does not support the audio element.
                    </audio>
                )}
                <BaeSvg iconName='bottomBubble' width='100vw' style={{ maxWidth: '500px' }} height='fit-content' color={getContentColor()} />
            </div>
        </div>
    );
};

export default AudioRecorder;
