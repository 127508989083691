import decryptMessage from "./decryptMessage";
import setLocalObject from "./setLocalObject";
import baesappApi from "../apis/baesappApi";
import getLocalToObject from "./getLocalToObject";

const setNewChats = (newChats) => {
    const clientChats = getLocalToObject('chats');
    for(const key in newChats){
        let newChat = newChats[key];
        let decryptedMessages = [];
        newChat.conversation.messages.map(message => {
            let newMessage = message;
            console.log(newMessage.text);
            console.log(key);
            newMessage.text = decryptMessage(newMessage.text, key);
            if(message.replying){
                newMessage.replying.messageText = decryptMessage(newMessage.replying.messageText, key);
            }
            decryptedMessages.push(newMessage);
        });
        newChat.conversation.messages = decryptedMessages;
        clientChats[key] = newChat;
    };
    setLocalObject('chats', clientChats);
};

export default setNewChats;