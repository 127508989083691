import React, { useState } from "react";
import '../../styles/App.css';
import CryptoJS from 'crypto-js';
import sha from 'js-sha512';
import baesappText from '../../assets/baesappText.png';
import baesappTextDark from '../../assets/baesappTextDark.png';
import baesappApi from "../../apis/baesappApi";
import spinner from "../../assets/spinner.png";
import { connect } from 'react-redux';
import { setAuthenticated, setMyData } from "../../redux/actions";
import { Link, useNavigate } from 'react-router-dom';
import ConstructiveButton from "../../dumbComponents/Authentication/ConstructiveButton";
import syncDbSs from '../../hooks/syncDbSs';
import baesappIcon from '../../assets/baesappIcon.svg';
import hideEye from '../../assets/hideEye.svg';
import BaeSvg from "../../dumbComponents/BaeSvg";

const Login = ({
    setAuthenticated,
    setMyData,
    darkModeEnabled
}) => {

    const [username, setUsername] = useState('');
    const [passphrase, setPassphrase] = useState('');
    const [loading, setLoading] = useState(false);
    const [invalidCredentials, setInvalidCredentials] = useState(false);
    const [showPassphrase, setShowPassphrase] = useState(false);

    const navigate = useNavigate();

    const attemptLogin = async () => {
        if (username !== '' && passphrase.length > 17) {
            setLoading(true);
            setInvalidCredentials(false);
            await baesappApi.get('/login', {
                params: {
                    username,
                    passphrase
                }
            })
                .then(res => {
                    window.localStorage.setItem('ep', sha.sha512(passphrase));
                    if (res.data.chat.ss) {
                        window.localStorage.setItem('ss', CryptoJS.AES.decrypt(res.data.chat.ss, window.localStorage.getItem('ep')).toString(CryptoJS.enc.Utf8));
                    } else {
                        window.localStorage.setItem('ss', JSON.stringify({}));
                        console.log('hit');
                        syncDbSs();
                    };
                    setAuthenticated(true);
                    navigate('/home/chat');
                    setMyData(res.data);
                })
                .catch(err => {
                    setInvalidCredentials(true);
                    setLoading(false);
                })
        }
    };

    const renderLoginButton = () => {
        if (loading) {
            return (
                <ConstructiveButton buttonState="loading" text="Log in" />
            );
        } else {
            return (
                <ConstructiveButton buttonState="active" callback={attemptLogin} text="Log in" />
            );
        }
    };

    const renderPassphrase = () => {
        if (showPassphrase) {
            return <input className="loginInput" type="text" placeholder="Password" onKeyDown={e => { if (e.key === "Enter") { attemptLogin() } }} onChange={e => { setPassphrase(e.target.value) }} value={passphrase} />
        } else {
            return <input className="loginInput" type="password" placeholder="Password" onKeyDown={e => { if (e.key === "Enter") { attemptLogin() } }} security="false" onChange={e => { setPassphrase(e.target.value) }} value={passphrase} />
        }
    }

    const renderErrorMessage = () => {
        return <p className="secondaryText errorText">The username or password you have entered is incorrect.</p>

    };

    const toggleShowPassphrase = () => {
        setShowPassphrase(!showPassphrase);
    };

    return (
        <div className="container" style={{ backgroundColor: 'white', color: '#333' }}>
            <div className="flex vertical" style={{ width: '100%', maxWidth: '500px', alignItems: 'start', gap: '20px', padding: '20px', boxSizing: 'border-box' }}>
                <img src={baesappIcon} style={{ height: '50px' }}></img>
                <div className="flex vertical" style={{ lineHeight: '40px' }}>
                    <span style={{ fontSize: '40px' }}>Welcome</span>
                    <span style={{ fontSize: '40px' }}>to Baesapp</span>
                    <span style={{ lineHeight: '2em' }}>Please login to continue</span>
                </div>
                {invalidCredentials && renderErrorMessage()}
                <div style={{ width: '100%' }} className="flex vertical">
                    <span style={{ color: '#333' }}>Username</span>
                    <input className="loginInput" type="text" placeholder="Username" onChange={e => { setUsername(e.target.value) }} value={username} />
                    <span style={{ color: '#333' }}>Password</span>
                    {renderPassphrase()}
                    {
                        showPassphrase ?
                            <button style={{ marginTop: '-40px', marginRight: '10px', alignSelf: 'end', width: 'fit-content' }} onClick={toggleShowPassphrase}>
                                <BaeSvg iconName="eye" color="bubbleFlicker" width="1.6em" />
                            </button> :
                            <button style={{ marginTop: '-40px', marginRight: '10px', alignSelf: 'end', width: 'fit-content' }} onClick={toggleShowPassphrase}>
                                <BaeSvg iconName="closedEye" color="gray" width="1.6em" />
                            </button>
                    }
                    <a style={{ alignSelf: 'end', color: 'gray', margin: '5px 0 15px' }} className="linkText secondaryText" href="https://baesapp.com/authentication/resetPassword">Forgot password?</a>
                    {renderLoginButton()}
                    <button onClick={() => navigate('register')} className="constructiveButton" style={{ backgroundColor: 'white', border: '1px solid gray', color: '#333' }}>Sign Up</button>
                </div>
                <p style={{ alignSelf: 'center' }} className="secondaryText">By signing up, you agree to our <a className="linkText" href="https://google.com">Terms</a>,<br></br>
                    <a className="linkText" href="https://google.com">Data Policy</a> and <a className="linkText" href="https://google.com">Cookies Policy</a></p>
            </div>
        </div>
    );

    // return(
    //     <div className="container clamp">
    //         <div className="card loginCard">
    //             <img src={baesappIcon}></img>
    //             <span>Welcom</span>
    //             <span>to Baesapp</span>
    //             <span>Please login to continue</span>
    //             {renderErrorMessage()}
    //             <div className="clamp">
    //                 <input className="loginInput" type="text" placeholder="Username" onChange={e => {setUsername(e.target.value)}} value={username}/>
    //                 {renderPassphrase()}
    //                 <div className="showPassphraseContainer">
    //                     <input type="checkbox" onChange={toggleShowPassphrase} checked={showPassphrase}/>
    //                     <label className="secondaryText">Show passphrase</label>
    //                 </div>
    //             </div>
    //             {renderLoginButton()}
    //             <a className="linkText secondaryText" href="https://google.com">Forgot passphrase?</a>
    //         </div>
    //         <div className="card registerCard">
    //             <p className="secondaryText">Don't have an account?</p>
    //             <Link to="register">
    //                 <button className="button registerButton">Register</button>
    //             </Link>
    //             <p className="secondaryText">By registering, you agree to our <a className="linkText" href="https://google.com">Terms</a>,<br></br>
    //             <a className="linkText" href="https://google.com">Data Policy</a> and <a className="linkText" href="https://google.com">Cookies Policy</a></p>
    //         </div>
    //     </div>
    // );
};

const mapStateToProps = state => {
    return ({
        darkModeEnabled: state.darkModeEnabled
    });
};

export default connect(mapStateToProps, {
    setAuthenticated,
    setMyData
})(Login);
