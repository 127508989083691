const showNotificationBarReducer = (showNotificationBar = false, action) => {
    switch(action.type){
        case 'SHOW_NOTIFICATION_BAR_SET':
            return action.payload;
            case 'CLEAR_ALL':
                    return false;
        default: 
            return showNotificationBar;
    }
};

export default showNotificationBarReducer;
