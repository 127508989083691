import React, { useState } from "react";
import { connect } from 'react-redux';
import sha from 'js-sha512';
import CryptoJS from 'crypto-js';
import { useNavigate } from 'react-router-dom';
import BaeSvg from "../../../dumbComponents/BaeSvg";

import faceGuideIcon from '../../../assets/faceGuideIcon.png';
import baesappApi from "../../../apis/baesappApi";

import ProfileShotTaker from "../../../dumbComponents/Authentication/ProfileShotTaker";
import ConstructiveButton from "../../../dumbComponents/Authentication/ConstructiveButton";
import { setAuthenticated, setMyData } from "../../../redux/actions";
import syncDbSs from "../../../hooks/syncDbSs";

const InputSection = ({ label, placeholder, type, value, onChange }) => (
    <div style={{ width: '100%' }}>
        <span style={{ color: 'gray' }}>{label}</span>
        <input
            style={{ margin: '5px 0 20px 0' }}
            className="registerInput"
            onKeyDown={e => {
                if (e.key === 'Enter') {
                    // Handle Enter key press if needed
                }
            }}
            autoComplete={type}
            type={type}
            onChange={e => onChange(e.target.value)}
            placeholder={placeholder}
            value={value}
        />
    </div>
);

const Register = ({
    nationalId,
    registrationEmail,
    setAuthenticated,
    setMyData,
    setStep
}) => {

    const navigate = useNavigate();

    const [username, setUsername] = useState('');
    const [preferredName, setPreferredName] = useState('');
    const [passphrase, setPassphrase] = useState('');
    const [rePassphrase, setRePassphrase] = useState('');
    const [profileShotB64, setProfileShotB64] = useState(null);
    const [launchCamera, setLaunchCamera] = useState(false);
    const [showPassphrase, setShowPassphrase] = useState(false);
    const [showPassphraseHelp, setShowPassphraseHelp] = useState(false);
    const [loading, setLoading] = useState(false);
    const [tellThem, setTellThem] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const takeImage = () => {
        setLaunchCamera(true);
    };

    const config = {
        headers: {
            "Content-Type": "application/json",
            'X-National-Id-Auth-Token': nationalId.token
        },
        withCredentials: true
    };

    const attemptRegister = () => {

        setLoading(true);

        baesappApi.post('/register', {
            username,
            preferredName,
            passphrase,
            emailToken: registrationEmail.token,
            emailId: registrationEmail.emailId,
            profileShotB64
        }, config)
            .then(res => {
                window.localStorage.setItem('ep', sha.sha512(passphrase));
                if (res.data.chat.ss) {
                    window.localStorage.setItem('ss', CryptoJS.AES.decrypt(res.data.chat.ss, window.localStorage.getItem('ep')).toString(CryptoJS.enc.Utf8));
                } else {
                    window.localStorage.setItem('ss', JSON.stringify({}));
                    syncDbSs();
                };
                setMyData(res.data);
                setAuthenticated(true);
                setLoading(false);
                navigate('/home/chat');
            })
            .catch(err => {
                console.log(err);
                switch (err.response.data) {
                    case 'Too long username':
                        setErrorMessage('Your username cannot exceed 50 characters.');
                        break;
                    case 'Username taken':
                        setErrorMessage('We are sorry, this username is already taken.');
                        break;
                    case 'face':
                        setErrorMessage('We picked up a problem with your profile shot, please retake your profile shot.');
                        break;
                    default:
                        setErrorMessage('Something unexpected went wrong, please reload your browser.');
                }

                setLoading(false);
            })
    };

    const renderErrorMessage = () => {
        return (
            <p className="card errorText">
                {errorMessage}<br />
            </p>
        );
    }

    const toggleShowPassphrase = () => {
        setShowPassphrase(!showPassphrase);
    };

    const renderRegisterButton = () => {
        if (loading) return <ConstructiveButton style={{ width: '100%', marginTop: '20px' }} buttonState="loading" text="Register" />;
        return <ConstructiveButton style={{ width: '100%', marginTop: '20px' }} buttonState="active" text="Sign Up" callback={attemptRegister} />
    };


    const renderPassphraseStrength = () => {
        if (passphrase.length < 18) {
            return <p className="PassphraseStrength weak">{18 - passphrase.length + ' characters to go.'}</p>
        }
    };

    const renderPassphrase = () => {
        if (showPassphrase) {
            return (
                <>
                    <span style={{ color: 'gray' }}>Create a Password</span>
                    <input className="loginInput" type="text" placeholder="Password" onChange={e => { setPassphrase(e.target.value) }} value={passphrase} />
                    {showPassphrase ?
                        <button style={{ marginTop: '-40px', marginRight: '10px', alignSelf: 'end', width: 'fit-content' }} onClick={toggleShowPassphrase}>
                            <BaeSvg iconName="eye" color="bubbleFlicker" width="1.6em" />
                        </button> :
                        <button style={{ marginTop: '-40px', marginRight: '10px', alignSelf: 'end', width: 'fit-content' }} onClick={toggleShowPassphrase}>
                            <BaeSvg iconName="closedEye" color="gray" width="1.6em" />
                        </button>}
                    {renderPassphraseStrength()}
                </>
            )
        } else {
            return (
                <>
                    <span style={{ color: 'gray' }}>Create a Password</span>
                    <input className="loginInput" type="password" placeholder="Password" security="false" onChange={e => { setPassphrase(e.target.value) }} value={passphrase} />
                    {showPassphrase ?
                        <button style={{ marginTop: '-40px', marginRight: '10px', alignSelf: 'end', width: 'fit-content' }} onClick={toggleShowPassphrase}>
                            <BaeSvg iconName="eye" color="bubbleFlicker" width="1.6em" />
                        </button> :
                        <button style={{ marginTop: '-40px', marginRight: '10px', alignSelf: 'end', width: 'fit-content' }} onClick={toggleShowPassphrase}>
                            <BaeSvg iconName="closedEye" color="gray" width="1.6em" />
                        </button>}
                    {renderPassphraseStrength()}
                </>
            );
        }
    }

    const renderPassphraseHelpCard = () => {
        return (
            <div className="card PassphraseHelp">
                <h3 className="secondaryText">Your passphrase must:</h3>
                <p className="secondaryText">1. Be any sentence that you can remember.<strong>A big bannana coffee sounds good</strong></p>
                <p className="secondaryText">2. Not be easilly guessable.<strong>My super strong passphrase</strong></p>
                <p className="secondaryText">3. Contain 18 or more characters</p>
                <p className="secondaryText">4. May contain special characters<strong>(!@#$%^&*()_)</strong></p>
                <ConstructiveButton buttonState="active" text="Got it" callback={() => setShowPassphraseHelp(false)} />
            </div>
        );
    };

    const renderContent = () => {
        if (launchCamera) {
            return <ProfileShotTaker setLaunchCamera={setLaunchCamera} setProfileShotB64={setProfileShotB64} profileShotB64={profileShotB64} />;
        } else {
            return (
                <div className="container" style={{ justifyContent: 'start', alignSelf: 'center', width: '100vw', maxWidth: '500px', padding: '15px', boxSizing: 'border-box', alignItems: 'start' }}>
                    <button style={{ width: 'fit-content' }} onClick={() => setStep(2)}>
                        <BaeSvg iconName="chevronDown" color="text" height="50px" width="20px" style={{ transform: 'rotate(90deg)' }} />
                    </button>
                    <div className="flex vertical" style={{ gap: '10px', marginBottom: '20px' }}>
                        <span style={{ fontSize: '30px', fontWeight: 'bold' }}>{profileShotB64 ? "Final step!" : "Take a selfie!"}</span>
                        {!profileShotB64 && <span style={{ fontSize: '1.2em', color: 'gray', fontWeight: 'bold' }}>The face you use now must be used for future profile shot changes for authenticity. AI checks will be applied. Avoid using someone else's face or funny expressions. Feel free to smile!</span>}
                    </div>
                    {profileShotB64 && <img className="profileShotIcon" src={profileShotB64} />}
                    <ConstructiveButton style={{ marginBottom: '40px', width: '100%' }} buttonState="active" text={profileShotB64 ? "Retake Selfie" : "Take Selfie"} callback={() => setLaunchCamera(true)} />
                    {profileShotB64 &&
                        <>
                            {errorMessage && renderErrorMessage()}
                            <InputSection
                                label="Username"
                                type="text"
                                placeholder="New Username"
                                value={username}
                                onChange={setUsername}
                            />
                            <InputSection
                                label="What do people call you"
                                type="text"
                                placeholder="Preferred name"
                                value={preferredName}
                                onChange={setPreferredName}
                            />
                        </>
                    }
                    {profileShotB64 && renderPassphrase()}
                    {profileShotB64 && renderRegisterButton()}
                    {!profileShotB64 && <span className="authBottomHelper">Unsure about your profile iamge? <strong onClick={() => setTellThem(true)} style={{ color: 'var(--accent)', marginLeft: '5px' }}>Learn more</strong></span>}
                    {tellThem &&
                        <div className='authenticationInfo'>
                            <button style={{ width: 'fit-content' }} onClick={() => setTellThem(false)}>
                                <BaeSvg iconName="chevronDown" color="text" height="50px" width="20px" style={{ transform: 'rotate(90deg)' }} />
                            </button>
                            <span style={{ fontSize: '30px', fontWeight: 'bold' }}>The Significance of Clear Face Photos:</span>

                            <p style={{ fontSize: '1.2em', color: 'gray' }}>
                                <span style={{ color: 'var(--text)', fontWeight: 'bold' }}>Ensuring Genuine Interactions:</span><br />
                                A clear photo prevents deceptive profiles and fake accounts, fostering authenticity in your online interactions.
                            </p>

                            <p style={{ fontSize: '1.2em', color: 'gray' }}>
                                <span style={{ color: 'var(--text)', fontWeight: 'bold' }}>Fostering Trustworthiness:</span><br />
                                Your clear photo eliminates the possibility of catfishing, enhancing trust within our community for a more secure online environment.
                            </p>

                            <p style={{ fontSize: '1.2em', color: 'gray' }}>
                                <span style={{ color: 'var(--text)', fontWeight: 'bold' }}>Promoting Authenticity:</span><br />
                                Beyond preventing deception, a clear face photo contributes to an authentic digital space, enriching your experience as a valued member.
                            </p>

                            <p style={{ fontSize: '1.2em', color: 'gray' }}>
                                <span style={{ color: 'var(--text)', fontWeight: 'bold' }}>Need assistance? Contact our support team at{' '}
                                    <a href="mailto:support@baesapp.com?subject=Support%20Inquiry" style={{ color: 'var(--accent)', textDecoration: 'underline' }}>
                                        support@baesapp.com
                                    </a>.
                                </span>
                            </p>

                        </div>
                    }
                </div>
            );
        }
    };

    return (
        renderContent()
    );
};

const mapStateToProps = state => {
    return ({
        nationalId: state.nationalId,
        registrationEmail: state.registrationEmail
    });
};

export default connect(mapStateToProps, { setAuthenticated, setMyData })(Register);
