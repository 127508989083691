const transactionsListReducer = (transactionsList = [], action) => {
    switch(action.type){
        case 'TRANSACTIONS_LIST_SET':
            return action.payload;
            case 'CLEAR_ALL':
                    return [];
        default: 
            return transactionsList;
    }
};

export default transactionsListReducer;
