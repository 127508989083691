const visitChatFromMessageReducer = (visitChatFromMessage = false, action) => {
    switch(action.type){
        case 'SET_VISIT_CHAT_FROM_MESSAGE':
            return action.payload;
            case 'CLEAR_ALL':
                    return false;
        default: 
            return visitChatFromMessage;
    }
};

export default visitChatFromMessageReducer;
