import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import baesappApi from "../../apis/baesappApi";
import BaeSvg from "../../dumbComponents/BaeSvg";
import baesappIcon from "../../assets/baesappIcon.svg";
import ConstructiveButton from "../../dumbComponents/Authentication/ConstructiveButton";

const ResetPassword = () => {

    const [loading, setLoading] = useState(false);
    const [username, setUsername] = useState('');
    const [showPassphrase, setShowPassphrase] = useState(false);
    const [passphrase, setPassphrase] = useState('');
    const [emailVerified, setEmailVerified] = useState(false);
    const [emailSent, setEmailSent] = useState(false);
    const [resetSuccess, setResetSuccess] = useState(false);

    const navigate = useNavigate();

    const { search } = useLocation();
    const params = new URLSearchParams(search);

    let member_id = params.get('_id');
    let code = params.get('code');
    let usernameToReset = params.get('username');

    useEffect(() => {
        if (member_id && code) {
            setEmailVerified(true);
        };
    }, []);

    const request = () => {
        setLoading(true);
        baesappApi.post('/resetPassword/request', { username })
            .then(res => {
                setLoading(false);
                setEmailSent(res.data);
            })
            .catch(err => {
                console.log(err.message);
                setLoading(false);
            });
    };

    const attemptReset = () => {
        if (passphrase.length > 17) {
            setLoading(true);
            baesappApi.post('/resetPassword/reset', { member: member_id, code, newPassword: passphrase })
                .then(res => {
                    setLoading(false);
                    setResetSuccess(true);
                })
                .catch(err => {
                    console.log(err.message);
                    setLoading(false);
                });
        };
    };

    const renderResetButton = () => {
        if (loading) {
            return (
                <ConstructiveButton buttonState="loading" text="Reset Password" />
            );
        } else if (emailVerified) {
            return (
                <ConstructiveButton buttonState="active" callback={attemptReset} text="Reset Password" />
            );
        } else if (emailSent) {
            return (
                <ConstructiveButton buttonState="active" callback={request} text="Resend Email Link" />
            );
        } else {
            return (
                <ConstructiveButton buttonState="active" callback={request} text="Send Email Link" />
            );
        }
    };

    const renderPassphrase = () => {
        if (showPassphrase) {
            return <input className="loginInput" type="text" placeholder="Password" onKeyDown={e => { if (e.key === "Enter") { attemptReset() } }} onChange={e => { setPassphrase(e.target.value) }} value={passphrase} />
        } else {
            return <input className="loginInput" type="password" placeholder="Password" onKeyDown={e => { if (e.key === "Enter") { attemptReset() } }} security="false" onChange={e => { setPassphrase(e.target.value) }} value={passphrase} />
        }
    }

    const renderPassphraseStrength = () => {
        if ((passphrase.length < 18 && passphrase.length > 0)) {
            return <p className="PassphraseStrength weak">{18 - passphrase.length + ' characters to go.'}</p>
        }
    };

    return (
        <div className="container" style={{ backgroundColor: 'white', color: '#333' }}>
            <div className="flex vertical" style={{ width: '100%', maxWidth: '500px', height: '100%', alignItems: 'start', gap: '20px', padding: '20px', boxSizing: 'border-box' }}>
                <img src={baesappIcon} style={{ height: '50px' }}></img>
                {
                    resetSuccess ?
                        <>
                            <div className="flex vertical" style={{ lineHeight: '40px' }}>
                                <span style={{ fontSize: '40px' }}>Password</span>
                                <span style={{ fontSize: '40px' }}>Reset Complete</span>
                                <span style={{ lineHeight: '2em' }}>You can now login with your new password.</span>
                            </div>
                            <button onClick={() => navigate('/authentication')} className="constructiveButton" style={{ backgroundColor: 'white', border: '1px solid gray', color: '#333', padding: '0 50px' }}>Return To Login</button>
                        </> :
                        <>
                            <div className="flex vertical" style={{ lineHeight: '40px' }}>
                                <span style={{ fontSize: '40px' }}>Baesapp</span>
                                <span style={{ fontSize: '40px' }}>Password Reset</span>
                                <span style={{ lineHeight: '2em' }}>{emailVerified ? `Please enter a new password for ${usernameToReset}.` : "Fear not. We'll email you a link to reset your password."}</span>
                            </div>
                            <div style={{ width: '100%' }} className="flex vertical">
                                {!emailVerified &&
                                    <>
                                        <span style={{ color: '#333' }}>Username</span>
                                        <input className="loginInput" type="text" placeholder="Username" onChange={e => { setUsername(e.target.value) }} value={username} />
                                    </>
                                }
                                {emailVerified &&
                                    <>
                                        <span style={{ color: '#333' }}>Your new password must be at least 18 characters long</span>
                                        {renderPassphrase()}
                                        {passphrase.length > 0 && renderPassphraseStrength()}
                                        {
                                            showPassphrase ?
                                                <button style={{ marginTop: (passphrase.length < 18 && passphrase.length > 0) ? '-86px' : '-40px', marginBottom: "50px", marginRight: '10px', alignSelf: 'end', width: 'fit-content' }} onClick={() => setShowPassphrase(!showPassphrase)}>
                                                    <BaeSvg iconName="eye" color="bubbleFlicker" width="1.6em" />
                                                </button> :
                                                <button style={{ marginTop: (passphrase.length < 18 && passphrase.length > 0) ? '-86px' : '-40px', marginBottom: "50px", marginRight: '10px', alignSelf: 'end', width: 'fit-content' }} onClick={() => setShowPassphrase(!showPassphrase)}>
                                                    <BaeSvg iconName="closedEye" color="gray" width="1.6em" />
                                                </button>
                                        }
                                    </>
                                }
                                {emailSent &&
                                    <>
                                        <p>A password reset link has been sent to {emailSent}</p>
                                        <h3>Did not receive the email?</h3>
                                    </>
                                }
                                {renderResetButton()}
                                <button onClick={() => navigate('/authentication')} className="constructiveButton" style={{ backgroundColor: 'white', border: '1px solid gray', color: '#333' }}>Return To Login</button>
                            </div></>
                }
            </div>
        </div>
    );
};

export default ResetPassword;