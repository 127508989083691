const nationalIdReducer = (nationalId=false, action) => {
  switch(action.type){
    case 'NATIONAL_ID_REGISTRATION_COMPLETE':
      return action.payload;
      case 'CLEAR_ALL':
              return false;
    default:
      return nationalId;
  }
}

export default nationalIdReducer;
