const myOpenChatsReducer = (myOpenChats = [], action) => {
    switch (action.type) {
        case 'GOT_MY_DETAILS':
            return action.payload.openChats;
        case 'SET_MY_OPEN_CHATS':
            return action.payload;
        case 'CLEAR_ALL':
            return [];
        default:
            return myOpenChats;
    };
};

export default myOpenChatsReducer;
