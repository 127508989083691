const myInfoReducer = (myInfo=false, action) => {
    switch(action.type){
        case 'GOT_MY_DETAILS':
            if(!action.payload.info){
                return myInfo;
            };
            return action.payload.info;
        case 'NEW_MY_INFO_SET':
            return action.payload; 
            case 'CLEAR_ALL':
                    return false;
        default:
        return myInfo;
    };
  };
  
  export default myInfoReducer;
  