import React, { useEffect, useState } from "react";

import { connect } from 'react-redux';
import CryptoJS from 'crypto-js';
import { Routes, Route, useNavigate } from 'react-router-dom';

import backArrow from '../../../assets/backArrow.png';
import chevronAccent from '../../../assets/chevronAccent.png';

import baesappApi from '../../../apis/baesappApi';

import NumberPad from "../../../dumbComponents/NumberPad";
import ModalHeader from "../../../dumbComponents/Home/modalHeader";

const CreateMagicCash = ({
    magicCashNotes,
    setMagicCashNotes,
    magicCashNotesUnlocked
}) => {

    const navigate = useNavigate();

    const [maxAmountInCents, setMaxAmountInCents] = useState(25000);
    const [magicNoteKey, setMagicNoteKey] = useState('');

    const createMagicNote = (pin, setLoading) => {
        const ep = window.localStorage.getItem('ep');
        const epPassphrase = CryptoJS.AES.encrypt(JSON.stringify(magicNoteKey), ep).toString();
        baesappApi.post('/magicCash/create', {
            pin,
            passphrase: magicNoteKey,
            epPassphrase,
            maxAmountInCents
        })
            .then(res => {
                let newMagicCash = [...magicCashNotes];
                newMagicCash.push(res.data);
                if (magicCashNotesUnlocked) setMagicCashNotes(newMagicCash);
                navigate(-2);
            })
            .catch(err => {
                console.log(err);
                setLoading(false);
                window.alert('something went wrong')
            })
    };

    const numberFormat = Intl.NumberFormat(undefined, {
        style: "currency",
        currency: "ZAR",
    })


    return (
        <>
            <div className='fullScreen removeScrollBar modalContainer'>
                <div className='flex vertical'>
                    <div style={{ minHeight: '110px' }} />
                    <span className="maxValue">{numberFormat.format(maxAmountInCents / 100)}</span>
                    <span className="maxValueLabel">Slide to select the max value in ZAR</span>
                    <input className="maxAmountSlider" type="range" value={maxAmountInCents / 100} onChange={e => setMaxAmountInCents(e.target.value * 100)} min={1} max={500} />
                    <input
                        className="magicNoteInput"
                        type='text'
                        onChange={e => setMagicNoteKey(e.target.value)}
                        value={magicNoteKey}
                        placeholder='Magic cash key...'
                    />
                    {<span className={`magicNoteLengthProgress`}>{magicNoteKey.length > 15 ? magicNoteKey.length : `${magicNoteKey.length}/16`}</span>}
                </div>
                <ModalHeader title={'NEW MAGICASH'} actionName={'CREATE'} actionActive={magicNoteKey.length >= 16} actionFunction={() => navigate('confirmWithPin')} />
                <Routes>
                    {magicNoteKey.length >= 16 && <Route path='confirmWithPin' element={<NumberPad callback={createMagicNote} infoText="Wallet PIN" />} />}
                </Routes>
            </div >
        </>
    );
};

const mapStateToProps = state => {
    return ({
    });
};

export default connect(mapStateToProps, {})(CreateMagicCash);