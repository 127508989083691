import React, { useEffect, useState } from "react";

import { connect } from 'react-redux';

import { useNavigate, Route, Routes } from 'react-router-dom';

import addAccent from '../../../assets/addAccent.png';
import chevronAccent from '../../../assets/chevronAccent.png';
import spinner from '../../../assets/spinner.png';
import searchBoxIcon from '../../../assets/searchBoxIcon.png';
import searchBoxIconDark from '../../../assets/searchBoxIconDark.png';
import clearText from '../../../assets/clearText.png';
import clearTextDark from '../../../assets/clearTextDark.png';

import baesappApi from '../../../apis/baesappApi';

import { setNewContactRelations } from '../../../redux/actions';

import MemberModal from '../../../dumbComponents/MemberModal';

import syncDbSs from "../../../hooks/syncDbSs";
import addSs from "../../../hooks/addSs";
import syncChat from "../../../hooks/syncChat";
import setLocalObject from "../../../hooks/setLocalObject";
import ModalHeader from "../../../dumbComponents/Home/modalHeader";
import BaeSvg from "../../../dumbComponents/BaeSvg";

const Requests = ({
    darkModeEnabled,
    myChat,
    setNewContactRelations
}) => {

    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [displayedList, setDisplayedList] = useState([]);
    const [shouldShowCancel, setShouldShowCancel] = useState(false);
    const [searchInputElement, setSearchInputElement] = useState();
    const [segment, setSegment] = useState('Incoming');
    const [requestsList, setRequestsList] = useState([]);
    const [contactLoading, setContactLoading] = useState(false);
    const [confirmAction, setConfirmAction] = useState(false);
    const [totalUnreadMessages, setTotalUnreadMessages] = useState(0);

    const getSegmentMembers = (members) => {
        if (segment == 'Incoming') {
            return members.filter(member => myChat.requestsIncoming.includes(member._id));
        } else {
            return members.filter(member => myChat.requestsOutgoing.includes(member._id));
        }
    };

    useEffect(() => {
        setSearchInputElement(document.getElementById('searchInput'));
    }, []);

    useEffect(() => {
        if (myChat) {
            let tempTotalUnreadMessages = 0;
            myChat.chats.map(chat => {
                tempTotalUnreadMessages += chat.newMessages.length;
            });
            setTotalUnreadMessages(tempTotalUnreadMessages);
        };
    }, [myChat.chats]);

    const handleRequest = (url, payload, memberId) => {
        syncChat(myChat, setNewContactRelations);
        let data = {};
        if (url !== '/handleContactRequest') {
            data._id = payload;
        } else {
            data = payload;
        };
        baesappApi.post(url, data)
            .then(res => {
                switch (url) {
                    case '/requestContact':
                        if (res.data.ss) {
                            addSs(memberId, res.data.ss);
                        };
                        break;
                    case '/requestContact/cancel':
                        //nothing should happen so far
                        break;
                    case '/handleContactRequest':
                        if (res.data.ss) {
                            addSs(memberId, res.data.ss);
                        };
                        break;
                };
                syncDbSs();
                setNewContactRelations(res.data);
                setContactLoading(false);
                setConfirmAction(false);
            })
            .catch(err => {
                console.log(err);
                setContactLoading(false);
            });
    }

    useEffect(() => {
        if (searchText == '') {
            setDisplayedList(getSegmentMembers(requestsList));
        } else {
            const filteredMembers = requestsList.filter(member => member.memberId.username.toUpperCase().includes(searchText.toUpperCase()));
            setDisplayedList(getSegmentMembers(filteredMembers));
        };
    }, [segment]);

    useEffect(() => {
        setLoading(true);
        if (myChat) {
            baesappApi.post('/getMemberIds', { Ids: [...myChat.requestsIncoming, ...myChat.requestsOutgoing] })
                .then(res => {
                    setLoading(false);
                    setRequestsList(res.data);
                    setDisplayedList(getSegmentMembers(res.data));
                })
                .catch(err => {
                    console.log(err);
                    setLoading(false);
                })
        }
    }, [myChat.requestsIncoming, myChat.requestsOutgoing]);

    const handleOnBlur = (blurredCause) => {
        if (blurredCause == 'cancel') {
            setSearchText('');
            setShouldShowCancel(false);
        } else if (blurredCause == 'clear') {
            setSearchText('');
            searchInputElement.focus();
        }
    };

    useEffect(() => {
        if (searchText == '') {
            setDisplayedList(getSegmentMembers(requestsList));
        } else {
            const filteredMembers = requestsList.filter(member => member.memberId.username.toUpperCase().includes(searchText.toUpperCase()));
            setDisplayedList(getSegmentMembers(filteredMembers));
        };
    }, [searchText]);

    const memberProfileShotClicked = (member) => {
        let memberObj = member.memberId;
        memberObj._id = member._id;
        setLocalObject('memberModal', memberObj);
        navigate('memberModal');
    };

    const renderRequestMembersList = displayedList.map((member, index) => {
        return (
            <div key={member._id} className="flex chatCard" >
                <div className="flex horizontal chatCardLeft">
                    <img onClick={() => memberProfileShotClicked(member)} className="memberBarProfileShot" src={`https://baesapp.com/media/${member.memberId.profileShot}`} />
                    <div onClick={() => setConfirmAction(member)} className="flex vertical chatCardMainContent">
                        <span className="chatCardUsername">{member.memberId.username}</span>
                        <div className="chatCardContentContainer">
                            <span className="chatCardContent">{segment == 'Incoming' ? 'Tap to accept or decline' : 'Tap to cancel request'}</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    });

    const renderContent = () => {
        if (loading) {
            return (<BaeSvg iconName='sideHeart' color='loaderFlicker absoluteHorizontalCenter' height='20px' />);
        } else if (displayedList.length == 0) {
            return (
                <div className="flex spaceAround" style={{ width: '100vw', textAlign: 'center', marginTop: '50px' }}>
                    <span className="centerText noResults">No Results</span>
                </div>
            );
        } else {
            return renderRequestMembersList;
        };

    };

    return (
        <div className="fullScreen removeScrollBar modalContainer">
            <div className="flex vertical">
                <div style={{ minHeight: '115px', width: '50px' }} />
                <div className="segmentContainer flex spaceAround">
                    <button onClick={() => setSegment('Incoming')} style={{ backgroundColor: `${segment == 'Incoming' ? 'var(--text)' : ''}`, color: `${segment == 'Incoming' ? 'var(--base)' : 'var(--secondary)'}`, borderColor: `${segment == 'Incoming' ? '' : 'var(--secondary)'}` }} className="segmentButton">
                        <span>Incoming</span>
                        <strong style={{ fontSize: '1.5em' }}>{myChat.requestsIncoming.length}</strong>
                    </button>
                    <button onClick={() => setSegment('Outgoing')} style={{ backgroundColor: `${segment != 'Incoming' ? 'var(--text)' : ''}`, color: `${segment != 'Incoming' ? 'var(--base)' : 'var(--secondary)'}`, borderColor: `${segment != 'Incoming' ? '' : 'var(--secondary)'}` }} className="segmentButton">
                        <span>Outgoing</span>
                        <strong style={{ fontSize: '1.5em' }}>{myChat.requestsOutgoing.length}</strong>
                    </button>
                </div>
                <div className="flex spaceAround">
                    <div className="searchBarContainer" style={{ width: '100%' }}>
                        <div className="searchBar" style={shouldShowCancel ? { marginLeft: '10px' } : {}}>
                            <img className="searchBarIcon" src={darkModeEnabled ? searchBoxIcon : searchBoxIconDark} />
                            <input
                                onFocus={() => setShouldShowCancel(true)}
                                id="searchInput"
                                placeholder="Search"
                                value={searchText}
                                onChange={e => setSearchText(e.target.value)}
                                className={`cleanInput searchBarInput`} />
                            {searchText != '' && <img onClick={() => handleOnBlur('clear')} className="searchBarClear" src={darkModeEnabled ? clearTextDark : clearText} />}
                        </div>
                        {shouldShowCancel && <span onClick={() => handleOnBlur('cancel')} className="searchBarCancelText">
                            Cancel
                        </span>}
                    </div>
                </div>
                {renderContent()}
                <ModalHeader title={'REQUESTS'} actionName={'New'} actionActive={true} actionFunction={() => navigate('/home/search')} />
                {confirmAction &&
                    <>
                        <div onClick={() => setConfirmAction(false)} className="middlePageConvoBackdrop" />
                        <div className="bottomSlideInCard flex vertical spaceAround alignCenter confirmationCard">
                            {segment == 'Incoming'
                                ?
                                <>
                                    <span><strong>{confirmAction.memberId.username}</strong> wants to be contacts</span>
                                    <div className="middlePageConvoButtonsContainer">
                                        <button onClick={() => handleRequest('/handleContactRequest', { _id: confirmAction._id, action: 'decline' }, confirmAction._id)} className="cleanButton middlePageConvoButtonRed">Decline</button>
                                        <button onClick={() => handleRequest('/handleContactRequest', { _id: confirmAction._id, action: 'accept' }, confirmAction._id)} className="cleanButton middlePageConvoButtonAccent">Accept</button>
                                    </div>
                                </>
                                :
                                <>
                                    <span>Cancel contact request to <strong>{confirmAction.memberId.username}</strong>?</span>
                                    <div className="middlePageConvoButtonsContainer">
                                        <button onClick={() => handleRequest('/requestContact/cancel', confirmAction._id, confirmAction._id)} className="cleanButton middlePageConvoButtonRed">Yes</button>
                                        <button onClick={() => setConfirmAction(false)} className="cleanButton middlePageConvoButtonAccent">No</button>
                                    </div>
                                </>
                            }
                        </div>
                    </>
                }
                <Routes>
                    <Route path="/memberModal/*" element={<MemberModal />} />
                </Routes>
            </div>
        </div>
    );
};

const mapStateToProps = state => {
    return ({
        myChat: state.myChat,
        darkModeEnabled: state.darkModeEnabled
    });
};

export default connect(mapStateToProps, {
    setNewContactRelations
})(Requests);
