import React, { useState, useEffect } from "react";

import { connect } from 'react-redux';
import getLocalToObject from '../../../hooks/getLocalToObject';
import CryptoJS from 'crypto-js';
import { Routes, Route, useNavigate } from 'react-router-dom';

import baesappApi from '../../../apis/baesappApi';
import Switch from "../../../dumbComponents/Switch";
import ModalHeader from "../../../dumbComponents/Home/modalHeader";
import MemberModal from "../../../dumbComponents/MemberModal";
import BaeSvg from "../../../dumbComponents/BaeSvg";
import setLocalObject from "../../../hooks/setLocalObject";
import { setMySearch, setVisitMember } from "../../../redux/actions";

const SearchToPay = ({
    mySearch,
    setMySearch,
    setVisitMember
}) => {

    const [contactsList, setContactsList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [displayedList, setDisplayedList] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [searchInputElement, setSearchInputElement] = useState(null);
    const [shouldShowCancel, setShouldShowCancel] = useState(false);
    const [doSearch, setDoSearch] = useState(null);
    const [historyListActive, setHistoryListActive] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        setSearchInputElement(document.getElementById('searchInput'));
        setLoading(true);
        baesappApi.get('/getMySearch')
            .then(res => {
                setMySearch(res.data);
                setDisplayedList(res.data.searchHistory);
                setHistoryListActive(true);
                setLoading(false);
            })
            .catch(err => {
                setLoading(false);
                console.log(err);
            })
    }, []);

    const stopSearch = () => {
        clearTimeout(doSearch);
    };

    useEffect(() => {
        if (!!searchText) {
            stopSearch();
            setDoSearch(setTimeout(() => {
                search();
            }, 350))
        }
    }, [searchText]);

    const search = () => {
        if (searchText !== '') {
            setLoading(true);
            baesappApi.post('/searchMembers/username', { username: searchText, lastItem: false })
                .then(res => {
                    setDisplayedList(res.data);
                    setHistoryListActive(false);
                    setLoading(false);
                })
                .catch(err => {
                    console.log(err);
                    setLoading(false);
                })
        } else {
            setDisplayedList(mySearch.searchHistory);
            setHistoryListActive(true);
        }
    };

    const handleOnBlur = (blurredCause) => {
        if (blurredCause == 'cancel') {
            setSearchText('');
            setShouldShowCancel(false);
        } else if (blurredCause == 'clear') {
            setSearchText('');
            searchInputElement.focus();
        }
    };

    const memberProfileShotClicked = (member) => {
        let memberObj = member.memberId;
        memberObj._id = member._id;
        setLocalObject('memberModal', memberObj);
        navigate('memberModal');
    };

    const visitMember = (memberId, _id) => {
        let member = { ...memberId, _id };
        delete member.lastMessage;
        setVisitMember({
            path: 'wallet',
            memberEssentials: member
        });
        navigate(`/visit/wallet/pay?_id=${_id}`);
    };

    const renderMembersList = displayedList.map((member, index) => {
        return (
            <>
                <div key={member._id} className="flex horizontal chatCard" >
                    <div className="flex horizontal chatCardLeft">
                        <img onClick={() => memberProfileShotClicked(member)} className="memberBarProfileShot" src={`https://baesapp.com/media/${member.memberId.profileShot}`} />
                        <div onClick={() => visitMember(member.memberId, member._id)} className="flex vertical chatCardMainContent">
                            <span className="chatCardUsername">{member.memberId.username}</span>
                            <div className="chatCardContentContainer">
                                <span className="chatCardContent">Tap to pay</span>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    });

    const renderContent = () => {
        if (loading) {
            return (<BaeSvg iconName='sideHeart' color='loaderFlicker absoluteHorizontalCenter' height='20px' />);
        } else {
            if (!displayedList.length) {
                return (
                    <span className="centerText noResults">No Results</span>
                );
            } else {
                return (
                    <>
                        {historyListActive && <div style={{ fontSize: '1.2em', fontWeight: 'bold', color: 'var(--text)', height: 'fit-content', padding: '10px 0' }} className={"memberBar flex alignCenter"} >Recent</div>}
                        {renderMembersList}
                    </>
                );
            }
        };

    };

    return (
        <>
            <div className='fullScreen removeScrollBar modalContainer'>
                <div className='flex vertical'>
                    <div style={{ minHeight: '110px', width: '50px' }} />
                    <div className="flex spaceAround">
                        <div className="searchBarContainer" style={{ width: '100%' }}>
                            <div className="searchBar" style={shouldShowCancel ? { marginLeft: '10px' } : {}}>
                                <BaeSvg height='1em' style={{ margin: '10px 5px 10px 10px' }} iconName='magnifyingGlass' color='gray' />
                                <input
                                    onFocus={() => setShouldShowCancel(true)}
                                    id="searchInput"
                                    placeholder="Search"
                                    value={searchText}
                                    onChange={e => setSearchText(e.target.value)}
                                    className={`cleanInput searchBarInput`} />
                                {searchText != '' &&
                                    <button className="flex" onClick={() => handleOnBlur('clear')}>
                                        <BaeSvg height='1.4em' iconName='circleX' color='gray' />
                                    </button>
                                }
                            </div>
                            {shouldShowCancel && <span onClick={() => handleOnBlur('cancel')} className="searchBarCancelText">
                                Cancel
                            </span>}
                        </div>
                    </div>
                    {
                        renderContent()
                    }
                    <ModalHeader title={'PAY MEMBER'} actionName={''} actionActive={false} actionFunction={() => { }} />
                </div>
            </div>
            <Routes>
                <Route path="/memberModal/" element={<MemberModal />} />
            </Routes>
        </>

    );
};

const mapStateToProps = state => {
    return ({
        myChat: state.myChat,
        mySearch: state.mySearch
    });
};

export default connect(mapStateToProps, { setMySearch, setVisitMember })(SearchToPay);
