import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import primaryX from '../assets/primaryX.png';


import { useNavigate, Routes, Route } from "react-router-dom";

import addSs from '../hooks/addSs';
import syncChat from '../hooks/syncChat';
import syncDbSs from '../hooks/syncDbSs';
import getUpdatedChats from '../hooks/getUpdatedChats';

import { setMediaFollowing, setNewContactRelations, hitChatsEventListener, setUrgentCall } from '../redux/actions';

import baesappApi from '../apis/baesappApi';

import BaeButton from './BaeButton';
import getLocalToObject from '../hooks/getLocalToObject';
import setLocal from '../hooks/setLocal';
import BaeSvg from './BaeSvg';
import ConstructiveButton from './Authentication/ConstructiveButton';
import AstheticButton from './AstheticButton';

const MemberModal = ({
    setMediaFollowing,
    myMedia,
    myChat,
    setNewContactRelations,
    hitChatsEventListener,
    darkModeEnabled,
    setUrgentCall,
    my_id,
    setShowVisitMemberModal = false
}) => {

    const memberModal = getLocalToObject('memberModal');

    const navigate = useNavigate();;

    const [followingLoading, setFollowingLoading] = useState(false);
    const [contactLoading, setContactLoading] = useState(false);
    const [totalFollowers, setTotalFollowers] = useState('-');
    const [showMoreInfo, setShowMoreInfo] = useState(false);
    const [slideBaseY, setSlideBaseY] = useState('false');
    const [slideYDistance, setSlideYDistance] = useState(76);
    const [confirmAction, setConfirmAction] = useState(false);

    useEffect(() => {
        //if(!isSupported) syncChat();
        if (memberModal._id == my_id) navigate(-1);
        baesappApi.post('/following/getTotal', { memberId: memberModal._id }).then(res => {
            setTotalFollowers(res.data.totalFollowers);
        })
            .catch(err => {
                console.log(err);
            })
    }, []);

    const visitMember = (path) => {
        console.log('hit')
        navigate(`/visit/${path}?_id=${memberModal._id}`);
        setShowVisitMemberModal && setShowVisitMemberModal(false);
    };

    const toggleFollowing = (followeeId) => {
        setFollowingLoading(true);
        baesappApi.post('/following/toggleFollowing', { followeeId })
            .then(res => {
                setMediaFollowing(followeeId, res.data);
                setFollowingLoading(false);
            })
            .catch(err => console.log(err))
    };

    const voiceCallClicked = (urgent) => {
        setUrgentCall(urgent);
        navigate('/voiceCall');
    };

    const handleTouchMove = (e) => {
        const currY = Math.trunc(e.touches[0].clientY);
        if (slideBaseY == 'false') {
            setSlideBaseY(currY);
        } else {
            let newSlideDistance = (currY - slideBaseY) + 76;
            if (newSlideDistance < 200 && newSlideDistance > 1) {
                setSlideYDistance(newSlideDistance);
            };
        }
    };
    const handleTouchEnd = () => {
        if (slideYDistance > 100) {
            setSlideYDistance(200);
        } else {
            setSlideBaseY('false');
            setSlideYDistance(76);
        };
    };
    const toggleSlideYDistance = () => {
        if (slideYDistance == 200) {
            setSlideYDistance(76);
        } else {
            setSlideYDistance(200);
        };
    };

    const contactButtonClicked = (url, payload) => {
        syncChat(myChat, setNewContactRelations);
        let data = {};
        if (url !== '/handleContactRequest') {
            data._id = payload;
        } else {
            data = payload;
        };
        setContactLoading(true);
        baesappApi.post(url, data)
            .then(res => {
                if (!res.data.shouldSync) {
                    switch (url) {
                        case '/requestContact':
                            if (res.data.ss) {
                                addSs(memberModal._id, res.data.ss);
                            };
                            break;
                        case '/requestContact/cancel':
                            //nothing should happen so far
                            break;
                        case '/handleContactRequest':
                            if (res.data.ss) {
                                addSs(memberModal._id, res.data.ss);
                            };
                            break;
                        case '/removeContact':
                            break;
                    };
                };
                syncDbSs();
                setNewContactRelations(res.data);
                setContactLoading(false);
            })
            .catch(err => {
                console.log(err);
                setContactLoading(false);
            });
    };


    const renderFollowButton = () => {

        if (followingLoading) {
            return <ConstructiveButton style={{ width: '70%' }} buttonState='loading' />
        };
        if (myMedia) {
            if (myMedia.followers.includes(memberModal._id) && !myMedia.following.includes(memberModal._id)) {
                return <ConstructiveButton style={{ width: '70%' }} buttonState="active" callback={() => toggleFollowing(memberModal._id)} text="Follow Back" />
            };
            if (myMedia.following.includes(memberModal._id)) {
                return <ConstructiveButton style={{ width: '70%' }} buttonState="inactive" callback={() => toggleFollowing(memberModal._id)} text="Unfollow" />
            } else {
                return <ConstructiveButton style={{ width: '70%' }} buttonState="active" callback={() => toggleFollowing(memberModal._id)} text="Follow" />
            }
        }
        // if (myMedia) {
        //     if (myMedia.followers.includes(memberModal._id) && !myMedia.following.includes(memberModal._id)) {
        //         return <button className="constructiveButton" onClick={() => toggleFollowing(memberModal._id)}>Follow Back</button>
        //     };
        //     if (myMedia.following.includes(memberModal._id)) {
        //         return <button className="constructiveButton" style={{ backgroundColor: 'white', border: '1px solid gray', color: '#333' }} onClick={() => toggleFollowing(memberModal._id)}>Unfollow</button>
        //     } else {
        //         return <button className="constructiveButton" onClick={() => toggleFollowing(memberModal._id)}>Follow</button>
        //     }
        // }
    }

    const renderContactButton = () => {
        if (contactLoading) {
            return <ConstructiveButton style={{ width: '70%' }} buttonState='loading' />
        };
        if (myChat) {
            if (myChat.contacts.includes(memberModal._id)) {
                return <ConstructiveButton style={{ width: '70%' }} buttonState="inactive" callback={() => setConfirmAction(true)} text="Delete Contact" />
            };
            if (myChat.requestsIncoming?.includes(memberModal._id)) {
                return <ConstructiveButton style={{ width: '70%' }} buttonState='active' callback={() => contactButtonClicked('/handleContactRequest', { _id: memberModal._id, action: 'accept' })} text="Accept Contact" />
            };
            if (myChat.requestsOutgoing.includes(memberModal._id)) {
                return <ConstructiveButton style={{ width: '70%' }} buttonState='inactive' callback={() => contactButtonClicked('/requestContact/cancel', memberModal._id)} text='Cancel Request' />;
            } else {
                return <ConstructiveButton style={{ width: '70%' }} buttonState='active' callback={() => contactButtonClicked('/requestContact', memberModal._id)} text="Request Contact" />
            }
        } else {
            return null;
        }
    }

    return (
        <div className='fullScreen flex vertical alignCenter' style={{ zIndex: '2', maxWidth: '500px', gap: '20px' }}>
            <img style={{ alignSelf: 'flex-start', marginTop: '30px', marginLeft: '30px' }} onClick={() => setShowVisitMemberModal ? setShowVisitMemberModal(false) : navigate(-1)} src={primaryX} className="headerBackIcon" />
            <img
                onTouchEnd={handleTouchEnd}
                onTouchMove={(e) => handleTouchMove(e)}
                style={{
                    width: `${slideYDistance / 2}%`,
                    borderRadius: ((slideYDistance / 2) > 96) ? '4%' : `${100 - (slideYDistance / 2)}%`
                }}
                onClick={toggleSlideYDistance}
                className={"memberModalProfileShot"}
                src={`https://baesapp.com/media/${memberModal.profileShot}`}
            />
            <span className='memberModalCardUsername'>{memberModal.username}</span>
            <div style={{ width: '100%' }} className='flex vertical alignCenter'>
                {renderFollowButton()}
                {renderContactButton()}
            </div>
            <div className='flex spaceBetween memberModalButtonsContainer' style={{ marginBottom: '30px' }}>
                {myChat ? myChat.contacts.includes(memberModal._id) ?
                    <>
                        <button onClick={() => voiceCallClicked(true)} className='flex vertical alignCenter memberModalButton'>
                            <BaeSvg height='22px' color='red' iconName='phone' />
                            <span style={{ color: 'var(--error)' }}>URGENT</span>
                        </button>
                        <button onClick={() => voiceCallClicked(false)} className='flex vertical alignCenter memberModalButton'>
                            <BaeSvg height='22px' color='gray' iconName='phone' />
                            <span>CALL</span>
                        </button>
                    </> :
                    myChat.requestsIncoming?.includes(memberModal._id) ?
                        <button
                            style={{
                                maxWidth: '25%',
                                justifyContent: 'center'
                            }}
                            onClick={() => contactButtonClicked('/handleContactRequest', { _id: memberModal._id, action: 'accept' })} className='flex vertical alignCenter memberModalButton'>
                            <span>Add contact to call</span>
                        </button> :
                        myChat.requestsOutgoing.includes(memberModal._id) ?
                            <button
                                style={{
                                    maxWidth: '25%',
                                    justifyContent: 'center'
                                }}
                                className='flex vertical alignCenter memberModalButton'>
                                <span>Waiting for contact acceptance to call</span>
                            </button> :
                            <button
                                style={{
                                    maxWidth: '25%',
                                    justifyContent: 'center'
                                }}
                                onClick={() => contactButtonClicked('/requestContact', memberModal._id)} className='flex vertical alignCenter memberModalButton'>
                                <span>Request contact to call</span>
                            </button>
                    :
                    null
                }
                <button onClick={() => visitMember('wallet/pay')} className='flex vertical alignCenter memberModalButton'>
                    <BaeSvg height='22px' color='gray' iconName='dollar' />
                    <span>PAY</span>
                </button>
                <button onClick={() => visitMember('wallet/magicCash')} className='flex vertical alignCenter memberModalButton'>
                    <BaeSvg height='22px' color='magicashFlicker' iconName='magicash' />
                    <span className='magicashFlicker'>MAGICASH</span>
                </button>
            </div>
            <div className='flex vertical alignCenter' style={{width: '80%', gap: '20px'}}>
                <AstheticButton callback={() => visitMember('chat')} iconName='chat' color='gradSkyBlue' label="Chat"/>
                <AstheticButton callback={() => visitMember('media')} iconName='media' color='gradPeach' label="Media"/>
                <AstheticButton callback={() => visitMember('wallet')} iconName='wallet' color='gradMintGreen' label="Wallet"/>
                <AstheticButton callback={() => visitMember('events')} iconName='events' color='gradBlushPink' label="Events"/>
                <AstheticButton callback={() => visitMember('info')} iconName='info' color='gradLavender' label="Info"/>
                <AstheticButton callback={() => visitMember('games')} iconName='games' color='gradSoftOrange' label="Games"/>
            </div>
            {/* <div className='flex spaceAround' style={{ width: '100vw', maxWidth: '500px' }}>
                <button style={{ gap: '20px' }} className='modalContainerTile vertical'>
                    <div style={{ gap: '15px' }} onClick={() => visitMember('chat')} className='flex'>
                        <BaeSvg iconName='chat' color='gray' height='26px' />
                        <span>Chat</span>
                    </div>
                </button>
                <button style={{ gap: '20px' }} className='modalContainerTile vertical'>
                    <div style={{ gap: '15px' }} onClick={() => visitMember('media')} className='flex'>
                        <BaeSvg iconName='media' color='gray' height='26px' />
                        <span>Media</span>
                    </div>
                </button>
            </div>
            <div className='flex spaceAround' style={{ width: '100vw', maxWidth: '500px' }}>
                <button onClick={() => visitMember('wallet')} className='modalContainerTile'>
                    <BaeSvg iconName='wallet' color='gray' height='26px' />
                    <span>Wallet</span>
                </button>
                <button onClick={() => visitMember('events')} className='modalContainerTile'>
                    <BaeSvg iconName='events' color='gray' height='26px' />
                    <span>Events</span>
                </button>
            </div>
            <div className='flex spaceAround' style={{ width: '100vw', maxWidth: '500px' }}>
                <button onClick={() => visitMember('info')} className='modalContainerTile'>
                    <BaeSvg iconName='info' color='gray' height='26px' />
                    <span>Info</span>
                </button>
                <button onClick={() => visitMember('openChat')} className='modalContainerTile'>
                    <BaeSvg iconName='openChat' color='gray' height='26px' />
                    <span>Open chat</span>
                </button>
            </div>
            <div className='flex spaceAround' style={{ width: '100vw', maxWidth: '500px' }}>
                <button onClick={() => visitMember('games')} className='modalContainerTile'>
                    <BaeSvg iconName='games' color='gray' height='26px' />
                    <span>Games</span>
                </button>
            </div> */}
            <div style={{ minHeight: '20px' }}></div>
            {confirmAction &&
                <>
                    <div onClick={() => setConfirmAction(false)} className="middlePageConvoBackdrop" />
                    <div className="bottomSlideInCard flex vertical spaceAround alignCenter confirmationCard">
                        <>
                            <span>Delete <strong>{memberModal.username}</strong> from your contacts?</span>
                            <span>(Your messages with <strong>{memberModal.username}</strong> will not be deleted)</span>
                            <div className="middlePageConvoButtonsContainer">
                                <button onClick={() => contactButtonClicked('/removeContact', memberModal._id)} className="cleanButton middlePageConvoButtonRed">Delete</button>
                                <button onClick={() => setConfirmAction(false)} className="cleanButton middlePageConvoButtonAccent">Cancel</button>
                            </div>
                        </>
                    </div>
                </>
            }
        </div>
    );
};

const mapStateToProps = state => {
    return ({
        memberModal: state.memberModal,
        myMedia: state.myMedia,
        myChat: state.myChat,
        darkModeEnabled: state.darkModeEnabled,
        my_id: state.my_id
    });
};

export default connect(mapStateToProps, {
    setMediaFollowing,
    setNewContactRelations,
    hitChatsEventListener,
    setUrgentCall
})(MemberModal);