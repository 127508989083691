const myCompaniesReducer = (myCompanies=false, action) => {
    switch(action.type){
        case 'GOT_MY_DETAILS':
            return action.payload.companies;
        case 'MY_COMPANIES_SET':
            console.log(action.payload);
            return action.payload;
        case 'CLEAR_ALL':
            return false;
        default:
            return myCompanies;
    };
  };
  
  export default myCompaniesReducer;
  