import React, { useEffect, useState } from "react";

import { connect } from 'react-redux';
import { Routes, Route, useNavigate } from 'react-router-dom';

import chevronAccent from '../../../assets/chevronAccent.png';
import bellActive from '../../../assets/bellActive.png'
import bellActiveDark from '../../../assets/bellActiveDark.png'

import baesappApi from '../../../apis/baesappApi';
import ModalHeader from "../../../dumbComponents/Home/modalHeader";
import objectify from "../../../hooks/objectify";
import normalizeDate from "../../../hooks/normalizeDate";
import BaeSvg from "../../../dumbComponents/BaeSvg";


const Activity = ({
    myActivity
}) => {

    const navigate = useNavigate();

    const [myActivityList, setMyActivityList] = useState([]);
    const [memberIds, setMemberIds] = useState({});

    useEffect(() => {
        if (myActivity?.length) {
            let activityIds = [...new Set(myActivity.map(activity => activity.member))];
            baesappApi.post('/getMemberIds', { Ids: activityIds })
                .then(res => {
                    setMemberIds(objectify(res.data, '_id'));
                });
        }
    }, [myActivity]);

    const handleNavigation = (activityType) => {
        switch(activityType){
            case 'chat': {
                navigate('/home/chat/requests');
                break;
            };
            case 'doorbell': {
                navigate('/home/doorbell');
                break;
            };
            case 'events': {
                navigate('/home/events');
                break;
            };
            case 'media': {
                navigate('/home/media/following');
                break;
            };
            case 'wallet': {
                navigate('/home/wallet');
                break;
            };
        };
    };

    useEffect(() => {
        if (Object.keys(memberIds).length) {
            setMyActivityList(myActivity.map(activity => {
                return (
                    <button onClick={() => handleNavigation(activity.activityType)} className="activityCard">
                        <div className="flex alignCenter" style={{ gap: '10px' }}>
                            <BaeSvg iconName={activity.activityType} color='text' />
                            <div className="flex vertical">
                                <span style={{fontWeight: 'bold'}}>{memberIds[activity.member].memberId.username}</span>
                                <span style={{opacity: '0.4'}}>{activity.content}</span>
                            </div>
                        </div>
                        <span>{normalizeDate(activity.dateTime, false, false)}</span>
                    </button>
                );
            }));
        }
    }, [memberIds]);

    return (
        <div className='fullScreen removeScrollBar modalContainer'>
            <div style={{ height: '100px' }} />
            <div className='flex vertical' style={{ gap: '15px' }}>
                {myActivityList}
            </div>
            <ModalHeader title={'Activity'} actionActive={false} />
        </div>
    );
};

const mapStateToProps = state => {
    return ({
        myActivity: state.myActivity
    });
};

export default connect(mapStateToProps)(Activity);