import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux'

import { useNavigate } from 'react-router-dom';

import chevronAccent from '../../../assets/chevronAccent.png';
import inactiveLock from '../../../assets/inactiveLock.png';
import activeLock from '../../../assets/activeLock.png';
import unlockedLock from '../../../assets/unlockedLock.png';
import baesappApi from '../../../apis/baesappApi';

import { setNewMyInfo } from '../../../redux/actions';

const General = ({
    myInfo,
    generalKeys,
    setNewMyInfo
}) => {

    const navigate = useNavigate();

    const [fields, setFields] = useState([]);
    const [canSave, setCanSave] = useState(false);
    const [addressPredictions, setAddressPredictions] = useState([]);
    const [activeAddress, setActiveAddress] = useState(false);
    const [location, setLocation] = useState(false);

    const getLabelFromKey = (key) => {
        switch (key) {
            case 'address1': return 'Address 1';
            case 'address2': return 'Address 2';
            case 'city': return 'City';
            case 'provinceState': return 'Province';
            case 'zip': return 'ZIP code';
            case 'maritalStatus': return 'Relationship status';
            default: return;
        }
    };

    useEffect(() => {
        navigator.geolocation.getCurrentPosition((position) => {
            setLocation(position.coords.latitude + ',' + position.coords.longitude)
        });
    }, []);

    useEffect(() => {
        let labelfiedFields = [];

        for (const key in myInfo) {
            if (generalKeys.includes(key)) {
                const field = {
                    key,
                    label: getLabelFromKey(key),
                    value: myInfo[key].value,
                    public: myInfo[key].public
                };
                labelfiedFields.push(field);
            };
        };

        setFields(labelfiedFields);
    }, [myInfo]);

    const handleTextInput = (key, value) => {
        if (!canSave) setCanSave(true);
        let tempFields = [...fields];
        tempFields.map((field, index) => {
            if (field.key == key) {
                field.value = value;
            };
        });
        if (value == '' || value == ' ') {
            setActiveAddress(false);
            setAddressPredictions([]);
        } else if (key == 'address1' || key == 'address2' || key == 'city' || key == 'provinceState') {
            if(!activeAddress)setActiveAddress(key);
            baesappApi.post('/address/predictAddress', { text: value, location }).then(res => {
                setAddressPredictions(res.data.predictions);
            });
        }

        setFields(tempFields);
    };

    const toggleLocked = (key) => {
        if (!canSave) setCanSave(true);
        const toggledFieldIndex = fields.findIndex(field => field.key == key);
        let newFields = [...fields];
        newFields[toggledFieldIndex].public = !newFields[toggledFieldIndex].public;
        setFields(newFields);
    };

    const saveFields = () => {
        let toSaveFields = {};

        fields.map(field => {
            toSaveFields[field.key] = {
                public: field.public,
                value: field.value
            }
        });

        baesappApi.post('/setInfo', { info: toSaveFields })
            .then(res => {
                setNewMyInfo(res.data)
                setCanSave(false);
            })
            .catch(err => {
                console.log(err);
            })
    }

    const addressPredictionClicked = (address) => {
        baesappApi.post('/address/getDetails', { placeId: address.place_id}).then(res => {

            let streetNumber = '';
            let route = '';
            let address2 = '';
            let city = '';
            let provinceState = '';
            let country = '';
            let postalCode = '';

            res.data.result.address_components.map(component => {
                switch(component.types[0]){
                    case 'street_number':{
                        streetNumber = component.long_name;
                    }break;
                    case 'route':{
                        route = component.long_name;
                    }break;
                    case 'sublocality_level_1':{
                        address2 = component.long_name;
                    }break;
                    case 'locality':{
                        city = component.long_name;
                    }break;
                    case 'administrative_area_level_1':{
                        provinceState = component.long_name;
                    }break;
                    case 'country':{
                        country = component.long_name;
                    }break;
                    case 'postal_code':{
                        postalCode = component.long_name;
                    }break;
                }
            });

            let tempFields = fields.slice();
            tempFields.map(field => {
                switch(field.key){
                    case 'addressPlaceId': {
                        field.value = res.data.result.formatted_address;
                    }break;
                    case 'address1': {
                        field.value = streetNumber + ' ' + route;
                    }break;
                    case 'address2': {
                        field.value = address2;
                    }break;
                    case 'city': {
                        field.value = city;
                    }break;
                    case 'provinceState': {
                        field.value = provinceState;
                    }break;
                    case 'zip': {
                        field.value = postalCode;
                    }break;
                }
            });
            setFields(tempFields);
            setActiveAddress(false);
        });
    };

    const addrressPredictionsList = addressPredictions.map(address => {
        let text = address.description;

        let matchedChars = [];
        address.matched_substrings.map(substring => {
            const o = substring.offset;
            const l = substring.length;

            for (let pos = o; pos < (0 + l); pos++) {
                matchedChars.push(pos);
            };
        });

        let finalAddressText = [];
        for (let pos = 0; pos < text.length; pos++) {
            let characterData = { 
                value: text[pos],
                matched: matchedChars.includes(pos)
            };
            finalAddressText.push(characterData);
        };

        let finalAddressTextList = finalAddressText.map(char => {
            return(
                <span style={char.matched ? {color: 'var(--accent)', fontWeight: 'bold'} : {}}>{char.value}</span>
            );
        });

        return (
            <button onClick={() => addressPredictionClicked(address)} className='addressSuggestionButton'>
                <div className='infoPickable'>
                    {finalAddressTextList}
                </div>
            </button>
        );
    });

    const renderFields = fields.map((field, index) => {
        if(field.key != 'addressPlaceId'){
            return (
                <div className='flex vertical'>
                    <div className='infoFieldContainer'>
                        <span className='infoFieldLabel'>{field.label}</span>
                        <div className='infoFieldInputContainer'>
                            <input
                                id={field.key}
                                className={`cleanInput infoFieldTextInput ${field.value && 'infoFieldTextInputNoValue'}`}
                                placeholder='Type value...'
                                onFocus={() => setActiveAddress(field.key)}
                                onChange={(e) => handleTextInput(field.key, e.target.value)}
                                value={field.value}
                            />
                            <button className='infoLockButton' onClick={() => { if (field.value) toggleLocked(field.key) }}>
                                <img className="infoLockIcon" src={field.value == '' ? inactiveLock : field.public ? unlockedLock : activeLock} />
                            </button>
                        </div>
                    </div>
                    {(activeAddress == field.key && addressPredictions.length>0) && <div className='addressSuggestionsContainer'>
                        {addrressPredictionsList}
                    </div>}
                </div>
            );
        }else{
            return null;
        }
    });

    return (
        <>
            <div className='fullScreen secondaryScreen removeScrollBar'>
                <div style={{ height: '60px' }}></div>
                {renderFields}
                <div style={{ height: '60px' }}></div>
            </div>
            <div className="secondaryScreenHeaderContainer">
                <div className='secondaryScreenHeader'>
                    <div onClick={() => navigate(-1)} className="headerBackButtonContainer">
                        <img src={chevronAccent} className="headerBackIcon" />
                    </div>
                    <span className="modalHeaderTitle">General info</span>
                    {canSave ?
                        <span style={{ 'marginRight': '15px' }} className="headerButtonText" onClick={() => saveFields()}>save</span> :
                        <></>
                    }
                </div>
            </div>
        </>
    );
};

const mapStateToProps = state => {
    return ({
        myInfo: state.myInfo
    });
};

export default connect(mapStateToProps, { setNewMyInfo })(General);