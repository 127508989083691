import React, { useEffect, useState } from "react";

import { connect } from 'react-redux';

import { useNavigate } from 'react-router-dom';

import useWindowDimensions from "../hooks/useWindowDimensions";

import Spinner from '../assets/spinner.png';
import Backspace from '../assets/Backspace.png';
import BaeSvg from "./BaeSvg";


const NumberPad = ({
    infoText,
    callback,
    doubleBack = false
}) => {

    const navigate = useNavigate();

    const { height, width } = useWindowDimensions();

    const [number, setNumber] = useState('');
    const [loading, setLoading] = useState(false);


    navigator.vibrate = navigator.vibrate || navigator.webkitVibrate || navigator.mozVibrate || navigator.msVibrate;
    const keyPressed = (value) => {
        if (number.length != 6) {
            if (navigator.vibrate) {
                navigator.vibrate(40);
            }
            setNumber(`${number}${value}`);
        };
    };

    useEffect(() => {
        if (number.length == 6) {
            setLoading(true);
            callback(number, setLoading);
            setNumber('');
        }
    }, [number]);

    const backspace = () => {
        if (number.length) {
            setNumber(number.slice(0, number.length - 1));
        };
    };

    return (
        <>
            <div className='fullScreen removeScrollBar modalContainer'>
                <div className='flex vertical spaceAround' style={{height: '100%'}}>
                    {
                        loading ?
                            <BaeSvg iconName='sideHeart' color='loaderFlicker absoluteHorizontalCenter' height='20px' /> :
                            <>
                                <span className="confirmWithPinInfo">{infoText}</span>
                                <div className="pinProgressContainer">
                                    <div className={`pinProgressBall ${number.length > 0 && 'filled'}`} />
                                    <div className={`pinProgressBall ${number.length > 1 && 'filled'}`} />
                                    <div className={`pinProgressBall ${number.length > 2 && 'filled'}`} />
                                    <div className={`pinProgressBall ${number.length > 3 && 'filled'}`} />
                                    <div className={`pinProgressBall ${number.length > 4 && 'filled'}`} />
                                    <div className={`pinProgressBall ${number.length > 5 && 'filled'}`} />
                                </div>
                                <div className="keysContainer">
                                    <div className="keysRow">
                                        <button onClick={() => keyPressed('1')} className={`key ${width > height ? 'vh' : 'vw'}`}>1</button>
                                        <button onClick={() => keyPressed('2')} className={`key ${width > height ? 'vh' : 'vw'}`}>2</button>
                                        <button onClick={() => keyPressed('3')} className={`key ${width > height ? 'vh' : 'vw'}`}>3</button>
                                    </div>
                                    <div className="keysRow">
                                        <button onClick={() => keyPressed('4')} className={`key ${width > height ? 'vh' : 'vw'}`}>4</button>
                                        <button onClick={() => keyPressed('5')} className={`key ${width > height ? 'vh' : 'vw'}`}>5</button>
                                        <button onClick={() => keyPressed('6')} className={`key ${width > height ? 'vh' : 'vw'}`}>6</button>
                                    </div>
                                    <div className="keysRow">
                                        <button onClick={() => keyPressed('7')} className={`key ${width > height ? 'vh' : 'vw'}`}>7</button>
                                        <button onClick={() => keyPressed('8')} className={`key ${width > height ? 'vh' : 'vw'}`}>8</button>
                                        <button onClick={() => keyPressed('9')} className={`key ${width > height ? 'vh' : 'vw'}`}>9</button>
                                    </div>
                                    <div className="keysRow">
                                        <button onClick={() => { }} className={`forgotPin ${width > height ? 'vh' : 'vw'}`}>Forgot PIN?</button>
                                        <button onClick={() => keyPressed('0')} className={`key ${width > height ? 'vh' : 'vw'}`}>0</button>
                                        <div onClick={backspace} className={`backspaceContainer forgotPin ${width > height ? 'vh' : 'vw'}`}>
                                            <img className="backspaceIcon" src={Backspace} />
                                        </div>
                                    </div>
                                </div>
                                <span onClick={() => doubleBack ? navigate(-2) : navigate(-1)} className="cancelPinText">Cancel</span>
                            </>
                    }
                </div>
            </div>
        </>
    );
};

const mapStateToProps = state => {
    return ({
        myWallet: state.myWallet
    });
};

export default connect(mapStateToProps)(NumberPad);