import React, { useEffect, useState } from "react";

import { connect } from 'react-redux';

import { useNavigate, Route, Routes } from 'react-router-dom';

import baesappApi from '../../../apis/baesappApi';

import { setNewContact, hitChatsEventListener, setUrgentCall, setVisitMember } from '../../../redux/actions';

import MemberModal from '../../../dumbComponents/MemberModal';

import syncChat from "../../../hooks/syncChat";
import syncDbSs from "../../../hooks/syncDbSs";
import { setNewContactRelations } from "../../../redux/actions";
import setLocalObject from "../../../hooks/setLocalObject";
import ModalHeader from "../../../dumbComponents/Home/modalHeader";

import BaeSvg from "../../../dumbComponents/BaeSvg";

const ContactsModal = ({
    myChat,
    darkModeEnabled,
    hitChatsEventListener,
    setUrgentCall,
    setVisitMember
}) => {

    const navigate = useNavigate();

    const [contactsList, setContactsList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [displayedList, setDisplayedList] = useState([]);
    const [shouldShowCancel, setShouldShowCancel] = useState(false);
    const [searchInputElement, setSearchInputElement] = useState();
    const [totalUnreadMessages, setTotalUnreadMessages] = useState(0);
    const [totalIncomingContactRequests, setTotalIncomingContactRequests] = useState(0);
    const [confirmAction, setConfirmAction] = useState(false);
    const [confirmCallUser, setConfirmCallUser] = useState(false);
    const [slideBaseX, setSlideBaseX] = useState('false');
    const [slideXDistance, setSlideXDistance] = useState(0);
    const [currSlideId, setCurrSlideId] = useState(false);

    useEffect(() => {
        setSearchInputElement(document.getElementById('searchInput'));
    }, []);

    useEffect(() => {
        setLoading(true);
        baesappApi.post('/getMemberIds', { Ids: myChat.contacts })
            .then(res => {
                setLoading(false);
                setContactsList(res.data);
                setDisplayedList(res.data);
            })
            .catch(err => {
                console.log(err);
                setLoading(false);
            })
    }, [myChat.contacts]);

    useEffect(() => {
        if (myChat) {
            setTotalIncomingContactRequests(myChat.requestsIncoming.length);
        }
    }, [myChat.requestsIncoming]);

    useEffect(() => {
        if (myChat) {
            let tempTotalUnreadMessages = 0;
            myChat.chats.map(chat => {
                tempTotalUnreadMessages += chat.newMessages.length;
            });
            setTotalUnreadMessages(tempTotalUnreadMessages);
        };
    }, [myChat.chats]);

    const handleTouchMove = (e, callId) => {
        const currX = Math.trunc(e.touches[0].clientX);
        if (slideBaseX == 'false') {
            setSlideBaseX(currX);
        } else {
            setCurrSlideId(prevId => prevId != callId ? callId : prevId);
            let invertedSlideDistance = slideBaseX - currX;
            let newSlideDistance = -invertedSlideDistance;
            if (newSlideDistance > -200 && newSlideDistance < 1) {
                setSlideXDistance(newSlideDistance);
            };
        }
    };
    useEffect(() => {
        setSlideBaseX('false');
        setSlideXDistance(0);
    }, [currSlideId]);
    const handleTouchEnd = () => {
        if (slideXDistance < -25) {
            setSlideXDistance(-50);
        } else {
            setSlideBaseX('false');
            setSlideXDistance(0);
        };
    };

    const deleteContact = (_id) => {
        syncChat(myChat, setNewContactRelations);
        baesappApi.post('/removeContact', { _id })
            .then(res => {
                syncDbSs();
                setNewContactRelations(res.data);
            })
            .catch(err => {
                console.log(err);
            })
    };

    const handleOnBlur = (blurredCause) => {
        if (blurredCause == 'cancel') {
            setSearchText('');
            setShouldShowCancel(false);
        } else if (blurredCause == 'clear') {
            setSearchText('');
            searchInputElement.focus();
        }
    };

    const callUser = (member, urgent) => {
        setLocalObject('memberModal', { _id: member._id, ...member.memberId });
        setUrgentCall(urgent);
        navigate('/voiceCall');
    }

    useEffect(() => {
        if (searchText == '') {
            setDisplayedList([...contactsList]);
        } else {
            const filteredContacts = contactsList.filter(contact => contact.memberId.username.toUpperCase().includes(searchText.toUpperCase()));
            setDisplayedList(filteredContacts);
        };
    }, [searchText]);

    const memberProfileShotClicked = (member) => {
        let memberObj = member.memberId;
        memberObj._id = member._id;
        setLocalObject('memberModal', memberObj);
        navigate('memberModal');
    };

    const visitMember = (member) => {
        setVisitMember({
            path: 'chat',
            memberEssentials: {
                ...member.memberId,
                _id: member._id
            }
        });
        navigate(`/visit/chat?_id=${member._id}`);
    };

    let alphabetPosition;
    const renderContactsList = displayedList.sort((a, b) => a.memberId.username.localeCompare(b.memberId.username)).map((member, index) => {
        let newAlphabetPosition = false;
        if (alphabetPosition != member.memberId.username.slice(0, 1)) {
            alphabetPosition = member.memberId.username.slice(0, 1)
            newAlphabetPosition = true;
        };
        return (
            <>
                {newAlphabetPosition && <div className="alphabeticalListDevider">
                    {alphabetPosition}
                </div>}
                <div className="flex vertical">
                    <div className="flex horizontal chatCard" style={{ justifyContent: 'flex-end', marginBottom: '-60px' }}>
                        <button onClick={() => setConfirmAction(member)} className="flex alignCenter spaceAround" style={{ height: '60px', width: '50px', backgroundColor: 'var(--error)', borderRadius: '10px' }}>
                            <BaeSvg iconName='bin' color="black" style={{ opacity: '0.6' }} />
                        </button>
                    </div>
                    <div className="flex horizontal chatCard" style={{ height: '60px', marginBottom: '-60px', backgroundColor: 'var(--base)', transform: `translateX(${currSlideId == member._id ? slideXDistance : '0'}px)` }}>
                    </div>
                    <div key={member._id} onTouchEnd={handleTouchEnd} onTouchMove={(e) => handleTouchMove(e, member._id)} style={{ transform: `translateX(${currSlideId == member._id ? slideXDistance : '0'}px)` }} className="flex horizontal chatCard" >
                        <div className="flex horizontal chatCardLeft">
                            <img onClick={() => memberProfileShotClicked(member)} className="memberBarProfileShot" src={`https://baesapp.com/media/${member.memberId.profileShot}`} />
                            <div onClick={() => visitMember(member)} className="flex vertical chatCardMainContent">
                                <span className="chatCardUsername">{member.memberId.username}</span>
                                <div className="chatCardContentContainer">
                                    <span className="chatCardContent">Tap to message</span>
                                </div>
                            </div>
                        </div>
                        <div className="flex alignCenter chatCardRight" style={{ height: '100%', gap: '5px' }}>
                            <button style={{ height: '40%', fontSize: '1em' }} className='flex alignCenter vertical' onClick={() => setConfirmCallUser(member)}>
                                <BaeSvg height="70%" iconName='phone' color='accent' />
                            </button>
                        </div>
                    </div>
                </div>
            </>
        );
    });

    const renderContent = () => {
        if (loading) {
            return (<BaeSvg iconName='sideHeart' color='loaderFlicker absoluteHorizontalCenter' height='20px'/>);
        } else if (displayedList.length == 0) {
            return (
                <div className="flex spaceAround" style={{ width: '100vw', textAlign: 'center', marginTop: '50px' }}>
                    <span className="centerText noResults">No Results</span>
                </div>
            );
        } else {
            return renderContactsList;
        };

    };

    return (
        <div className="fullScreen removeScrollBar modalContainer">
            <div className="flex vertical" style={{ gap: '6px' }}>
                <div style={{ minHeight: '115px', width: '50px' }} />
                <div className="flex spaceAround">
                    <div className="searchBarContainer" style={{ width: '100%' }}>
                        <div className="searchBar" style={shouldShowCancel ? { marginLeft: '10px' } : {}}>
                            <BaeSvg height='1em' style={{ margin: '10px 5px 10px 10px' }} iconName='magnifyingGlass' color='gray' />
                            <input
                                onFocus={() => setShouldShowCancel(true)}
                                id="searchInput"
                                placeholder="Search"
                                value={searchText}
                                onChange={e => setSearchText(e.target.value)}
                                className={`cleanInput searchBarInput`} />
                            {searchText != '' &&
                                <button className="flex" onClick={() => handleOnBlur('clear')}>
                                    <BaeSvg height='1.4em' iconName='circleX' color='gray' />
                                </button>
                            }
                        </div>
                        {shouldShowCancel && <span onClick={() => handleOnBlur('cancel')} className="searchBarCancelText">
                            Cancel
                        </span>}
                    </div>
                </div>
                <button className="blueListTextButton" onClick={() => navigate("/home/chat/requests")}>
                    requests{totalIncomingContactRequests > 0 && ' (' + totalIncomingContactRequests + ')'}
                </button>
                {renderContent()}
                <div style={{ minHeight: '115px' }} />
                <ModalHeader title={'CONTACTS'} actionName={'New'} actionActive={true} actionFunction={() => navigate('/home/search')} />
                {confirmAction &&
                    <>
                        <div onClick={() => setConfirmAction(false)} className="middlePageConvoBackdrop" />
                        <div className="bottomSlideInCard flex vertical spaceAround alignCenter confirmationCard">
                            <>
                                <span>Delete <strong>{confirmAction.memberId.username}</strong> from your contacts?</span>
                                <span>(Your messages with <strong>{confirmAction.memberId.username}</strong> will not be deleted)</span>
                                <div className="middlePageConvoButtonsContainer">
                                    <button onClick={() => deleteContact(confirmAction._id)} className="cleanButton middlePageConvoButtonRed">Delete</button>
                                    <button onClick={() => setConfirmAction(false)} className="cleanButton middlePageConvoButtonAccent">Cancel</button>
                                </div>
                            </>
                        </div>
                    </>
                }
                {confirmCallUser &&
                    <>
                        <div onClick={() => setConfirmCallUser(false)} className="middlePageConvoBackdrop" />
                        <div className="bottomSlideInCard flex vertical spaceAround alignCenter confirmationCard">
                            {confirmCallUser.memberId.username}
                            <div style={{ fontWeight: 'bold' }} className="middlePageConvoButtonsContainer spaceAround">
                                <button onClick={() => callUser(confirmCallUser, true)} className="middlePageConvoButtonRed flex vertical alignCenter">
                                    <BaeSvg height="40px" style={{ marginBottom: '15px' }} iconName='phone' color='red' />
                                    <span style={{ fontWeight: 'bold' }}>URGENT</span>
                                </button>
                                <button onClick={() => callUser(confirmCallUser, false)} className="flex vertical alignCenter">
                                    <BaeSvg height="40px" style={{ marginBottom: '15px' }} iconName='phone' color='gray' />
                                    <span style={{ color: 'grey', fontWeight: 'bold' }}>CALL</span>
                                </button>
                            </div>
                        </div>
                    </>
                }
                <Routes>
                    <Route path="/memberModal/*" element={<MemberModal />} />
                </Routes>
            </div>
        </div>
    );
};

const mapStateToProps = state => {
    return ({
        myChat: state.myChat,
        darkModeEnabled: state.darkModeEnabled
    });
};

export default connect(mapStateToProps, {
    setNewContact,
    hitChatsEventListener,
    setUrgentCall,
    setVisitMember
})(ContactsModal);
