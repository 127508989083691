const memberModalReducer = (memberModal=false, action) => {
    switch(action.type){
      case 'MEMBER_MODAL_SET':
        console.log('hitttteft')
        return action.payload;
        case 'CLEAR_ALL':
          console.log('hittttt')
                return false;
      default:
        return memberModal;
    };
  };
  
  export default memberModalReducer;
  