import React, { useState, useEffect, useRef } from "react";

import { connect } from 'react-redux';

import { Routes, Route, Link, useNavigate } from 'react-router-dom';
import normalizeDate from "../../../hooks/normalizeDate";

import baesappApi from "../../../apis/baesappApi";

import CreateEvent from './createEvent';
import EventDetails from "./EventDetails";
import setLocalObject from "../../../hooks/setLocalObject";
import { setTopBarOffset } from "../../../redux/actions";
import DailyEventsViewer from "../../DailyEventsViewer";
import Calendar from "../../Calendar";



const Events = ({
    myEvents
}) => {

    const navigate = useNavigate();

    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedDateEvents, setSelectedDateEvents] = useState([]);

    useEffect(() => {
        if (myEvents) {
            const nextUpcomingEvent = myEvents.sort((a, b) => {
                return new Date(a.dateTime) - new Date(b.dateTime)
            }).find(event => isToday(event.dateTime) || !dateInPast(event.dateTime));
        }
    }, [myEvents]);

    useEffect(() => {
        if (myEvents) {
            setSelectedDateEvents(myEvents.sort((a, b) => {
                return new Date(a.dateTime) - new Date(b.dateTime)
            }).filter(event => isSelectedDate(event.dateTime)));
        }
    }, [myEvents, selectedDate]);

    const dateInPast = (date) => {
        if (new Date(date).setHours(0, 0, 0, 0) <= new Date().setHours(0, 0, 0, 0)) {
            return true;
        };
        return false;
    }


    const isToday = (dateParameter) => {
        const today = new Date();
        const eventDate = new Date(dateParameter);
        return eventDate.getDate() === today.getDate() && eventDate.getMonth() === today.getMonth() && eventDate.getFullYear() === today.getFullYear();
    }

    const isSelectedDate = (dateParameter) => {
        const date1 = new Date(selectedDate);
        const eventDate = new Date(dateParameter);
        return eventDate.getDate() === date1.getDate() && eventDate.getMonth() === date1.getMonth() && eventDate.getFullYear() === date1.getFullYear();
    }

    const getDaysUntil = (_eventDate) => {
        const eventDate = new Date(_eventDate);
        const fullYear = eventDate.getFullYear();
        const month = eventDate.getMonth() + 1;
        const date = eventDate.getDate();

        const toDate = new Date(`${fullYear}-${month}-${date}`.replace(/-/g, "/"));
        const nowDate = new Date();

        const timeDifference = toDate.getTime() - nowDate.getTime();

        return Math.ceil(timeDifference / (1000 * 3600 * 24));
    };

    const eventClicked = (event) => {
        console.log(event);
        setLocalObject('clickedEvent', event);
        navigate('eventDetails');
    };

    const Main = () => {
        return (
            <div
                className="fullScreen flex removeScrollBar vertical">
                <div style={{ height: '60px' }} />
                <Calendar setSelectedDate={setSelectedDate}/>
                <DailyEventsViewer events={selectedDateEvents} />
                <div style={{ height: '60px' }} />
            </div>
        );
    }

    return (
        <Routes>
            <Route path='/*' element={Main()} />
            <Route path='/createEvent/*' element={<CreateEvent />} />
            <Route path='/eventDetails' element={<EventDetails />} />
        </Routes>
    );
};

const mapStateToProps = state => {
    return ({
        myEvents: state.myEvents
    });
};

export default connect(mapStateToProps, { setTopBarOffset })(Events);