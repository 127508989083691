import React, { useEffect, useState, useRef } from "react";

import { connect } from 'react-redux';
import { Routes, Route, useNavigate } from 'react-router-dom';
import baesappApi from "../../../apis/baesappApi";

import getLocalToObject from "../../../hooks/getLocalToObject";

import Pay from './Pay';
import MagicCash from './MagicCash';
import DepositZAR from "../../Home/Wallet/DepositZAR";
import WithdrawZAR from "../../Home/Wallet/WithdrawZAR";
import { setNewWalletHasPin } from "../../../redux/actions";
import normalizeDate from "../../../hooks/normalizeDate";
import getMonthNumber from "../../../hooks/getMonthNumber";

const VisitWallet = ({
    myWallet,
    setNewWalletHasPin,
    visitMember
}) => {


    const walletCard = useRef();

    const [flashRed, setFlashRed] = useState(false);
    const [flashGreen, setFlashGreen] = useState(false);
    const [previousTotalInCents, setPreviousTotalInCents] = useState(null);
    const [pin1, setPin1] = useState('');
    const [pin2, setPin2] = useState('');
    const [thisMemberTransactions, setThisMemberTransactions] = useState([]);

    const navigate = useNavigate();

    useEffect(() => {
        if (myWallet.totalInCents != previousTotalInCents && previousTotalInCents != null) {
            if (myWallet.totalInCents > previousTotalInCents) {
                triggerFlashGreen();
            } else {
                triggerFlashRed();
            }
            setPreviousTotalInCents(myWallet.totalInCents);
        };
        setPreviousTotalInCents(myWallet.totalInCents);
    }, [myWallet.totalInCents]);

    useEffect(() => {
        if (myWallet) {
            const newTransactions = myWallet.transactions.filter(transaction => transaction.memberId == visitMember.memberEssentials._id);
            setThisMemberTransactions(newTransactions.slice().reverse());
        }
    }, [myWallet, visitMember]);

    const triggerFlashGreen = () => {
        setFlashGreen(true);
        setTimeout(() => {
            setFlashGreen(false);
        }, 2000);
    };
    const triggerFlashRed = () => {
        setFlashRed(true);
        setTimeout(() => {
            setFlashRed(false);
        }, 2000);
    };

    const numberFormat = Intl.NumberFormat("en-US", {
        style: 'currency',
        currency: 'ZAR'
    });

    const isMoneyIncoming = (transactionType) => {
        if (
            transactionType == 'deposit' ||
            transactionType == 'incomingPayment' ||
            transactionType == 'magicCashIncoming'
        ) {
            return true;
        } else {
            return false;
        };
    };

    const getTransactionType = (transactionType) => {
        switch (transactionType) {
            case 'deposit':
                return 'Deposit';
            case 'outgoingPayment':
                return 'Outgoing payment';
            case 'incomingPayment':
                return 'Incoming payment';
            case 'withdrawal':
                return 'Withdrawal';
            case 'magicCashIncoming':
            case 'magicCashOutgoing':
                return 'Magic cash';
        };
    };


    let currentDate;
    let nextPreviousDate = false;
    let newDate = false;
    const transactionsList = thisMemberTransactions.map((transaction, index) => {

        const utcDate = new Date(transaction.date).toString();
        const transactionYear = utcDate.slice(11, 15);
        const transactionMonth = getMonthNumber(utcDate.slice(4, 7));
        const transactionDate = utcDate.slice(8, 10);
        const transactionHour = utcDate.slice(16, 18);
        const transactionMinutes = utcDate.slice(19, 21);

        const previousDate = nextPreviousDate;
        let previousDateStr;
        currentDate = transactionMonth + '/' + transactionDate + '/' + transactionYear;
        nextPreviousDate = currentDate;

        if (currentDate != previousDate) {
            previousDateStr = normalizeDate(previousDate);
            newDate = true;
        } else {
            newDate = false;
        }

        return (
            <>
                {newDate && previousDate && <li id={transaction._id} style={{ 'zIndex': thisMemberTransactions.length - index }} className="messageDateSeparator"><span className="messageDateSeparatorContent">{previousDateStr}</span></li>}
                <li key={transaction._id} className="flex vertical">
                    {index + 1 == thisMemberTransactions.length && <li id={transaction._id} style={{ 'zIndex': thisMemberTransactions.length - index }} className="messageDateSeparator"><span className="messageDateSeparatorContent">{normalizeDate(currentDate)}</span></li>}
                    <div className={`${isMoneyIncoming(transaction.transactionType) ? 'leftifyContainer leftWalletContainer' : 'rightifyContainer rightWalletContainer'}`}>
                        <div className={isMoneyIncoming(transaction.transactionType) ? 'leftify' : 'rightify'} style={{ color: '#000000a6' }}>
                            <div>
                                <div style={{ alignSelf: 'stretch', display: 'flex', flexDirection: 'column' }}>
                                    <span style={{fontSize: '1.3em', fontWeight: 'bold'}}>{isMoneyIncoming(transaction.transactionType) ? '+ ' : '- '}{numberFormat.format(transaction.amountInCents / 100)}</span>
                                    <span className={`transactionAmount`} style={{fontWeight: 'normal'}}>
                                        {transaction.reference || getTransactionType(transaction.transactionType)}
                                    </span>
                                </div>
                            </div>
                            <div className={isMoneyIncoming(transaction.transactionType) ? 'messageTimeTextLeft' : 'messageTimeTextRight'} style={{ color: '#000000a6' }}>
                                <small>{transactionHour + ':' + transactionMinutes}</small>
                            </div>
                        </div>
                    </div>
                </li>
            </>
        );

    });

    const renderContent = () => {
        return (
            <div className="fullScreen walletContainer removeScrollBar">
                <ul className="removeScrollBar fullScreen messagesContainer">
                    <div style={{ height: '165px' }} />
                    {transactionsList}
                    <div style={{ height: '60px' }} />
                </ul>
                <div ref={walletCard} className="walletCard walletCardVisit">
                    <div className="depWithContainer">
                        {myWallet.totalInCents > 0 && <span className="withdrawText" onClick={() => navigate('/home/wallet/withdrawZAR')}>Withdraw</span>}
                        <span className="depositText" onClick={() => navigate('/home/wallet/depositZAR')}>Deposit</span>
                    </div>
                    <div className="balanceContainer">
                        <small className={`availableBalanceText`}>Available balance:</small>
                        <h1 className={`balanceText ${flashGreen && 'flashGreen'} ${flashRed && 'flashRed'}`}>{numberFormat.format(myWallet.totalInCents / 100)}</h1>
                    </div>
                    <div className="walletButtonsContainer">
                        <div onClick={() => navigate(`magicCash?_id=${visitMember.memberEssentials._id}`)} style={{backgroundColor: 'transparent'}} className="walletButton openMagicCashButton">
                            <img className="walletButtonProfileShot" src={`https://baesapp.com/media/${visitMember.memberEssentials.profileShot}`} />
                            <span className="walletButtonText magicashFlicker">Magic cash</span>
                        </div>
                        <div onClick={() => navigate(`pay?_id=${visitMember.memberEssentials._id}`)} className="walletButton openPayButton">
                            <img className="walletButtonProfileShot" src={`https://baesapp.com/media/${visitMember.memberEssentials.profileShot}`} />
                            <span className="walletButtonText visitPayText">Pay</span>
                        </div>
                    </div>
                </div>
                <Routes>
                    <Route path='/pay/*' element={<Pay memberId={visitMember.memberEssentials._id} memberName={visitMember.memberEssentials.username} />} />
                    <Route path='depositZAR' element={<DepositZAR />} />
                    {myWallet.totalInCents > 0 && <Route path='withdrawZAR' element={<WithdrawZAR />} />}
                    <Route path='/magicCash' element={<MagicCash memberId={visitMember.memberEssentials._id} memberName={visitMember.memberEssentials.username} />} />
                </Routes>
            </div>
        );
    };

    const setWalletPin = () => {
        baesappApi.post('/setWalletPin', { pin: pin1 })
            .then(res => {
                setNewWalletHasPin(true);
            })
            .catch(err => {
                console.log(err);
                window.alert('Unexpected error occured')
            })
    };

    return (
        <>
            {
                myWallet.hasPin ?
                    renderContent() :
                    <>
                        <div onClick={() => navigate(-1)} className="fullScreen fixed top left depositModal" />
                        <div className="depositModalCard fixed flex">
                            <p>Create a wallet pin code to activate your wallet</p>
                            <input
                                type='number'
                                onChange={e => { if (e.target.value.length < 7) setPin1(e.target.value) }}
                                value={pin1}
                                placeholder='Create new pin'
                            />
                            <input
                                type='number'
                                onChange={e => { if (e.target.value.length < 7) setPin2(e.target.value) }}
                                value={pin2}
                                placeholder='Re-enter pin...'
                            />
                            {pin1 == pin2 && pin1.length == 6 && <button onClick={setWalletPin}>Confirm wallet pin</button>}
                        </div>
                    </>
            }
        </>
    );
};

const mapStateToProps = state => {
    return ({
        myWallet: state.myWallet,
        my_id: state.my_id,
        visitMember: state.visitMember
    });
};

export default connect(mapStateToProps, {
    setNewWalletHasPin
})(VisitWallet);