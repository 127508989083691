import React, { useEffect, useState, useRef } from "react";

import { connect } from 'react-redux';
import { useNavigate } from "react-router-dom";
import getLocalToObject from "../hooks/getLocalToObject";
import io from 'socket.io-client';

import setLocalObject from "../hooks/setLocalObject";
import baesappApi from "../apis/baesappApi";
import getLocal from "../hooks/getLocal";
import setLocal from '../hooks/setLocal';

import waterDrop from '../assets/waterDrop.wav';
import ringing from '../assets/ringing.wav';

const MyDoorbellRung = ({
    my_id,
    myId,
    socket
}) => {

    const ringerMemberId = getLocalToObject('ringerMemberId');
    const note = getLocal('note');

    const navigate = useNavigate(-1);

    const renderContent = () => {
        return (
            <div className="callBackground" >
                <div className="callContainer">
                    <div className="flex vertical alignCenter">
                        <img className="callMemberProfileShotBackground" src={`https://baesapp.com/media/${ringerMemberId.profileShot}`} />
                        <img className="callMemberProfileShot" src={`https://baesapp.com/media/${ringerMemberId.profileShot}`} />
                    </div>
                    <span>{ringerMemberId.username}</span>
                    <span>Knock Knock</span>
                    {
                        note != 'none' && 
                        <span>{note}</span>
                    }
                    <div className="flex spaceAround alignCenter callActionButtonsContainer">
                        <button onClick={() => navigate(-1)} className="callActionButton">

                        </button>
                    </div>
                </div>
            </div >
        );
    };

    return renderContent();
};

const mapStateToProps = state => {
    return ({
        memberModal: state.memberModal,
        my_id: state.my_id,
        myId: state.myId
    });
};

export default connect(mapStateToProps, {})(MyDoorbellRung);
