const membersTypingOpenChatReducer = (membersTypingOpenChat = [], action) => {
    switch (action.type) {
        case 'ADD_MEMBERS_TYPING_OPEN_CHAT':{
            let newMembersTypingOpenChat = [...membersTypingOpenChat, action.payload];
            return newMembersTypingOpenChat;
        };
        case 'REMOVE_FROM_MEMBERS_TYPING_OPEN_CHAT':{
            let newMembersTypingOpenChat = [...membersTypingOpenChat];
            const indexOfMemberId = newMembersTypingOpenChat.findIndex(member => member == action.payload);
            if(indexOfMemberId != -1){
                newMembersTypingOpenChat.splice(indexOfMemberId, 1);
            };
            return newMembersTypingOpenChat;
        };
        case 'CLEAR_ALL':
            return [];
        default:
            return membersTypingOpenChat;
    }
};

export default membersTypingOpenChatReducer;