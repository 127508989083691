import { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import baesappApi from '../../../apis/baesappApi';

import { useNavigate, Route, Routes } from 'react-router-dom';
import ModalHeader from '../../../dumbComponents/Home/modalHeader';
import normalizeDate from '../../../hooks/normalizeDate';

import { setUrgentCall, updateCalls } from '../../../redux/actions';
import setLocalObject from '../../../hooks/setLocalObject';
import BaeSvg from '../../../dumbComponents/BaeSvg';

const Calls = ({
    myChat,
    my_id,
    updateCalls,
    setUrgentCall,
    darkModeEnabled
}) => {

    const navigate = useNavigate();

    const [showDetail, setShowDetail] = useState(false);

    const newCall = () => {
        navigate('/home/chat/contacts');
    };

    useEffect(() => {
        baesappApi.get('/getMyCalls')
            .then(res => {
                updateCalls(res.data);
            })
            .catch(err => {
                window.alert('Oops, an unexpected error occured, please refresh the page')
            })
    }, []);

    const callUser = (member, urgent) => {
        setLocalObject('memberModal', { _id: member._id, ...member.memberId });
        setUrgentCall(urgent);
        navigate('/voiceCall');
    }

    function getTimeDifference(endDateTime, answerDateTime) {
        const end = new Date(endDateTime);
        const answer = new Date(answerDateTime);
        const diffMs = end - answer;
        const totalSeconds = Math.floor(diffMs / 1000);
        const minutes = Math.floor(totalSeconds / 60);
        const seconds = totalSeconds % 60;

        if (minutes === 0) {
            return `${seconds} seconds`;
        } else if (seconds === 0) {
            return `${minutes} minute${minutes > 1 ? 's' : ''}`;
        } else {
            return `${minutes} minute${minutes > 1 ? 's' : ''}, ${seconds} second${seconds > 1 ? 's' : ''}`;
        }
    }

    let callsList = null;
    if (myChat) {
        callsList = myChat.calls.slice().reverse().map(call => {
            const displayMember = call.caller._id == my_id ? call.receiver : call.caller;
            const profileShot = displayMember.memberId.profileShot;
            const username = displayMember.memberId.username;
            const utcDate = new Date(call.initiationDateTime).toString();
            const callHour = utcDate.slice(16, 18);
            const callMinutes = utcDate.slice(19, 21);
            const getCallType = () => {
                if (call.caller._id == my_id) {
                    if (call.urgentCall) {
                        if (call.declinded) return 'Urgent Outgoing Declined';
                        return 'Urgent Outgoing';
                    } else {
                        if (call.declinded) return 'Outgoing Declined';
                        return 'Outgoing';
                    }
                } else {
                    if (call.urgentCall) {
                        if (call.declinded) return 'Urgent Incoming Declined';
                        return 'Urgent Incoming';
                    } else {
                        if (call.declinded) return 'Incoming Declined';
                        return 'Incoming';
                    }
                };
            };

            const getCallIcon = () => {
                if (call.caller._id == my_id) {
                    return (
                        <BaeSvg height='0.8em' style={{ marginRight: '5px' }} iconName='phoneOutgoing' color={call.urgentCall ? 'red' : 'gray'} />
                    );
                } else {
                    return null;
                }
            };

            const getTypeColor = () => {
                if(call.urgentCall)return 'var(--error)'
                if (call.answerDateTime) {
                    return 'var(--secondary)'
                } else {
                    return 'var(--error)'
                };
            };

            return (
                <>
                    <button className="flex horizontal chatCard" style={{ paddingLeft: '5px', borderRadius: '10px', zIndex: '1', backgroundColor: call.urgentCall ? darkModeEnabled ? '#461a1a' : '#ffb3b3' : 'var(--base)' }} >
                        <div onClick={() => callUser(displayMember, call.urgentCall)} className="flex horizontal chatCardLeft">
                            <img onClick={() => console.log('open memberModal')} className="memberBarProfileShot" src={`https://baesapp.com/media/${profileShot}`} />
                            <div className="flex vertical chatCardMainContent">
                                <span className="chatCardUsername" style={{color: getTypeColor()}}>{username}</span>
                                <div className="chatCardContentContainer">
                                    {getCallIcon()}
                                    <span className="chatCardContent" style={{ color: call.urgentCall ? 'var(--error)' : 'var(--secondary)' }}>{getCallType()}</span>
                                </div>
                            </div>
                        </div>
                        <div className="flex alignCenter chatCardRight" style={{ height: '100%', gap: '5px' }}>
                            <span style={call.urgentCall ? { color: 'var(--error)' } : {}} onClick={() => callUser(displayMember, call.urgentCall)}>{normalizeDate(call.initiationDateTime, false, true)}</span>
                            <button style={{ height: '50%' }} className='flex alignCenter' onClick={() => showDetail == call._id ? setShowDetail(false) : setShowDetail(call._id)}>
                                <BaeSvg height='70%' iconName='info' color={call.urgentCall ? 'red' : 'accent'}/>
                            </button>
                        </div>
                    </button>
                    <button onClick={() => setShowDetail(false)} className="flex chatCard vertical" style={{ marginBottom: showDetail == call._id ? '40px' : '0', marginTop: showDetail == call._id ? '40px' : '-60px', transitionDuration: '200ms', minHeight: '60px', gap: '20px', alignItems: 'flex-start' }}>
                        {showDetail == call._id ? call.declinded ?
                            <span style={{ marginLeft: '20px', fontWeight: 'bold', color: 'var(--secondary)' }}>Declined</span> :
                            call.answerDateTime ?
                                <span style={{ marginLeft: '20px', fontWeight: 'bold', color: 'var(--secondary)' }}>Duration: {getTimeDifference(call.endDateTime, call.answerDateTime)}</span> :
                                <span style={{ marginLeft: '20px', fontWeight: 'bold', color: 'var(--secondary)' }}>Not answered</span> :
                            null
                        }
                        {showDetail == call._id && <span style={{ marginLeft: '20px', fontWeight: 'bold', color: 'var(--secondary)' }}>@ {callHour}:{callMinutes}</span>}
                    </button>
                    <div className="chatCardSeparator"></div>
                </>
            );
        });
    };

    return (
        <div className='fullScreen removeScrollBar modalContainer flex vertical' style={{ gap: '5px' }}>
            <div style={{ minHeight: '100px' }} />
            {callsList}
            <ModalHeader title={'CALLS'} actionName={'NEW'} actionActive={true} actionFunction={() => newCall()} />
        </div>
    );
};

const mapStateToProps = state => {
    return ({
        myChat: state.myChat,
        my_id: state.my_id,
        darkModeEnabled: state.darkModeEnabled
    });
};

export default connect(mapStateToProps, { updateCalls, setUrgentCall })(Calls);
