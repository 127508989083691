import React, { useEffect, useRef, useState } from "react";

import { connect } from 'react-redux';
import { useNavigate, Route, Routes } from 'react-router-dom';

const DailyEventsViewer = ({
    events
}) => {

    const navigate = useNavigate();

    const timelineContainerRef = useRef(null);

    const [f, setF] = useState(false); //p divided by m equals the multiplying factor (f)
    const [dailyStartDate, setDailyStartDate] = useState(new Date());
    const [dailyGaps, setDailyGaps] = useState([]);
    const [focusEvent, setFocusEvent] = useState(false);


    const isFocused = (_id) => {
        return focusEvent == _id;
    };

    function extractTime(dateString) {
        // Create a Date object from the ISO string
        const date = new Date(dateString);

        // Get UTC hours and minutes
        const hours = date.getUTCHours();
        const minutes = date.getUTCMinutes();

        // Format hours and minutes to always have two digits
        const formattedHours = hours.toString().padStart(2, '0');
        const formattedMinutes = minutes.toString().padStart(2, '0');

        // Return the formatted time string
        return `${formattedHours}:${formattedMinutes}`;
    }

    function formatTime(minutes) {
        const hours = Math.floor(minutes / 60);
        const remainingMinutes = minutes % 60;
        let result = "";

        // Check if hours exist, add to result if they do
        if (hours > 0) {
            result += `${hours}hr `;
        }
        // Check if minutes exist, add to result if they do
        if (remainingMinutes > 0) {
            result += `${remainingMinutes}min`;
        }

        // Trim any extra space at the end and return the result
        return result.trim();
    }

    function calculateAndSetDailyGaps() {
        let tempEvents = JSON.parse(JSON.stringify(events));
        console.log(tempEvents);
        if (tempEvents.length === 0){
            setDailyGaps([]);
            return;
        } 

        // Step 1: Sort the events based on the start time
        tempEvents.sort((a, b) => new Date(a.dateTime) - new Date(b.dateTime));

        // Step 2: Merge overlapping events
        let mergedEvents = [];
        let currentEvent = tempEvents[0];

        for (let i = 1; i < tempEvents.length; i++) {
            if (new Date(tempEvents[i].dateTime) <= new Date(currentEvent.endDateTime)) {
                // Overlapping or contiguous events, merge them
                currentEvent.endDateTime = new Date(Math.max(new Date(currentEvent.endDateTime), new Date(tempEvents[i].endDateTime))).toISOString();
            } else {
                // No overlap, push the current event to the result and move to the next
                mergedEvents.push(currentEvent);
                currentEvent = tempEvents[i];
            }
        }
        mergedEvents.push(currentEvent); // Push the last processed event

        // Step 3: Calculate gaps between merged events
        let gaps = [];
        for (let i = 0; i < mergedEvents.length - 1; i++) {
            let gapStart = mergedEvents[i].endDateTime;
            let gapEnd = mergedEvents[i + 1].dateTime;

            if (gapStart !== gapEnd) {
                // Convert ISO strings to Date objects
                const start = new Date(gapStart);
                const end = new Date(gapEnd);

                // Calculate the difference in minutes
                const gapLengthInMinutes = (end - start) / (1000 * 60);

                // Push the gap with gapLengthInMinutes
                gaps.push({
                    dateTime: gapStart,
                    endDateTime: gapEnd,
                    gapLengthInMinutes: Math.abs(gapLengthInMinutes) // Ensure positive value
                });
            }
        }

        setDailyGaps(gaps);
    }

    function getM(data) {
        // Initialize minDate and maxDate with extreme values
        let minDate = new Date(8640000000000000); // Set minDate to a large date
        let maxDate = new Date(-8640000000000000); // Set maxDate to a small date

        // Loop through each item in the data array
        data.forEach(item => {
            // Convert dateTime and endDateTime from item to Date objects
            const startDate = new Date(item.dateTime);
            const endDate = new Date(item.endDateTime);

            // Update minDate and maxDate based on the current item's dates
            if (startDate < minDate) minDate = startDate;
            if (endDate < minDate) minDate = endDate;
            if (startDate > maxDate) maxDate = startDate;
            if (endDate > maxDate) maxDate = endDate;
        });

        setDailyStartDate(minDate);
        // Calculate the difference in minutes between maxDate and minDate
        const differenceInMinutes = (maxDate - minDate) / (1000 * 60);

        return differenceInMinutes;
    }

    function getMinutesBetweenDates(date1, date2) {
        date1 = new Date(date1);
        date2 = new Date(date2);
        const millisecondsPerMinute = 1000 * 60; // 1000 milliseconds per second, 60 seconds per minute
        const diffInMilliseconds = Math.abs(date2 - date1); // Absolute value to handle date1 being greater than date2
        const diffInMinutes = diffInMilliseconds / millisecondsPerMinute;
        return Math.floor(diffInMinutes); // Optionally round down to the nearest whole number
    }

    const getMinutesFromStart = (eventDateTime) => {
        return getMinutesBetweenDates(dailyStartDate, eventDateTime);
    };

    useEffect(() => {
        let p = 0; //Available pixels in div
        let m = 0; //Total minutes between day start and end of events
        if (timelineContainerRef && events) {
            console.log(events);
            p = timelineContainerRef.current.clientHeight;
            m = getM(events);
            calculateAndSetDailyGaps();
            setF(p / m);
        }
    }, [events, timelineContainerRef]);

    const renderTimeLine = () => {
        return (
            <div ref={timelineContainerRef} style={styles.timelineContainer}>
                {f && <>
                    <div style={styles.timelineTimesContainer}>
                        {events.map(event => {
                            if (event.endDateTime) {
                                const mfs = getMinutesFromStart(event.dateTime);//minutes from start
                                const yPos = mfs * f;//y position
                                const ml = getMinutesBetweenDates(event.dateTime, event.endDateTime);//minutes length
                                const height = ml * f;
                                const yEndPos = height + yPos;
                                return (
                                    <>
                                        <span
                                            className={isFocused(event._id) ? 'flashColorToBase' : ''}
                                            style={{
                                                ...styles.lineLabel,
                                                top: `${yPos}px`,
                                                color: focusEvent ? isFocused(event._id) ? '' : 'var(--secondary)' : 'var(--accent50)',
                                                opacity: focusEvent ? isFocused(event._id) ? '1' : '0.5' : '1'
                                            }}>{extractTime(event.dateTime)}</span>
                                        <span
                                            className={isFocused(event._id) ? 'flashColorToBase' : ''}
                                            style={{
                                                ...styles.lineLabel,
                                                top: `${yEndPos}px`,
                                                transform: 'translateY(-80%)',
                                                color: focusEvent ? isFocused(event._id) ? '' : 'var(--secondary)' : 'var(--accent50)',
                                                opacity: focusEvent ? isFocused(event._id) ? '1' : '0.5' : '1'
                                            }}>{extractTime(event.endDateTime)}</span>
                                    </>
                                )
                            }
                        })}
                        {dailyGaps.map(gap => {
                            console.log(gap);
                            if (gap.endDateTime) {
                                const mfs = getMinutesFromStart(gap.dateTime);//minutes from start
                                const yPos = mfs * f;//y position
                                const ml = getMinutesBetweenDates(gap.dateTime, gap.endDateTime);//minutes length
                                const height = ml * f;
                                return (
                                    <>
                                        <span style={{
                                            ...styles.lineLabel,
                                            top: `${yPos + (height / 2)}px`,
                                            color: focusEvent ? '#8b8b8b' : '#00D739',
                                            transform: 'translateY(-50%)'
                                        }}>{formatTime(gap.gapLengthInMinutes)}</span>
                                    </>
                                )
                            }
                        })}
                    </div>
                    <div style={styles.timelineLinesContainer}>
                        {events.map(event => {
                            if (event.endDateTime) {
                                const mfs = getMinutesFromStart(event.dateTime);//minutes from start
                                const yPos = mfs * f;//y position
                                const ml = getMinutesBetweenDates(event.dateTime, event.endDateTime);//minutes length
                                const height = ml * f;
                                return (
                                    <div
                                        className={isFocused(event._id) ? 'flashBackgroundToBase' : ''}
                                        style={{
                                            ...styles.line,
                                            height: `${height}px`,
                                            top: `${yPos}px`,
                                            width: focusEvent ? isFocused(event._id) ? '50%' : '30%' : '30%',
                                            backgroundColor: focusEvent ? isFocused(event._id) ? '' : 'var(--secondary)' : 'var(--accent50)',
                                            opacity: focusEvent ? isFocused(event._id) ? '1' : '0.5' : '1'
                                        }} />
                                )
                            }
                        })}
                        {dailyGaps.map(gap => {
                            const mfs = getMinutesFromStart(gap.dateTime);//minutes from start
                            const yPos = mfs * f;//y position
                            const ml = getMinutesBetweenDates(gap.dateTime, gap.endDateTime);//minutes length
                            const height = ml * f;
                            return (
                                <div style={{
                                    ...styles.line,
                                    height: `${height}px`,
                                    top: `${yPos}px`,
                                    width: '30%',
                                    backgroundColor: focusEvent ? '#8b8b8b' : '#00D739',
                                }} />
                            )
                        })}
                    </div>
                </>
                }
            </div>
        );
    };

    const renderEventButtons = () => {
        return (
            <div style={styles.eventButtonsContainer}>
                {events.map(event => {
                    return (
                        <div
                            className={isFocused(event._id) ? 'flashBackgroundToBase' : ''}
                            onClick={() => {
                                if (focusEvent == event._id) {
                                    setFocusEvent(false);
                                } else {
                                    setFocusEvent(event._id)
                                }
                            }}
                            style={{
                                ...styles.eventButton,
                                backgroundColor: isFocused(event._id) ? '' : 'var(--primary)',
                                color: isFocused(event._id) ? 'white' : 'var(--text)'
                            }}>
                            <span>{event.name}</span>
                        </div>
                    );
                })}
            </div>
        );
    };

    return (
        <div style={styles.bottomHalfDrawer}>
            {renderTimeLine()}
            {renderEventButtons()}
        </div>
    );
};

const styles = {
    eventButton: {
        width: '80%',
        height: '50px',
        borderRadius: '15px',
        display: 'flex',
        alignItems: 'center',
        padding: '0 15px',
        boxSizing: 'border-box'
    },
    eventButtonsContainer: {
        width: '75%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        alignItems: 'center'
    },
    lineLabel: {
        color: 'var(--accent)',
        position: 'absolute',
        fontWeight: 'bold'
    },
    line: {
        backgroundColor: 'var(--accent50)',
        borderRadius: '20px',
        position: 'absolute',
        border: '2px solid var(--base)',
        boxShadow: '#8080807d 0 0 7px',
        boxSizing: 'border-box',
        transitionDuration: '100ms'
    },
    timelineLinesContainer: {
        height: '100%',
        width: '50%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'relative'
    },
    timelineTimesContainer: {
        height: '100%',
        width: '50%',
        position: 'relative'
    },
    timelineContainer: {
        height: '100%',
        width: '25%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around'
    },
    bottomHalfDrawer: {
        width: '100%',
        maxWidth: '500px',
        backgroundColor: 'var(--base)',
        height: '50vh',
        borderTopRightRadius: '15px',
        borderTopLeftRadius: '15px',
        display: 'flex',
        position: 'absolute',
        bottom: '50px',
        margin: 'auto',
        left: '0',
        right: '0',
        padding: '20px',
        boxSizing: 'border-box',
        boxShadow: '#8080804f 0 0 10px'
    }
};

const mapStateToProps = state => {
    return ({

    });
};

export default connect(mapStateToProps)(DailyEventsViewer);
