import decryptMessage from "./decryptMessage";
import setLocalObject from "./setLocalObject";
import getLocalToObject from "./getLocalToObject";

const setEndNew = (newMessages) => {
    let clientChats = getLocalToObject('chats');
    for(const key in newMessages){
        let decryptedMessages = [];
        newMessages[key].map(message => {
                if(clientChats[key].conversation.messages.findIndex(clientMessage => clientMessage._id == message._id) == -1){
                    let decryptedMessage = {...message};
                    decryptedMessage.text = decryptMessage(decryptedMessage.text, key);
                    if(message.replying){
                        decryptedMessage.replying.messageText = decryptMessage(decryptedMessage.replying.messageText, key);
                    }
                    decryptedMessages.push(decryptedMessage);
                }
        });
        clientChats[key].conversation.messages.push(...decryptedMessages);
    };
    setLocalObject('chats', clientChats);
};

export default setEndNew;