import React from "react";

import {connect} from 'react-redux';

import {useNavigate, Route, Routes} from 'react-router-dom';

import collapseArrow from '../../../assets/collapseArrow.png';

import baesappApi from '../../../apis/baesappApi';

import {setSearchableByProfileShot} from '../../../redux/actions';

const MyFaceModal = ({
    mySearch,
    setSearchableByProfileShot
}) => {

    const navigate = useNavigate();

    const handleCollapseModal = () => {
        navigate(-1);
    };
    
    const toggleSearchableByProfileShot = () => {
        baesappApi.post('/setSearchableByProfileShot', {bool: !mySearch.searchableByProfileShot})
            .then(res => {
                setSearchableByProfileShot(res.data);
            })
            .catch(err => console.log(err))
    }

    return(
        <>
            <div className="flex spaceAround fixed modalBackdrop">
                <div className='fixed modalBackground'>
                    <div className='postsContainer' style={mySearch.searchableByProfileShot ? {backgroundColor:'grey'} : {backgroundColor:'green'}} onClick={toggleSearchableByProfileShot}/>
                    <div onClick={() => handleCollapseModal()} className="fixed bottom modalCollapseButton flex alignCenter spaceAround">
                        <div className="modalCollapseButtonItems flex horizontal alignCenter spaceAround">
                            <p className="modalCollapseButtonText">My Face</p>
                            <img className="collapseArrowIcon" src={collapseArrow}/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

const mapStateToProps = state => {
    return({
        mySearch: state.mySearch
    });
};

export default connect(mapStateToProps, {
    setSearchableByProfileShot
})(MyFaceModal);
