import {initializeApp} from 'firebase/app';
import {getMessaging, getToken, onMessage} from 'firebase/messaging';

import baesappApi from './apis/baesappApi';


const firebaseConfig = {
    apiKey: "AIzaSyBaa8LKjdR-hyWZfLdhkBPXcKs_9yI8YcA",
    authDomain: "baesapp-89a77.firebaseapp.com",
    projectId: "baesapp-89a77",
    storageBucket: "baesapp-89a77.appspot.com",
    messagingSenderId: "167046637927",
    appId: "1:167046637927:web:9a44cdf7cb3d59694fa7c2",
    measurementId: "G-34T99SG7LC"
  };
  
const app = initializeApp(firebaseConfig);

const messaging = getMessaging();

export const getTheToken = (setToken) => {
    return getToken(messaging, {vapidKey: 'BHCBxdp4levAqPf3gw2QvnDHKaAyiommyXH3dvtnCPMS3qYQfsBOu9v6clI8B_PFvPwuMJDLkH22fHQPspSMt-c'}).then((currentToken) => {
      if (currentToken) {
        setToken(currentToken);
      } else {
        console.log('No registration token available. Request permission to generate one.');
        
        baesappApi.post('/logger', {msg: 'No registration token available. Request permission to generate one.'});
        setToken(false);
      }
    }).catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
      baesappApi.post('/logger', {msg: `errrrr time; ${err}`});
      // catch error while creating client token
    });
  };

  export const onMessageListener = () =>
  new Promise((resolve) => {
      onMessage(messaging, (payload) => {
      resolve(payload);
    });
   });