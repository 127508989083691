const membersTypingReducer = (membersTyping = [], action) => {
    switch (action.type) {
        case 'ADD_MEMBERS_TYPING':{
            let newMembersTyping = [...membersTyping, action.payload];
            return newMembersTyping;
        };
        case 'REMOVE_FROM_MEMBERS_TYPING':{
            let newMembersTyping = [...membersTyping];
            const indexOfMemberId = newMembersTyping.findIndex(member => member == action.payload);
            if(indexOfMemberId != -1){
                newMembersTyping.splice(indexOfMemberId, 1);
            };
            return newMembersTyping;
        };
        case 'CLEAR_ALL':
            return [];
        default:
            return membersTyping;
    }
};

export default membersTypingReducer;