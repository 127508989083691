import React, {useState, useEffect} from "react";

import {Routes, Route, useNavigate} from 'react-router-dom';
import {connect} from 'react-redux';

import MediaComments from "../../Home/Media/MediaComments";
import MediaShare from "../../Home/Media/MediaShare";
import baesappApi from '../../../apis/baesappApi';

const ChatPostViewer = ({
    _post,
    my_id
}) => {

    const navigate = useNavigate();

    const [likedCurrentPost, setLikedCurrentPost] = useState(false);
    const [post, setPost] = useState({..._post});
    const [muted, setMuted] = useState(true);
    const [showControls, setShowControls] = useState(false);

    useEffect(() => {
        setLikedCurrentPost(post.likes.includes(my_id));
    }, [post]);

    useEffect(() => {
        const allVideos = document.getElementsByTagName('video');
        for(let i=0; i<allVideos.length; i++){
            allVideos[i].muted = muted;
        };
    }, [muted]);

    const likePost = () => {
        baesappApi.post('/postLike', {
            postId: post._id,
            shouldRemove: likedCurrentPost
        })
        .then(res => {
            let tempPost = {...post};
            if(likedCurrentPost){
                tempPost.likes.splice(tempPost.likes.indexOf(my_id), 1);
            }else{
                tempPost.likes.push(my_id);
            };
            setPost({...tempPost});
        })
        .catch(err => console.log(err));
    };
    const openPostComments = () => {
        navigate('mediaComments');
    };
    const sharePost = () => {
        navigate('mediaShare');
    };
    const toggleMute = () => {
        setMuted(!muted);
    };

    const postClicked = () => {
        setShowControls(true);
        setTimeout(() => {
            setShowControls(false);
        }, [4000]);
    };

    const Controls = () => {
        return(
            <div className="flex vertical spaceAround" style={{height: '50vh', width: '50px', background: 'white', position: 'fixed', right: '0px', bottom: '55px'}}>
                <button style={{backgroundColor: post.likes.includes(my_id) ? 'red' : 'white'}} onClick={() => likePost()}>like</button>
                <button onClick={() => openPostComments()}>comments</button>
                <button onClick={() => sharePost()}>share</button>
                <button onClick={() => toggleMute()}>mute</button>
            </div>
        );
    };

    return(
        <>
            <div>
             {post.data[0].contentType == "image" && <img onClick={() => postClicked()} className="mediaPost" src={"https://baesapp.s3.eu-central-1.amazonaws.com/"+post.data[0].s3Key}/>}
             {post.data[0].contentType == "video" && <video muted onClick={() => postClicked()} id={post._id} loop playsInline autoPlay className="mediaPost" src={"https://baesapp.s3.eu-central-1.amazonaws.com/"+post.data[0].s3Key}/> }
            </div>
            {showControls && <Controls/>}
            <Routes>
                <Route path="/mediaComments/*" element={<MediaComments currentPost={-1} _post={post} _setPost={setPost}/>}/>
                <Route path="/mediaShare" element={<MediaShare post={post}/>}/>
            </Routes>
        </>
    );
};

const mapStateToProps = state => {
    return({
        my_id: state.my_id
    });
};

export default connect(mapStateToProps)(ChatPostViewer);