import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import baesappApi from '../apis/baesappApi';
import Peer from 'simple-peer';
import { useNavigate } from 'react-router-dom';

import mute from '../assets/mute.svg';
import unmute from '../assets/unmute.svg';
import phone from '../assets/phone.svg';
import urgentSound from '../assets/urgentSound.mp3';

const ReceiveVoiceCall = ({
    socket,
    my_id
}) => {

    const navigate = useNavigate();

    const [mediaStream, setMediaStream] = useState(null);
    const [receivingCall, setReceivingCall] = useState(false);
    const [caller, setCaller] = useState(false);
    const [callerSignal, setCallerSignal] = useState(false);
    const [callAccepted, setCallAccepted] = useState(false);
    const [urgentCall, setUrgentCall] = useState(false);
    const [callId, setCallId] = useState(false);
    const [muted, setMuted] = useState(false);
    const [contactMuted, setContactMuted] = useState(false);
    const [vibrate, setVibrate] = useState(false);
    const [vibrateId, setVibrateId] = useState(false);

    const audio = new Audio(urgentSound);
  
    const playUrgentAudio = () => {
        setVibrate(true);
        audio.play();
    };
  
    const stopUrgentAudio = () => {
        console.log('hit');
        setVibrate(false);
        audio.pause();
        audio.currentTime = 0;
    };

    useEffect(() => {
        if(vibrate){
            setVibrateId(setInterval(() => {
                window.navigator.vibrate(500);
              }, 2000));
        }else{
            clearInterval(vibrateId);
        }
    }, [vibrate]);

    const userAudio = useRef();

    useEffect(() => {
        if ('permissions' in navigator) {
          navigator.permissions.query({ name: 'speaker' })
            .then((permissionStatus) => {
              if (permissionStatus.state === 'prompt') {
                console.log('Permission to play audio requested');
              }
            });
        }
      }, []);

    useEffect(() => {
        if(!receivingCall){
            stopUrgentAudio();
        };
    }, [receivingCall]);

    useEffect(() => {
        socket.on(`incomingVoiceCall${my_id}`, data => {
            if (data.urgentCall) {
                playUrgentAudio();
            } else {
                console.log('hi');
            };
            setReceivingCall(true);
            setCaller(data.memberModal);
            setCallerSignal(data.signal);
            setUrgentCall(data.urgentCall);
            setCallId(data.callId);
        });
        window.addEventListener('beforeunload', endCall);

        socket.on(`callEnded${my_id}`, data => {
            console.log(data);
            if (userAudio.current) {
                userAudio.current.srcObject = null;
            };
            cancelMediaStream();
            setReceivingCall(false);
            setCaller(false);
            setCallerSignal(false);
            setCallAccepted(false);
            setUrgentCall(false);
        });

        socket.on(`callMuted${my_id}`, data => {
            setContactMuted(true);
        });

        socket.on(`callUnMuted${my_id}`, data => {
            setContactMuted(false);
        });

        return () => {
            window.removeEventListener('beforeunload', endCall);
        };
    }, []);

    const getMediaDevices = async () => {
        setMediaStream(await navigator.mediaDevices.getUserMedia({ video: false, audio: true }));
    };

    const cancelMediaStream = () => {
        if (mediaStream) {
            mediaStream.getTracks().forEach(track => track.stop());
            setMediaStream(null);
        }
    };

    useEffect(() => {
        if (mediaStream) {
            setCallAccepted(true);
            const peer = new Peer({
                initiator: false,
                trickle: false,
                stream: mediaStream
            });
            peer.on('signal', data => {
                baesappApi.post('/answerCall', { contactId: caller._id, signal: data, callId })
            });
            peer.on('stream', stream => {
                userAudio.current.srcObject = stream;
            });
            peer.signal(callerSignal);
        }
    }, [mediaStream]);

    const acceptCall = () => {
        stopUrgentAudio();
        getMediaDevices();
    };

    const declineCall = () => {
        cancelMediaStream();
        baesappApi.post('/declineCall', { contactId: caller._id, callId });
        setReceivingCall(false);
        setCaller(false);
        setCallerSignal(false);
        setUrgentCall(false);
    };

    const endCall = () => {
        cancelMediaStream();
        baesappApi.post('/endCall', { contactId: caller._id, callId });
        userAudio.current.srcObject = null;
        setReceivingCall(false);
        setCaller(false);
        setCallerSignal(false);
        setCallAccepted(false);
        setUrgentCall(false);
    }

    const muteClicked = () => {
        if (mediaStream) {
            mediaStream.getTracks().forEach(track => {
                if (track.kind === 'audio') {
                    track.enabled = muted;
                }
            });
            baesappApi.post('/muteCall', { contactId: caller._id, mutedState: !muted ? 'muted' : 'unMuted' })
                .then(res => {
                    setMuted(!muted);
                })
                .catch(err => {
                    console.log(err);
                    window.alert('something unexpected went wrong, please end the call and try again');
                })
        }
    }

    const renderContent = () => {
        if (receivingCall) {
            return (
                <>
                    {!urgentCall && <img className="callProfileShotBackground" src={`https://baesapp.com/media/${caller.profileShot}`} />}
                    {urgentCall && <div style={{ backgroundColor: 'var(--error)', filter: 'brightness(80%)' }} className="callProfileShotBackground" />}
                    <div className='flex vertical alignCenter spaceAround' style={{ position: 'absolute', height: '100vh', width: '100vw', maxWidth: '500px' }}>
                        <div className='flex vertical alignCenter spaceAround'>
                            <img className="callProfileShot" src={`https://baesapp.com/media/${caller.profileShot}`} />
                            <span style={{ fontSize: '1.6em', marginTop: '20px' }}>{caller.username}</span>
                            {!callAccepted && urgentCall ? <span>Incoming Urgent call!</span> : <span>Incoming call</span>}
                            {muted && <span style={{ fontSize: '1.6em', marginTop: '20px' }}>You muted your call</span>}
                            {contactMuted && <span style={{ fontSize: '1.6em', marginTop: '20px' }}>{caller.username} muted their call</span>}
                        </div>
                        <audio ref={userAudio} autoPlay />
                        <div className='flex spaceAround' style={{ width: '100%', maxWidth: '500px' }}>
                            {!callAccepted && <button style={{
                                height: '70px',
                                width: '70px',
                                borderRadius: '50%',
                                backgroundColor: 'var(--error)',
                                display: 'flex',
                                justifyContent: 'space-around',
                                alignItems: 'center'
                            }} onClick={declineCall}>
                                <img style={{ opacity: '0.5', height: '37px' }} src={phone} />
                            </button>}
                            {!callAccepted && <button style={{
                                height: '70px',
                                width: '70px',
                                borderRadius: '50%',
                                backgroundColor: 'var(--success)',
                                display: 'flex',
                                justifyContent: 'space-around',
                                alignItems: 'center'
                            }} onClick={acceptCall}>
                                <img style={{ opacity: '0.5', height: '37px' }} src={phone} />
                            </button>}
                            {callAccepted && <button style={{
                                height: '70px',
                                width: '70px',
                                borderRadius: '50%',
                                backgroundColor: 'white',
                                display: 'flex',
                                justifyContent: 'space-around',
                                alignItems: 'center'
                            }} onClick={muteClicked}>
                                <img style={{ opacity: '0.5', height: '37px' }} src={muted ? mute : unmute} />
                            </button>}
                            {callAccepted && <button style={{
                                height: '70px',
                                width: '70px',
                                borderRadius: '50%',
                                backgroundColor: 'var(--error)',
                                display: 'flex',
                                justifyContent: 'space-around',
                                alignItems: 'center'
                            }} onClick={endCall}>
                                <img style={{ opacity: '0.5', height: '37px' }} src={phone} />
                            </button>}
                        </div>
                    </div>
                </>
            );
        } else {
            return null;
        }
    }

    return (renderContent());
};

const mapStateToProps = state => {
    return ({
        my_id: state.my_id
    });
};

export default connect(mapStateToProps)(ReceiveVoiceCall);
