import React, { useState } from 'react';
import { connect } from 'react-redux';

import ConstructiveButton from '../../../../dumbComponents/Authentication/ConstructiveButton';
import baesappApi from '../../../../apis/baesappApi';
import { setNationalId } from '../../../../redux/actions';
import BaeSvg from '../../../../dumbComponents/BaeSvg';


const InputSection = ({ label, placeholder, type, value, onChange }) => (
    <div style={{ width: '100%' }}>
        <span style={{ color: 'gray' }}>{label}</span>
        <input
            style={{ margin: '5px 0 20px 0' }}
            className="registerInput"
            onKeyDown={e => {
                if (e.key === 'Enter') {
                    // Handle Enter key press if needed
                }
            }}
            autoComplete={type}
            type={type}
            onChange={e => onChange(e.target.value)}
            placeholder={placeholder}
            value={value}
        />
    </div>
);

const SouthAfrica = ({
    setStep,
    setNationalId,
    nationalId
}) => {

    const [nationalIdNumber, setNationalIdNumber] = useState('');
    const [firstNames, setFirstNames] = useState('');
    const [lastname, setLastname] = useState('');
    const [verifyIdentityLoading, setVerifyIdentityLoading] = useState('');
    const [identityInvalid, setIdentityInvalid] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [tellThem, setTellThem] = useState(false);
    const [emailBody, setEmailBody] = useState('');
    const [emailSubject, setEmailSubject] = useState('');
    const [errorActionText, setErrorActionText] = useState('');
    const [tel, setTel] = useState(1);


    const renderVerifyIdButton = () => {
        if (verifyIdentityLoading) return <ConstructiveButton buttonState='loading' />
        if (nationalIdNumber.length == 13 && firstNames !== '' && lastname !== '') {
            return <ConstructiveButton buttonState="active" text="Verify identity" callback={attemptIdentityVerification} />
        } else {
            return <ConstructiveButton buttonState="inactive" text="Verify identity" />
        }
    };

    const renderAlreadyVerified = () => {
        if (nationalId) {
            return (
                <div className="card stepCard" onClick={() => setStep(3)}>
                    <p className="secondaryText">click here to continue as <strong>{nationalId.firstNames}</strong></p>
                </div>
            );
        }
    };

    const attemptIdentityVerification = () => {
        if (nationalIdNumber.length !== 13 || firstNames === '' || lastname === '') return;

        setVerifyIdentityLoading(true);
        setIdentityInvalid(false);
        baesappApi.post('/nationalIdAuth/ZA', { nationalId: nationalIdNumber, firstNames, lastname })
            .then(res => {
                setNationalId(res.data, firstNames);
                setVerifyIdentityLoading(false);
                setStep(3);
            })
            .catch(err => {
                console.log(err.response.data)
                switch (err.response.data) {
                    case 'notUnique':
                        setErrorMessage('There is already a baesapp member with this ID document. Is your identity stolen?');
                        setEmailBody('I noticed a potential issue with stolen identity on BaesApp. Please investigate and take necessary actions.');
                        setEmailSubject('Report Stolen Identity');
                        setErrorActionText('Report stolen identity.');
                        break;
                    case 'nonExistent':
                        setErrorMessage('Your ID document values are entered incorrectly. Are you sure your details are correct?')
                        setEmailBody('I am confident that the reported information is accurate. Please confirm.')
                        setEmailSubject("Confirmation request");
                        setErrorActionText('Yes I am sure.');
                        break;
                    case 'Invalid Body':
                        setErrorMessage('An unexpected error has occured, please try again later.')
                        setEmailBody('I encountered a problem due to an invalid body. Please investigate and provide assistance.');
                        setEmailSubject('Report invalid body');
                        setErrorActionText('Report this problem.');
                        break;
                }
                setVerifyIdentityLoading(false);
                setIdentityInvalid(true);
            })
    }

    const renderErrorMessage = () => {
        return (
            <p className="errorText card">
                {errorMessage}<br />
                <a
                    href={`mailto:support@baesapp.com?subject=${encodeURIComponent(emailSubject)}&body=${encodeURIComponent(emailBody)}`}
                    style={{ color: 'var(--accent)', textDecoration: 'underline' }}
                >
                    {errorActionText}
                </a>
            </p>
        );
    }


    return (
        <div className="container" style={{ justifyContent: 'start', alignSelf: 'center', width: '100vw', maxWidth: '500px', padding: '15px', boxSizing: 'border-box', alignItems: 'start' }}>
            <button style={{ width: 'fit-content' }} onClick={() => setStep(1)}>
                <BaeSvg iconName="chevronDown" color="text" height="50px" width="20px" style={{ transform: 'rotate(90deg)' }} />
            </button>
            <div className="flex vertical" style={{ gap: '10px', marginBottom: '20px' }}>
                <span style={{ fontSize: '30px', fontWeight: 'bold' }}>Great! Let's find out more about you</span>
                <span style={{ fontSize: '1.2em', color: 'gray', fontWeight: 'bold' }}>Please enter your Personal Details exactly as they appear on your official identification document.</span>
            </div>
            {renderAlreadyVerified()}
            <InputSection
                label="National ID number"
                type="number"
                placeholder="Enter National ID"
                value={nationalIdNumber}
                onChange={setNationalIdNumber}
            />

            <InputSection
                label="First Names"
                type="text"
                placeholder="Enter First Names"
                value={firstNames}
                onChange={setFirstNames}
            />

            <InputSection
                label="Surname"
                type="text"
                placeholder="Enter Surname"
                value={lastname}
                onChange={setLastname}
            />
            {renderVerifyIdButton()}
            <span className="authBottomHelper">Why do we request this information? <strong onClick={() => setTellThem(true)} style={{ color: 'var(--accent)', marginLeft: '5px' }}>Learn more</strong></span>
            {tellThem &&
                <div className='authenticationInfo'>
                    <button style={{ width: 'fit-content' }} onClick={() => setTellThem(false)}>
                        <BaeSvg iconName="chevronDown" color="text" height="50px" width="20px" style={{ transform: 'rotate(90deg)' }} />
                    </button>
                    <span style={{ fontSize: '30px', fontWeight: 'bold' }}>Your Privacy and Safety Matter to Us</span>
                    <p style={{ fontSize: '1.2em', color: 'gray' }}>
                        <span style={{ color: 'var(--text)', fontWeight: 'bold' }}>Why We Need Your Info:</span><br />
                        We request your details to ensure authenticity and promote respectful behavior in our community. Your information helps verify identities and encourages accountability.
                    </p>
                    <p style={{ fontSize: '1.2em', color: 'gray' }}>
                        <span style={{ color: 'var(--text)', fontWeight: 'bold' }}>How We Keep It Secure:</span><br />
                        We safeguard your info using a complex code (SHA-256) that's extremely difficult to decipher. Additionally, we use a secure method (HTTPS) for transmitting info over the internet, ensuring its protection during transfer.
                    </p>
                    <p style={{ fontSize: '1.2em', color: 'gray' }}>
                        <span style={{ color: 'var(--text)', fontWeight: 'bold' }}>Your Privacy Matters:</span><br />
                        We prioritize your privacy. If you have any questions about how we handle your information, feel free to ask our support team at{' '}
                        <a href="mailto:support@baesapp.com?subject=Privacy%20Inquiry" style={{ color: 'var(--accent)', textDecoration: 'underline' }}>
                            support@baesapp.com
                        </a>.
                        We're here to assist and maintain a secure environment for you.
                    </p>
                </div>
            }
            {identityInvalid && renderErrorMessage()}
        </div>
    );

    // return(
    //     <div className="clamp">
    //         <p className='secondaryText'>
    //             <strong>NOTE:</strong><br/>
    //             {identityInvalid && renderErrorMessage()}
    //             Please enter the values <strong>exactly</strong><br/> as they appear on you South African <br/> national ID document.</p>
    //             <input 
    //                 onChange={e => setNationalIdNumber(e.target.value)}
    //                 value={nationalIdNumber}
    //                 inputprops={{ inputMode: 'numeric' }}
    //                 className="lineInput" 
    //                 inputMode="numerical" 
    //                 placeholder="ID number" 
    //                 onKeyPress={(e) => {
    //                     if (!/[0-9]/.test(e.key)) {
    //                     e.preventDefault();
    //                     }
    //                     if (e.key === 'Enter') {
    //                         attemptIdentityVerification();
    //                     }
    //             }}/>
    //         <input className="lineInput" type="text" onKeyDown={e => {if(e.key === "Enter"){attemptIdentityVerification()}}} onChange={e => setFirstNames(e.target.value)} value={firstNames} placeholder="First names"/>
    //         <input className="lineInput" type="text" onKeyDown={e => {if(e.key === "Enter"){attemptIdentityVerification()}}} onChange={e => setLastname(e.target.value)} value={lastname} placeholder="Surname"/>
    //         {renderVerifyIdButton()}
    //     </div>
    // );
};

export default connect(null, { setNationalId })(SouthAfrica);
