import React, { useEffect, useState } from "react";

import { connect } from 'react-redux';
import { Routes, Route, useNavigate } from 'react-router-dom';

import searchBoxIcon from '../../../assets/searchBoxIcon.png'
import searchBoxIconDark from '../../../assets/searchBoxIconDark.png'
import clearText from '../../../assets/clearText.png'
import clearTextDark from '../../../assets/clearTextDark.png'

import baesappApi from '../../../apis/baesappApi';

import MemberModal from "../../../dumbComponents/MemberModal";
import normalizeDate from "../../../hooks/normalizeDate";
import setLocalObject from "../../../hooks/setLocalObject";
import getLocalToObject from "../../../hooks/getLocalToObject";
import Explore from "./Explore";
import { setVisitMember } from "../../../redux/actions";


const OpenChat = ({
    darkModeEnabled,
    myOpenChats,
    membersTypingOpenChat,
    my_id,
    setVisitMember
}) => {

    const navigate = useNavigate();
    const [filteredOpenChats, setFilteredOpenChats] = useState(false);
    const [memberIds, setMemberIds] = useState(false);
    const [shouldShowCancel, setShouldShowCancel] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [searchInputElement, setSearchInputElement] = useState();
    const [baseOpenChats, setBaseOpenChats] = useState();                                                                                        

    useEffect(() => {
        setSearchInputElement(document.getElementById('searchInput'));
    }, []);

    useEffect(() => {
        if (myOpenChats) {

            let Ids = [];
            myOpenChats.map(openChat => {
                Ids.push(openChat.member);
            });
            setMemberIds(getLocalToObject('openChatMemberIds'));
            baesappApi.post('/getMemberIds', { Ids })
                .then(res => {
                    const newMemberIds = {};
                    res.data.map(member => {
                        newMemberIds[member._id] = member.memberId;
                    });
                    setLocalObject('openChatMemberIds', newMemberIds);
                    setMemberIds(newMemberIds);
                })
                .catch(err => {
                    console.log(err);
                });
        }
    }, [myOpenChats]);

    useEffect(() => {
        if (filteredOpenChats) {
            let tempFilteredOpenChats = [];
            tempFilteredOpenChats = baseOpenChats.filter(openChat => openChat.username.toUpperCase().includes(searchText.toUpperCase()));
            setFilteredOpenChats(tempFilteredOpenChats);
        }
    }, [searchText]);

    useEffect(() => {
        if (memberIds) {
            populateFilteredOpenChats();
        }
    }, [memberIds]);

    const handleOnBlur = (blurredCause) => {
        if (blurredCause == 'cancel') {
            setSearchText('');
            setShouldShowCancel(false);
        } else if (blurredCause == 'clear') {
            setSearchText('');
            searchInputElement.focus();
        }
    };

    const memberProfileShotClicked = (filteredOpenChat) => {
        let member = { ...filteredOpenChat };
        member._id = filteredOpenChat.member_id;
        setLocalObject('memberModal', member);
        navigate('memberModal');
    };

    const visitMember = (filteredOpenChat) => {
        let member = { ...filteredOpenChat };
        member._id = filteredOpenChat.member_id;
        setVisitMember({
            path: 'chat',
            memberEssentials: member
        });
        navigate(`/visit/openChat?_id=${member._id}`);
    };

    const populateFilteredOpenChats = () => {
        let newFilteredOpenChats = [];

        myOpenChats.map(openChat => {
            let openChatData = {};

            openChatData['username'] = memberIds[openChat.member].username;
            openChatData['member_id'] = openChat.member;
            openChatData['profileShot'] = memberIds[openChat.member].profileShot;
            openChatData['preferredName'] = memberIds[openChat.member].preferredName;
            openChatData['dateTime'] = openChat.messages[openChat.messages.length - 1].dateTime;
            openChatData['text'] = openChat.messages[openChat.messages.length - 1].text;
            openChatData['sender'] = openChat.messages[openChat.messages.length - 1].sender;
            openChatData['viewers'] = openChat.totalIndividualReads;

            newFilteredOpenChats.push(openChatData);
        });

        newFilteredOpenChats = newFilteredOpenChats.sort(function(a,b){
            return new Date(b.dateTime) - new Date(a.dateTime);
          });

        setBaseOpenChats(newFilteredOpenChats);
        setFilteredOpenChats(newFilteredOpenChats);
    };

    let openChatsList = null;
    if (filteredOpenChats) {
        openChatsList = filteredOpenChats.map(filteredOpenChat => {
            return (
                <>
                    <button key={filteredOpenChat.username} className="flex horizontal chatCard" >
                        <div className="flex horizontal chatCardLeft">
                            <img onClick={() => memberProfileShotClicked(filteredOpenChat)} className="memberBarProfileShot" src={`https://baesapp.com/media/${filteredOpenChat.profileShot}`} />
                            <div onClick={() => visitMember(filteredOpenChat)} className="flex vertical chatCardMainContent">
                                <span className="chatCardUsername">{filteredOpenChat.username}</span>
                                {
                                    membersTypingOpenChat.includes(filteredOpenChat.member_id) ?
                                        <div className="chatCardContentContainer">
                                            <span className="typingText">typing...</span>
                                        </div> :
                                        <div className="chatCardContentContainer">
                                            <span style={filteredOpenChat.sender == my_id ? {} : {color: 'var(--accent50)'}} className="chatCardContent">{filteredOpenChat.sender == my_id ? 'you:' : `${filteredOpenChat.username}:`}</span>
                                            <pre style={{margin: '0'}}> </pre><span className="chatCardContent">{filteredOpenChat.text}</span>
                                        </div>
                                }
                            </div>
                        </div>
                        <div onClick={() => visitMember(filteredOpenChat)} className="flex vertical chatCardRight">
                            <span>{normalizeDate(filteredOpenChat.dateTime, false, true)}</span>
                        </div>
                    </button>
                    <div className="chatCardSeparator"></div>
                </>
            );
        });
    };


    const renderOpenChats = () => {
        if (filteredOpenChats) {
            if (filteredOpenChats.length) {
                return openChatsList;
            } else {
                return (
                    <span className="centerText noResults">No Results</span>
                );
            }
        } else {
            return null;
        }
    }

    const Main = () => {
        return (
            <div className="fullScreen flex removeScrollBar vertical" style={{ width: '100vw', maxWidth: '500px', overflow: 'scroll' }}>
                <div className="flex vertical">
                    <div style={{ minHeight: '60px' }} />
                    <div className="searchBarContainer" style={{ width: '100%' }}>
                        <div className="searchBar" style={shouldShowCancel ? { marginLeft: '10px' } : {}}>
                            <img className="searchBarIcon" src={darkModeEnabled ? searchBoxIcon : searchBoxIconDark} />
                            <input
                                onFocus={() => setShouldShowCancel(true)}
                                id="searchInput"
                                placeholder="Search..."
                                value={searchText}
                                onChange={e => setSearchText(e.target.value)}
                                className={`cleanInput searchBarInput`} />
                            {searchText != '' && <img onClick={() => handleOnBlur('clear')} className="searchBarClear" src={darkModeEnabled ? clearTextDark : clearText} />}
                        </div>
                        {shouldShowCancel && <span onClick={() => handleOnBlur('cancel')} className="searchBarCancelText">
                            Cancel
                        </span>}
                    </div>
                    {renderOpenChats()}
                    <div className="e2eeContainer">
                        <h2>WARNING:</h2>
                        <p>Open chat messages are not end-to-end encrypted.</p>
                        <p>For end-to-end encrypted messaging, request members as contacts and message them using</p>
                        <p><strong onClick={() => navigate('/home/chat')} style={{color: 'var(--accent)'}}>Chat</strong></p>
                    </div>
                    <div style={{ minHeight: '50px' }} />
                </div>
            </div>
        );
    }

    return (
        <Routes>
            <Route path="/*" element={Main()} />
            <Route path="/memberModal/*" element={<MemberModal />} />
            <Route path="/explore/*" element={<Explore />} />
        </Routes>
    );

};

const mapStateToProps = state => {
    return ({
        darkModeEnabled: state.darkModeEnabled,
        myOpenChats: state.myOpenChats,
        membersTypingOpenChat: state.membersTypingOpenChat,
        my_id: state.my_id
    });
};

export default connect(mapStateToProps, {
    setVisitMember
})(OpenChat);
