import decryptMessage from "./decryptMessage";
import setLocalObject from "./setLocalObject";
import getLocalToObject from "./getLocalToObject";

const setStartNew = (newMessages) => {
    let clientChats = getLocalToObject('chats');
    for(const key in newMessages){
        let decryptedMessages = [];
        newMessages[key].map(message => {
            console.log(message);
            let decryptedMessage = {...message};
            console.log('main: '+decryptedMessage.text);
            decryptedMessage.text = decryptMessage(decryptedMessage.text, key);
            if(message.replying){
                console.log('replying: '+decryptedMessage.replying.messageText);
                decryptedMessage.replying.messageText = decryptMessage(decryptedMessage.replying.messageText, key);
            }
            decryptedMessages.push(decryptedMessage);
            console.log('do');
        });
        clientChats[key].conversation.messages.unshift(...decryptedMessages);
    };
    setLocalObject('chats', clientChats);
};

export default setStartNew;