import React, { useEffect, useState } from "react";

import { connect } from 'react-redux';

import { useNavigate } from "react-router-dom";

import myPostsModalIcon from '../../assets/myPostsModalIcon.png';
import followingModalIcon from '../../assets/followingModalIcon.svg';
import myFaceModalIcon from '../../assets/myFaceModalIcon.png';
import editIcon from '../../assets/editIcon.png';
import archive from '../../assets/archive.svg';
import phone from '../../assets/phone.svg';
import swipeHistory from '../../assets/swipeHistory.png';
import swipeHistoryDark from '../../assets/swipeHistoryDark.png';
import controls from '../../assets/controls.png';
import controlsDark from '../../assets/controlsDark.png';
import myMatcherProfile from '../../assets/myMatcherProfile.png';
import myMatcherProfileDark from '../../assets/myMatcherProfileDark.png';


import myPostsModalIconDark from '../../assets/myPostsModalIconDark.png';
import followingModalIconDark from '../../assets/followingModalIconDark.svg';
import myFaceModalIconDark from '../../assets/myFaceModalIconDark.png';
import editIconDark from '../../assets/editIconDark.png';
import archiveDark from '../../assets/archiveDark.svg';
import phoneDark from '../../assets/phoneDark.svg';
import contacts from '../../assets/contacts.svg';
import contactsDark from '../../assets/contactsDark.svg';


import editIconAccent from '../../assets/editIconAccent.png';

import { setShowNotificationBar, setFollowingModalShowFollowers } from '../../redux/actions';
import setLocalObject from "../../hooks/setLocalObject";
import BaeSvg from "../../dumbComponents/BaeSvg";

const TopBar = ({
    myId,
    showNotificationBar,
    setShowNotificationBar,
    setFollowingModalShowFollowers,
    darkModeEnabled,
    editChats,
    myChat,
    topBarOffset
}) => {

    const [totalIncomingContactRequests, setTotalIncomingContactRequests] = useState(0);



    useEffect(() => {
        if (myChat) {
            setTotalIncomingContactRequests(myChat.requestsIncoming.length);
        }
    }, [myChat.requestsIncoming]);

    const path = window.location.pathname;
    const navigate = useNavigate();
    const buttonHeight = '23px';

    const renderMediaRightButtons = () => {
        return (
            <div className="topBarHomeRight flex horizonal spaceAround alignCenter">
                <button onClick={() => navigate('/home/media/myPosts')} >
                    <BaeSvg color="text" iconName='myPosts' height={buttonHeight} />
                </button>
                <button onClick={() => navigate('/home/media/saved')} >
                    <BaeSvg color="text" iconName='star' height={buttonHeight} />
                </button>
                <button onClick={() => navigate('/home/media/following')} >
                    <BaeSvg color="text" iconName='person' height={buttonHeight} />
                </button>
            </div>
        );
    };
    const renderSearchRightButtons = () => {
        return (
            <div className="topBarHomeRight flex horizonal spaceAround alignCenter" style={{ justifyContent: 'flex-end' }}>
                <button onClick={() => navigate('/home/search/searchFilter')} >
                    <BaeSvg color="text" iconName='filter' height={buttonHeight} width={buttonHeight} />
                </button>
            </div>
        );
    };

    const renderWalletRightButtons = () => {
        return (
            <div className="topBarHomeRight flex horizonal spaceAround alignCenter">
                <button onClick={() => navigate('/home/wallet/withdrawZAR')} >
                    <BaeSvg color="text" iconName='withdraw' height={buttonHeight} />
                </button>
                <button onClick={() => navigate('/home/wallet/depositZAR')} >
                    <BaeSvg color="text" iconName='deposit' height={buttonHeight} />
                </button>
            </div>
        );
    };

    const renderEventsRightButtons = () => {
        return (
            <div className="topBarHomeRight flex horizonal spaceAround alignCenter">
                <button onClick={() => navigate('/home/events/createEvent')} >
                    <BaeSvg color="text" iconName='plus' height={buttonHeight} />
                </button>
            </div>
        );
    };

    const renderChatRightButtons = () => {
        return (
            <div className="topBarHomeRight flex horizonal spaceAround alignCenter">
                <button onClick={() => navigate('/home/chat/calls')} >
                    <BaeSvg color="text" iconName='phone' height={buttonHeight} />
                </button>
                <button onClick={() => navigate('/home/chat/contacts')} >
                    <BaeSvg color="text" iconName='person' height={buttonHeight} />
                </button>
                {
                    totalIncomingContactRequests > 0 &&
                    <div className="notificationBubble">
                        {totalIncomingContactRequests}
                    </div>
                }
            </div>
        );

    };
    const renderOpenChatRightButtons = () => {
        return (
            <div className="topBarHomeRight flex horizonal spaceAround alignCenter">
                <img className="modalButtonIcon" onClick={() => navigate('/home/openChat/explore')} src={darkModeEnabled ? phoneDark : phone} />
            </div>
        );
    };
    const renderMatcherRightButtons = () => {
        return (
            <div className="topBarHomeRight flex horizonal spaceAround alignCenter">
                <img className="modalButtonIcon" onClick={() => navigate('/home/matcher/controls')} src={darkModeEnabled ? controlsDark : controls} />
                <img className="modalButtonIcon" onClick={() => navigate('/home/matcher/swipeHistory')} src={darkModeEnabled ? swipeHistoryDark : swipeHistory} />
                <img className="modalButtonIcon" onClick={() => navigate('/home/matcher/myCard')} src={darkModeEnabled ? myMatcherProfileDark : myMatcherProfile} />
            </div>
        );
    };

    const getCurrentTool = () => {
        if (path.startsWith('/home/media')) {
            return 'Media'
        };
        if (path.startsWith('/home/search')) {
            return 'Search'
        };
        if (path.startsWith('/home/chat')) {
            return 'Chat'
        };
        if (path.startsWith('/home/wallet')) {
            return 'Wallet'
        };
        if (path.startsWith('/home/info')) {
            return 'Info'
        };
        if (path.startsWith('/home/events')) {
            return 'Events'
        };
        if (path.startsWith('/home/openChat')) {
            return 'Open Chat'
        };
        if (path.startsWith('/home/doorbell')) {
            return 'Bell'
        };
        if (path.startsWith('/home/matcher')) {
            return 'Matcher'
        };
        if (path.startsWith('/home/games')) {
            return 'Games'
        };
    };
    const aboutToolClicked = () => {
        if (path.startsWith('/home/media')) {
            return
        };
        if (path.startsWith('/home/search')) {
            return
        };
        if (path.startsWith('/home/chat')) {
            navigate('/home/chat/about');
        };
        if (path.startsWith('/home/wallet')) {
            return
        };
        if (path.startsWith('/home/info')) {
            return
        };
        if (path.startsWith('/home/events')) {
            return
        };
        if (path.startsWith('/home/openChat')) {
            return
        };
        if (path.startsWith('/home/doorbell')) {
            return
        };
        if (path.startsWith('/home/matcher')) {
            return
        };
    };

    const renderRightButtons = () => {
        if (path.startsWith('/home/media')) {
            return renderMediaRightButtons();
        };
        if (path.startsWith('/home/search')) {
            return renderSearchRightButtons();
        };
        if (path.startsWith('/home/chat')) {
            return renderChatRightButtons();
        };
        if (path.startsWith('/home/openChat')) {
            return renderOpenChatRightButtons();
        };
        if (path.startsWith('/home/matcher')) {
            return renderMatcherRightButtons();
        };
        if (path.startsWith('/home/wallet')) {
            return renderWalletRightButtons();
        };
        if (path.startsWith('/home/events')) {
            return renderEventsRightButtons();
        };
    };


    return (
        <div className={'topBar fixed flex horizontal'}
            style={{
                transform: `translateY(${topBarOffset}px)`,
                transitionDuration: '300ms',
                backgroundColor: darkModeEnabled ? '#00000059' : '#ffffff59' ,
                border: 'none',
                height: '50px'
            }}>
            <div className="topBarContentContainer">
                <button onClick={aboutToolClicked} className="topBarHomeLeft">
                </button>
                {renderRightButtons()}
            </div>
        </div>
    );
};

const mapStateToProps = state => {
    return ({
        myId: state.myId,
        showNotificationBar: state.showNotificationBar,
        darkModeEnabled: state.darkModeEnabled,
        editChats: state.editChats,
        myChat: state.myChat,
        topBarOffset: state.topBarOffset
    });
};

export default connect(mapStateToProps, {
    setShowNotificationBar,
    setFollowingModalShowFollowers
})(TopBar);
