import React, { useEffect, useState } from "react";

import { connect } from 'react-redux';

import { Routes, Route, useNavigate } from 'react-router-dom';

import baesappApi from '../../../apis/baesappApi';
import ModalHeader from "../../../dumbComponents/Home/modalHeader";
import BaeSvg from "../../../dumbComponents/BaeSvg";

const Loans = ({
}) => {

    const navigate = useNavigate();

    console.log('hi there');

    return (
        <>
            <div className='fullScreen removeScrollBar modalContainer'>
                <div className='flex vertical'>
                    <div style={{ minHeight: '110px' }} />
                    <div className="flex vertical"
                        style={{
                            backgroundColor: 'var(--text)',
                            color: 'var(--base)',
                            minHeight: '150px',
                            padding: '20px',
                            boxSizing: 'border-box'
                        }}>
                        <span style={{
                            color: 'var(--secondary)',
                            fontSize: '1.3em',
                            fontWeight: 'bold'
                        }}>Outstanding debt</span>
                        <span style={{ fontWeight: 'bold', fontSize: '2.4em' }}>R1,130.23</span>
                    </div>
                    <div className="flex spaceAround"
                        style={{
                            width: '100%',
                            transform: 'translateY(-50%)',
                            marginBottom: '-70px',
                            zIndex: '1'
                        }}>
                        <button
                            onClick={() => navigate('createMagicCash')}
                            className="flex alignCenter walletActionButton">
                            <BaeSvg height="20px" iconName="plus" color="accent" />
                            <span>New Loan</span>
                        </button>
                    </div>
                    <ModalHeader title={'LOANS'} actionName={''} actionActive={false} actionFunction={() => { }} />
                </div>
            </div>
        </>
    );
};

const mapStateToProps = state => {
    return ({
    });
};

export default connect(mapStateToProps)(Loans);