import React from "react";

const LoadingDrop = ({
}) => {
    return (
        <>
            <div className="loadingDrop1"></div>
        </>
    );
};
export default LoadingDrop;