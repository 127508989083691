const registrationEmailReducer = (registrationEmail = false, action) => {
    switch(action.type){
        case 'EMAIL_REGISTRATION_COMPLETE':
            return action.payload;
            case 'CLEAR_ALL':
                    return false;
        default:
            return registrationEmail;
    }
};

export default registrationEmailReducer;