import { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import baesappApi from '../../../apis/baesappApi';

import { useNavigate, Route, Routes } from 'react-router-dom';
import ModalHeader from '../../../dumbComponents/Home/modalHeader';
import normalizeDate from '../../../hooks/normalizeDate';

import e2ee from '../../../assets/e2ee.svg';
import memberVerified from '../../../assets/memberVerified.svg';
import noSpam from '../../../assets/noSpam.svg';
import chatActive from '../../../assets/chatActive.svg';
import aboutUrgentCall from '../../../assets/aboutUrgentCall.svg';
import filter from '../../../assets/filter.svg';
import BaeSvg from '../../../dumbComponents/BaeSvg';

const About = ({
    myChat,
    my_id
}) => {

    const navigate = useNavigate();

    /*
       Problems that Chat solve:
   
       end-to-end encrypted and able to log in from any device.
       National ID based contacts, you know who you are talking to and no one can make fake accounts becuase of this.
       Must request and accept someone as a contact before you can call or message them. Thus no spam.
       Call can be either a normal call or an urgent call, letting the person know that it is important to answer the call.
    */

    return (
        <div className='fullScreen removeScrollBar modalContainer flex vertical' style={{ gap: '10px' }}>
            <div style={{ minHeight: '100px' }} />
            <div className='aboutText'>
                <div className='flex vertical alignCenter'>
                    <svg style={{isolation: "isolate"}} viewBox="862.921 522.5 148.158 62" width="148.158pt" height="62pt">
                        <defs>
                            <clipPath id="_clipPath_QIurwjyeBSTMOTT4iZAaG8FTijw7ZZvs">
                                <rect x="862.921" y="522.5" width="148.158" height="62" />
                            </clipPath>
                        </defs>
                        <g clip-path="url(#_clipPath_QIurwjyeBSTMOTT4iZAaG8FTijw7ZZvs)">
                            <path
                                d="M 933.872 548.879 L 933.872 548.879 C 934.506 549.978 934.129 551.385 933.031 552.02 L 905.608 567.852 C 904.509 568.486 903.102 568.109 902.468 567.011 L 902.468 567.011 C 901.834 565.912 902.211 564.505 903.309 563.87 L 930.732 548.038 C 931.831 547.404 933.238 547.781 933.872 548.879 Z"
                                style={{stroke:'none',fill:'#808080',strokeMiterlimit:'10'}} />
                            <path
                                d="M 973.982 567.851 L 973.982 567.851 C 973.348 568.949 971.941 569.326 970.843 568.692 L 943.429 552.86 C 942.331 552.225 941.954 550.818 942.588 549.719 L 942.588 549.719 C 943.222 548.621 944.629 548.244 945.727 548.878 L 973.141 564.71 C 974.239 565.345 974.616 566.752 973.982 567.851 Z"
                                style={{stroke:'none',fill:'#808080',strokeMiterlimit:'10'}} />
                            <path
                                d=" M 881.425 584.5 L 899.367 584.5 C 905.618 584.5 910.693 579.425 910.693 573.174 L 910.693 573.174 C 910.693 566.924 905.618 561.849 899.367 561.849 L 874.246 561.849 C 867.996 561.849 862.921 566.924 862.921 573.174 L 862.921 573.174 L 862.921 573.174 L 862.921 573.174 L 862.921 584.5 L 874.246 584.5 L 881.425 584.5 Z "
                                fill-rule="evenodd" fill="rgb(128,128,128)" />
                            <path
                                d=" M 992.95 584.5 L 975.371 584.5 C 969.247 584.5 964.275 579.528 964.275 573.404 L 964.275 573.404 C 964.275 567.28 969.247 562.308 975.371 562.308 L 999.983 562.308 C 1006.107 562.308 1011.079 567.28 1011.079 573.404 L 1011.079 573.404 L 1011.079 573.404 L 1011.079 573.404 L 1011.079 584.5 L 999.983 584.5 L 992.95 584.5 Z "
                                fill-rule="evenodd" fill="rgb(128,128,128)" />
                            <path
                                d=" M 938.093 522.5 C 935.492 522.5 933.243 523.58 931.84 525.331 C 930.441 527.084 929.855 529.354 929.855 531.804 L 929.855 533.091 L 933.385 533.091 L 933.385 531.804 C 933.385 529.947 933.844 528.485 934.599 527.538 C 935.352 526.592 936.378 526.03 938.093 526.03 C 939.811 526.03 940.832 526.56 941.587 527.501 C 942.34 528.443 942.8 529.925 942.8 531.804 L 942.8 533.091 L 946.33 533.091 L 946.33 531.804 C 946.33 529.341 945.71 527.046 944.307 525.295 C 942.906 523.544 940.69 522.5 938.093 522.5 Z  M 929.855 534.268 C 927.905 534.268 926.325 535.849 926.325 537.799 L 926.325 549.567 C 926.325 551.516 927.905 553.097 929.855 553.097 L 946.33 553.097 C 948.28 553.097 949.861 551.516 949.861 549.567 L 949.861 537.799 C 949.861 535.849 948.28 534.268 946.33 534.268 L 929.855 534.268 Z "
                                fill="rgb(67,142,255)" />
                            <path
                                d=" M 982.516 572.238 L 982.516 568.914 C 982.516 568.135 981.888 567.502 981.115 567.502 L 981.115 567.502 L 981.115 567.502 L 981.115 567.502 L 981.115 567.502 C 980.342 567.502 979.714 568.135 979.714 568.914 L 979.714 578.276 C 979.714 579.055 980.342 579.688 981.115 579.688 L 981.115 579.688 C 981.888 579.688 982.516 579.055 982.516 578.276 L 982.516 574.653 L 987.009 574.653 L 987.009 578.276 C 987.009 579.055 987.636 579.688 988.41 579.688 L 988.41 579.688 C 989.183 579.688 989.811 579.055 989.811 578.276 L 989.811 568.914 C 989.811 568.135 989.183 567.502 988.41 567.502 L 988.41 567.502 L 988.41 567.502 L 988.41 567.502 L 988.41 567.502 C 987.636 567.502 987.009 568.135 987.009 568.914 L 987.009 572.238 L 982.516 572.238 Z  M 994.14 570.502 L 994.14 570.502 C 994.913 570.502 995.541 571.13 995.541 571.903 L 995.541 578.287 C 995.541 579.06 994.913 579.688 994.14 579.688 L 994.14 579.688 C 993.367 579.688 992.739 579.06 992.739 578.287 L 992.739 571.903 C 992.739 571.13 993.367 570.502 994.14 570.502 L 994.14 570.502 L 994.14 570.502 Z  M 994.14 566.661 L 994.14 566.661 C 994.968 566.661 995.64 567.333 995.64 568.161 L 995.64 568.161 C 995.64 568.989 994.968 569.661 994.14 569.661 L 994.14 569.661 C 993.312 569.661 992.64 568.989 992.64 568.161 L 992.64 568.161 C 992.64 567.333 993.312 566.661 994.14 566.661 L 994.14 566.661 Z "
                                fill-rule="evenodd" fill="rgb(235,235,235)" />
                            <path
                                d="M 931.765 540.63 L 931.765 540.63 C 932.077 540.63 932.33 540.884 932.33 541.197 L 932.33 544.823 C 932.33 545.136 932.077 545.39 931.765 545.39 L 931.765 545.39 C 931.453 545.39 931.2 545.136 931.2 544.823 L 931.2 541.197 C 931.2 540.884 931.453 540.63 931.765 540.63 Z"
                                style={{stroke:'none',fill:'#EBEBEB',strokeMiterlimit:'10'}} />
                            <path
                                d="M 933.824 541.803 L 933.824 541.803 C 933.98 542.073 933.888 542.419 933.617 542.575 L 930.479 544.384 C 930.209 544.54 929.862 544.448 929.706 544.177 L 929.706 544.177 C 929.55 543.907 929.642 543.561 929.913 543.405 L 933.051 541.596 C 933.321 541.44 933.668 541.532 933.824 541.803 Z"
                                style={{stroke:'none',fill:'#EBEBEB',strokeMiterlimit:'10'}} />
                            <path
                                d="M 933.824 544.217 L 933.824 544.217 C 933.668 544.488 933.321 544.58 933.051 544.424 L 929.913 542.615 C 929.642 542.459 929.55 542.113 929.706 541.843 L 929.706 541.843 C 929.862 541.572 930.209 541.48 930.479 541.636 L 933.617 543.445 C 933.888 543.601 933.98 543.947 933.824 544.217 Z"
                                style={{stroke:'none',fill:'#EBEBEB',strokeMiterlimit:'10'}} />
                            <path
                                d="M 938.095 540.63 L 938.095 540.63 C 938.407 540.63 938.66 540.884 938.66 541.197 L 938.66 544.823 C 938.66 545.136 938.407 545.39 938.095 545.39 L 938.095 545.39 C 937.783 545.39 937.53 545.136 937.53 544.823 L 937.53 541.197 C 937.53 540.884 937.783 540.63 938.095 540.63 Z"
                                style={{stroke:'none',fill:'#EBEBEB',strokeMiterlimit:'10'}} />
                            <path
                                d="M 940.154 541.803 L 940.154 541.803 C 940.31 542.073 940.218 542.419 939.947 542.575 L 936.809 544.384 C 936.539 544.54 936.192 544.448 936.036 544.177 L 936.036 544.177 C 935.88 543.907 935.972 543.561 936.243 543.405 L 939.381 541.596 C 939.651 541.44 939.998 541.532 940.154 541.803 Z"
                                style={{stroke:'none',fill:'#EBEBEB',strokeMiterlimit:'10'}} />
                            <path
                                d="M 940.154 544.217 L 940.154 544.217 C 939.998 544.488 939.651 544.58 939.381 544.424 L 936.243 542.615 C 935.972 542.459 935.88 542.113 936.036 541.843 L 936.036 541.843 C 936.192 541.572 936.539 541.48 936.809 541.636 L 939.947 543.445 C 940.218 543.601 940.31 543.947 940.154 544.217 Z"
                                style={{stroke:'none',fill:'#EBEBEB',strokeMiterlimit:'10'}} />
                            <path
                                d="M 944.42 540.67 L 944.42 540.67 C 944.735 540.67 944.99 540.923 944.99 541.235 L 944.99 544.855 C 944.99 545.167 944.735 545.42 944.42 545.42 L 944.42 545.42 C 944.105 545.42 943.85 545.167 943.85 544.855 L 943.85 541.235 C 943.85 540.923 944.105 540.67 944.42 540.67 Z"
                                style={{stroke:'none',fill:'#EBEBEB',strokeMiterlimit:'10'}} />
                            <path
                                d="M 946.474 541.843 L 946.474 541.843 C 946.63 542.113 946.538 542.459 946.268 542.615 L 943.137 544.424 C 942.867 544.58 942.522 544.488 942.366 544.217 L 942.366 544.217 C 942.21 543.947 942.302 543.601 942.572 543.445 L 945.703 541.636 C 945.973 541.48 946.318 541.572 946.474 541.843 Z"
                                style={{stroke:'none',fill:'#EBEBEB',strokeMiterlimit:'10'}} />
                            <path
                                d="M 946.474 544.256 L 946.474 544.256 C 946.318 544.527 945.973 544.621 945.703 544.464 L 942.572 542.649 C 942.302 542.492 942.21 542.145 942.366 541.874 L 942.366 541.874 C 942.522 541.603 942.867 541.509 943.137 541.666 L 946.268 543.481 C 946.538 543.638 946.63 543.985 946.474 544.256 Z"
                                style={{stroke:'none',fill:'#EBEBEB',strokeMiterlimit:'10'}} />
                            <path
                                d=" M 881.646 572.238 L 881.646 568.914 C 881.646 568.135 881.018 567.502 880.245 567.502 L 880.245 567.502 L 880.245 567.502 L 880.245 567.502 L 880.245 567.502 C 879.472 567.502 878.844 568.135 878.844 568.914 L 878.844 578.276 C 878.844 579.055 879.472 579.688 880.245 579.688 L 880.245 579.688 C 881.018 579.688 881.646 579.055 881.646 578.276 L 881.646 574.653 L 886.138 574.653 L 886.138 578.276 C 886.138 579.055 886.766 579.688 887.539 579.688 L 887.539 579.688 C 888.313 579.688 888.94 579.055 888.94 578.276 L 888.94 568.914 C 888.94 568.135 888.313 567.502 887.539 567.502 L 887.539 567.502 L 887.539 567.502 L 887.539 567.502 L 887.539 567.502 C 886.766 567.502 886.138 568.135 886.138 568.914 L 886.138 572.238 L 881.646 572.238 Z  M 893.27 570.502 L 893.27 570.502 C 894.043 570.502 894.671 571.13 894.671 571.903 L 894.671 578.287 C 894.671 579.06 894.043 579.688 893.27 579.688 L 893.27 579.688 C 892.496 579.688 891.869 579.06 891.869 578.287 L 891.869 571.903 C 891.869 571.13 892.496 570.502 893.27 570.502 L 893.27 570.502 L 893.27 570.502 Z  M 893.27 566.661 L 893.27 566.661 C 894.098 566.661 894.77 567.333 894.77 568.161 L 894.77 568.161 C 894.77 568.989 894.098 569.661 893.27 569.661 L 893.27 569.661 C 892.442 569.661 891.77 568.989 891.77 568.161 L 891.77 568.161 C 891.77 567.333 892.442 566.661 893.27 566.661 L 893.27 566.661 Z "
                                fill-rule="evenodd" fill="rgb(235,235,235)" />
                        </g>
                    </svg>
                    <p>Our end-to-end encrypted platform allows for secure communication from any device.</p>
                </div>
                <div className='flex vertical alignCenter'>
                    <img src={memberVerified} />
                    <p>With national ID based contacts, you can be sure you're talking to who you think you are, and fake accounts are a thing of the past.</p>
                </div>
                <div className='flex vertical alignCenter'>
                    <img src={noSpam} />
                    <p>Our system requires a contact request and acceptance before allowing any communication, ensuring a spam-free experience.</p>
                </div>
                <div className='flex vertical alignCenter'>
                    <img src={aboutUrgentCall} />
                    <p>Need to make an urgent call? Baesapp Chat offers the option to send an alert to the receiver, letting them know the call is urgent.</p>
                </div>
                <div className='flex vertical alignCenter'>
                    <BaeSvg iconName='filter' height='82px' width='fit-content' color='accent'/>
                    <p>You can create your own filters and add members to display only certain groups of chats at a time, for a more organized messaging experience.</p>
                </div>
                <div className='flex vertical alignCenter'>
                    <img src={chatActive} />
                    <p>With all of these features and more, Baesapp Chat is the perfect solution for secure and efficient communication.</p>
                </div>
                <div style={{ height: '30px' }}></div>
            </div>
            <ModalHeader title={'ABOUT CHATS'} actionName={''} actionActive={true} actionFunction={() => { }} />
        </div>
    );
};

const mapStateToProps = state => {
    return ({
        myChat: state.myChat,
        my_id: state.my_id
    });
};

export default connect(mapStateToProps)(About);
