import React, { useEffect, useState } from "react";

import { connect } from 'react-redux';

import { Routes, Route, useNavigate } from 'react-router-dom';

import baesappApi from '../../../apis/baesappApi';
import { setMyWalletTotal, setNewMyWalletTransaction } from "../../../redux/actions";
import NumberPad from "../../../dumbComponents/NumberPad";

const Pay = ({
    myWallet,
    my_id,
    setMyWalletTotal,
    setNewMyWalletTransaction,
    memberName,
    memberId
}) => {

    const navigate = useNavigate();

    const [amountInCents, setAmountInCents] = useState(0);
    const [myReference, setMyReference] = useState('');
    const [theirReference, setTheirReference] = useState('');
    const [success, setSuccess] = useState(false);
    const [totalInputActive, setTotalInputActive] = useState(false);

    const payMember = (pin, setLoading) => {
        let payData = { memberId, amountInCents, pin };
        if (myReference.length > 0) payData['myReference'] = myReference;
        if (theirReference.length > 0) payData['theirReference'] = theirReference;
        baesappApi.post('/payZar', payData)
            .then(res => {
                setMyWalletTotal(res.data.totalLeftInCents);
                setNewMyWalletTransaction(res.data);
                setSuccess(true);
                navigate(-1);
                setTimeout(() => {
                    navigate(-1);
                }, 2000)
            })
            .catch(err => {
                window.alert('something went wrong')
            })
    };

    const getTotalString = () => {
        let leftString;
        let rightString;
        const toFixed = (amountInCents / 100).toFixed(2);

        leftString = toFixed.slice(0, toFixed.indexOf('.'));
        leftString = (+leftString).toLocaleString();

        rightString = toFixed.slice(toFixed.indexOf('.'));

        return ('R' + leftString + rightString);
    };

    const renderMain = () => {
        return (
            <>
                <div className="fullScreen fixed top left depositModal" />
                <div className="depositModalCard fixed flex">
                    {success ?
                        <p>Successfully paid {memberName} R{amountInCents / 100}</p> :
                        <>
                            <div className="flex vertical">
                                <span style={totalInputActive ? { color: 'var(--accent)', fontSize: '50px', textAlign: 'center', fontWeight: 'bold' } : { color: 'var(--text)', fontSize: '45px', textAlign: 'center', fontWeight: 'bold' }}>{getTotalString()}</span>
                                <input
                                    style={{ opacity: '0', height: '50px', marginTop: '-50px', paddingRight: '100%' }}
                                    type='number'
                                    min="0"
                                    id="totalInput"
                                    pattern="\d*"
                                    onFocus={() => setTotalInputActive(true)}
                                    onBlur={() => setTotalInputActive(false)}
                                    onChange={e => setAmountInCents(e.target.value)}
                                    value={amountInCents}
                                    placeholder={0}
                                />
                            </div>
                            <div className="flex vertical">
                                <span style={{ color: 'var(--secondary)' }}>{'My reference (optional)'}</span>
                                <input
                                    type='text'
                                    className="walletTextInput"
                                    onChange={e => setMyReference(e.target.value)}
                                    value={myReference}
                                    placeholder='Type value...'
                                />
                            </div>
                            <div className="flex vertical">
                                <span style={{ color: 'var(--secondary)' }}>{'Their reference (optional)'}</span>
                                <input
                                    type='text'
                                    className="walletTextInput"
                                    onChange={e => setTheirReference(e.target.value)}
                                    value={theirReference}
                                    placeholder='Type value...'
                                />
                            </div>
                            <button className="walletVisitPayButton" style={amountInCents > 0 ? { color: 'var(--accent)' } : { color: 'var(--primary)' }} onClick={() => {
                                if (amountInCents > 0) {
                                    navigate(`confirmWithPin?_id=${memberId}`)
                                }
                            }}>Send</button>
                        </>
                    }
                </div>
            </>
        );
    };


    return (
        <>
            {renderMain()}
            <Routes>
                {amountInCents > 0 && <Route path='confirmWithPin' element={<NumberPad callback={payMember} infoText="Wallet PIN" />} />}
            </Routes>
        </>
    );
}


const mapStateToProps = state => {
    return ({
        my_id: state.my_id,
        myWallet: state.myWallet
    });
};

export default connect(mapStateToProps, {
    setMyWalletTotal,
    setNewMyWalletTransaction
})(Pay);