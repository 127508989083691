const mySearchReducer = (mySearch=false, action) => {
  switch(action.type){
    case 'GOT_MY_DETAILS':
      return action.payload.search;
    case 'MY_SEARCH_SET':
      return action.payload;
    case 'MY_SEARCH_HISTORY_SET':
      const newSearchHistory = action.payload;
      let newMySearch = {...mySearch};
      newMySearch.searchHistory = newSearchHistory;
      return newMySearch;
    case 'SEARCHABLE_BY_PROFILE_SHOT_SET':
      let newSearch = {...mySearch};
      newSearch.searchableByProfileShot = action.payload;
      return newSearch;
      case 'CLEAR_ALL':
              return false;
    default:
      return mySearch;
  };
};

export default mySearchReducer;
