const myMediaReducer = (myMedia=false, action) => {
  switch(action.type){
    case 'GOT_MY_DETAILS':
      return action.payload.media;
    case 'NEW_POST_SET':{
      let newPosts = [action.payload, ...myMedia.posts];
      let newMedia = {...myMedia};
      newMedia.posts = newPosts;
      return newMedia;}
    case 'SET_MY_POSTS':{
      let newMedia = {...myMedia};
      newMedia.posts = action.payload;
      return newMedia;}
    case 'SET_SAVED_POSTS':{
      let newMedia = {...myMedia};
      newMedia.saved = action.payload;
      return newMedia;}
    case 'NEW_MEDIA_FOLLOWING_SET':{
      let newMedia = {...myMedia};
      let newMediaFollowing = [...newMedia.following];
      if(action.payload.action == '+'){
        newMediaFollowing.push(action.payload._id);
      }else{
        newMediaFollowing = newMediaFollowing.filter(member => member != action.payload._id);
      }
      newMedia.following = newMediaFollowing;
      
      return newMedia;}

    case 'NEW_MEDIA_FOLLOWERS_SET':{
      console.log('changed: '+JSON.stringify(action.payload));
      let newMedia = {...myMedia};
      let newMediaFollowers = [...newMedia.followers];
      if(action.payload.action == '+'){
        newMediaFollowers.push(action.payload._id);
      }else{
        newMediaFollowers = newMediaFollowers.filter(member => member != action.payload._id);
      }
      newMedia.followers = newMediaFollowers;
      
      return newMedia;}
    case 'POST_DELETED':{
      let newPosts = myMedia.posts.filter(post => post._id != action.payload);
      let newMedia = {...myMedia};
      newMedia.posts = newPosts;
      return newMedia;
    }
    case 'CLEAR_ALL':
            return false;

    default:
      return myMedia;
  };
};

export default myMediaReducer;
