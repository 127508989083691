import React, { useEffect, useState, useContext } from "react";

import { connect } from 'react-redux';
import { Routes, Route, useNavigate } from 'react-router-dom';

import { setVisitMember } from "../../../redux/actions";

import baesappApi from '../../../apis/baesappApi';
import SearchToInvite from "./SearchToInvite";
import LoadingDrop from "../../../dumbComponents/LoadingDrop";


const Games = ({
    gameInvitation,
    setVisitMember
}) => {

    const [gameInvitationList, setGameInvitationList] = useState([]);

    const navigate = useNavigate();

    useEffect(() => {
        console.log(gameInvitation);
        setGameInvitationList(gameInvitation.map(invitation => {
            return <button onClick={() => visitMember(invitation.memberModal)}>{invitation.memberModal.username} wants to play</button>
        }));
    }, [gameInvitation]);

    const visitMember = (member) => {
        setVisitMember({
            path: 'games',
            memberEssentials: member
        });
        navigate(`/visit/games?_id=${member._id}`);
    };
    
    return (
        <>
            <div className="fullScreen walletContainer removeScrollBar flex vertical">
                <div style={{ minHeight: '50px' }}></div>
                <LoadingDrop />
            </div>
            <Routes>
                <Route path='/searchToInvite' element={<SearchToInvite />} />
            </Routes>
            {gameInvitationList}
        </>
    );
};

const mapStateToProps = state => {
    return ({
        gameInvitation: state.gameInvitation
    });
};

export default connect(mapStateToProps, {setVisitMember})(Games);
