import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BaeSvg from '../../dumbComponents/BaeSvg';
import { connect } from 'react-redux';

const Core = ({
    myId,
    myChat,
    myActivity
}) => {

    const navigate = useNavigate();

    const [totalNewMessages, setTotalNewMessages] = useState(0);
    const [totalNewActivity, setTotalNewActivity] = useState(0);

    useEffect(() => {
        let tempTotalNewMessages = 0;
        myChat.chats?.map(chat => {
            tempTotalNewMessages += chat.newMessages.length;
        });
        setTotalNewMessages(tempTotalNewMessages);
    }, [myChat]);

    useEffect(() => {
        let tempTotalNewActivity = 0;
        myActivity?.map(activity => {
            if (!activity.seen) tempTotalNewActivity++;
        });
        setTotalNewActivity(tempTotalNewActivity);
    }, [myActivity]);

    const handleNavigation = (path) => {
        navigate(path);
    };

    // return (
    //     <div className="flex vertical removeScrollBar coreContainer">
    //         <>
    //             <div onClick={() => handleNavigation('/settings')} style={{ gap: '20px', width: '100%' }} className="flex vertical alignCenter" >
    //                 <div className="flex vertical" style={{ zIndex: '1', alignSelf: 'start', margin: '20px 0 -110px 20px', gap: '15px' }}>
    //                     <div className="flex alignCenter" style={{ fontSize: '1.3em', gap: '10px' }}>
    //                         <BaeSvg iconName="baesappLogo" height="20px" color="text" />
    //                         <span>Baesapp</span>
    //                     </div>
    //                     <span style={{ fontSize: '1.8em', fontWeight: 'bold' }}>{myId.username}</span>
    //                 </div>
    //                 <img style={{ zIndex: '1' }} className="bottomNavIcon" src={myId ? `https://baesapp.com/media/${myId.profileShot}` : ''} />
    //                 <img className="bottomNavIcon bottomNavIconBlur" src={myId ? `https://baesapp.com/media/${myId.profileShot}` : ''} />
    //             </div>
    //             <div className="bottomNavListBar">
    //                 <span>GENERAL</span>
    //             </div>
    //             <div className="bottomNavExpandedButtonContainer vertical">
    //                 <button onClick={() => handleNavigation('/settings')} className="flex alignCenter" style={{ gap: '22px', width: '100%', fontSize: 'inherit' }}>
    //                     <BaeSvg iconName="settings" height="30px" style={{ maxWidth: '18px', maxHeight: '18px' }} color="text" />
    //                     <span>Settings</span>
    //                 </button>
    //                 <div className="bottomNavLineDevider" />
    //                 <button onClick={() => handleNavigation('/activity')} className="flex alignCenter spaceBetween" style={{ width: '100%', fontSize: 'inherit' }}>
    //                     <div className="flex alignCenter" style={{ gap: '22px' }}>
    //                         <BaeSvg iconName="notifications" height="30px" style={{ maxWidth: '18px', maxHeight: '18px' }} color="text" />
    //                         <span>Activity</span>
    //                     </div>
    //                     {totalNewActivity > 0 && <span style={{ color: 'white', minWidth: '40px', backgroundColor: 'var(--error)', borderRadius: '30px' }}>{totalNewActivity}</span>}
    //                 </button>
    //             </div>
    //             <div className="bottomNavListBar">
    //                 <span>MAIN APPS</span>
    //             </div>
    //             <div className="bottomNavExpandedButtonContainer vertical">
    //                 <button onClick={() => handleNavigation('/home/chat')} className="flex alignCenter spaceBetween" style={{ width: '100%', fontSize: 'inherit' }}>
    //                     <div className="flex alignCenter" style={{ gap: '22px' }}>
    //                         <BaeSvg iconName="chat" height="30px" style={{ maxWidth: '18px', maxHeight: '18px' }} color="text" />
    //                         <span>Chat</span>
    //                     </div>
    //                     {totalNewMessages > 0 && <span style={{ color: 'white', minWidth: '40px', backgroundColor: 'var(--error)', borderRadius: '30px' }}>{totalNewMessages}</span>}
    //                 </button>
    //                 <div className="bottomNavLineDevider" />
    //                 <button onClick={() => handleNavigation('/home/media')} className="flex alignCenter" style={{ gap: '22px', width: '100%', fontSize: 'inherit' }}>
    //                     <BaeSvg iconName="media" height="30px" style={{ maxWidth: '18px', maxHeight: '18px' }} color="text" />
    //                     <span>Media</span>
    //                 </button>
    //                 <div className="bottomNavLineDevider" />
    //                 <button onClick={() => handleNavigation('/home/search')} className="flex alignCenter" style={{ gap: '22px', width: '100%', fontSize: 'inherit' }}>
    //                     <BaeSvg iconName="search" height="30px" style={{ maxWidth: '18px', maxHeight: '18px' }} color="text" />
    //                     <span>Search</span>
    //                 </button>
    //                 <div className="bottomNavLineDevider" />
    //                 <button onClick={() => handleNavigation('/home/wallet')} className="flex alignCenter" style={{ gap: '22px', width: '100%', fontSize: 'inherit' }}>
    //                     <BaeSvg iconName="wallet" height="30px" style={{ maxWidth: '18px', maxHeight: '18px' }} color="text" />
    //                     <span>Wallet</span>
    //                 </button>
    //             </div>

    //             <div className="bottomNavListBar">
    //                 <span>OTHER APPS</span>
    //             </div>
    //             <div className="bottomNavExpandedButtonContainer vertical" style={{ marginBottom: '50px' }}>
    //                 <button onClick={() => handleNavigation('/home/info')} className="flex alignCenter" style={{ gap: '22px', width: '100%', fontSize: 'inherit' }}>
    //                     <BaeSvg iconName="info" height="30px" style={{ maxWidth: '18px', maxHeight: '18px' }} color="text" />
    //                     <span>Info</span>
    //                 </button>
    //                 <div className="bottomNavLineDevider" />
    //                 <button onClick={() => handleNavigation('/home/events')} className="flex alignCenter" style={{ gap: '22px', width: '100%', fontSize: 'inherit' }}>
    //                     <BaeSvg iconName="events" height="30px" style={{ maxWidth: '18px', maxHeight: '18px' }} color="text" />
    //                     <span>Events</span>
    //                 </button>
    //                 <div className="bottomNavLineDevider" />
    //                 <button onClick={() => handleNavigation('/home/games')} className="flex alignCenter" style={{ gap: '22px', width: '100%', fontSize: 'inherit' }}>
    //                     <BaeSvg iconName="games" height="30px" style={{ maxWidth: '18px', maxHeight: '18px' }} color="text" />
    //                     <span>Games</span>
    //                 </button>
    //             </div>
    //         </>
    //     </div>
    // );

    return (
        <div style={{
            backgroundColor: 'var(--base)',
            position: 'absolute',
            height: '100vh',
            width: '100vw',
            top: '0',
            bottom: '0',
            zIndex: '1',
            maxWidth: '500px'
        }}>
            <div className="flex vertical removeScrollBar coreContainer">
                <div onClick={() => handleNavigation('/settings')} style={{ gap: '20px', width: '100%' }} className="flex vertical alignCenter" >
                    <div className="flex vertical"
                        style={{
                            zIndex: '1',
                            alignSelf: 'start',
                            margin: '40px 0 0 10px',
                            gap: '15px',
                            fontWeight: 'bold',
                            color: 'var(--text)',
                            fontWeight: 'bold',
                            background: 'var(--base)',
                            padding: '10px',
                            borderRadius: '14px',
                            boxShadow: '#0000004f 5px 5px 8px'
                        }}>
                        <div className="flex alignCenter" style={{ fontSize: '1.3em', gap: '10px' }}>
                            <BaeSvg iconName="baesappLogo" height="20px" color="text" />
                            <span>Baesapp</span>
                        </div>
                    </div>
                </div>
                <p style={{
                    fontSize: '2em',
                    alignSelf: 'start',
                    marginLeft: '10px',
                    marginBottom: '5px',
                    fontWeight: 'bold'
                }}>Dashboard</p>
                <p style={{
                    fontSize: '1.2em',
                    alignSelf: 'start',
                    margin: '0 0 0 10px'
                }}>Quick access to all your features and activity.</p>
                <div className="bottomNavListBar">
                    <span>Account</span>
                </div>
                <div className="bottomNavExpandedButtonContainer vertical">
                    <button onClick={() => handleNavigation('/settings')} className="flex alignCenter spaceBetween" style={{ gap: '22px', width: '100%', fontSize: 'inherit', justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                            <img style={{ zIndex: '1' }} className="bottomNavIcon" src={myId ? `https://baesapp.com/media/${myId.profileShot}` : ''} />
                            <span style={{ fontWeight: 'bold' }}>{myId.username}</span>
                        </div>
                        <BaeSvg iconName="chevronDown" height="30px" style={{ transform: 'rotate(270deg)', maxWidth: '18px', maxHeight: '18px' }} color="secondary" />
                    </button>
                </div>
                <div className="bottomNavListBar">
                    <span>General</span>
                </div>
                <div className="bottomNavExpandedButtonContainer vertical">
                    <button onClick={() => handleNavigation('/settings')} className="flex alignCenter" style={{ gap: '22px', width: '100%', fontSize: 'inherit' }}>
                        <BaeSvg iconName="settings" height="30px" style={{ maxWidth: '18px', maxHeight: '18px' }} color="text" />
                        <span>Settings</span>
                    </button>
                    <div className="bottomNavLineDevider" />
                    <button onClick={() => handleNavigation('/activity')} className="flex alignCenter spaceBetween" style={{ width: '100%', fontSize: 'inherit' }}>
                        <div className="flex alignCenter" style={{ gap: '22px' }}>
                            <BaeSvg iconName="notifications" height="30px" style={{ maxWidth: '18px', maxHeight: '18px' }} color="text" />
                            <span>Activity</span>
                        </div>
                        {totalNewActivity > 0 && <span style={{ color: 'white', minWidth: '40px', backgroundColor: 'var(--error)', borderRadius: '30px' }}>{totalNewActivity}</span>}
                    </button>
                </div>
                <div className="bottomNavListBar">
                    <span>Main apps</span>
                </div>
                <div className="bottomNavExpandedButtonContainer vertical">
                    <button onClick={() => handleNavigation('/home/chat')} className="flex alignCenter spaceBetween" style={{ width: '100%', fontSize: 'inherit' }}>
                        <div className="flex alignCenter" style={{ gap: '22px' }}>
                            <BaeSvg iconName="chat" height="30px" style={{ maxWidth: '18px', maxHeight: '18px' }} color="text" />
                            <span>Chat</span>
                        </div>
                        {totalNewMessages > 0 && <span style={{ color: 'white', minWidth: '40px', backgroundColor: 'var(--error)', borderRadius: '30px' }}>{totalNewMessages}</span>}
                    </button>
                    <div className="bottomNavLineDevider" />
                    <button onClick={() => handleNavigation('/home/media')} className="flex alignCenter" style={{ gap: '22px', width: '100%', fontSize: 'inherit' }}>
                        <BaeSvg iconName="media" height="30px" style={{ maxWidth: '18px', maxHeight: '18px' }} color="text" />
                        <span>Media</span>
                    </button>
                    <div className="bottomNavLineDevider" />
                    <button onClick={() => handleNavigation('/home/search')} className="flex alignCenter" style={{ gap: '22px', width: '100%', fontSize: 'inherit' }}>
                        <BaeSvg iconName="search" height="30px" style={{ maxWidth: '18px', maxHeight: '18px' }} color="text" />
                        <span>Search</span>
                    </button>
                    <div className="bottomNavLineDevider" />
                    <button onClick={() => handleNavigation('/home/wallet')} className="flex alignCenter" style={{ gap: '22px', width: '100%', fontSize: 'inherit' }}>
                        <BaeSvg iconName="wallet" height="30px" style={{ maxWidth: '18px', maxHeight: '18px' }} color="text" />
                        <span>Wallet</span>
                    </button>
                </div>

                <div className="bottomNavListBar">
                    <span>Other apps</span>
                </div>
                <div className="bottomNavExpandedButtonContainer vertical" style={{ marginBottom: '50px' }}>
                    <button onClick={() => handleNavigation('/home/info')} className="flex alignCenter" style={{ gap: '22px', width: '100%', fontSize: 'inherit' }}>
                        <BaeSvg iconName="info" height="30px" style={{ maxWidth: '18px', maxHeight: '18px' }} color="text" />
                        <span>Info</span>
                    </button>
                    <div className="bottomNavLineDevider" />
                    <button onClick={() => handleNavigation('/home/events')} className="flex alignCenter" style={{ gap: '22px', width: '100%', fontSize: 'inherit' }}>
                        <BaeSvg iconName="events" height="30px" style={{ maxWidth: '18px', maxHeight: '18px' }} color="text" />
                        <span>Events</span>
                    </button>
                    <div className="bottomNavLineDevider" />
                    <button onClick={() => handleNavigation('/home/games')} className="flex alignCenter" style={{ gap: '22px', width: '100%', fontSize: 'inherit' }}>
                        <BaeSvg iconName="games" height="30px" style={{ maxWidth: '18px', maxHeight: '18px' }} color="text" />
                        <span>Games</span>
                    </button>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = state => {
    return ({
        myId: state.myId,
        myChat: state.myChat,
        myActivity: state.myActivity
    });
};

export default connect(mapStateToProps)(Core);