import React, { useState, useEffect, useRef } from "react";

import { connect } from 'react-redux';

import { useFilePicker } from 'use-file-picker';

import convert2mp4 from '../../../hooks/convert2mp4';

import { useNavigate, Route, Routes } from 'react-router-dom';

import newPost from '../../../assets/newPost.png';
import collapseArrow from '../../../assets/collapseArrow.png';

import baesappApi from '../../../apis/baesappApi';

import { setNewPost, deletePost, setMyPosts } from '../../../redux/actions';

import MyPostViewer from '../../../dumbComponents/Home/Media/MyPostViewer';
import ModalHeader from "../../../dumbComponents/Home/modalHeader";
import NewPostLoading from "./NewPostLoading";
import ModalBackgroundButton from "../../../dumbComponents/ModalBackgroundButton";
import BaeSvg from '../../../dumbComponents/BaeSvg';
import EditNewPost from "./EditNewPost";
import GenerateAIImage from "../GenerateAIImage";

const MyPostsModal = ({
    myMedia,
    myId,
    setNewPost,
    deletePost,
    setMyPosts
}) => {

    const [currLoadBoxFlash, setCurrLoadBoxFlash] = useState([1, 2, 3]);
    const [loadingMyMedia, setLoadingMyMedia] = useState(false);
    const [myPostsList, setMyPostsList] = useState([]);
    const [newPostLoading, setNewPostLoading] = useState(false);
    const [postUploadCompleted, setPostUploadCompleted] = useState(false);
    const [newPostAskContentType, setNewPostAskContentType] = useState(false);
    const [newPostMediaContentType, setNewPostMediaContentType] = useState(false);
    const [newPostContent, setNewPostContent] = useState(false);
    const [totalQuotes, setTotalQuotes] = useState(0);
    const [totalAudios, setTotalAudios] = useState(0);
    const [totalImages, setTotalImages] = useState(0);
    const [totalVideos, setTotalVideos] = useState(0);

    const navigate = useNavigate();
    const [openFileSelector, { filesContent, loading, errors }] = useFilePicker({
        readAs: "DataURL",
        accept: ["image/*", 'video/*'],
        maxFileSize: 100
    });

    useEffect(() => {
        setBoxes();
        getMyPosts();
    }, []);
    useEffect(() => {
        if (filesContent[0]) {
            if (filesContent[0].content.startsWith('data:video/mp4')) {
                setNewPostContent(filesContent[0].content);
                setNewPostMediaContentType('data:video/mp4');
                navigate('/home/media/myPosts/editNewPost');
            };
            if (filesContent[0].content.startsWith('data:video/quicktime')) {
                setNewPostContent(filesContent[0].content);
                setNewPostMediaContentType('data:video/quicktime');
                navigate('/home/media/myPosts/editNewPost');
            };
            if (filesContent[0].content.startsWith('data:image/jpeg') || filesContent[0].content.startsWith('data:image/png')) {
                setNewPostContent(filesContent[0].content);
                setNewPostMediaContentType('image');
                navigate('/home/media/myPosts/editNewPost');
            }
        }
    }, [filesContent]);
    useEffect(() => {
        if (myMedia) {
            let newTotalQuotes = 0;
            let newTotalAudios = 0;
            let newTotalImages = 0;
            let newTotalVideos = 0;
            myMedia.posts.map(post => {
                if (post.data[0].contentType == 'quote') {
                    newTotalQuotes++;
                } else if (post.data[0].contentType == 'audio') {
                    newTotalAudios++;
                } else if (post.data[0].contentType == 'image') {
                    newTotalImages++;
                } else if (post.data[0].contentType == 'video') {
                    newTotalVideos++;
                }
            });
            setTotalQuotes(newTotalQuotes);
            setTotalAudios(newTotalAudios);
            setTotalImages(newTotalImages);
            setTotalVideos(newTotalVideos);
            setMyPostsList(myMedia.posts.map(post => {
                if (post.data[0].contentType == 'quote') {
                    return (
                        <button
                            onClick={() => {
                                postClicked(post._id);
                            }}
                            className="galleryImage flex alignCenter spaceAround" style={{ backgroundColor: 'transparent', transform: 'none' }}>
                            <div key={post._id} style={{ padding: '5px', border: '2px solid var(--accent)', borderRadius: '16px', height: '80%', width: '80%' }} className="galleryImage flex alignCenter spaceAround">
                                <span style={{ fontSize: '1em', textAlign: 'center' }}>{post.data[0].s3Key}</span>
                            </div>
                        </button>
                    );
                } else if (post.data[0].contentType == 'audio') {
                    return (
                        <button
                            onClick={() => {
                                postClicked(post._id);
                            }} key={post._id} className="galleryImage" style={{ backgroundColor: 'transparent' }}>
                            <BaeSvg iconName="audio" color='accent' />
                        </button>
                    );
                } else if (post.data[0].contentType == 'aIImage') {
                    return (
                        <button
                            onClick={() => {
                                postClicked(post._id);
                            }} key={post._id} className="galleryImage">
                            <img style={{ height: '100%', width: '100%', objectFit: 'cover', opacity: '1' }} src={post.data[0].aIImageUrl} />
                        </button>
                    );
                } else {
                    return (
                        <button
                            onClick={() => {
                                postClicked(post._id);
                            }} key={post._id} className="galleryImage">
                            <img style={{ height: '100%', width: '100%', objectFit: 'cover', opacity: post.data[0].contentType == 'video' ? '0.9' : '1' }} src={`https://baesapp.s3.eu-central-1.amazonaws.com/${post.data[0].thumbnail || post.data[0].s3Key}`} />
                            {post.data[0].contentType == 'video' && <BaeSvg iconName="playButton" height="50%" style={{ marginTop: '-75px' }} color="text" />}
                        </button>
                    );
                }
            }));
        }
    }, [myMedia]);

    const aIImageSelected = (content) => {
        setNewPostContent(content);
        setNewPostMediaContentType('aIImage');
        navigate('/home/media/myPosts/editNewPost');
    }

    const postClicked = (activePostId) => {
        window.localStorage.setItem('activePostId', activePostId);
        navigate('/home/media/viewPost');
    };
    const aIImageClicked = () => {
        navigate('generateAIImage');
    }
    const postVideo = (caption, location, members) => {
        setNewPostLoading(true);
        navigate('/home/media/myPosts/newPostLoading');
        baesappApi.post('/postVideo', { videoB64: newPostContent, caption: caption, location: location, members: members })
            .then(res => {
                setNewPost(res.data);
                postUploadComplete();
            })
            .catch(err => {
                window.alert(err);
                console.log(err);
                postUploadComplete();
            })
    };
    const postImage = (caption, location, members) => {
        setNewPostLoading(true);
        navigate('/home/media/myPosts/newPostLoading');
        baesappApi.post('/postImage', {
            b64Image: newPostContent,
            caption: caption,
            location: location,
            members: members
        })
            .then(res => {
                setNewPost(res.data);
                postUploadComplete();
            })
            .catch(err => {
                console.log(err);
                window.alert(err);
                postUploadComplete();
            })
    };
    const postQuote = (caption, location, members) => {
        setNewPostLoading(true);
        navigate('/home/media/myPosts/newPostLoading');
        baesappApi.post('/postQuote', { quote: newPostContent, caption: caption, location: location, members: members })
            .then(res => {
                setNewPost(res.data);
                postUploadComplete();
            })
            .catch(err => {
                console.log(err);
                window.alert(err);
                postUploadComplete();
            })
    };
    const postAudio = (caption, location, members) => {
        setNewPostLoading(true);
        navigate('/home/media/myPosts/newPostLoading');
        baesappApi.post('/postAudio', {
            audioB64: newPostContent,
            caption: caption,
            location: location,
            members: members
        })
            .then(res => {
                setNewPost(res.data);
                postUploadComplete();
            })
            .catch(err => {
                console.log(err);
                window.alert(err);
                postUploadComplete();
            })
    };
    const postAIImage = (caption, location, members) => {
        setNewPostLoading(true);
        navigate('/home/media/myPosts/newPostLoading');
        baesappApi.post('/postAIImage', {
            imageUrl: newPostContent,
            caption: caption,
            location: location,
            members: members
        })
            .then(res => {
                setNewPost(res.data);
                postUploadComplete();
            })
            .catch(err => {
                console.log(err);
                window.alert(err);
                postUploadComplete();
            })
    };

    const quoteClicked = () => {
        setNewPostContent('');
        setNewPostMediaContentType('quote');
        navigate('/home/media/myPosts/editNewPost');
    };
    const audioClicked = () => {
        setNewPostContent(false);
        setNewPostMediaContentType('audio');
        navigate('/home/media/myPosts/editNewPost');
    };

    const postUploadComplete = () => {
        setNewPostAskContentType(false);
        setPostUploadCompleted(true);
        setTimeout(() => {
            setNewPostLoading(false);
            navigate(-2);
            setPostUploadCompleted(false);
        }, 1000);
    }

    const setBoxes = () => {
        const mover = setTimeout(() => {
            if (currLoadBoxFlash[0] == 16) {
                clearTimeout(mover)
            } else {
                setCurrLoadBoxFlash(prev => [...prev, prev.slice(-1)[0] + 1, prev.slice(-1)[0] + 2, prev.slice(-1)[0] + 3]);
                setBoxes();
            }
        }, 100);
    };

    const getMyPosts = () => {
        setLoadingMyMedia(true);
        baesappApi.get('/getMyPosts')
            .then(res => {
                setMyPosts(res.data.reverse());
                setLoadingMyMedia(false);
            })
            .catch(err => {
                console.log(err);
                window.alert('Something unexpected went wrong, please reload the page.');
                setLoadingMyMedia(false);
            })
    };

    const convertTheVideo = async () => {
        let convertedVideo = await convert2mp4(filesContent[0].content, 'mp4');
        return (convertedVideo);
    };

    let loaderArr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18];
    const loaderList = loaderArr.map(item => {
        return (
            <div className={currLoadBoxFlash.includes(item) ? 'flashMediaLoader galleryImage' : 'galleryImage'}>

            </div>
        );
    });

    const MediaContentSelector = () => {
        return (
            <>
                <ModalBackgroundButton dismiss={() => setNewPostAskContentType(false)} unblur={true} />
                <div className="flex vertical" style={{
                    position: 'fixed',
                    zIndex: '26',
                    overflow: 'hidden',
                    borderRadius: '20px',
                    top: '20px',
                    right: '20px'
                }}>
                    <button onClick={openFileSelector} className="mediaContentSelectorButton flex alignCenter spaceBetween">
                        Image / Video
                        <BaeSvg iconName="videoImage" height="30%" color="text" />
                    </button>
                    <button onClick={audioClicked} className="mediaContentSelectorButton flex alignCenter spaceBetween">
                        Voice note
                        <BaeSvg iconName="audio" height="40%" color="text" />
                    </button>
                    <button onClick={quoteClicked} className="mediaContentSelectorButton flex alignCenter spaceBetween">
                        Quote
                        <BaeSvg iconName="text" height="25%" color="text" />
                    </button>
                    <button onClick={aIImageClicked} className="mediaContentSelectorButton flex alignCenter spaceBetween">
                        AI Image
                        <BaeSvg iconName="robot" height="25%" color="text" />
                    </button>
                </div>
            </>
        );
    };

    return (
        <>
            <div className="fullScreen removeScrollBar modalContainer">
                <div className="flex vertical" style={{ gap: '6px' }}>
                    <div style={{ minHeight: '115px', width: '50px' }} />
                    <div className="mediaGridContainer" style={{ paddingBottom: '114px', bottom: '0', maxHeight: '100vh' }}>
                        <div className={'flashMediaLoader galleryImage'} style={{ height: '0' }}></div>
                        <div className={'flashMediaLoader galleryImage mediaInfoContainer'} style={{ background: 'none', color: 'var(--text)' }}>
                            <span><strong>{totalImages}</strong> Photos, <strong>{totalVideos}</strong> Videos, <strong>{totalQuotes}</strong> Quotes, <strong>{totalAudios}</strong> Audio</span>
                        </div>
                        <div className={'flashMediaLoader galleryImage'} style={{ height: '0' }}></div>
                        {loadingMyMedia ? loaderList : myPostsList}
                    </div>
                    <ModalHeader title={'MY GALLERY'} actionName={'New'} actionActive={true} actionFunction={() => setNewPostAskContentType(true)} />
                    {newPostAskContentType && MediaContentSelector()}
                </div>
            </div>
            {
                (!loadingMyMedia && (myMedia?.posts?.length == 0)) &&
                <span
                    onClick={() => setNewPostAskContentType(true)}
                    className="noResults centerText"
                    style={{
                        position: 'absolute',
                        width: 'fit-content',
                        left: '0',
                        right: '0',
                        margin: 'auto'
                    }}
                >Press new to post your first media</span>
            }
            <Routes>
                <Route path="/generateAIImage/*" element={<GenerateAIImage aIImageSelected={aIImageSelected} />} />
                {newPostMediaContentType && <Route path="/editNewPost/*" element={<EditNewPost
                    setNewPostContent={setNewPostContent}
                    contentType={newPostMediaContentType}
                    postVideo={postVideo}
                    postImage={postImage}
                    postAudio={postAudio}
                    postQuote={postQuote}
                    postAIImage={postAIImage}
                    content={newPostContent}
                />} />}
                {newPostLoading && newPostContent && <Route path="/newPostLoading" element={<NewPostLoading
                    fileContent={newPostContent}
                    fileContentType={newPostMediaContentType}
                    postUploadCompleted={postUploadCompleted}
                />} />}
            </Routes>
        </>
    );
};

const mapStateToProps = state => {
    return ({
        myMedia: state.myMedia,
        myId: state.myId
    });
};

export default connect(mapStateToProps, { setNewPost, deletePost, setMyPosts })(MyPostsModal);
