const myWalletReducer = (myWallet=false, action) => {
    switch(action.type){
      case 'GOT_MY_DETAILS':
        return action.payload.wallet;
      case 'CLEAR_ALL':
        return false;
      case 'NEW_WALLET_SET':
        return action.payload;
      case 'MY_WALLET_TOTAL_SET':{
        let newMyWallet = {...myWallet};
        newMyWallet.totalInCents = action.payload;
        return newMyWallet;
      }
      case 'SET_NEW_MY_WALLET_HAS_PIN':{
        let newMyWallet = {...myWallet};
        newMyWallet.hasPin = action.payload;
        return newMyWallet;
      }
      case 'NEW_MY_WALLET_TRANSACTION_SET':{
        let newMyWallet = {...myWallet};
        newMyWallet.transactions.push(action.payload);
        return newMyWallet;
      }
      case 'SET_NEW_MAGIC_CASH_TO_DELETE':{
        let newMyWallet = {...myWallet};
        if(newMyWallet.magicCashToDelete){
          newMyWallet.magicCashToDelete.push(action.payload);
        }else{
          newMyWallet['magicCashToDelete'] = [action.payload];
        };
        return newMyWallet;
      }
      case 'DELETE_MAGIC_CASH_TO_DELETE':{
        let newMyWallet = {...myWallet};
        if(newMyWallet.magicCashToDelete.length){
          newMyWallet.magicCashToDelete.splice(newMyWallet.magicCashToDelete.indexOf(action.payload), 1);
        }
        return newMyWallet;
      }
      default:
        return myWallet;
    };
  };
  
  export default myWalletReducer;
  