import React, { useState, useEffect } from "react";

import EmailAuth from "./EmailAuth";
import NationalIdAuth from "./NationalIdAuth";
import Registration from "./Registration";
import { setDarkModeEnabled } from "../../../redux/actions";

const Register = () => {

    const [step, setStep] = useState(1);

    var r = document.querySelector(':root');
    setDarkModeEnabled(false);
    r.style.setProperty('--base', '#fff');
    r.style.setProperty('--primary', '#F4F4F4');
    r.style.setProperty('--primaryGlass', '#e9e9e980');
    r.style.setProperty('--primaryBlur', '#ffffffdd');
    r.style.setProperty('--secondary', '#bbbbbb');
    r.style.setProperty('--onAccent', '#fff');
    r.style.setProperty('--text', '#000');

    const renderStep = () => {
        switch (step) {
            case 1:
                return <EmailAuth setStep={setStep} />;
            case 2:
                return <NationalIdAuth setStep={setStep} />;
            case 3:
                return <Registration setStep={setStep} />;
        }
    };

    return (
        <div className="container">
            {renderStep()}
        </div>
    );
};

export default Register;