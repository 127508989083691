import React, { useEffect, useState } from "react";

import { connect } from 'react-redux';
import { Routes, Route, useNavigate } from 'react-router-dom';

import chevronAccent from '../../../assets/chevronAccent.png';
import baesappApi from '../../../apis/baesappApi';
import getLocalToObject from "../../../hooks/getLocalToObject";


const Explore = ({
    darkModeEnabled
}) => {

    const navigate = useNavigate();

    const renderContent = () => {
        return null;
    };

    return (
        <div className='fullScreen removeScrollBar modalContainer'>
            {renderContent()}
                <div className="secondaryScreenHeaderContainer">
                    <div className='secondaryScreenHeader'>
                        <div onClick={() => navigate(-1)} className="headerBackButtonContainer">
                            <img src={chevronAccent} className="headerBackIcon" />
                        </div>
                        <span className="modalHeaderTitle">Explore</span>
                    </div>
                </div>
            </div >
    );
};

const mapStateToProps = state => {
    return ({
        darkModeEnabled: state.darkModeEnabled
    });
};

export default connect(mapStateToProps,)(Explore);
