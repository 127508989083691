import React, { useState, useEffect, useRef } from "react";

import { connect } from 'react-redux';
import { Route, Routes, useNavigate } from 'react-router-dom';

import { setNewPost, deletePost, setMyPosts } from '../../../redux/actions';

import ModalHeader from "../../../dumbComponents/Home/modalHeader";
import AddAddress from "./AddAddress";
import AddMembers from "./AddMembers";
import AudioRecorder from "../../../dumbComponents/AudioRecorder";

const EditNewPost = ({
    contentType,
    content,
    setNewPostContent,
    myId,
    postImage,
    postVideo,
    postQuote,
    postAIImage,
    postAudio = () => { }
}) => {

    const navigate = useNavigate();

    const [caption, setCaption] = useState('');
    const [location, setLocation] = useState(false);
    const [members, setMembers] = useState([]);
    const [displayedMembers, setDisplayedMembers] = useState([]);
    const [displayedMemberFaces, setDisplayedMemberFaces] = useState([]);

    const postToGallery = () => {
        switch (contentType) {
            case 'image': {
                postImage(caption, location, members);
                break;
            };
            case 'data:video/mp4':
            case 'data:video/quicktime':
                {
                    postVideo(caption, location, members);
                    break;
                };
            case 'audio': {
                postAudio(caption, location, members);
                break;
            };
            case 'quote': {
                postQuote(caption, location, members);
                break;
            };
            case 'aIImage': {
                postAIImage(caption, location, members);
                break;
            }
        }
    };

    useEffect(() => {
        setDisplayedMemberFaces(displayedMembers.map(member => {
            return (
                <img style={{ height: '30px', width: '30px', borderRadius: '50%', marginRight: '-7px', border: '2px solid var(--base)' }} src={`https://baesapp.com/media/${member.memberId.profileShot}`}></img>
            )
        }));
    }, [displayedMembers]);

    return (
        <>
            <div className="fullScreen removeScrollBar modalContainer">
                <div className="flex vertical" style={{ gap: '6px' }}>
                    <div style={{ minHeight: '115px', width: '50px' }} />
                    <input
                        onChange={e => setCaption(e.target.value)}
                        placeholder="Add caption"
                        className="editNewPostInput bluePlaceholder">
                    </input>
                    <button onClick={() => navigate('addAddress')} className={`editNewPostButton ${location ? 'newMediaPostInfo' : ''}`}>
                        {location ? location.description : 'Add location'}
                    </button>
                    <button
                        style={{ display: 'flex', justifyContent: 'flex-start' }}
                        onClick={() => navigate('addMembers')} className={`editNewPostButton ${displayedMembers.length ? 'newMediaPostInfo' : ''}`}>
                        {displayedMembers.length ? displayedMemberFaces : 'Tag members'}
                    </button>
                    {
                        contentType == 'aIImage' ?
                            <img style={{ width: '98%', borderRadius: '7px', alignSelf: 'center' }} src={content} /> :
                            contentType == 'image' ?
                                <img style={{ width: '98%', borderRadius: '7px', alignSelf: 'center' }} src={content} /> :
                                contentType == 'data:video/quicktime' ?
                                    <video playsInline loop autoPlay muted controls style={{ width: '98%', borderRadius: '7px', alignSelf: 'center' }} >
                                        <source src={content} type="video/quicktime" />
                                    </video> :
                                    contentType == 'data:video/mp4' ?
                                        <video playsInline loop autoPlay muted controls style={{ width: '98%', borderRadius: '7px', alignSelf: 'center' }} >
                                            <source src={content} type="video/mp4" />
                                        </video> :
                                        contentType == 'quote' ?
                                            <div className="flex vertical" style={{ marginTop: '20px' }}>
                                                {myId &&
                                                    <div className="flex alignCenter" style={{ gap: '10px', marginBottom: '-55px', zIndex: '1', marginLeft: '20px' }}>
                                                        <img style={{ height: '40px', width: '40px', borderRadius: '50%' }} src={`https://baesapp.com/media/${myId.profileShot}`} />
                                                        <span style={{ fontSize: '1.5em', fontWeight: 'bold' }}>{myId.username}</span>
                                                    </div>
                                                }
                                                <textarea
                                                    placeholder="Type your quote here..."
                                                    value={content}
                                                    onChange={e => setNewPostContent(e.target.value)}
                                                    rows='6'
                                                    style={{
                                                        fontSize: '1.7em',
                                                        border: 'none',
                                                        resize: 'none',
                                                        width: 'calc(100% - 60px)',
                                                        borderRadius: '30px',
                                                        backgroundColor: 'var(--primaryGlass)',
                                                        color: 'var(--text)',
                                                        padding: '60px 20px 20px',
                                                        alignSelf: 'center',
                                                        outline: 'none',
                                                        border: '2px solid var(--accent)'
                                                    }} />
                                            </div> :
                                            contentType == 'audio' ?
                                                <AudioRecorder base64={content} setBase64={setNewPostContent} /> :
                                                null
                    }
                    <div style={{ minHeight: '20px', width: '50px' }} />
                    <ModalHeader title={'ADD TO GALLERY'} actionName={'FINISH'} actionActive={(contentType == 'quote') || (contentType == 'audio') ? content : true} actionFunction={() => postToGallery()} />
                </div>
            </div>
            <Routes>
                <Route path='/addAddress' element={<AddAddress _setLocation={setLocation} _location={location} />} />
                <Route path='/addMembers' element={<AddMembers _members={members} _displayedMembers={displayedMembers} _setMembers={setMembers} _setDisplayedMembers={setDisplayedMembers} />} />
            </Routes>
        </>
    );
};

const mapStateToProps = state => {
    return ({
        myMedia: state.myMedia,
        myId: state.myId
    });
};

export default connect(mapStateToProps, { setNewPost, deletePost, setMyPosts })(EditNewPost);
