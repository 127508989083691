import React, { useEffect, useState, useRef } from "react";

import { connect } from 'react-redux';

import { Routes, Route, useNavigate } from 'react-router-dom';

import baesappApi from '../../../apis/baesappApi';

import { setNewWalletHasPin, setTopBarOffset, setTransactionsList, setVisitMember } from '../../../redux/actions';

import DepositZAR from './DepositZAR';
import MagicCash from "./MagicCash";
import WithdrawZAR from "./WithdrawZAR";
import MemberModal from '../../../dumbComponents/MemberModal';
import normalizeDate from "../../../hooks/normalizeDate";
import setLocalObject from "../../../hooks/setLocalObject";
import BaeSvg from "../../../dumbComponents/BaeSvg";
import SearchToPay from "./SearchToPay";
import Loans from "./Loans";

const Wallet = ({
    memberModal,
    myWallet,
    setNewWalletHasPin,
    my_id,
    setTransactionsList,
    transactionsList,
    setTopBarOffset,
    myAuthentication,
    setVisitMember
}) => {

    const navigate = useNavigate();

    const walletCard = useRef();

    const [pin1, setPin1] = useState('');
    const [pin2, setPin2] = useState('');
    const [previousTotalInCents, setPreviousTotalInCents] = useState(null);
    const [flashRed, setFlashRed] = useState(false);
    const [flashGreen, setFlashGreen] = useState(false);
    const [memberIds, setMemberIds] = useState([]);
    const [loading, setLoading] = useState(false);
    const [scrollY, setScrollY] = useState(0);

    const scrollElementRef = useRef(null);

    const populateMemberIds = () => {
        let Ids = [];
        myWallet.transactions.map(transaction => {
            if (!Ids.includes(transaction.memberId)) {
                Ids.push(transaction.memberId);
            };
        });
        setLoading(true);
        baesappApi.post('/getMemberIds', { Ids })
            .then(res => {
                let tempMemberIds = {};
                res.data.map(member => {
                    tempMemberIds[member._id] = member.memberId;
                });
                setMemberIds(tempMemberIds);
                setLoading(false);
            })
            .catch(err => {
                console.log(err);
                setLoading(false);
            });
    };

    useEffect(() => {
        if (Object.entries(memberIds).length) updateTransactionsList();
    }, [memberIds]);

    useEffect(() => {
        if (myWallet.transactions) populateMemberIds();
        if (myWallet.totalInCents != previousTotalInCents && previousTotalInCents != null) {
            if (myWallet.totalInCents > previousTotalInCents) {
                triggerFlashGreen();
            } else {
                triggerFlashRed();
            }
            setPreviousTotalInCents(myWallet.totalInCents);
        };
        setPreviousTotalInCents(myWallet.totalInCents);
    }, [myWallet.totalInCents]);

    const triggerFlashGreen = () => {
        setFlashGreen(true);
        setTimeout(() => {
            setFlashGreen(false);
        }, 2000);
    };
    const triggerFlashRed = () => {
        setFlashRed(true);
        setTimeout(() => {
            setFlashRed(false);
        }, 2000);
    };

    const setWalletPin = () => {
        baesappApi.post('/setWalletPin', { pin: pin1 })
            .then(res => {
                setNewWalletHasPin(true);
            })
            .catch(err => {
                console.log(err);
                window.alert('Unexpected error occured')
            })
    };
    const numberFormat = Intl.NumberFormat(undefined, {
        style: "currency",
        currency: "ZAR",
    })

    const isMoneyIncoming = (transactionType) => {
        if (
            transactionType == 'deposit' ||
            transactionType == 'incomingPayment' ||
            transactionType == 'magicCashIncoming'
        ) {
            return true;
        } else {
            return false;
        };
    };

    const getTransactionType = (transactionType) => {
        switch (transactionType) {
            case 'deposit':
                return 'Deposit';
            case 'outgoingPayment':
                return 'Outgoing payment';
            case 'incomingPayment':
                return 'Incoming payment';
            case 'withdrawal':
                return 'Withdrawal';
            case 'magicCashIncoming':
            case 'magicCashOutgoing':
                return 'Magic cash';
        };
    };

    const memberProfileShotClicked = (member) => {
        if (member._id == my_id) {
            navigate('/account');
        } else {
            setLocalObject('memberModal', member);
            navigate('memberModal');
        }
    };

    const visitMember = (memberId, _id) => {
        let member = { ...memberId, _id };
        delete member.lastMessage;
        setVisitMember({
            path: 'wallet',
            memberEssentials: member
        });
        navigate(`/visit/wallet?_id=${_id}`);
    };

    const updateTransactionsList = () => {
        let tempTransactionsList = [];
        myWallet.transactions.map(transaction => {
            let newTransaction = { ...transaction };
            newTransaction._id = transaction.memberId;
            newTransaction['memberId'] = memberIds[transaction.memberId];
            tempTransactionsList.push(newTransaction);
        });
        setTransactionsList(tempTransactionsList.slice().reverse());
    };

    const renderList = transactionsList.map((transaction, index) => {
        return (
            <div className="transactionListCard" style={{ marginBottom: index + 1 == transactionsList.length ? walletCard.current?.clientHeight + 55 : '18px'}}>
                <img onClick={() => memberProfileShotClicked({ _id: transaction._id, ...transaction.memberId })} className="transactionListProfileShot" src={`https://baesapp.com/media/${transaction.memberId.profileShot}`} />
                <div onClick={() => { if (transaction._id != my_id) visitMember(transaction.memberId, transaction._id) }} className="transactionTextContainer">
                    <div className="transactionTextTop">
                        <span style={{fontSize: '1.2em', fontWeight: 'bold'}}>{transaction.memberId.username}</span>
                        <small className="transactionWeakText">{normalizeDate(transaction.date, false, false)}</small>
                    </div>
                    <div className={`walletColorCard ${isMoneyIncoming(transaction.transactionType) ? 'walletColorGreen' : 'walletColorRed'}`}>
                        <span>
                            {isMoneyIncoming(transaction.transactionType) ? '+ ' : '- '} <strong>{numberFormat.format(transaction.amountInCents / 100)}</strong>
                        </span>
                        {transaction.reference == 'Poes mal' && <small>{getTransactionType(transaction.transactionType)}</small>}
                        {transaction.reference != 'Poes mal' && <small>{transaction.reference || getTransactionType(transaction.transactionType)}</small>}
                    </div>
                </div>
            </div>
        );
    });

    const renderContent = () => {
        return (
            <>
                <div className="fullScreen walletContainer removeScrollBar flex vertical">
                    <div style={{ minHeight: '50px' }}></div>
                    <div className="flex vertical"
                        style={{
                            backgroundColor: 'var(--text)',
                            color: 'var(--base)',
                            minHeight: '150px',
                            padding: '20px',
                            boxSizing: 'border-box'
                        }}>
                        <span style={{
                            color: 'var(--secondary)',
                            fontSize: '1.3em',
                            fontWeight: 'bold'
                        }}>Total Balance</span>
                        <span style={{ fontWeight: 'bold', fontSize: '2.4em' }}>{numberFormat.format(myWallet.totalInCents / 100).slice(2)}</span>
                    </div>
                    <div className="flex spaceAround"
                        style={{
                            width: '100%',
                            transform: 'translateY(-50%)',
                            marginBottom: '-70px'
                        }}>
                        <button
                            onClick={() => navigate('searchToPay')}
                            className="flex alignCenter walletActionButton">
                            <BaeSvg height="20px" iconName="dollar" color="accent" />
                            <span>Send</span>
                        </button>
                        <button
                            onClick={() => navigate('magicash')}
                            className="flex alignCenter walletActionButton magicashFlicker">
                            <BaeSvg height="20px" iconName="magicash" color="magicashFlicker" />
                            <span>Magicash</span>
                        </button>
                    </div>
                    <div style={{
                        height: 'fill-available',
                        overflow: 'scroll',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '10px'
                    }}>
                        <div style={{minHeight: '45px'}}/>
                        <span style={{fontSize: '1.5em', color: 'var(--secondary)', fontWeight: 'bold', textAlign: 'center'}}>Wallet Activity</span>
                        {renderList}
                    </div>
                </div>
                <Routes>
                    <Route path='depositZAR' element={<DepositZAR />} />
                    <Route path='magicash/*' element={<MagicCash />} />
                    <Route path='memberModal/*' element={<MemberModal />} />
                    <Route path='searchToPay/*' element={<SearchToPay />} />
                    {myWallet.totalInCents > 0 && <Route path='withdrawZAR' element={<WithdrawZAR />} />}
                </Routes>
            </>
        );
    }

    return (
        <>
            {
                myWallet.hasPin ?
                    renderContent() :
                    <>
                        <div onClick={() => navigate(-1)} className="fullScreen fixed top left depositModal" />
                        <div className="depositModalCard fixed flex">
                            <p>Create a wallet pin code to activate your wallet</p>
                            <input
                                type='number'
                                onChange={e => { if (e.target.value.length < 7) setPin1(e.target.value) }}
                                value={pin1}
                                placeholder='Create new pin'
                            />
                            <input
                                type='number'
                                onChange={e => { if (e.target.value.length < 7) setPin2(e.target.value) }}
                                value={pin2}
                                placeholder='Re-enter pin...'
                            />
                            {pin1 == pin2 && pin1.length == 6 && <button onClick={setWalletPin}>Confirm wallet pin</button>}
                        </div>
                    </>
            }
        </>
    );
};

const mapStateToProps = state => {
    return ({
        memberModal: state.memberModal,
        my_id: state.my_id,
        myWallet: state.myWallet,
        transactionsList: state.transactionsList,
        myAuthentication: state.myAuthentication
    });
};

export default connect(mapStateToProps, {
    setNewWalletHasPin,
    setTransactionsList,
    setTopBarOffset,
    setVisitMember
})(Wallet);