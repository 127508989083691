import React, { useEffect, useState } from "react";

import { connect } from 'react-redux';
import CryptoJS from 'crypto-js';
import { Routes, Route, useNavigate } from 'react-router-dom';

import baesappApi from '../../../apis/baesappApi';
import { setMyWalletTotal, setNewMyWalletTransaction } from "../../../redux/actions";

const MagicCash = ({
    myWallet,
    memberId,
    memberName,
    setNewMyWalletTransaction,
    setMyWalletTotal
}) => {

    const navigate = useNavigate();

    const [amountInCents, setAmountInCents] = useState(0);
    const [passphrase, setPassphrase] = useState('');
    const [totalInputActive, setTotalInputActive] = useState(false);

    const consumeMagicNote = () => {
        baesappApi.post('/magicCash/use', {
            passphrase,
            amountInCents,
            memberId
        })
            .then(res => {
                setMyWalletTotal(res.data.totalLeftInCents);
                setNewMyWalletTransaction(res.data);
                navigate(-1);
            })
            .catch(err => {
                window.alert('something went wrong')
            })
    };

    const getTotalString = () => {
        let leftString;
        let rightString;
        const toFixed = (amountInCents / 100).toFixed(2);

        leftString = toFixed.slice(0, toFixed.indexOf('.'));
        leftString = (+leftString).toLocaleString();

        rightString = toFixed.slice(toFixed.indexOf('.'));

        return ('R' + leftString + rightString);
    };

    return (
        <>
            <div onClick={() => navigate(-1)} className="fullScreen fixed top left depositModal" />
            <div className="depositModalCard fixed flex">
                <div className="flex vertical">
                    <span className="magicashFlicker" style={totalInputActive ? { fontSize: '50px', textAlign: 'center', fontWeight: 'bold' } : { fontSize: '45px', textAlign: 'center', fontWeight: 'bold' }}>{getTotalString()}</span>
                    <input
                        style={{ opacity: '0', height: '50px', marginTop: '-50px', paddingRight: '100%' }}
                        type='number'
                        min="0"
                        id="totalInput"
                        pattern="\d*"
                        onFocus={() => setTotalInputActive(true)}
                        onBlur={() => setTotalInputActive(false)}
                        onChange={e => { if (e.target.value <= 50000) { setAmountInCents(e.target.value) } }}
                        value={amountInCents}
                        placeholder={0}
                    />
                </div>
                <div className="flex vertical">
                    <span style={passphrase.length > 15 ? { color: 'var(--secondary)' } : { color: 'var(--error)' }}>Magic cash keyphrase</span>
                    <input
                        type='text'
                        className="walletTextInput"
                        onChange={e => setPassphrase(e.target.value)}
                        value={passphrase}
                        placeholder='Type value...'
                    />
                    <span style={passphrase.length > 15 ? { color: 'var(--secondary)' } : { color: 'var(--error)' }} className={`magicNoteLengthProgress`}>{passphrase.length > 15 ? passphrase.length : `${passphrase.length}/16`}</span>
                </div>
                <button className="walletVisitPayButton magicashFlicker" style={amountInCents > 0 && passphrase.length > 16 ? { color: 'var(--accent)' } : { color: 'var(--primary)' }} onClick={() => {
                    if (amountInCents > 0 && passphrase.length > 16) {
                        consumeMagicNote()
                    }
                }}>Voilà</button>
            </div>
        </>
    );
};

const mapStateToProps = state => {
    return ({
        myWallet: state.myWallet
    });
};

export default connect(mapStateToProps, {
    setNewMyWalletTransaction,
    setMyWalletTotal
})(MagicCash);