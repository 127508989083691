import React, { useEffect, useState } from "react";

import { connect } from 'react-redux';

import { Routes, Route, useNavigate } from 'react-router-dom';

import baesappApi from '../../../apis/baesappApi';
import { setMyWalletTotal, setNewMyWalletTransaction } from "../../../redux/actions";
import ModalHeader from "../../../dumbComponents/Home/modalHeader";

const DepositZAR = ({
  myWallet,
  my_id,
  setMyWalletTotal,
  setNewMyWalletTransaction
}) => {

  const navigate = useNavigate();

  const [amountInCents, setAmountInCents] = useState(0);
  const [totalInputActive, setTotalInputActive] = useState(false);

  var yoco = new window.YocoSDK({
    publicKey: 'pk_test_ac1f58de1Wvlmm819af4',
  });

  const getTotalString = () => {
    let leftString;
    let rightString;
    const toFixed = (amountInCents / 100).toFixed(2);

    leftString = toFixed.slice(0, toFixed.indexOf('.'));
    leftString = (+leftString).toLocaleString();

    rightString = toFixed.slice(toFixed.indexOf('.'));

    return ('R' + leftString + rightString);
  };

  const depositMoney = () => {
    yoco.showPopup({
      amountInCents: parseInt(amountInCents),
      currency: 'ZAR',
      name: 'Baesapp',
      description: 'Baesapp wallet',
      callback: function (result) {
        // This function returns a token that your server can use to capture a payment
        if (result.error) {
          const errorMessage = result.error.message;
          alert("error occured: " + errorMessage);
        } else {
          baesappApi.post('/depositZAR', { token: result.id, amountInCents })
            .then(res => {
              setMyWalletTotal(res.data.totalLeftInCents);
              setNewMyWalletTransaction(res.data);
            })
            .catch(err => {
              alert("error occured: " + err);
            });
        }
        // In a real integration - you would now pass this chargeToken back to your
        // server along with the order/basket that the customer has purchased.
      }
    });
  };

  return (
    <>
      <div className='fullScreen removeScrollBar modalContainer'>
        <div className='flex vertical'>
          <div style={{ minHeight: '110px', width: '50px' }} />
          <span style={{
            color: 'var(--secondary)',
            fontSize: '1.5em',
            width: '70%',
            alignSelf: 'center',
            textAlign: 'center',
            margin: '50px 0'
          }}>How much would you like to deposit?</span>
          <div className="flex vertical">
            <span style={totalInputActive ? { color: 'var(--accent)', fontSize: '50px', textAlign: 'center', fontWeight: 'bold' } : { color: 'var(--text)', fontSize: '45px', textAlign: 'center', fontWeight: 'bold' }}>{getTotalString()}</span>
            <input
              style={{ opacity: '0', height: '50px', marginTop: '-50px', paddingRight: '100%' }}
              type='number'
              min="0"
              pattern="\d*"
              onFocus={() => setTotalInputActive(true)}
              onBlur={() => setTotalInputActive(false)}
              onChange={e => setAmountInCents(e.target.value)}
              value={amountInCents}
              placeholder={200}
            />
          </div>
          <ModalHeader title={'DEPOSIT ZAR'} actionName={`Deposit R${amountInCents / 100}`} actionActive={amountInCents >= 200}
            actionFunction={() => {
              depositMoney();
              navigate(-1)
            }}
          />
        </div>
      </div >
    </>
  );
};

const mapStateToProps = state => {
  return ({
    my_id: state.my_id,
    myWallet: state.myWallet
  });
};

export default connect(mapStateToProps, {
  setMyWalletTotal,
  setNewMyWalletTransaction
})(DepositZAR);