const ssReducer = (ss = false, action) => {
    switch(action.type){
        case 'ADD_SS':{
          let newSs = {...ss};
          newSs[action.payload.key] = action.payload.value;
          return newSs;
        };
        case 'REMOVE_SS':{
          let newSs = {...ss};
          delete newSs[action.payload];
          return newSs;
        };
        case 'CLEAR_ALL':
                return false;
        default:
            return ss;
    }
};

export default ssReducer;
