import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux'

import baesappApi from '../../../apis/baesappApi';

import { useNavigate } from 'react-router-dom';

import { setNewMyInfo } from '../../../redux/actions';
import ModalHeader from '../../../dumbComponents/Home/modalHeader';
import Switch from '../../../dumbComponents/Switch';

const PersonalityAttributes = ({
    myInfo,
    generalKeys,
    setNewMyInfo
}) => {

    const navigate = useNavigate();

    const [canSave, setCanSave] = useState(true);
    const [newMyInfoLocal, setNewMyInfoLocal] = useState(false);

    useEffect(() => {
        if (myInfo) {
            setNewMyInfoLocal(myInfo);
        }
    }, []);

    const categoryFields = [
        {
            field: 'personalityTraits',
            label: 'What personality traits do you have?',
            values: [
                "Adventurous",
                "Affectionate",
                "Agreeable",
                "Analytical",
                "Assertive",
                "Attentive",
                "Calm",
                "Charismatic",
                "Cheerful",
                "Confident",
                "Considerate",
                "Courageous",
                "Creative",
                "Curious",
                "Dedicated",
                "Dependable",
                "Determined",
                "Disciplined",
                "Easygoing",
                "Energetic",
                "Enthusiastic",
                "Flexible",
                "Friendly",
                "Generous",
                "Gentle",
                "Giving",
                "Gracious",
                "Honest",
                "Humble",
                "Imaginative",
                "Independent",
                "Industrious",
                "Innovative",
                "Intelligent",
                "Intuitive",
                "Kind",
                "Logical",
                "Loyal",
                "Meticulous",
                "Modest",
                "Open-minded",
                "Organized",
                "Patient",
                "Persistent",
                "Persuasive",
                "Philosophical",
                "Practical",
                "Proactive",
                "Professional",
                "Quick-witted",
                "Realistic",
                "Reliable",
                "Resourceful",
                "Respectful",
                "Responsible",
                "Self-assured",
                "Self-aware",
                "Self-disciplined",
                "Sensitive",
                "Serious",
                "Sincere",
                "Social",
                "Strategic",
                "Supportive",
                "Thoughtful",
                "Tolerant",
                "Trustworthy",
                "Understanding",
                "Versatile",
                "Witty"
                ],
            isArray: true
        }
    ]

    const saveFields = () => {
        if (canSave) {
            let toSaveFields = {};
            toSaveFields['personalityTraits'] = newMyInfoLocal['personalityTraits'];

            baesappApi.post('/setInfo', { info: toSaveFields })
                .then(res => {
                    setNewMyInfo(res.data)
                    navigate(-1);
                })
                .catch(err => {
                    console.log(err);
                })
        }
    };

    const valueClicked = (field, value, isArray) => {
        let tempNewMyInfo = { ...newMyInfoLocal };
        if (isArray) {
            const indexOfValue = tempNewMyInfo[field].value.findIndex(_value => value == _value);
            if (indexOfValue != -1) {
                tempNewMyInfo[field].value.splice(indexOfValue, 1);
            } else {
                tempNewMyInfo[field].value.push(value);
            };
        } else {
            if (tempNewMyInfo[field].value == value) {
                tempNewMyInfo[field].value = '';
            } else {
                tempNewMyInfo[field].value = value;
            }
        };
        setNewMyInfoLocal(tempNewMyInfo);
    };

    const isSelected = (field, value) => {
        if (field.isArray) {
            return newMyInfoLocal[field.field].value.includes(value);
        } else {
            return newMyInfoLocal[field.field].value == value;
        }
    };

    const fieldPublic = (field) => {
        if (newMyInfoLocal) {
            return newMyInfoLocal[field].public;
        } else {
            return false;
        }
    };

    const privateButtonClicked = (field) => {
        let newNewMyInfoLocal = { ...newMyInfoLocal };
        newNewMyInfoLocal[field].public = !newNewMyInfoLocal[field].public;
        setNewMyInfoLocal(newNewMyInfoLocal);

        let toSaveFields = {};

        toSaveFields[field] = {
            value: newNewMyInfoLocal[field].value,
            public: newNewMyInfoLocal[field].public
        }

        baesappApi.post('/setInfo', { info: toSaveFields })
        .then(res => {
            setNewMyInfo(res.data);
        })
        .catch(err => {
            console.log(err);
            setNewMyInfoLocal(myInfo);
        })
    };

    let fieldsList = [];
    if (newMyInfoLocal) {
        fieldsList = categoryFields.map(field => {
            let fieldValuesList = field.values.map(value => {
                return (
                    <div onClick={() => valueClicked(field.field, value, field.isArray)} className='infoPickable' style={isSelected(field, value) ? { borderColor: 'var(--accent)', color: 'var(--text)' } : {}}>
                        {value}
                    </div>
                )
            });
            return (
                <div className='flex vertical' style={{ marginLeft: '20px' }}>
                    <div className='flex fieldEditHeader'>
                        <div className='flex'>
                            <img style={{ maxWidth: '20px', maxHeight: '20px', marginRight: '15px', minWidth: '20px', objectFit: 'contain' }} src={field.src} />
                            <span style={{ fontWeight: 'bold', fontSize: '1.2em' }}>{field.label}</span>
                        </div>
                    </div>
                    <div>
                        {fieldValuesList}
                    </div>
                    <div className="flex infoFieldPrivateSwitch" style={{width: 'fit-content', margin: '5px 20px 20px 0px', alignSelf: 'flex-end'}}>
                        <span className="infoFieldPrivateLabel" style={!fieldPublic(field.field) ? { color: 'var(--accent)' } : {}}>private mode</span>
                        <Switch callback={() => privateButtonClicked(field.field)} switchOn={!fieldPublic(field.field)} switchBallText={fieldPublic(field.field) ? 'OFF' : 'ON'} />
                    </div>
                    <div className='infoLineSeparator' />
                </div>
            );
        })
    }

    return (
        <div className='fullScreen removeScrollBar modalContainer'>
            <div style={{ height: '100px' }} />
            {fieldsList}
            <ModalHeader title={'PERSONALITY'} actionName={'SAVE'} actionActive={canSave} actionFunction={() => saveFields()} />
        </div>
    );
};

const mapStateToProps = state => {
    return ({
        myInfo: state.myInfo
    });
};

export default connect(mapStateToProps, { setNewMyInfo })(PersonalityAttributes);