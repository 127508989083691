import React from "react";
import { Routes, Route } from 'react-router-dom';

import Login from './Login';
import Register from './Register';
import ResetPassword from "./ResetPassword";


const Authentication = () => {
    return(
        <Routes>
            <Route path="/" element={<Login/>}/>
            <Route path="/register" element={<Register/>}/>
            <Route path="/resetPassword" element={<ResetPassword/>}/>
        </Routes>
    );
};

export default Authentication;