import getMonthNumber from "./getMonthNumber";

const normalizeDate = (_itemDate, allowTodayText = true, chatCardDate = false) => {
    let normalizedDate = '';
    let timeText = '';
    let dayText = '';
    let dateText = '';
    let monthText = '';
    let yearText = '';

    const itemDate = new Date(_itemDate).toString();
    const itemDateYear = itemDate.slice(11, 15);
    const itemDateMonth = getMonthNumber(itemDate.slice(4, 7));
    const itemDateDate = itemDate.slice(8, 10);
    const itemDateDay = itemDate.slice(0, 3);
    const itemDateTime = itemDate.slice(16, 21);

    const currDateUtc = new Date();
    const currYear = currDateUtc.getFullYear();
    const currDate = currDateUtc.toString();

    //set dayText
    const getDaysDifference = () => {
        let itemDateUtc = new Date(itemDateMonth + '/' + itemDateDate + '/' + itemDateYear);
        return Math.floor(currDateUtc / 8.64e7) - Math.floor(itemDateUtc / 8.64e7);
    };
    let daysDifference = getDaysDifference();

    if (daysDifference == 1) {
        if (allowTodayText) {
            dayText = 'Today';
        } else {
            timeText = itemDateTime;
        }
    } else if (daysDifference == 2) {
        dayText = 'Yesterday';
    } else if (daysDifference < 8) {
        switch (itemDateDay) {
            case 'Sun':
                dayText = 'Sunday';
                break;
            case 'Mon':
                dayText = 'Monday';
                break;
            case 'Tue':
                dayText = 'Tuesday';
                break;
            case 'Wed':
                dayText = 'Wednesday';
                break;
            case 'Thu':
                dayText = 'Thursday';
                break;
            case 'Fri':
                dayText = 'Friday';
                break;
            case 'Sat':
                dayText = 'Saturday';
                break;
        }
    };


    if (dayText == '' && timeText == '') {
        //set dateText
        dateText = itemDateDate;
        //set monthText
        monthText = itemDate.slice(4, 7);
        //set yearText
        yearText = itemDateYear;
    };

    if (timeText != '') {
        normalizedDate = timeText;
    } else {
        if (dayText == '') {
            if (chatCardDate) {
                normalizedDate = itemDateDate+'/'+itemDateMonth+'/'+itemDateYear;
            } else {
                if (yearText == currYear) {
                    normalizedDate = itemDateDay + ', ' + dateText + ' ' + monthText + ' ' + yearText;
                } else {
                    normalizedDate = dateText + ' ' + monthText + ' ' + yearText;
                }
            }
        } else {
            normalizedDate = dayText;
        }
    }

    return normalizedDate;
};

export default normalizeDate;