import React, { useEffect, useState } from "react";

import {connect} from 'react-redux';
import {useNavigate, Route, Routes} from 'react-router-dom';

import baesappApi from "../apis/baesappApi";

import getLocal from '../hooks/getLocal';

const Company = ({
}) => {

    const navigate = useNavigate();

    const [company, setCompany] = useState(false);
    const [newFolderName, setNewFolderName] = useState(false);
    const [newFolderKeys, setNewFolderKeys] = useState([
        {
            key: 'address1',
            label: 'Address 1',
            selected: false
        },
            
        {   key: 'address2',
            label: 'Address 2',
            selected: false
        },
        {   key: 'city',
            label: 'City',
            selected: false
        },
        {   key: 'employmentStatus',
            label: 'Employment status',
            selected: false
        },
        {   key: 'emergencyContact',
            label: 'Emergency contact',
            selected: false
        },
        {   key: 'maritalStatus',
            label: 'Marital status',
            selected: false
        },
        {   key: 'occupation',
            label: 'Occupation',
            selected: false
        },
        {   key: 'provinceState',
            label: 'Province / State',
            selected: false
        },
        {   key: 'studentStatus',
            label: 'Student status',
            selected: false
        },
        {   key: 'zip',
            label: 'ZIP code',
            selected: false
        },
    ]);

    useEffect(() => {
        baesappApi.post('/getCompany', getLocal('companyId'))
            .then(res => {
                 setCompany(res.data);
            })
            .catch(err => {
                console.log(err);
            })
    }, []);

    const createFolder = () => {
        let keys = newFolderKeys.filter(newFolderKey => newFolderKey.selected);
        keys.map(key => delete key.selected);

        baesappApi.post('/createFolder', {companyId: company._id, name: newFolderName, keys: newFolderKeys})
            .then(res => {
                console.log(res);
            })
            .catch(err => {
                console.log(err);
            });
    };

    const Main = () => {
        return(
            <div>
                <button onClick={() => createFolder()}>Create new folder</button>
                <button onClick={() => navigate(-1)}>back</button>
            </div>
        );
    };
    
    const CreateFolder = () => {
        const SelectNewFolderKeys = () => {
            const toggleNewFolderKey = (key) => {
                let tempNewFolderKeys = [...newFolderKeys];
                tempNewFolderKeys.map((tempNewFolderKey, index) => {
                    if(tempNewFolderKey.key == key){
                        tempNewFolderKey[index].selected = !tempNewFolderKey[index].selected;
                    };
                });
                setNewFolderKeys(tempNewFolderKeys);
            };
            const newFolderKeysList = newFolderKeys.map(newFolderKey => {
                return(
                    <div onClick={() => toggleNewFolderKey(newFolderKey.key)}>
                        <p>{newFolderKey.label}</p>
                        <p>{newFolderKey.selected}</p>
                    </div>
                );
            });
            return(
                <div>
                    {newFolderKeysList}
                    <button onClick={() => navigate(-1)}>Back</button>
                    <button onClick={() => navigate(-1)}>Done</button>
                </div>
            );
        };
        const Root = () => {
            return(
                <div>
                    <p>folder name</p>
                    <input value={newFolderName} onChange={e => setNewFolderName(e.target.value)}></input>
                    <button onClick={() => navigate('selectNewFolderKeys')}>Select fields</button>
                    <button onClick={() => createFolder()}>create</button>
                    <button onClick={() => navigate(-1)}>Back</button>
                </div>
            );
        };
        return(
            <Routes>
                <Route path="/" element={<Root/>}/>
                <Route path="/selectNewFolderKeys" element={<SelectNewFolderKeys/>}/>
            </Routes>
        );
    };

    return(
        <div>
            <Routes>
                <Route path="/" element={<Main/>}/>
                <Route path="/createFolder/*" element={<CreateFolder/>}/>
            </Routes>
        </div>
    );
};

const mapStateToProps = state => {
    return({
    });
};

export default connect(mapStateToProps)(Company);
