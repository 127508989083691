const myMatcherReducer = (myMatcher = false, action) => {
    switch (action.type) {
        case 'GOT_MY_DETAILS':
            return action.payload.matcher;
        case 'MY_MATCHER_LIKE_SET':{
            let newMyMatcher = {...myMatcher};
            newMyMatcher.like = action.payload;
            return newMyMatcher;
        }
        case 'MY_MATCHER_MATCHES_SET':{
            let newMyMatcher = {...myMatcher};
            newMyMatcher.matches = action.payload;
            return newMyMatcher;
        }
        case 'MY_MATCHER_LIKEDME_SET':{
            let newMyMatcher = {...myMatcher};
            newMyMatcher.likedMe = action.payload;
            return newMyMatcher;
        }
        case 'CLEAR_ALL':
            return [];
        default:
            return myMatcher;
    };
};

export default myMatcherReducer;
