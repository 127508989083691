import React, { useEffect, useState } from "react";

import { connect } from 'react-redux';

import spacemanDark from '../assets/spacemanDark.png';
import spaceman from '../assets/spaceman.png';
import star from '../assets/star.png';
import starDark from '../assets/starDark.png';

const Switch = ({
    darkModeEnabled,
    switchOn = false,
    heightPx = 34,
    callback,
    switchBallText = ''
}) => {
    return (
        <div onClick={() => callback()} className={`flex switchContainer ${switchOn ? 'switchOn' : 'switchOff'} alignCenter`}
            style={{
                height: `${heightPx}px`,
                width: `${(heightPx*1.667) - 4}px`,
                paddingLeft: switchOn ? `${(heightPx*0.6667)-4}px` : '0px'
            }}>
                <div className="switchBall" style={{
                    height: `${heightPx-4}px`,
                    width: `${heightPx-4}px`,
                }}>{switchBallText}</div>
        </div>
    );
};

const mapStateToProps = state => {
    return ({
        darkModeEnabled: state.darkModeEnabled
    });
};

export default connect(mapStateToProps)(Switch);