import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux'

import baesappApi from '../../../apis/baesappApi';

import ModalHeader from '../../../dumbComponents/Home/modalHeader';
import BaeSvg from '../../../dumbComponents/BaeSvg';
import Switch from '../../../dumbComponents/Switch';
import { Route, Routes, useNavigate } from 'react-router-dom';
import MemberModal from '../../../dumbComponents/MemberModal';
import setLocalObject from '../../../hooks/setLocalObject';

const AddMembers = ({
    _setMembers,
    _setDisplayedMembers,
    _members,
    _displayedMembers
}) => {

    const navigate = useNavigate();

    const [searchText, setSearchText] = useState('');
    const [segment, setSegment] = useState('Searched');
    const [selectedMembers, setSelectedMembers] = useState([]);
    const [searchInputElement, setSearchInputElement] = useState();
    const [shouldShowCancel, setShouldShowCancel] = useState(false);
    const [displayedList, setDisplayedList] = useState([]);
    const [doSearch, setDoSearch] = useState(null);
    const [searchResults, setSearchResults] = useState([]);
    const [searching, setSearching] = useState(false);
    const [selectedMembersData, setSelectedMembersData] = useState({});

    useEffect(() => {
        setSearchInputElement(document.getElementById('searchInput'));
        setSelectedMembers(_members);
        let newSelectedMembersData = {};
        _displayedMembers.map(member => {
            newSelectedMembersData[member._id]=member;
        });
        setSelectedMembersData(newSelectedMembersData);
    }, []);

    useEffect(() => {
        if (searchText == '' || searchText == ' ') {
            if (segment == 'Selected') {
                let displayedSelectedMembers = [];
                for (const key in selectedMembersData) {
                    if (selectedMembers.includes(key)) displayedSelectedMembers.push(selectedMembersData[key]);
                };
                setDisplayedList(displayedSelectedMembers);
            } else {
                setDisplayedList([]);
            };
        } else {
            if (segment == 'Selected') {
                let displayedSelectedMembers = [];
                for (const key in selectedMembersData) {
                    if (selectedMembers.includes(key)) displayedSelectedMembers.push(selectedMembersData[key]);
                };
                setDisplayedList(displayedSelectedMembers);
            } else {
                setDisplayedList(searchResults);
            };
        }
    }, [segment, searchResults]);

    const search = (value) => {
        if (value !== '') {
            setSearching(true);
            baesappApi.post('/searchMembers/username', { username: value, lastItem: false })
                .then(res => {
                    setSearchResults(res.data);
                    setSearching(false);
                })
                .catch(err => {
                    console.log(err);
                    setSearching(false);
                })
        } else {
            setSearchResults([]);
        }
    };

    const searchTextChanged = (e) => {
        setSearchText(e.target.value);
        stopSearch();
        setDoSearch(setTimeout(() => {
            search(e.target.value);
        }, 350))
    };

    const stopSearch = () => {
        clearTimeout(doSearch);
    };

    const handleOnBlur = (blurredCause) => {
        if (blurredCause == 'cancel') {
            setSearchText('');
            setShouldShowCancel(false);
        } else if (blurredCause == 'clear') {
            setSearchText('');
            searchInputElement.focus();
        }
    };

    const applyMembers = () => {
        _setMembers(selectedMembers);
        let displayedSelectedMembers = [];
        for (const key in selectedMembersData) {
            if (selectedMembers.includes(key)) displayedSelectedMembers.push(selectedMembersData[key]);
        };
        _setDisplayedMembers(displayedSelectedMembers);
        navigate(-1);
    };

    const memberClicked = (member) => {
        let newSelectedMembersData = selectedMembersData;
        newSelectedMembersData[member._id] = member;
        setSelectedMembersData(newSelectedMembersData)
        if (selectedMembers.includes(member._id)) {
            setSelectedMembers(selectedMembers.filter(selectedMember => selectedMember != member._id));
        } else {
            setSelectedMembers([...selectedMembers, member._id]);
        };
    };

    const memberProfileShotClicked = (member) => {
        let memberObj = member.memberId;
        memberObj._id = member._id;
        setLocalObject('memberModal', memberObj);
        navigate('memberModal');
    };

    const renderSearchedList = displayedList.map((member) => {
        return (
            <div key={member._id} className="flex horizontal chatCard" >
                <div className="flex horizontal chatCardLeft">
                    <img onClick={() => memberProfileShotClicked(member)} className="memberBarProfileShot" src={`https://baesapp.com/media/${member.memberId.profileShot}`} />
                    <div onClick={() => memberClicked(member)} className="flex vertical chatCardMainContent">
                        <span className="chatCardUsername">{member.memberId.username}</span>
                        <div className="chatCardContentContainer">
                            <span className="chatCardContent">Tap to {selectedMembers.includes(member._id) ? 'remove from post' : 'add to post'}</span>
                        </div>
                    </div>
                </div>
                <Switch callback={() => memberClicked(member)} switchOn={selectedMembers.includes(member._id)} />
            </div>
        );
    });

    const renderContent = () => {
        if (searching) {
            return (
                <span className="centerText noResults">Searching for {searchText}...</span>
            );
        } else {
            if (!displayedList.length) {
                return (
                    <span className="centerText noResults">No Results</span>
                );
            } else {
                return (
                    renderSearchedList
                );
            }
        };
    };

    return (
        <div className='fullScreen removeScrollBar modalContainer'>
            <div className='flex vertical'>
                <div style={{ minHeight: '110px', width: '50px' }} />
                <div className="segmentContainer flex spaceAround">
                    <button onClick={() => setSegment('Searched')} style={{ backgroundColor: `${segment == 'Searched' ? 'var(--text)' : ''}`, color: `${segment == 'Searched' ? 'var(--base)' : 'var(--secondary)'}`, borderColor: `${segment == 'Searched' ? '' : 'var(--secondary)'}` }} className="segmentButton">Search</button>
                    <button onClick={() => setSegment('Selected')} style={{ backgroundColor: `${segment != 'Searched' ? 'var(--text)' : ''}`, color: `${segment != 'Searched' ? 'var(--base)' : 'var(--secondary)'}`, borderColor: `${segment != 'Searched' ? '' : 'var(--secondary)'}` }} className="segmentButton">Selected({selectedMembers.length})</button>
                </div>
                {segment != 'Selected' && <div className="flex spaceAround">
                    <div className="searchBarContainer" style={{ width: '100%' }}>
                        <div className="searchBar" style={shouldShowCancel ? { marginLeft: '10px' } : {}}>
                            <BaeSvg height='1em' style={{ margin: '10px 5px 10px 10px' }} iconName='magnifyingGlass' color='gray' />
                            <input
                                onFocus={() => setShouldShowCancel(true)}
                                id="searchInput"
                                placeholder="Search"
                                value={searchText}
                                onChange={searchTextChanged}
                                className={`cleanInput searchBarInput`} />
                            {searchText != '' &&
                                <button className="flex" onClick={() => handleOnBlur('clear')}>
                                    <BaeSvg height='1.4em' iconName='circleX' color='gray' />
                                </button>
                            }
                        </div>
                        {shouldShowCancel && <span onClick={() => handleOnBlur('cancel')} className="searchBarCancelText">
                            Cancel
                        </span>}
                    </div>
                </div>}
                {renderContent()}
                <ModalHeader title={'TAG MEMBERS'} actionName={'APPLY'} actionActive={true} actionFunction={() => applyMembers()} />
                <Routes>
                    <Route path="/memberModal/*" element={<MemberModal />} />
                </Routes>
            </div>
        </div>
    );
};

const mapStateToProps = state => {
    return ({
    });
};

export default connect(mapStateToProps)(AddMembers);