import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { Routes, Route, useNavigate } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import { io } from 'socket.io-client';

import Activity from "./Modals/Activity";
import Home from './Home';
import Authentication from "./Authentication";
import Splash from "./Splash";
import Visit from './Visit';
import Settings from './Settings';
import Company from './Company';
import MyDoorbellRung from './MyDoorbellRung';

import syncDbSs from "../hooks/syncDbSs";
import updateWallet from "../hooks/updateWallet";

import { setFollowingModalShowFollowers, setAuthenticated, setMyData, setShowNotificationBar, setMediaFollowers, setNewIncomingRequest, setNewOutgoingRequest, setNewContact, hitChatsEventListener, setMyWalletTotal, setNewMyWalletTransaction, setNewWallet, setNewMagicCashToDelete, setMyChat, thisMemberTyped, removeFromMembersTyping, setDarkModeEnabled, setMyEvents, setMyOpenChats, thisMemberTypedOpenChat, removeFromMembersTypingOpenChat, setGameInvitation } from "../redux/actions";

import baesappApi from "../apis/baesappApi";
import setLocal from "../hooks/setLocal";
import { getTheToken, onMessageListener } from "../firebase";
import getLocalToObject from "../hooks/getLocalToObject";
import setLocalObject from "../hooks/setLocalObject";
import setEndNew from "../hooks/setEndNew";
import getUpdatedChats from "../hooks/getUpdatedChats";
import getMyChat from '../hooks/getMyChat';
import VoiceCallUser from "./VoiceCallUser";
import ReceiveVoiceCall from "./ReceiveVoiceCall";
import BaeSvg from "../dumbComponents/BaeSvg";

const Main = ({
    authenticated,
    setAuthenticated,
    setMyData,
    setShowNotificationBar,
    setDarkModeEnabled,
    setMediaFollowers,
    setNewIncomingRequest,
    setNewOutgoingRequest,
    setNewContact,
    hitChatsEventListener,
    setNewWallet,
    my_id,
    setNewMagicCashToDelete,
    setMyChat,
    thisMemberTyped,
    removeFromMembersTyping,
    setGameInvitation,
    setMyEvents,
    setMyOpenChats,
    thisMemberTypedOpenChat,
    removeFromMembersTypingOpenChat,
    showNotificationBar,
    setFollowingModalShowFollowers
}) => {

    const [showSplash, setShowSplash] = useState(false);
    const [notificationHit, setNotificationHit] = useState();
    const navigate = useNavigate();

    const [token, setToken] = useState(false);
    const [socket, setSocket] = useState(false);
    const [animating, setAnimating] = useState(false);

    const path = window.location.pathname;

    function getCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    }

    useEffect(() => {
        if (showNotificationBar) {
            setTimeout(() => {
                setShowNotificationBar(false);
            }, 4000);
        }
    }, [showNotificationBar]);


    const showNotificationBarClicked = () => {
        if (showNotificationBar.path == '/media/following') setFollowingModalShowFollowers(true);
        if (showNotificationBar.path == '/media/memberModal') {
            setLocalObject('memberModal', {
                _id: showNotificationBar._id,
                username: showNotificationBar.username,
                profileShot: showNotificationBar.profileShot
            });
        }
        navigate(showNotificationBar.path);
    };

    useEffect(() => {
        const isDark = getCookie('theme') == 'dark' ? true : false;
        if (isDark) {
            setDarkModeEnabled(true);
            var r = document.querySelector(':root');
            r.style.setProperty('--base', '#000');
            r.style.setProperty('--primary', '#3a3a3a');
            r.style.setProperty('--primaryGlass', '#3a3a3a80');
            r.style.setProperty('--primaryBlur', '#000000a4');
            r.style.setProperty('--secondary', '#686868');
            r.style.setProperty('--onAccent', '#000');
            r.style.setProperty('--text', '#fff');
        } else {
            setDarkModeEnabled(false);
            var r = document.querySelector(':root');
            r.style.setProperty('--base', '#fff');
            r.style.setProperty('--primary', '#F4F4F4');
            r.style.setProperty('--primaryGlass', '#e9e9e980');
            r.style.setProperty('--primaryBlur', '#ffffffdd');
            r.style.setProperty('--secondary', '#bbbbbb');
            r.style.setProperty('--onAccent', '#fff');
            r.style.setProperty('--text', '#000');
        }
    }, [authenticated]);

    useEffect(() => {
        if (socket && my_id) {
            socket.on('connection', (socket) => {
            });
            socket.on(my_id, (args) => {
                console.log(args.data);
                handleNotification(args.data);
            });
            socket.on(`incomingVoiceCall${my_id}`, (data) => {
                console.log(data);
            });
        }
    }, [socket]);

    const handleNotification = (data) => {
        switch (data.type) {
            case 'receivedMessage':
            case 'deletedMessage':
                getMyChat()
                    .then(res => setMyChat(res))
                getUpdatedChats(hitChatsEventListener);
                break;
            case 'memberTyping':
                thisMemberTyped(data._id);
                setTimeout(() => {
                    removeFromMembersTyping(data._id);
                }, 1000);
                break;
            case 'memberTypingOpenChat':
                thisMemberTypedOpenChat(data._id);
                setTimeout(() => {
                    removeFromMembersTypingOpenChat(data._id);
                }, 1000);
                break;
            case 'messagesRead':
                getMyChat()
                    .then(res => setMyChat(res))
                break;
            case 'walletIncomingPayment': {
                if (data._id != my_id) {
                    updateWallet(setNewWallet);
                    setShowNotificationBar(data);
                }
                break;
            }
            case 'incomingGameInvitation': {
                setGameInvitation(data);
                break;
            }
            case 'newEvent': {
                baesappApi.get('/event/getMyEvents').then(res => {
                    setMyEvents(res.data);
                })
                break;
            }
            case 'doorbellRing': {
                setLocalObject('ringerMemberId', data.memberId);
                setLocal('note', 'none');
                navigate('/doorbellRung');
                break;
            }
            case 'doorbellNote': {
                setLocalObject('ringerMemberId', data.memberId);
                setLocalObject('note', data.note);
                navigate('/doorbellRung');
                break;
            }
            case 'openChats': {
                baesappApi.get('/openChats/getMyOpenChats')
                    .then(res => {
                        setMyOpenChats(res.data);
                    })
                    .catch(err => {
                        console.log(err);
                    })
                break;
            }
            case 'walletMagicCashUsed': {
                if (data._id != my_id) {
                    updateWallet(setNewWallet);
                    setNewMagicCashToDelete(data.magicCashId);
                    setShowNotificationBar(data);
                }
                break;
            }
            case 'following':
                setShowNotificationBar(data);
                setMediaFollowers(data._id, data.action);
                break;
            case 'contactRelation':
                setShowNotificationBar(data);
                if (data.incomingAction !== '0') setNewIncomingRequest({ _id: data._id, action: data.incomingAction });
                if (data.outgoingAction !== '0') setNewOutgoingRequest({ _id: data._id, action: data.outgoingAction });
                if (data.contactAction !== '0') setNewContact({ _id: data._id, action: data.contactAction });
                getMyChat()
                    .then(res => setMyChat(res))
                break;
            default: return;
        }
        setNotificationHit(!notificationHit);
    }

    useEffect(() => {
        return () => {
            if (socket) {
                socket.close();
            }
        }
    }, []);

    useEffect(() => {
        if (my_id) {
            connectSocket();
        } else {
            disconnectSocket();
        };
    }, [my_id]);

    const disconnectSocket = () => {
        if (socket) {
            socket.disconnect();
        };
    };
    const connectSocket = () => {
        setSocket(io('https://baesapp.herokuapp.com/'));
    };

    const incept = async () => {
        setShowSplash(true);
        await baesappApi.get('/inception')
            .then(res => {
                if (res.data.chat.ss) {
                    window.localStorage.setItem('ss', CryptoJS.AES.decrypt(res.data.chat.ss, window.localStorage.getItem('ep')).toString(CryptoJS.enc.Utf8));
                } else {
                    window.localStorage.setItem('ss', JSON.stringify({}));
                    syncDbSs();
                };

                setMyData(res.data);
                setAuthenticated(true);
               // setAnimating(true);
                setShowSplash(false);
               // setTimeout(() => {
              //      setAnimating(false);
              //  }, 600);
                if (path == '/') {
                    navigate('/home/chat');
                }
                return;
            })
            .catch(err => {
                console.log(err);
                setAuthenticated(false);
                //setAnimating(true);
                setShowSplash(false);
               // setTimeout(() => {
              //      setAnimating(false);
              //  }, 600);
                if (!path.startsWith('/authentication')) navigate('/authentication');
            })
    };

    useEffect(() => {
        incept();
    }, []);

    return (
        <div className="container fixed safeArea" style={{ margin: 'auto', left: '0', right: '0' }} >
            {(showSplash || animating) ?
                <div style={{ position: 'absolute', left: 0, right: 0, top: 0, bottom: 0, margin: 'auto', justifyContent: 'center' }} className='container'>
                    <div className={`splashLogoBlock ${animating ? 'splashGrow' : ''}`} />
                    <BaeSvg className={`splashLogoBubble ${animating ? 'splashFall' : ''}`} style={{ marginTop: '66px', height: 'fit-content', width: '44px' }} iconName="bottomBubble" height='fit-content' width="44px" color="var(--text)" />
                    <span className="splashText">Baesapp</span>
                </div>
                :
                <>
                    {
                        !animating &&
                        <Routes>
                            <Route path="/home/*" element={<Home />} />
                            <Route path="/authentication/*" element={<Authentication />} />
                            <Route path="/visit/*" element={<Visit socket={socket} />} />
                            <Route path="/settings/*" element={<Settings />} />
                            <Route path="/activity/*" element={<Activity />} />
                            <Route path="/company/*" element={<Company />} />
                            <Route path="/voiceCall/*" element={<VoiceCallUser socket={socket} />} />
                            {authenticated && <Route path="/doorbellRung" element={<MyDoorbellRung />} />}
                        </Routes>
                    }
                    {socket && <ReceiveVoiceCall socket={socket} />}
                    {
                        showNotificationBar &&
                        <button onClick={showNotificationBarClicked} className="notificationBar flex spaceAround alignCenter">
                            <img className="topBarHomeProfileShot" src={`https://baesapp.com/media/${showNotificationBar.profileShot}`} />
                            <p>{showNotificationBar.username}</p>
                            <p>{showNotificationBar.event}</p>
                        </button>
                    }
                </>
            }
        </div>
    );
    // return (
    //     <div className="container fixed" style={{margin: 'auto', left: '0', right: '0'}} >
    //         {showSplash ? <Splash /> :
    //             <>
    //                 <Routes>
    //                     <Route path="/home/*" element={<Home />} />
    //                     <Route path="/authentication/*" element={<Authentication />} />
    //                     <Route path="/visit/*" element={<Visit socket={socket}/>} />
    //                     <Route path="/settings/*" element={<Settings />} />
    //                     <Route path="/activity/*" element={<Activity />} />
    //                     <Route path="/company/*" element={<Company />} />
    //                     <Route path="/voiceCall/*" element={<VoiceCallUser socket={socket} />} />
    //                     {authenticated && <Route path="/doorbellRung" element={<MyDoorbellRung />} />}
    //                 </Routes>
    //                 {socket && <ReceiveVoiceCall socket={socket} />}
    //                 {
    //                     showNotificationBar &&
    //                     <button onClick={showNotificationBarClicked} className="notificationBar flex spaceAround alignCenter">
    //                         <img className="topBarHomeProfileShot" src={`https://baesapp.com/media/${showNotificationBar.profileShot}`} />
    //                         <p>{showNotificationBar.username}</p>
    //                         <p>{showNotificationBar.event}</p>
    //                     </button>
    //                 }
    //             </>
    //         }
    //     </div>
    // );
};

const mapStateToProps = state => {
    return ({
        authenticated: state.authenticated,
        showNotificationBar: state.showNotificationBar,
        chatsEventListener: state.chatsEventListener,
        my_id: state.my_id,
        showNotificationBar: state.showNotificationBar
    });
};

export default connect(
    mapStateToProps,
    {
        setFollowingModalShowFollowers,
        setAuthenticated,
        setDarkModeEnabled,
        setMyData,
        setShowNotificationBar,
        setMediaFollowers,
        setNewIncomingRequest,
        setNewOutgoingRequest,
        setNewContact,
        hitChatsEventListener,
        setNewMyWalletTransaction,
        setMyWalletTotal,
        setNewWallet,
        setNewMagicCashToDelete,
        setMyChat,
        thisMemberTyped,
        removeFromMembersTyping,
        setMyEvents,
        setMyOpenChats,
        thisMemberTypedOpenChat,
        removeFromMembersTypingOpenChat,
        setGameInvitation
    }
)(Main);
