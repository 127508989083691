const myAuthenticationReducer = (myAuthentication=false, action) => {
  switch(action.type){
    case 'GOT_MY_DETAILS':
      return action.payload.authentication;
      case 'CLEAR_ALL':
              return false;
    default:
      return myAuthentication;
  };
};

export default myAuthenticationReducer;
