import React, { useEffect, useState, useRef } from "react";

import { connect } from 'react-redux';

import { Routes, Route, Link, useNavigate } from 'react-router-dom';

import baesappApi from "../../../apis/baesappApi";

import { setMyId, setNewMyInfo, setSearchableByProfileShot, setTopBarOffset } from '../../../redux/actions';
import Favourites from "./Favourites";
import Form from "./Form";

import normalizeDate from '../../../hooks/normalizeDate';

import WebCam from "../../../dumbComponents/WebCam";

import General from './General';
import Medical from "./Medical";
import PersonalityAttributes from "./PersonalityAttributes";
import PhysicalAttributes from "./PhysicalAttributes";

import faceGuideIcon from '../../../assets/faceGuideIcon.png';
import arrowRight from '../../../assets/arrowRight.png';
import ruler from '../../../assets/ruler.png';
import mail from '../../../assets/mail.png';
import sellphone from '../../../assets/sellphone.png';
import rings from '../../../assets/rings.png';
import tie from '../../../assets/tie.png';
import heartBeat from '../../../assets/heartBeat.png';
import pill from '../../../assets/pill.png';
import sadFace from '../../../assets/sadFace.png';
import plusMinus from '../../../assets/plusMinus.png';
import drop from '../../../assets/drop.png';
import emergencyPhone from '../../../assets/emergencyPhone.png';
import weight from '../../../assets/weight.png';
import person from '../../../assets/person.png';
import comb from '../../../assets/comb.png';
import string from '../../../assets/string.png';
import eye from '../../../assets/eye.png';
import coloPalete from '../../../assets/coloPalete.png';
import glove from '../../../assets/glove.png';
import fox from '../../../assets/fox.png';
import music from '../../../assets/music.png';
import movie from '../../../assets/movie.png';
import tv from '../../../assets/tv.png';
import book from '../../../assets/book.png';
import editIcon from '../../../assets/editIcon.png';
import editIconDark from '../../../assets/editIconDark.png';
import cake from "../../../assets/cake.png";
import briefcase from "../../../assets/briefcase.png";
import gender from "../../../assets/gender.png";
import hobbies from "../../../assets/hobbies.png";
import languages from "../../../assets/languages.png";
import education from "../../../assets/education.png";
import degree from "../../../assets/degree.png";
import skills from "../../../assets/skills.png";

import Switch from "../../../dumbComponents/Switch";
import Address from "./Address";


const Info = ({
    myInfo,
    myId,
    setNewMyInfo,
    darkModeEnabled,
    myAuthentication,
    setMyId,
    mySearch,
    setTopBarOffset
}) => {

    const navigate = useNavigate();

    const [retardMode, setRetardMode] = useState(false);
    const [facialRecognitionAllowed, setFacialRecognitionAllowed] = useState(false);
    const [newUsername, setNewUsername] = useState('');
    const [newPreferredName, setNewPreferredName] = useState('');
    const [newPhoneNumber, setNewPhoneNumber] = useState('');
    const [doUpdate, setDoUpdate] = useState(false);
    const [invalidUsername, setInvalidUsername] = useState(false);
    const [validUsername, setValidUsername] = useState(false);
    const [preferredNameUpdated, setPreferredNameUpdated] = useState();
    const [emailUpdated, setEmailUpdated] = useState();
    const [newEmail, setNewEmail] = useState('');
    const [emailInvalid, setEmailInvalid] = useState(false);
    const [phoneUpdated, setPhoneUpdated] = useState(false);
    const [phoneInvalid, setPhoneInvalid] = useState(false);
    const [newHeight, setNewHeight] = useState('');
    const [heightUpdated, setHeightUpdated] = useState(false);
    const [heightInvalid, setHeightInvalid] = useState(false);
    const [newWeight, setNewWeight] = useState('');
    const [weightUpdated, setWeightUpdated] = useState(false);
    const [weightInvalid, setWeightInvalid] = useState(false);
    const [scrollY, setScrollY] = useState(0);

    const scrollElementRef = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            if (scrollElementRef.current) {
                const scrollTop = scrollElementRef.current.scrollTop;
                const scrollDirection = scrollTop > scrollY ? 'down' : 'up';
                if (scrollDirection == 'down') {
                    setTopBarOffset('-50');
                } else if (scrollDirection == 'up') {
                    setTopBarOffset('0');
                }
                setScrollY(scrollTop);
            }
        };
        if (scrollY == 0) {
            setTopBarOffset('0')
        }
        const scrollElement = scrollElementRef.current;
        if (scrollElement) {
            scrollElement.addEventListener('scroll', handleScroll);
            return () => {
                scrollElement.removeEventListener('scroll', handleScroll);
            };
        }
    }, [scrollY]);

    useEffect(() => {
        if (mySearch) {
            setFacialRecognitionAllowed(mySearch.searchableByProfileShot);
        }
    }, [mySearch]);

    const validEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    const categoryClicked = (categoryName) => {
        switch (categoryName) {
            case 'GENERAL': {
                navigate('form');
                break;
            }
            case 'MEDICAL': {
                navigate('medical');
                break;
            }
            case 'PHYSICAL ATTRIBUTES': {
                navigate('physicalAttributes');
                break;
            }
            case 'PERSONALITY': {
                navigate('personalityAttributes');
                break;
            }
            case 'FAVOURITES': {
                navigate('favourites');
                break;
            }
            case 'ADDRESS': {
                navigate('address');
                break;
            }
        }
    };

    const getImage = (fieldName) => {
        switch (fieldName) {
            case 'Add my Email': return mail;
            case 'Add my Phone Number': return sellphone;
            case 'Marital status': return rings;
            case 'Employment status': return tie;
            case 'Blood type': return drop;
            case 'Rh Factor': return plusMinus;
            case 'Allergies': return sadFace;
            case 'Current Medications': return pill;
            case 'Medical Conditions': return heartBeat;
            case 'Emergency contacts': return emergencyPhone;
            case 'Add my Height': return ruler;
            case 'Add my Weight': return weight;
            case 'Body type': return person;
            case 'Hair color': return comb;
            case 'Hair type': return string;
            case 'Eye color': return eye;
            case 'Skin tone': return coloPalete;
            case 'Dexterity': return glove;
            case 'Color': return coloPalete;
            case 'Animal': return fox;
            case 'Music genre': return music;
            case 'Movie': return movie;
            case 'TV show': return tv;
            case 'Book': return book;
            case 'DOB': return cake;
            case 'SEX': return gender;
            case 'Hobby': return hobbies;
            case 'Highest education levels': return education;
            case 'Degrees': return degree;
            case 'Languages spoken': return languages;
            case 'Job skills': return skills;
            case 'Occupation': return briefcase;
            case 'First Name':
            case 'Last Name':
            case 'Address 1':
            case 'Address 2':
            case 'Account Email':
            case 'Preferred Name':
            case 'City':
            case 'Province / State':
            case 'ZIP':
            case 'Country':
            case 'Add my Personality traits':
                {
                    return false;
                }
            default: return person;
        }
    }

    const isFreeTextField = (fieldName) => {
        switch (fieldName) {
            case 'Add my Height': return true
            case 'Add my Weight': return true
            case 'First Name': return true
            case 'Last Name': return true
            case 'Account Email': return true
            case 'SEX': return true
            case 'DOB': return true
            case 'Preferred Name': return true
            case 'Add my Phone Number': return true
            case 'Add my Email': return true
            case 'Add': return true;
            default: return false;
        }
    }

    const getValue = (fieldName) => {
        let field;
        if (myAuthentication && myInfo) {
            switch (fieldName) {
                case 'Add my Email': {
                    field = 'email'
                    break;
                }
                case 'Add my Phone Number': {
                    field = 'phone'
                    break;
                }
                case 'Marital status': {
                    field = 'maritalStatus'
                    break;
                }
                case 'Employment status': {
                    field = 'employmentStatus'
                    break;
                }
                case 'Blood type': {
                    field = 'bloodType'
                    break;
                }
                case 'Rh Factor': {
                    field = 'rhFactor'
                    break;
                }
                case 'Allergies': {
                    field = 'allergies'
                    break;
                }
                case 'Occupation': {
                    field = 'occupation'
                    break;
                }
                case 'Job skills': {
                    field = 'jobSkills'
                    break;
                }
                case 'Languages spoken': {
                    field = 'languagesSpoken'
                    break;
                }
                case 'Degrees': {
                    field = 'degrees'
                    break;
                }
                case 'Highest education levels': {
                    field = 'highestEducationLevels'
                    break;
                }
                case 'Current Medications': {
                    field = 'medications'
                    break;
                }
                case 'Medical Conditions': {
                    field = 'medicalConditions'
                    break;
                }
                case 'Emergency contacts': {
                    field = 'emergencyContacts'
                    break;
                }
                case 'Add my Height': {
                    field = 'height'
                    break;
                }
                case 'Add my Weight': {
                    field = 'weight'
                    break;
                }
                case 'Body type': {
                    field = 'bodyType'
                    break;
                }
                case 'Hair color': {
                    field = 'hairColor'
                    break;
                }
                case 'Hair type': {
                    field = 'hairType'
                    break;
                }
                case 'Eye color': {
                    field = 'eyeColor'
                    break;
                }
                case 'Skin tone': {
                    field = 'skinTone'
                    break;
                }
                case 'Dexterity': {
                    field = 'dexterity'
                    break;
                }
                case 'Color': {
                    field = 'favouriteColors'
                    break;
                }
                case 'Animal': {
                    field = 'favouriteAnimals'
                    break;
                }
                case 'Music genre': {
                    field = 'favouriteMusicGenres'
                    break;
                }
                case 'Movie': {
                    field = 'favouriteMovies'
                    break;
                }
                case 'TV show': {
                    field = 'favouriteTVShows'
                    break;
                }
                case 'Book': {
                    field = 'favouriteBooks'
                    break;
                }
                case 'Author': {
                    field = 'favouriteAuthors'
                    break;
                }
                case 'Hobby': {
                    field = 'favouriteHobbies'
                    break;
                }
                case 'First Name': {
                    field = 'firstName'
                    break;
                }
                case 'Last Name': {
                    field = 'lastname'
                    break;
                }
                case 'Preferred Name': {
                    field = 'preferredName'
                    break;
                }
                case 'DOB': {
                    field = 'dateOfBirth'
                    break;
                }
                case 'SEX': {
                    field = 'sex'
                    break;
                }
                case 'Account Email': {
                    field = 'email'
                    break;
                }
                case 'Address 1': {
                    field = 'address1'
                    break;
                }
                case 'Address 2': {
                    field = 'address2'
                    break;
                }
                case 'City': {
                    field = 'city'
                    break;
                }
                case 'ZIP': {
                    field = 'zip'
                    break;
                }
                case 'Country': {
                    field = 'country'
                    break;
                }
                case 'Add my Personality traits': {
                    field = 'personalityTraits'
                    break;
                }
                case 'Province / State': {
                    field = 'provinceState'
                    break;
                }
            };
            if (field == 'firstName') {
                return myAuthentication.firstNames
            } else if (field == 'lastname') {
                return myAuthentication.lastname
            } else if (field == 'preferredName') {
                return myId.preferredName
            } else if (field == 'dateOfBirth') {
                return normalizeDate(myAuthentication.dateOfBirth)
            } else if (fieldName == 'Account Email') {
                return myAuthentication.email
            } else if (field == 'sex') {
                return myAuthentication.sex
            } else if (field == 'height') {
                return myInfo[field].value / 100;
            } else if (field == 'weight') {
                return myInfo[field].value / 100;
            }
            return myInfo[field].value;
        } else {
            return '';
        }
    }

    const takePhoto = (_screenShot) => {
        baesappApi.post('/setInfo/updateProfileShot', { profileShotB64: _screenShot })
            .then(res => {
                setMyId(res.data);
            })
            .catch(err => {
                console.log(err);
            })
    };

    const toggleFacialRegognitionAllowed = () => {
        const newFacialRecognitionAllowed = !facialRecognitionAllowed;
        setFacialRecognitionAllowed(!facialRecognitionAllowed);
        baesappApi.post('/setSearchableByProfileShot', { bool: newFacialRecognitionAllowed })
            .then(res => {
                setSearchableByProfileShot(res.data);
            })
            .catch(err => {
                console.log(err);
                setFacialRecognitionAllowed(!newFacialRecognitionAllowed);
            })
    };

    const getFieldStateValue = (fieldName) => {
        switch (fieldName) {
            case 'Preferred Name':
                return newPreferredName;
            case 'Add my Phone Number':
                return newPhoneNumber;
            case 'Add my Email':
                return newEmail;
            case 'Add my Height':
                return newHeight;
            case 'Add my Weight':
                return newWeight;
            default: return ''
        }
    }

    const stopSearch = () => {
        clearTimeout(doUpdate);
    };

    const newUsernameChanged = (value) => {
        setNewUsername(value);
        setDoUpdate(setTimeout(() => {
            baesappApi.post('/setInfo/updateUsername', { username: value })
                .then(res => {
                    setMyId(res.data);
                    setValidUsername(true);
                    setTimeout(() => {
                        setValidUsername(false);
                    }, 1000);
                    setInvalidUsername(false);
                })
                .catch(err => {
                    setInvalidUsername(err.message);
                    setValidUsername(false);
                })
        }, 350));
    };
    const newPreferredNameChanged = (value) => {
        setNewPreferredName(value);
        setDoUpdate(setTimeout(() => {
            baesappApi.post('/setInfo/updatePreferredName', { preferredName: value })
                .then(res => {
                    setMyId(res.data);
                    setPreferredNameUpdated(true);
                    setTimeout(() => {
                        setPreferredNameUpdated(false);
                    }, 1000);
                })
                .catch(err => {
                    console.log(err.message);
                })
        }, 350));
    };

    const saveField = (field, fieldValue) => {
        let toSaveFields = {};
        toSaveFields[field] = {
            public: false,
            value: fieldValue
        };

        baesappApi.post('/setInfo', { info: toSaveFields })
            .then(res => {
                setNewMyInfo(res.data)
                switch (field) {
                    case 'email':
                        setEmailUpdated(true);
                        setTimeout(() => {
                            setEmailUpdated(false);
                        }, 1000);
                        break;
                    case 'phone':
                        setPhoneUpdated(true);
                        setTimeout(() => {
                            setPhoneUpdated(false);
                        }, 1000);
                        break;
                    case 'height':
                        setHeightUpdated(true);
                        setTimeout(() => {
                            setHeightUpdated(false);
                        }, 1000);
                        break;
                    case 'weight':
                        setWeightUpdated(true);
                        setTimeout(() => {
                            setWeightUpdated(false);
                        }, 1000);
                        break;
                    default: return null;
                }
            })
            .catch(err => {
                console.log(err);
            })
    }

    function isValidPhoneNumber(phoneNumber) {
        // Check if the phone number contains at least one digit and a country code
        if (/^\+?\d{1,3}[\s-]?\d{1,14}[\s-]?\d{4,14}$/.test(phoneNumber)) {
            return true;
        } else {
            return false;
        }
    }

    function validHeight(num) {
        if (num === '') return true;
        const regex = /^(\d{1,3}([.,]\d{1,2})?|\d{1,2}([.,]\d{1,2})?)$/;
        return num >= 65 && num <= 250 && regex.test(num.toString());
    }
    function validWeight(num) {
        if (num === '') return true;
        const regex = /^(\d{1,3}([.,]\d{1,2})?|\d{1,2}([.,]\d{1,2})?)$/;
        return num >= 0 && num <= 250 && regex.test(num.toString());
    }

    const freeTextFieldChanged = (value, fieldName) => {
        stopSearch();
        switch (fieldName) {
            case 'Preferred Name':
                newPreferredNameChanged(value)
                break;
            case 'Add my Phone Number':
                value = value.replace(/[^\d+()\-.\s]/g, "");
                setNewPhoneNumber(value);
                if (isValidPhoneNumber(value)) {
                    setPhoneInvalid(false);
                    setDoUpdate(setTimeout(() => {
                        saveField('phone', value);
                    }, 350));
                } else {
                    setPhoneInvalid(true);
                }
                break;
            case 'Add my Email':
                setNewEmail(value.toLowerCase());
                if (validEmail(value)) {
                    setEmailInvalid(false);
                    setDoUpdate(setTimeout(() => {
                        saveField('email', value);
                    }, 350));
                } else {
                    setEmailInvalid(true);
                }
                break;
            case 'Add my Height':
                if (!retardMode) {
                    value = value.replace(/,/g, ".");
                    setNewHeight(value);
                    if (validHeight(value)) {
                        setHeightInvalid(false);
                        setDoUpdate(setTimeout(() => {
                            saveField('height', value * 100);
                        }, 350));
                    } else {
                        setHeightInvalid(true);
                    }
                }
            case 'Add my Weight':
                if (!retardMode) {
                    value = value.replace(/,/g, ".");
                    setNewWeight(value);
                    if (validWeight(value)) {
                        setWeightInvalid(false);
                        setDoUpdate(setTimeout(() => {
                            saveField('weight', value * 100);
                        }, 350));
                    } else {
                        setWeightInvalid(true);
                    }
                }
                break;
            default: return ''
        }
    };

    const getFieldColor = (field) => {
        let active = false;
        switch (field) {
            case 'Preferred Name':
                active = preferredNameUpdated;
                break;
            case 'Add my Email':
                active = emailUpdated;
                if (emailInvalid) return 'var(--error)'
                break;
            case 'Add my Phone Number':
                active = phoneUpdated;
                if (phoneInvalid) return 'var(--error)'
                break;
            case 'Add my Height':
                active = heightUpdated;
                if (heightInvalid) return 'var(--error)'
                break;
            case 'Add my Weight':
                active = weightUpdated;
                if (weightInvalid) return 'var(--error)'
                break;
            default: active = false;
        }
        if (active) {
            return 'var(--accent)';
        } else {
            return 'var(--text)';
        };
    }

    const getInputType = (field) => {
        switch (field) {
            case 'Add my Phone Number': return 'tel';
            case 'Add my Email': return 'email';
            case 'Add my Height':
            case 'Add my Weight': {
                return 'decimal';
            }
            default: return 'text';
        }
    };

    function toImperial(value, field) {
        if (field == 'Add my Height') {
            const inches = value / 2.54;
            const feet = Math.floor(inches / 12);
            const remainingInches = Math.round(inches % 12);
            return `${feet}'${remainingInches}"`;
        } else {
            const poundsPerKg = 2.20462;
            const pounds = (value * poundsPerKg).toFixed(2);
            return pounds;
        }
    }

    const getSwitch = (categoryName) => {
        switch (categoryName) {
            case 'PHONE':
            case 'EMAIL':
            case 'PERSONALITY': {
                return true;
            }
            default: return false;
        }
    }

    const fieldPublic = (categoryName) => {
        if (myInfo) {
            switch (categoryName) {
                case 'PHONE': return myInfo['phone'].public;
                case 'EMAIL': return myInfo['email'].public;
                case 'PERSONALITY': return myInfo['personalityTraits'].public;
                default: return false;
            }
        } else {
            return false;
        }
    };

    const privateButtonClicked = (categoryName) => {
        let newField = { public: !fieldPublic(categoryName) };
        let toSaveFields = {};

        switch (categoryName) {
            case 'PHONE': {
                newField['value'] = myInfo['phone'].value;
                toSaveFields['phone'] = newField;
                break;
            }
            case 'EMAIL': {
                newField['value'] = myInfo['email'].value;
                toSaveFields['email'] = newField;
                break;
            }
            case 'PERSONALITY': {
                newField['value'] = myInfo['personalityTraits'].value;
                toSaveFields['personalityTraits'] = newField;
                break;
            }
            default: return false;
        };

        baesappApi.post('/setInfo', { info: toSaveFields })
            .then(res => {
                setNewMyInfo(res.data);
            })
            .catch(err => {
                console.log(err);
            })
    };

    const renderCategory = (categoryName, fields) => {
        const fieldsList = fields.map(field => {
            let fieldValue = getValue(field);
            if (Array.isArray(fieldValue)) {
                let newFieldValue = '';
                fieldValue.map((value, index) => {
                    newFieldValue = newFieldValue + value + `${(index + 1 == fieldValue.length) ? '' : ', '}`;
                });
                fieldValue = newFieldValue;
            }
            const imageSrc = getImage(field);
            return (
                <button className="infoField" onClick={() => !isFreeTextField(field) && categoryClicked(categoryName)}>
                    <div className="flex alignCenter" style={{ height: '100%', width: '100%', textAlign: 'start' }}>
                        {imageSrc && <img style={{ maxWidth: '20px', maxHeight: '20px', marginRight: '15px', minWidth: '20px', objectFit: 'contain' }} src={imageSrc} />}
                        {
                            !isFreeTextField(field) ?
                                <span>{field}</span> :
                                <input
                                    className="infoInputBox"
                                    style={{
                                        color: getFieldColor(field),
                                        transitionDuration: '300ms'
                                    }}
                                    type={getInputType(field)}
                                    inputMode={((field == 'Add my Height') || (field == 'Add my Weight')) ? 'decimal' : ''}
                                    autoComplete={getInputType(field)}
                                    value={((field == 'Add my Height') || (field == 'Add my Weight')) ? retardMode ? toImperial(fieldValue, field) : getFieldStateValue(field) : getFieldStateValue(field)}
                                    pattern={field == 'Add my Phone Number' ? "\d{3}-\d{3}-\d{4}" : ((field == 'Add my Height') || (field == 'Add my Weight')) ? "\\d{1,3}(\\.\\d{1,2})?" : ''}
                                    oninput={field == 'Add my Phone Number' ? "this.value=this.value.replace(/[^0-9-]/g,'');" : ((field == 'Add my Height') || (field == 'Add my Weight')) ? "(?=.{1,5}$)\\d{1,3}(\\.\\d{1,2})?" : ''}
                                    onChange={e => freeTextFieldChanged(e.target.value, field)}
                                    placeholder={(fieldValue != '') ? (fieldValue != null) ? ((field == 'Add my Height') || (field == 'Add my Weight')) ? retardMode ? toImperial(fieldValue, field) : fieldValue : fieldValue : fieldValue : field}
                                />

                        }
                    </div>
                    {field == 'Add my Height' && <span onClick={() => setRetardMode(!retardMode)} style={{ color: 'gray' }}>{retardMode ? 'feet' : 'cm'}</span>}
                    {field == 'Add my Weight' && <span onClick={() => setRetardMode(!retardMode)} style={{ color: 'gray' }}>{retardMode ? 'lb' : 'kg'}</span>}
                    {
                        !isFreeTextField(field) &&
                        <div className="flex alignCenter" style={{ height: '100%' }}>
                            {
                                (fields.length > 1) ?
                                    (fieldValue != '') ?
                                        <span className="infoFieldValueRight">{fieldValue}</span> :
                                        <strong className="infoFieldValueRight">Add</strong> :
                                    null
                            }
                            <img style={{ height: '20%', marginLeft: '10px' }} src={arrowRight} />
                        </div>
                    }
                </button>
            );
        });
        return (
            <div className="flex vertical">
                <div className="infoCategory">
                    <span style={{ marginLeft: '15px' }}>
                        {categoryName}
                    </span>
                    {getSwitch(categoryName) && <div className="flex infoFieldPrivateSwitch">
                        <span className="infoFieldPrivateLabel" style={!fieldPublic(categoryName) ? { color: 'var(--accent)' } : {}}>private mode</span>
                        <Switch callback={() => privateButtonClicked(categoryName)} switchOn={!fieldPublic(categoryName)} switchBallText={fieldPublic(categoryName) ? 'OFF' : 'ON'} />
                    </div>}
                </div>
                {fieldsList}
            </div>
        );
    };

    const renderWebCam = () => {
        return (
            <div className="fixed blurBackground">
                <WebCam takePhoto={takePhoto} />
            </div>
        );
    }

    const renderMainContent = () => {
        return (
            <div
                className="fullScreen flex removeScrollBar vertical"
                ref={scrollElementRef}>
                <div style={{ minHeight: '70px' }}></div>
                <button
                    style={{
                        width: 'fit-content',
                        alignSelf: 'center'
                    }}
                    onClick={() => navigate('takePhoto')} className="flex">
                    <img className="infoProfileShot" src={`https://baesapp.com/media/${myId.profileShot}`} />
                </button>
                <div className="flex alignCenter" style={{ marginTop: '5px' }}>
                    <input
                        id="usernameInput"
                        className="infoInputBox"
                        value={newUsername}
                        onChange={e => newUsernameChanged(e.target.value)}
                        placeholder={myId?.username}
                        style={{
                            fontSize: '1.7em',
                            backgroundColor: 'var(--primaryGlass)',
                            padding: '3px 2em 3px 1ch',
                            borderRadius: '7px',
                            color: invalidUsername ? 'var(--error)' : validUsername ? 'var(--accent)' : 'var(--text)',
                            transitionDuration: '300ms'
                        }}
                    >
                    </input>
                    <img onClick={() => {
                        document.getElementById('usernameInput').focus();
                    }} style={{ height: '1.3em', marginLeft: '-2em' }} src={darkModeEnabled ? editIconDark : editIcon} />
                </div>
                <div className="flex vertical">
                    <div className="infoCategory">
                        <span style={{ marginLeft: '15px' }}>
                            PROFILE SHOT
                        </span>
                    </div>
                    <div className="infoField" >
                        <div className="flex alignCenter" style={{ height: '100%', textAlign: 'start' }}>
                            <span>Allow facial recognition</span>
                        </div>
                        {mySearch && <Switch callback={toggleFacialRegognitionAllowed} switchOn={facialRecognitionAllowed} switchBallText={facialRecognitionAllowed ? 'YES' : 'NO'} />}
                    </div>
                </div>
                <div className="flex vertical">
                    <div className="infoCategory">
                        <span style={{ marginLeft: '15px' }}>
                            MY ID
                        </span>
                    </div>
                    <div className="infoField" >
                        <div className="flex alignCenter" style={{ height: '100%', color: 'var(--secondary)', fontWeight: '600', textAlign: 'start' }}>
                            <span>{getValue('First Name') + ' ' + getValue('Last Name')}</span>
                        </div>
                    </div>
                    <div className="infoField" >
                        <div className="flex alignCenter" style={{ height: '100%', color: 'var(--secondary)', fontWeight: '600', textAlign: 'start' }}>
                            <span>{getValue('Account Email')}</span>
                        </div>
                    </div>
                    <div className="infoField" >
                        <div className="flex alignCenter" style={{ height: '100%', color: 'var(--secondary)', fontWeight: '600', textAlign: 'start' }}>
                            <img style={{ maxWidth: '20px', maxHeight: '20px', marginRight: '15px', minWidth: '20px', objectFit: 'contain' }} src={getImage('SEX')} />
                            <span>{getValue('SEX')}</span>
                        </div>
                    </div>
                    <div className="infoField" >
                        <div className="flex alignCenter" style={{ height: '100%', color: 'var(--secondary)', fontWeight: '600', textAlign: 'start' }}>
                            <img style={{ maxWidth: '20px', maxHeight: '20px', marginRight: '15px', minWidth: '20px', objectFit: 'contain' }} src={getImage('DOB')} />
                            <span>{getValue('DOB')}</span>
                        </div>
                    </div>
                </div>
                {renderCategory('PREFERRED NAME', ['Preferred Name'])}
                {renderCategory('PHONE', ['Add my Phone Number'])}
                {renderCategory('EMAIL', ['Add my Email'])}
                {renderCategory('GENERAL', ['Marital status', 'Employment status', 'Occupation', 'Job skills', 'Languages spoken', 'Degrees', 'Highest education levels'])}
                {renderCategory('MEDICAL', ['Blood type', 'Allergies', 'Current Medications', 'Medical Conditions', 'Emergency contacts'])}
                {renderCategory('PHYSICAL ATTRIBUTES', ['Add my Height', 'Add my Weight', 'Body type', 'Hair color', 'Hair type', 'Eye color', 'Skin tone', 'Dexterity'])}
                {renderCategory('ADDRESS', ['Address 1', 'Address 2', 'City', 'Province / State', 'ZIP', 'Country'])}
                {renderCategory('PERSONALITY', ['Add my Personality traits'])}
                {renderCategory('FAVOURITES', ['Color', 'Animal', 'Music genre', 'Movie', 'TV show', 'Book', 'Author', 'Hobby'])}
                <div style={{ minHeight: '70px' }}></div>
            </div>
        );
    }

    return (
        <>
            {renderMainContent()}
            <Routes>
                <Route path="physicalAttributes" element={<PhysicalAttributes />} />
                <Route path="personalityAttributes" element={<PersonalityAttributes />} />
                <Route path="favourites" element={<Favourites />} />
                <Route path="medical" element={<Medical />} />
                <Route path="form" element={<Form />} />
                <Route path="address" element={<Address />} />
                <Route path="takePhoto" element={renderWebCam()} />
            </Routes>
        </>
    );
};

const mapStateToProps = state => {
    return ({
        myInfo: state.myInfo,
        myAuthentication: state.myAuthentication,
        myId: state.myId,
        darkModeEnabled: state.darkModeEnabled,
        mySearch: state.mySearch
    });
};

export default connect(mapStateToProps, { setNewMyInfo, setMyId, setTopBarOffset })(Info);