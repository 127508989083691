const chatMemberIdsReducer = (chatMemberIds = [], action) => {
    switch(action.type){
        case 'CHAT_MEMBER_IDS_SET':
            return action.payload;
            case 'CLEAR_ALL':
                return [];
        default: 
            return chatMemberIds;
    }
};

export default chatMemberIdsReducer;
