const objectify = (array, key) => {
    let arrayObjectified = {};

    array.map(item => {
        arrayObjectified[item[key]] = item;
    });

    return arrayObjectified;
};

export default objectify;