import decryptMessage from "./decryptMessage";
import setLocalObject from "./setLocalObject";
import getLocalToObject from "./getLocalToObject";

const deleteMessages = (deletedMessages) => {
    let clientChats = getLocalToObject('chats');
    for(const key in deletedMessages){
        const messagesToDelete = deletedMessages[key];
        if(clientChats[key]){
            clientChats[key].conversation.messages = clientChats[key].conversation.messages.filter(message => !messagesToDelete.includes(message._id));
        }
    };
    setLocalObject('chats', clientChats);
};

export default deleteMessages;