import React, { useEffect, useState, useRef } from "react";

import { connect } from 'react-redux';

import Hammer from 'hammerjs';

import { Routes, Route, useNavigate } from 'react-router-dom';

import baesappApi from '../../../apis/baesappApi';
import MatcherControls from "./MatcherControls";
import MatcherSwipeHistory from "./MatcherSwipeHistory";
import MatcherMyCard from "./MatcherMyCard";

import like from '../../../assets/like.png';
import nope from '../../../assets/nope.png';
import eye from '../../../assets/eye.png';
import eyeDark from '../../../assets/eyeDark.png';

const Matcher = ({
    my_id,
    darkModeEnabled
}) => {

    const [members, setMembers] = useState([]);
    const [memberDetails, setMemberDetails] = useState(false);

    const navigate = useNavigate();
    const path = window.location.pathname;

    let removedMembers = useRef([]);
    let nextMembers = useRef([]);


    let tinderContainer;
    let allCards = [];
    var moveOutWidth = document.body.clientWidth;

    useEffect(() => {
        baesappApi.post('/matcher/getMembers', { filterItems: [] })
            .then(res => {
                setMembers(res.data);
                if (res.data.length) {
                    window.localStorage.setItem('matcherLastItem', res.data[res.data.length - 1]._id);
                };
            })
            .catch(err => {
                console.log('err');
            })
    }, []);

    useEffect(() => {
        if (members.length) {
            tinderContainer = document.querySelector('.tinder');
            allCards = document.querySelectorAll('.tinder--card');
            const nope = document.getElementById('nope');
            const love = document.getElementById('love');

            initCards();

            var nopeListener = createButtonListener(false);
            var loveListener = createButtonListener(true);

            nope.addEventListener('click', nopeListener);
            love.addEventListener('click', loveListener);

            allCards.forEach(function (el) {
                var hammertime = new Hammer(el);

                hammertime.on('pan', function (event) {
                    el.classList.add('moving');
                });


                hammertime.on('pan', function (event) {
                    if (event.deltaX === 0) return;
                    if (event.center.x === 0 && event.center.y === 0) return;

                    tinderContainer.classList.toggle('tinder_love', event.deltaX > 0);
                    tinderContainer.classList.toggle('tinder_nope', event.deltaX < 0);

                    var xMulti = event.deltaX * 0.03;
                    var yMulti = event.deltaY / 80;
                    var rotate = xMulti * yMulti;

                    event.target.style.transform = 'translate(' + event.deltaX + 'px, ' + event.deltaY + 'px) rotate(' + rotate + 'deg)';
                });

                hammertime.on('panend', function (event) {
                    el.classList.remove('moving');
                    tinderContainer.classList.remove('tinder_love');
                    tinderContainer.classList.remove('tinder_nope');

                    var keep = Math.abs(event.deltaX) < 80 || Math.abs(event.velocityX) < 0.5;

                    event.target.classList.toggle('removed', !keep);

                    if (keep) {
                        event.target.style.transform = '';
                    } else {
                        var endX = Math.max(Math.abs(event.velocityX) * moveOutWidth, moveOutWidth);
                        var toX = event.deltaX > 0 ? endX : -endX;
                        var endY = Math.abs(event.velocityY) * moveOutWidth;
                        var toY = event.deltaY > 0 ? endY : -endY;
                        var xMulti = event.deltaX * 0.03;
                        var yMulti = event.deltaY / 80;
                        var rotate = xMulti * yMulti;

                        memberSwiped(event);

                        event.target.style.transform = 'translate(' + toX + 'px, ' + (toY + event.deltaY) + 'px) rotate(' + rotate + 'deg)';
                        event.target.style.opacity = 0;
                        initCards();
                    }
                });
            });
        };
    }, [members, memberDetails]);

    const memberSwiped = (event) => {
        if (event.deltaX > 0) memberLiked();
        if (event.deltaX < 0) memberNoped();
    };

    const handleCardAction = (index) => {
        console.log(window.localStorage.getItem('matcherLastItem'));
        console.log(index);
        if ((index == 9) && window.localStorage.getItem('matcherLastItem')) {
            baesappApi.post('/matcher/getMembers', { filterItems: [], lastItem: window.localStorage.getItem('matcherLastItem') })
                .then(res => {
                    nextMembers.current = res.data;
                    if (res.data.length) {
                        window.localStorage.setItem('matcherLastItem', res.data[res.data.length - 1]._id);
                    };
                })
                .catch(err => {
                    console.log('err');
                })
        };
        if (index == 14) {
            console.log('incoroprated next members');
            removedMembers.current = [];
            setMembers([...members.slice(-5), ...nextMembers.current]);
        };
    }

    const memberLiked = () => {
        const currentCardIndex = removedMembers.current.length;
        removedMembers.current = [...removedMembers.current.slice(), currentCardIndex];
        handleCardAction(currentCardIndex);
    };

    const memberNoped = () => {
        const currentCardIndex = removedMembers.current.length;
        removedMembers.current = [...removedMembers.current.slice(), currentCardIndex];
        handleCardAction(currentCardIndex);
    };

    const flipCard = () => {
        if (memberDetails) {
            setMemberDetails(false);
        } else {
            const currentCardIndex = removedMembers.current.length;
            let currentMember = members[currentCardIndex];
            setMemberDetails(currentMember);
        }
    };

    function initCards(card, index) {
        var newCards = document.querySelectorAll('.tinder--card:not(.removed)');

        newCards.forEach(function (card, index) {
            card.style.zIndex = allCards.length - index;
        });

        tinderContainer.classList.add('loaded');
    }

    function createButtonListener(love) {
        return function (event) {
            var cards = document.querySelectorAll('.tinder--card:not(.removed)');
            var moveOutWidth = document.body.clientWidth * 1.5;

            if (!cards.length) return false;

            var card = cards[0];

            card.classList.add('removed');

            if (love) {
                card.style.transform = 'translate(' + moveOutWidth + 'px, -100px) rotate(-30deg)';
                memberLiked();
            } else {
                card.style.transform = 'translate(-' + moveOutWidth + 'px, -100px) rotate(30deg)';
                memberNoped();
            }

            initCards();

            event.preventDefault();
        };
    }

    const cardsList = members.map((member, index) => {

        return (
            <div id={member._id} style={removedMembers.current.includes(index) ? { transform: 'translate(-' + moveOutWidth * 2 + 'px, -100px) rotate(30deg)' } : {}} class={`${removedMembers.current.includes(index) ? 'removed' : ''} tinder--card`}>
                <img src={`https://baesapp.com/media/${member.memberId.profileShot}`} />
                <div className="matcherUsername">{member.memberId.preferredName}</div>
            </div>
        );
    })


    return (
        <>
            <div class="tinder">
                <div class="tinder--status">
                    <div style={{ color: 'var(--accent)'}} class="fa fa-remove-text">NOPE</div>
                    <div style={{ color: 'var(--error)'}} class="fa fa-heart-text">LIKE</div>
                </div>

                <div class="tinder--cards">
                    {cardsList}
                </div>

                <div class="tinder--buttons">
                    <button id="nope"><div class="fa fa-remove"><img style={{height: '60%'}} src={nope}></img></div></button>
                    <button onClick={() => flipCard()}><div class="matcherFlipButton"><img style={{height: '60%'}} src={darkModeEnabled ? eyeDark : eye}></img></div></button>
                    <button id="love"><div class="fa fa-heart"><img style={{height: '60%'}} src={like}></img></div></button>
                </div>

                {memberDetails &&
                    <div className="matcherDetailsCard">
                        <button onClick={() => flipCard()}><div class="matcherFlipButton"></div></button>
                    </div>
                }
            </div>
            <Routes>
                <Route path="/controls" element={<MatcherControls />} />
                <Route path="/swipeHistory" element={<MatcherSwipeHistory />} />
                <Route path="/myCard" element={<MatcherMyCard />} />
            </Routes>
        </>
    );

};


const mapStateToProps = state => {
    return ({
        my_id: state.my_id,
        darkModeEnabled: state.darkModeEnabled
    });
};

export default connect(mapStateToProps, {})(Matcher);
