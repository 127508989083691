import React, { useEffect } from "react";

import { connect } from 'react-redux';
import { Routes, Route } from 'react-router-dom';

import BottomNav from "./BottomNav";
import TopBar from "./TopBar";

import Chat from './Chat';
import Media from './Media';
import Search from './Search';
import Info from './Info';
import Wallet from "./Wallet";
import Events from './Events';
import Doorbell from './Doorbell';
import OpenChat from "./OpenChat";
import Matcher from './Matcher';
import Games from './Games';
import { setVisitMember } from "../../redux/actions";
import Core from "./Core";

const Home = ({
    setVisitMember
}) => {

    useEffect(() => {
        console.log('resvis');
        setVisitMember(false);
    }, []);

    return (
        <>
            <Routes>
                <Route path="/chat/*" element={<Chat />} />
                <Route path='/media/*' element={<Media />} />
                <Route path='/search/*' element={<Search />} />
                <Route path='/info/*' element={<Info />} />
                <Route path='/wallet/*' element={<Wallet />} />
                <Route path='/core/*' element={<Core />} />
                <Route path='/events/*' element={<Events />} />
                <Route path="/matcher/*" element={<Matcher />} />
                <Route path='/doorbell/*' element={<Doorbell />} />
                <Route path='/openChat/*' element={<OpenChat />} />
                <Route path='/games/*' element={<Games />} />
            </Routes>
            <TopBar/>
            <BottomNav />
        </>
    );
};

const mapStateToProps = state => {
    return ({});
};

export default connect(mapStateToProps, {
    setVisitMember
})(Home);
